import React from 'react';
import { AuctionWithoutTimerArr } from '../../constants/AuctionWithoutTimerArr';
import { AuctionTimeHourFormat } from '../../constants/AuctionTime';

export const prepareAuctionTimeLabel = (diffTime, item, isShowTimeOption = false) => {
  if (item?.timeOption === null) {
    return null;
  }

  if (AuctionWithoutTimerArr.includes(item?.status) && isShowTimeOption) {
    return AuctionTimeHourFormat[item?.timeOption];
  }
  if (isFinished(diffTime)) {
    return 'Finished';
  }

  return diffTime.split('').map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span className="search-time__char" key={index}>
      {item}
    </span>
  ));
};

export const isFinished = diffTime => {
  return diffTime === null || diffTime === '00:00';
};
