import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import FormCurrencyInputField from '../../common/components/formFields/FormCurrencyInputField';
import { AuctionTimeName } from '../../constants/AuctionTime';
import { AuctionStatusType, AuctionStatusTypeName } from '../../constants/AuctionStatusType';
import FormGroupRadioField from '../../common/components/formFields/FormGroupRadioField';
import { AuctionPublishTypeName } from '../../constants/AuctionPublishType';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import CommonButton from '../../common/components/CommonButton';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import { fetchOrganizationGroup } from '../AddVehicleActions';
import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';
import { AuctionStatus } from '../../constants/AuctionStatus';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import FormCheckboxField from '../../common/components/formFields/FormCheckboxField';

const auctionTimes = constantToSelect(AuctionTimeName);
const auctionTypes = constantToSelect(AuctionStatusTypeName);
const auctionPublishTypes = constantToSelect(AuctionPublishTypeName);
const dueInDateTypes = constantToSelect(DueInDateTypeName);

const { PRIMARY, DISABLED } = CommonButtonVariants;

const ConfirmLaunchForm = ({ form, prevStep, item }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationGroup = useSelector(state => state.addVehicle.get('organizationGroup'));
  const confirmLaunchValidationError = useSelector(state =>
    state.addVehicle.get('confirmLaunchValidationError'),
  );

  useEffect(() => {
    dispatch(fetchOrganizationGroup());
  }, [dispatch]);
  return (
    <Form autoComplete="off" className="text-left">
      <h3 className="text-uppercase font-weight-bold text-center">Confirm this launch</h3>
      {item?.isFreeRepublishAvailable === false && (
        <p className="confirm-launch-form__limit-text">
          You have reached a limit of 3 launches within one transaction. By clicking ok you
          agree to a new launch fee.
        </p>
      )}
      <p className="text-center font-weight-bold">
        You are launching the vehicle with the following settings:
      </p>
      <Row>
        <Col md={6}>
          <FormSelectField
            name="type"
            label="Choose a status"
            options={auctionTypes}
            disabled={!item}
          />

          {form?.values?.type?.value === AuctionStatusType.MINIMUM_PRICE && (
            <>
              <FormCurrencyInputField
                name="minimumPriceInPence"
                label="Minimum Offer"
                maxLength={14}
                invalid={
                  !!form.errors.minimumPriceInPence ||
                  confirmLaunchValidationError?.minimumPriceInPence ||
                  confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm
                }
                disabled={!item}
              />
              <CommonErrorLabel
                value={
                  confirmLaunchValidationError?.minimumPriceInPence ||
                  (confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm &&
                    'Minimum Offer must be greater than 0')
                }
              />
            </>
          )}
          {form?.values?.type?.value === AuctionStatusType.PHYSICAL && (
            <>
              <FormCurrencyInputField
                name="autoAcceptPriceInPence"
                label="Reserve Price"
                maxLength={14}
                invalid={
                  !!form.errors.autoAcceptPriceInPence ||
                  confirmLaunchValidationError?.autoAcceptPriceInPence ||
                  confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm
                }
                disabled={!item}
              />
              <CommonErrorLabel
                value={
                  confirmLaunchValidationError?.autoAcceptPriceInPence ||
                  (confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm &&
                    'Reserve Price must be greater than 0')
                }
              />
            </>
          )}

          {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL && (
            <FormSelectField
              name="tryingToDealDeliveryDateType"
              label="Due in date"
              options={dueInDateTypes}
              disabled={!item}
            />
          )}

          {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL &&
            form?.values?.tryingToDealDeliveryDateType?.value ===
              DueInDateType.CUSTOM_DATE && (
              <>
                <FormDatePickerField
                  label="Delivery date"
                  name="tryingToDealDeliveryDate"
                  invalid={
                    !!form.errors.tryingToDealDeliveryDate ||
                    confirmLaunchValidationError?.tryingToDealDeliveryDate
                  }
                  readOnly
                  disabled={!item || isLoading}
                  isPastDateDisabled
                />
                <CommonErrorLabel
                  value={confirmLaunchValidationError?.tryingToDealDeliveryDate}
                />
              </>
            )}
        </Col>
        <Col md={6}>
          <FormSelectField
            name="timeOption"
            label="Choose a time"
            options={auctionTimes}
            disabled={!item}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="pt-3">
          {organizationGroup && (
            <FormGroupRadioField
              name="publishTarget"
              radios={auctionPublishTypes}
              classNames="auction-publish-radios"
              disabled={!item}
            />
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <CommonButton
            label="Cancel"
            handleClick={prevStep}
            color={AuctionStatus.CANCELLED}
            disabled={isLoading || !item}
          />
        </Col>
        <Col md={6} className="d-flex flex-column align-items-end">
          <CommonButton
            type="submit"
            label="Ok"
            className="mb-3"
            variant={isLoading || !item ? DISABLED : PRIMARY}
            disabled={isLoading || !item}
          />
        </Col>
      </Row>
    </Form>
  );
};

ConfirmLaunchForm.defaultProps = {
  item: null,
};

ConfirmLaunchForm.propTypes = {
  form: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  item: PropTypes.object,
};

export default ConfirmLaunchForm;
