export const checkObligatoryPhotos = photos => {
  if (photos.length > 6) {
    const loadedPhotos = [];
    photos.splice(7);
    photos.map(el => loadedPhotos.push(el));
    if (loadedPhotos.length === 7) {
      return true;
    }
    return false;
  }
  return false;
};
