import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeenSlider } from 'keen-slider/react';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'keen-slider/keen-slider.min.css';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { BigPlayButton, ControlBar, Player } from 'video-react';
import { prepareDamagesDescription } from 'common/helpers/prepareDamagesDescription';
import cameraIcon from '../../assets/img/cameraIcon.svg';
import warningIcon from '../../assets/img/Ripple-2.7s-211px.gif';
import iconPoint from '../../assets/img/grupa11.png';
import VehicleImagesPopupSlider from './VehicleImagesPopupSlider';
import { checkInteriorPerspective } from '../../addvehicle/helpers/checkInteriorPerspective';
import { PhotoPerspective } from '../../constants/PhotoPerspective';

const VehicleImagesSlider = ({ photos, videos }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showPopupSlider, setShowPopupSlider] = useState(false);
  const [showDamagesPopupSlider, setShowDamagesPopupSlider] = useState(false);
  const [isInteriorPhoto, setIsInteriorPhoto] = useState(false);
  const [damages, setDamages] = useState([]);
  const [clickedPhoto, setClickedPhoto] = useState(0);
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });
  const [secondSliderRef, secondSlider] = useKeenSlider({
    initial: 0,
    slidesPerView: 5,
  });
  useEffect(() => {
    if (slider) {
      slider.resize();
    }
    if (secondSlider) {
      secondSlider.resize();
    }
  }, [isLoading, slider, secondSlider, photos]);

  const filteredRequiredPhotos = photos?.filter(item => item?.id);

  return (
    <div className="summary__slider-wrapper">
      <div ref={sliderRef} className="keen-slider summary__slider">
        {photos.length > 0
          ? photos
              .filter(item => item.fileUrl)
              .map((item, idx) => (
                <div
                  key={item?.id}
                  className="keen-slider__slide summary__slider_image_container"
                >
                  <button
                    className="summary__slide-button"
                    type="button"
                    onClick={() => {
                      setClickedPhoto(idx);
                      setShowPopupSlider(true);
                    }}
                  >
                    <img className="summary__slide" src={item?.fileUrl} alt="Vehicle" />
                  </button>
                  {item.perspective !== PhotoPerspective.MASTER && !isEmpty(item?.damages) && (
                    <>
                      <button
                        type="button"
                        className="photo_gallery_damage_button"
                        onClick={() => {
                          setDamages(item?.damages);
                          setShowDamagesPopupSlider(true);
                          setIsInteriorPhoto(checkInteriorPerspective(item.perspective));
                        }}
                      >
                        <img
                          className="damages__warning-icon"
                          src={warningIcon}
                          alt="Warning icon"
                        />
                      </button>
                      <div className="photo_gallery_damage_info">
                        <span>
                          Tap <img src={iconPoint} alt="tab icon" /> to see{' '}
                          {prepareDamagesDescription(item?.damages)}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))
          : null}

        {videos.length > 0
          ? videos.map(item => (
              <div
                key={item.id}
                className="keen-slider__slide summary__video_player summary__slider_image_container"
              >
                <Player
                  src={item?.fileUrl}
                  className="summary__slider_image_container__player"
                >
                  <BigPlayButton position="center" />
                  <ControlBar />
                </Player>
              </div>
            ))
          : null}

        <div className="summary__photo-counter-wrapper">
          <img src={cameraIcon} alt="Camera icon" />
          <span className="summary__photo-counter">{filteredRequiredPhotos?.length}</span>
        </div>
        {slider && (
          <>
            <button
              type="button"
              onClick={e =>
                e.stopPropagation() ||
                slider.prev() ||
                secondSlider.moveToSlideRelative(
                  (((currentSlide - 1) % slider.details().size) + slider.details().size) %
                    slider.details().size,
                )
              }
              className="summary__slider-arrow-button summary__slider-arrow-button--left"
            >
              <i className="fas fa-chevron-left" />
            </button>

            <button
              type="button"
              onClick={e =>
                e.stopPropagation() ||
                slider.next() ||
                secondSlider.moveToSlideRelative((currentSlide + 1) % slider.details().size)
              }
              className="summary__slider-arrow-button summary__slider-arrow-button--right"
            >
              <i className="fas fa-chevron-right" />
            </button>
          </>
        )}
        {slider && (
          <div className="summary__slider-dots-wrapper">
            {[...Array(slider.details().size).keys()].map(item => (
              <button
                aria-label="Switch"
                type="button"
                key={item}
                onClick={() => {
                  slider.moveToSlideRelative(item, true);
                  secondSlider.moveToSlideRelative(item, true);
                }}
                className={`summary__slider-dot ${
                  currentSlide === item ? 'summary__slider-dot--active' : null
                }`}
              />
            ))}
          </div>
        )}
      </div>
      <div
        ref={secondSliderRef}
        className={`keen-slider summary__thumbnails-slider ${
          (photos?.length || videos?.length) && photos?.length + videos?.length < 5
            ? `summary__thumbnails-slider-${photos.length + videos?.length}`
            : ''
        }`}
      >
        {filteredRequiredPhotos?.concat(videos).length &&
          photos
            ?.filter(item => item?.id)
            ?.concat(videos)
            .map((item, idx) =>
              item?.fileUrl ? (
                <div
                  key={item?.id}
                  className="keen-slider__slide summary__slider_image_container"
                >
                  <button
                    className="summary__slide-button"
                    type="button"
                    onClick={() => {
                      slider.moveToSlideRelative(idx, true);
                      secondSlider.moveToSlideRelative(idx, true);
                    }}
                  >
                    <img
                      alt="Thumbnail slider item"
                      className={`summary__slide ${
                        idx === currentSlide
                          ? 'summary__slide-active'
                          : 'summary__slide-inactive'
                      }`}
                      src={item?.type === 'video' ? item?.previewUrl : item?.fileUrl}
                    />
                  </button>
                </div>
              ) : null,
            )}
        {secondSlider && (
          <>
            <button
              type="button"
              onClick={e =>
                e.stopPropagation() ||
                secondSlider.moveToSlideRelative(
                  (((currentSlide - 1) % slider.details().size) + slider.details().size) %
                    slider.details().size,
                ) ||
                slider.prev()
              }
              className="summary__slider-arrow-button summary__slider-arrow-button--left"
            >
              <i className="fas fa-chevron-left" />
            </button>

            <button
              type="button"
              onClick={e =>
                e.stopPropagation() ||
                secondSlider.moveToSlideRelative((currentSlide + 1) % slider.details().size) ||
                slider.next()
              }
              className="summary__slider-arrow-button summary__slider-arrow-button--right"
            >
              <i className="fas fa-chevron-right" />
            </button>
          </>
        )}
      </div>
      <SweetAlert
        custom
        show={showPopupSlider}
        onCancel={() => {
          setShowPopupSlider(false);
        }}
        onConfirm={() => {}}
        title=""
        customButtons={
          <>
            <button
              type="button"
              className="photo_gallery_close_button"
              onClick={() => setShowPopupSlider(false)}
            >
              X
            </button>
          </>
        }
        customClass="photo_gallery_popup"
      >
        <VehicleImagesPopupSlider
          photos={photos}
          videos={videos}
          initial={clickedPhoto}
          onDamageClick={(damages, perspective) => {
            setIsInteriorPhoto(checkInteriorPerspective(perspective));
            setShowDamagesPopupSlider(true);
            setDamages(damages);
          }}
        />
      </SweetAlert>
      <SweetAlert
        custom
        show={showDamagesPopupSlider}
        onCancel={() => {
          setShowDamagesPopupSlider(false);
        }}
        onConfirm={() => {}}
        title=""
        customButtons={
          <>
            <button
              type="button"
              className="photo_gallery_return_button"
              onClick={() => setShowDamagesPopupSlider(false)}
            >
              <i className="fas fa-chevron-left" />
            </button>
          </>
        }
        customClass="photo_gallery_popup"
      >
        <VehicleImagesPopupSlider
          photos={damages}
          initial={0}
          showImageTitle={!isInteriorPhoto}
        />
      </SweetAlert>
    </div>
  );
};

VehicleImagesSlider.propTypes = {
  photos: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
};

export default VehicleImagesSlider;
