import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const FormPill = ({ groupName, feature, isFeatureSelected, deleteCallback, handleClick }) => {
  return (
    <label
      className={`mr-3 noselect add-details__label ${
        isFeatureSelected ? 'add-details__label--loaded' : 'add-details__label--not-loaded'
      }  add-details__label-${
        typeof isFeatureSelected === 'function' ? isFeatureSelected(feature) : false
      }`}
    >
      <Field
        type="checkbox"
        name={groupName}
        value={feature}
        onClick={() => {
          deleteCallback(feature);
          handleClick();
        }}
      />
      {`${feature}`}
    </label>
  );
};

export default FormPill;

FormPill.defaultProps = {
  deleteCallback: () => {},
  handleClick: () => {},
};

FormPill.propTypes = {
  groupName: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
  isFeatureSelected: PropTypes.func.isRequired,
  deleteCallback: PropTypes.func,
  handleClick: PropTypes.func,
};
