import { useEffect, useRef } from 'react';

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    // eslint-disable-next-line no-return-assign
    return () => (isMounted.current = false);
  }, []);

  return isMounted;
}
