import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import RouterPaths from '../constants/RouterPaths';

const Hero = () => {
  return (
    <div className="hero">
      <Container className="hero-content">
        <h2 className="hero-title">
          BUY, SELL & UNDERWRITE TRADE VEHICLES FROM THE UK&apos;S MOST TRUSTED MOTOR DEALERS
        </h2>
        <p className="hero-description" />
        <h3 className="hero-subtitle">Try it now!</h3>
        <Row className="hero-register-box">
          <Col lg="2" md="3" sm="4" className="hero-left">
            <NavLink
              to={RouterPaths.REGISTER}
              className="auth-button auth-button--active"
              activeClassName="auth-button--active"
            >
              Register
            </NavLink>
          </Col>
          <Col xs="1" className="hero-center">
            <h3 className="hero-or-text">Or</h3>
          </Col>
          <Col lg="2" md="3" sm="4" className="hero-right">
            <NavLink
              to={RouterPaths.LOGIN}
              className="auth-button"
              activeClassName="auth-button--active"
            >
              Login
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
