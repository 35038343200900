import * as Yup from 'yup';
import { isAllOptionSelected } from '../utils/yearsRangeOptions';

export default () => {
  return Yup.object().shape({
    yearMinValue: Yup.string()
      .nullable()
      .when('allRangeOfYears', {
        is: value => !isAllOptionSelected(value),
        then: Yup.string().required('Field is required'),
      }),
    yearMaxValue: Yup.string()
      .nullable()
      .when('allRangeOfYears', {
        is: value => !isAllOptionSelected(value),
        then: Yup.string().required('Field is required'),
      }),
  });
};
