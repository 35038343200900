import { fromJS } from 'immutable';

const initialData = fromJS({
  myProfileDetails: {},
  tokenInfo: null,
});

export const FETCH_MY_PROFILE_SUCCESS = 'FETCH_MY_PROFILE_SUCCESS';
export const GET_TOKEN_INFO_SUCCESS = 'GET_TOKEN_INFO_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case FETCH_MY_PROFILE_SUCCESS:
      return state.merge({
        myProfileDetails: payload.myProfileDetails,
      });
    case GET_TOKEN_INFO_SUCCESS:
      return state.merge({
        tokenInfo: payload,
      });
    default:
      return state;
  }
};
