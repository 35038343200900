import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    email: Yup.string()
      .required('Field is required')
      .email('Email has the wrong format'),
    message: Yup.string()
      .required('Field is required')
      .max(1000, 'Message must be between 1 and 1000 characters'),
  });
};
