import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { reverse } from 'named-urls';
import _ from 'lodash';
import MyVehiclesHeader from './MyVehiclesHeader';
import {
  clearMyAuctions,
  getAuction,
  handleAuctionEvent,
  searchMyAuctions,
  setVehicleFilter,
} from './MyVehiclesActions';
import MyVehiclesItem from './MyVehiclesItem';
import InfinityLoader from '../common/components/InfinityLoader';
import { AUCTION_DELETED, SSE_SELLER, SSEClient } from '../api/SSEClient';
import MyVehicleSearch from './MyVehicleSearch';
import RouterPaths from '../constants/RouterPaths';
import { MY_VEHICLES_REFRESH_SUCCESS } from './MyVehiclesReducer';

const sseClient = new SSEClient(SSE_SELLER);

const MyVehiclesPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const itemsRef = useRef({});
  const [scrollTo, setScrollTo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [auctionsIds, setAuctionsIds] = useState([]);

  const status = useSelector(state => state.myVehicles.get('filter'));
  const hasMore = useSelector(state => state.myVehicles.get('hasMore'));
  const searchItems = useSelector(state => state.myVehicles.get('items').toJS());
  const searchTotalItems = useSelector(state => state.myVehicles.get('totalItems'));
  const auctionsTimestamp = useSelector(state => state.myVehicles.get('timeStamp'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const [hasAccess, setHasAccess] = useState(false);
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    status,
    search: '',
  });

  const updateSearchItems = newItem => {
    dispatch({
      type: MY_VEHICLES_REFRESH_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  useEffect(() => {
    if (!isSeller) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isBuyer, isSeller, history]);

  useEffect(() => {
    sseClient.listen(auctionsIds, response => {
      dispatch(
        handleAuctionEvent(response, () => {
          if (response.type === AUCTION_DELETED) {
            return dispatch(
              searchMyAuctions(searchParams, auctionsIds => {
                setAuctionsIds(prev => prev.concat(auctionsIds));
                setLoaded(true);
              }),
            );
          }
          return dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          );
        }),
      );
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionsIds, dispatch, searchParams]);

  const scrollToItem = () => {
    if (!scrollTo) return;
    if (itemsRef.current[scrollTo]) {
      itemsRef.current[scrollTo].scrollIntoView({ behavior: 'auto', block: 'center' });
      setScrollTo(null);
    }
  };

  useEffect(() => {
    if (location.state && location.state.offset && location.state.auctionId) {
      setSearchParams({
        offset: _.get(location, 'state.offset', 0) + 1,
        status,
        search: '',
      });
      setScrollTo(location.state.auctionId);
      history.replace({ ...history.location, state: {} });
      return;
    }
    dispatch(
      searchMyAuctions(searchParams, auctionsIds => {
        setAuctionsIds(auctionsIds);
        setLoaded(true);
        scrollToItem();
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  const loadData = () => {
    setSearchParams(prev => ({
      ...prev,
      offset: prev.offset + 1,
    }));
  };

  const clearResults = () => {
    dispatch(clearMyAuctions());
    setAuctionsIds([]);
    setLoaded(false);
  };

  const handleFilter = status => {
    if (searchParams.status !== status) {
      clearResults();
      dispatch(setVehicleFilter(status));
      setSearchParams(prev => ({
        ...prev,
        offset: 0,
        status,
      }));
    }
  };

  const setRef = (ref, id) => {
    itemsRef.current[id] = ref;
  };

  const handleSearch = values => {
    if (searchParams.search !== values.search) {
      clearResults();
      setSearchParams(prev => ({
        ...prev,
        offset: 0,
        search: values.search,
      }));
    }
  };

  return (
    <>
      {hasAccess && (
        <>
          <MyVehiclesHeader handlerChange={handleFilter} />
          <MyVehicleSearch handleSearch={handleSearch} />
          {loaded && (
            <>
              {searchTotalItems > 0 ? (
                <InfiniteScroll
                  next={loadData}
                  hasMore={hasMore}
                  loader={<InfinityLoader />}
                  dataLength={searchItems.length}
                >
                  {searchItems.map(item => (
                    <MyVehiclesItem
                      getRef={el => setRef(el, item.id)}
                      onClickItem={() =>
                        history.push({
                          pathname: reverse(RouterPaths.MY_VEHICLES_DETAILS, { id: item.id }),
                          state: {
                            offset: searchParams.offset,
                            auctionId: item.id,
                            status: searchParams.status,
                          },
                        })
                      }
                      item={item}
                      key={item.id}
                      auctionTimestamp={auctionsTimestamp}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <>
                  {searchParams.search ? (
                    <p>Oops, we could not find any result for the given phrase</p>
                  ) : (
                    <p>There are no vehicles</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyVehiclesPage;
