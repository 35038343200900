import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';
import filterIcon from '../../assets/img/search/filterIcon.png';
import filledFilterIcon from '../../assets/img/search/filledFilterIcon.png';
import { AuctionStatusTypeName } from '../../constants/AuctionStatusType';

const DropdownFilterByAuctionStatus = ({ type, handleChangeSortByStatusFilter }) => {
  const [isStatusFilterDropdownOpen, setIsStatusFilterDropdownOpen] = useState(false);
  return (
    <>
      <Dropdown
        isOpen={isStatusFilterDropdownOpen}
        toggle={() => setIsStatusFilterDropdownOpen(!isStatusFilterDropdownOpen)}
        className="search-header__status-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="search-header__status-dropdown-toggle-button search-sort"
        >
          {type === null ? 'filter by' : AuctionStatusTypeName[type]}
          <img
            src={type === null ? filterIcon : filledFilterIcon}
            alt="Filter icon"
            className="search-header__status-dropdown-icon"
          />
        </DropdownToggle>
        <DropdownMenu className="search-header__status-dropdown-menu">
          <DropdownItem
            active={type === null}
            className="search-header__status-dropdown-menu-item"
            onClick={() => (type !== null ? handleChangeSortByStatusFilter(null) : null)}
          >
            All statuses
          </DropdownItem>
          {Object.keys(AuctionStatusTypeName).map(item => (
            <DropdownItem
              key={item}
              active={type === item}
              className="search-header__status-dropdown-menu-item"
              onClick={() => (type !== item ? handleChangeSortByStatusFilter(item) : null)}
            >
              {AuctionStatusTypeName[item]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

DropdownFilterByAuctionStatus.defaultProps = {
  type: null,
};

DropdownFilterByAuctionStatus.propTypes = {
  type: PropTypes.string,
  handleChangeSortByStatusFilter: PropTypes.func.isRequired,
};

export default DropdownFilterByAuctionStatus;
