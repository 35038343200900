import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';

const { ONE_WEEK, TWO_WEEKS, THREE_WEEKS, FOUR_WEEKS, CUSTOM_DATE } = DueInDateType;
const {
  ONE_WEEK: ONE_WEEK_NAME,
  TWO_WEEKS: TWO_WEEKS_NAME,
  THREE_WEEKS: THREE_WEEKS_NAME,
  FOUR_WEEKS: FOUR_WEEKS_NAME,
  CUSTOM_DATE: CUSTOM_DATE_NAME,
} = DueInDateTypeName;

export const prepareTryingToDealDeliveryTypeLabel = tryingToDealDeliveryType => {
  switch (tryingToDealDeliveryType) {
    case ONE_WEEK:
      return ONE_WEEK_NAME;
    case TWO_WEEKS:
      return TWO_WEEKS_NAME;
    case THREE_WEEKS:
      return THREE_WEEKS_NAME;
    case FOUR_WEEKS:
      return FOUR_WEEKS_NAME;
    case CUSTOM_DATE:
      return CUSTOM_DATE_NAME;
    default:
      return '';
  }
};
