import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryString } from '../../common/helpers/queryString';
import { impersonate } from '../AuthActions';

const ImpersonatePage = () => {
  const dispatch = useDispatch();
  const query = useQueryString();

  useEffect(() => {
    const organizationMemberId = query.get('om');
    const token = query.get('t');
    dispatch(impersonate({ organizationMemberId, token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <h5>Logging ...</h5>
    </div>
  );
};

export default ImpersonatePage;
