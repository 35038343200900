const IMAGE_MAX_WIDTH = 1920;
const IMAGE_MAX_HEIGHT = 1080;

/**
 *
 * @param file
 * @returns {Promise<void>}
 */
export const resizeImage = async file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      const image = new Image();
      image.onload = async () => {
        if (image.width > IMAGE_MAX_WIDTH || image.height > IMAGE_MAX_HEIGHT) {
          let width;
          let height;

          if (image.width > image.height) {
            height = image.height * (IMAGE_MAX_WIDTH / image.width);
            width = IMAGE_MAX_WIDTH;
          } else {
            width = image.width * (IMAGE_MAX_HEIGHT / image.height);
            height = IMAGE_MAX_HEIGHT;
          }
          const resizedImage = processImg(image.src, width, height, file.type);
          const resizedFile = await dataUrlToFile(resizedImage, file);
          resolve(resizedFile);
        }
        resolve(file);
      };

      image.src = e.target.result;
    };

    reader.readAsDataURL(file);
  });
};

/**
 *
 * @param dataUrl
 * @param file
 * @returns {Promise<File>}
 */
const dataUrlToFile = async (dataUrl, file) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], file.name, { type: file.type });
};

/**
 *
 * @param image
 * @param width
 * @param height
 * @param type
 * @returns {string}
 */
const processImg = (image, width, height, type) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const img = new Image();
  img.src = image;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL(type, 0.8);
};
