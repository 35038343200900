import React, { useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import AsyncSelect from 'react-select/async';
import { get } from 'lodash';

const FormAsyncSelectFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    onChangeHandler,
    loadOptions,
    defaultOptions,
    onInputChange = () => {},
    loadRightAway,
    invalid,
    disabled,
  } = props;

  const [isActiveFetchData, setActiveFetchData] = useState(loadRightAway);

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = get(form.values, field.name, '');

  const onFocusHandler = () => {
    setActiveFetchData(true);
  };

  const loadOneOption = (inputValue, callback) => {
    if (!value?.label) {
      callback(false);
      return false;
    }

    callback([value]);
    return true;
  };

  return (
    <Row>
      <Col>
        <Label className="form-input-field-label">{label}</Label>
        <FormGroup className={disabled ? 'form-async-select-field--disabled' : ''}>
          <AsyncSelect
            loadOptions={isActiveFetchData ? loadOptions : loadOneOption}
            defaultOptions={defaultOptions}
            placeholder={placeholder}
            value={value}
            name={field.name}
            isDisabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onInputChange={onInputChange}
            onChange={option => {
              form.setFieldValue(field.name, option);
              onChangeHandler(option);
            }}
            onFocus={onFocusHandler}
            className={(invalid || error) && 'react-select__control'}
            classNamePrefix="react-select"
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error?.value}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormAsyncSelectField = props => {
  return <Field {...props} component={FormAsyncSelectFieldInner} />;
};

FormAsyncSelectField.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  loadRightAway: true,
  invalid: '',
  disabled: false,
};

FormAsyncSelectField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  loadRightAway: PropTypes.bool,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};

export default FormAsyncSelectField;
