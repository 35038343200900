export const PhotoPerspective = {
  MASTER: 'MASTER',
  LEFT_FRONT: 'LEFT_FRONT',
  LEFT_SIDE: 'LEFT_SIDE',
  LEFT_REAR: 'LEFT_REAR',
  REAR: 'REAR',
  RIGHT_REAR: 'RIGHT_REAR',
  RIGHT_SIDE: 'RIGHT_SIDE',
  RIGHT_FRONT: 'RIGHT_FRONT',
  OTHER: 'OTHER',
  INTERIOR_1: 'INTERIOR_1',
  INTERIOR_2: 'INTERIOR_2',
  INTERIOR_3: 'INTERIOR_3',
  INTERIOR_4: 'INTERIOR_4',
};
