export const prepareFilename = (filenameWordsArr, fileExtension) => {
  if (typeof filenameWordsArr === 'object' && typeof fileExtension === 'string') {
    let preparedFilename = '';
    filenameWordsArr.forEach((item, index) => {
      preparedFilename = `${preparedFilename}${item}${
        index !== filenameWordsArr.length - 1 ? '_' : ''
      }`;
    });
    return `${preparedFilename}.${fileExtension}`;
  }
  return 'file';
};
