export const AuctionTime = {
  AUCTION_TIME_30: 'AUCTION_TIME_30',
  AUCTION_TIME_45: 'AUCTION_TIME_45',
  AUCTION_TIME_60: 'AUCTION_TIME_60',
  AUCTION_TIME_180: 'AUCTION_TIME_180',
  AUCTION_TIME_1440: 'AUCTION_TIME_1440',
  AUCTION_TIME_2880: 'AUCTION_TIME_2880',
  AUCTION_TIME_4320: 'AUCTION_TIME_4320',
};

export const AuctionTimeName = {
  AUCTION_TIME_60: '1 hour',
  AUCTION_TIME_180: '3 hours',
  AUCTION_TIME_1440: '1 day',
  AUCTION_TIME_2880: '2 days',
  AUCTION_TIME_4320: '3 days',
};

export const AuctionTimeHourFormat = {
  AUCTION_TIME_30: '30:00',
  AUCTION_TIME_45: '45:00',
  AUCTION_TIME_60: '1:00:00',
  AUCTION_TIME_180: '3:00:00',
  AUCTION_TIME_1440: '1 day',
  AUCTION_TIME_2880: '2 days',
  AUCTION_TIME_4320: '3 days',
};
