import { fromJS } from 'immutable';

export const FETCH_MY_PROFILE_SUCCESS = 'FETCH_MY_PROFILE_SUCCESS';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';

const initialData = fromJS({
  myProfileDetails: {},
  last4: '',
  paymentMethod: '',
});

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case FETCH_MY_PROFILE_SUCCESS:
      return state.merge({
        myProfileDetails: payload.myProfileDetails,
      });
    case GET_PAYMENT_METHOD_SUCCESS:
      return state.merge({
        last4: payload.last4,
        paymentMethod: payload.paymentMethod,
      });
    default:
      return state;
  }
};
