import * as Yup from 'yup';
import { FormRegExp } from '../../../constants/FormRegExp';
import { ValidationMessage } from '../../../constants/ValidationMessage';

export default () => {
  return Yup.object().shape({
    landlinePhoneNumber: Yup.string()
      .nullable()
      .matches(FormRegExp.INTEGER, 'Type only numbers')
      .max(15, 'Phone number is too long - should be 15 chars maximum'),
    postalCodeId: Yup.string().required('Field is required'),
    city: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(100, 'City is too long - should be 100 chars maximum'),
    county: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(100, 'County is too long - should be 100 chars maximum')
      .nullable(),
    address: Yup.string()
      .required('Field is required')
      .max(100, 'Address is too long - should be 100 chars maximum')
      .nullable(),
    address2: Yup.string()
      .max(100, 'Address (line 2) is too long - should be 100 chars maximum')
      .nullable(),
    additionalLocation: Yup.array().of(
      Yup.object().shape({
        postalCode: Yup.string().required('Field is required'),
        address: Yup.string()
          .required('Field is required')
          .max(100, 'Address is too long - should be 100 chars maximum')
          .nullable(),
        address2: Yup.string()
          .max(100, 'Address (line 2) is too long - should be 100 chars maximum')
          .nullable(),
        city: Yup.string()
          .required('Field is required')
          .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
          .max(100, 'City is too long - should be 100 chars maximum'),
        county: Yup.string()
          .required('Field is required')
          .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
          .max(100, 'County is too long - should be 100 chars maximum')
          .nullable(),
      }),
    ),
    website: Yup.string()
      .matches(FormRegExp.URL_NO_HTTPS, ValidationMessage.WEBSITE_ADDRESS)
      .max(200, 'Website is too long - should be 200 chars maximum')
      .nullable(),
  });
};
