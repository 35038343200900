import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import noImageImg from '../assets/img/search/noimage.svg';
import { AuctionStatus, AuctionStatusColors } from '../constants/AuctionStatus';
import { preparePriceFormat } from '../common/helpers/preparePriceFormat';
import { capitalizeString } from '../common/helpers/capitalizeString';
import { BidStatusTypeName } from '../constants/BidStatusType';
import { AuctionItemMessage } from '../constants/AuctionItemMessage';
import OrganizationRoleBox from '../myvehicles/OrganizationRoleBox';
import { pencesToPounds } from '../common/helpers/pencesToPounds';
import AuctionTypeLabel from '../myvehicles/AuctionTypeLabel';
import { getPriceColor } from '../common/helpers/auctionColorHelper';
import AuctionTimeLabel from '../myvehicles/AuctionTimeLabel';
import { auctionListType } from '../constants/AuctionListType';
import CurrentBidLabel from '../myvehicles/CurrentBidLabel';
import { AUCTION_BID_MAX_BIDS_LIMIT } from '../constants/AuctionBid';

const MyBidsItem = ({ item, onClickItem, getRef }) => {
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const id = useSelector(state => state.auth.get('id'));
  const [bidBarColor, setBidBarColor] = useState('');
  const [bidBarMessage, setBidBarMessage] = useState('');
  const [heightOfItem, setHeightOfItem] = useState(0);
  const [windowFocus, setWindowFocus] = useState(false);

  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  let highestBid = null;
  let highestBidAmount = null;
  if (item && item.bids?.length) {
    highestBid = item.bids.filter(bid => bid.isHighest)?.[0];
    if (highestBid) {
      highestBidAmount = (highestBid?.amountInPence / 100).toFixed(0);
    }
  }

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    renderMyBidItemMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  let image = noImageImg;
  if (item?.vehicle?.vehicleMedia?.photos?.length) {
    image = item?.vehicle?.vehicleMedia?.photos?.[0]?.fileUrl;
  }

  const title = `${item?.vehicle?.vehicleModel?.makeName} ${item?.vehicle?.vehicleModel?.name} ${item?.vehicle?.derivative}`;
  const isHighestBid = highestBid?.bidderOrganizationId === organizationId;

  const organizationHighestBids = item?.bids
    ?.filter(item => item.bidderOrganizationId === organizationId)
    .map(item => item.amountInPence);

  const organizationHighestBid =
    organizationHighestBids.length > 0 &&
    organizationHighestBids.reduce((a, b) => {
      return Math.max(a, b);
    });

  const myBidsAmount = item?.bids
    ?.filter(item => item.bidderId === id)
    .map(item => item.amountInPence);
  const myHighestBid =
    myBidsAmount.length > 0 &&
    myBidsAmount?.reduce((a, b) => {
      return Math.max(a, b);
    });

  const organizationRole = item?.organizationRole || item?.seller?.organizationContact?.role;

  const renderMyBidItemMessage = () => {
    let message;
    let statusColor;
    switch (item?.status) {
      case AuctionStatus.PUBLISHED:
        if (isHighestBid) {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} is the highest`;
          statusColor = AuctionStatusColors.PUBLISHED;
        } else if (item?.bids?.length === AUCTION_BID_MAX_BIDS_LIMIT) {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest`;
          statusColor = AuctionStatusColors.DRAFT;
        } else if (item?.bids?.length > 0 && item?.bids?.length < AUCTION_BID_MAX_BIDS_LIMIT) {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} is not the highest - try again`;
          statusColor = AuctionStatusColors.REJECTED;
        }
        break;
      case AuctionStatus.CANCELLED:
        message = AuctionItemMessage.CANCELLED;
        statusColor = AuctionStatusColors.CANCELLED;
        break;
      case AuctionStatus.TO_ACCEPT:
        if (isHighestBid) {
          message = AuctionItemMessage.TO_ACCEPT_BUYER;
          statusColor = AuctionStatusColors.TO_ACCEPT_SELLER;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest.`;
          statusColor = AuctionStatusColors.DRAFT;
        }
        break;
      case AuctionStatus.WAITING:
        if (isHighestBid) {
          message = AuctionItemMessage.WAITING;
          statusColor = AuctionStatusColors.TO_ACCEPT_BUYER;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest.`;
          statusColor = AuctionStatusColors.DRAFT;
        }
        break;
      case AuctionStatus.REJECTED:
        if (isHighestBid) {
          message = BidStatusTypeName.REJECTED;
          statusColor = AuctionStatusColors.REJECTED;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest.`;
          statusColor = AuctionStatusColors.DRAFT;
        }
        break;
      case AuctionStatus.SOLD:
        if (isHighestBid) {
          message = AuctionItemMessage.WON;
          statusColor = AuctionStatusColors.SOLD;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest. The winning bid was ${preparePriceFormat(
            item?.wonBidPriceInPence,
            true,
          )}`;
          statusColor = AuctionStatusColors.DRAFT;
        }
        break;

      default:
        return null;
    }
    setBidBarColor(statusColor);
    setBidBarMessage(message);
    return { message, statusColor };
  };

  const renderAuctionType = (isDesktop = true) => {
    return (
      <>
        <p
          className={`auction-item__status-type auction-item__status-type--bid ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <span className="search-aucion-type__name">
            <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />
          </span>
        </p>
        <p
          className={`auction-item__status-type auction-item__status-type--bid ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <span className="search-aucion-type__name">
            <CurrentBidLabel hasBid={!!item?.highestBidInPence} type={item.type} />
          </span>

          <span className="search-min-price" style={getPriceColor(item.shouldPriceBeGreen)}>
            <CurrencyFormat
              value={
                pencesToPounds(item?.highestBidInPence) ||
                pencesToPounds(item?.minimumPriceInPence) ||
                0
              }
              thousandSeparator={true}
              allowNegative={false}
              prefix="£"
              displayType="text"
            />
          </span>
        </p>
      </>
    );
  };

  return (
    <Row
      className="search-box-of-car my-vehicles-box-of-car search-box-of-car--mobile"
      key={item.id}
      onClick={onClickItem}
    >
      <Col
        xs={7}
        md="3"
        className="search-box-of-img search-box-of-img--mobile"
        style={{ height: `${heightOfItem}px` }}
      >
        <div className="search-box-of-img-wrapper" ref={el => getRef(el)}>
          <OrganizationRoleBox role={organizationRole} />
          <img
            className="search-img-in-box"
            src={image}
            alt="car"
            onError={e => {
              e.target.src = noImageImg;
            }}
          />
        </div>
        <div className="search-title-wrapper--mobile">
          <p className="search-title--mobile">{title}</p>
        </div>
        <div className="search-status-wrapper--mobile">{renderAuctionType(false)}</div>
      </Col>
      <Col xs={5} md="9">
        <div
          ref={el => {
            if (!el) return;
            setHeightOfItem(el.getBoundingClientRect().height + 15);
          }}
        >
          <Row className="auction-basic-data-row">
            <Col md="8" className="search-content-left">
              <p className="search-title">{title}</p>
              <Row md="12" lg="10" className="auction-basic-data-wrapper">
                <Col md="6" lg="4">
                  <p className="auction-item__basic-car-data search-year">
                    {item?.vehicle?.year}
                  </p>
                  <p className="auction-item__basic-car-data search-registration">
                    {item?.vehicle?.registrationPlate}
                  </p>
                  <p className="auction-item__basic-car-data search-mileage">
                    {item?.vehicle?.mileage}
                  </p>
                </Col>
                <Col md="6">
                  <p className="auction-item__basic-car-data search-transmission">
                    {capitalizeString(item?.vehicle?.transmission)}
                  </p>
                  <p className="auction-item__basic-car-data search-fuel">
                    {item?.vehicle?.fuel}
                  </p>
                  <p className="auction-item__basic-car-data search-city">
                    {item?.location?.city}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="search-content-right">
              <AuctionTimeLabel
                auctionTimestamp={item?.timeStamp}
                item={item}
                listType={auctionListType.MY_BIDS}
                organizationId={organizationId}
                windowFocus={windowFocus}
              />
              {renderAuctionType(true)}

              <p className="search-highest-bid">
                {AuctionStatus.SOLD === item.status && (
                  <>
                    <span className="search-highest-bid__status">Highest bid:</span>
                    <span className="search-min-price">
                      <CurrencyFormat
                        value={highestBidAmount || String(item?.wonBidPriceInPence / 100)}
                        thousandSeparator={true}
                        allowNegative={false}
                        prefix="£"
                        displayType="text"
                      />
                    </span>
                  </>
                )}
                {myHighestBid &&
                  ![AuctionStatus.SOLD, AuctionStatus.CANCELLED].includes(item.status) && (
                    <>
                      <span className="search-highest-bid__status">Your highest bid:</span>
                      <span className="search-min-price">
                        <CurrencyFormat
                          value={myHighestBid / 100}
                          thousandSeparator={true}
                          allowNegative={false}
                          prefix="£"
                          displayType="text"
                        />
                      </span>
                    </>
                  )}
              </p>
            </Col>
          </Row>
        </div>
      </Col>
      {bidBarMessage && bidBarColor && (
        <Col xs="12" className="my-vehicles-warning" style={{ backgroundColor: bidBarColor }}>
          <p className="my-vehicles-warning-text">{bidBarMessage}</p>
        </Col>
      )}
    </Row>
  );
};
MyBidsItem.defaultProps = {
  onClickItem: () => {},
  getRef: () => {},
};
MyBidsItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClickItem: PropTypes.func,
  getRef: PropTypes.func,
};

export default withRouter(MyBidsItem);
