export const CommonButtonVariants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DISABLED: 'disabled',
  ORGANIZATION_NAV: 'organization-nav',
  AUCTION: 'auction',
  SUCCESS: 'success',
  DANGER: 'danger',
  GREY: 'grey',
  PUBLISH: 'publish',
  CAR_FEATURE: 'feature',
  CAR_FEATURE_SELECTED: 'feature--selected',
  DELETE: 'delete',
};

export const CommonButtonSize = {
  DEFAULT: 'default',
  LARGE: 'large',
  SMALL: 'small',
  MEDIUM: 'medium',
};
