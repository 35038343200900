import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AuctionSortType, AuctionSortTypeName } from '../constants/AuctionSortType';
import sortAscImg from '../assets/img/search/sort-down-asc.svg';
import sortDescImg from '../assets/img/search/sort-down-desc.svg';
import DropdownFilterByAuctionStatus from '../common/components/DropdownFilterByAuctionStatus';

const SearchHeader = ({
  total,
  sort,
  handleChangeSort,
  type,
  handleChangeSortByStatusFilter,
}) => {
  const sortName = AuctionSortTypeName?.[sort];
  const image = sort === AuctionSortType.ENDING_SOONEST ? sortAscImg : sortDescImg;

  return (
    <Row>
      <Col xs="6">
        <p className="search-vehicles-found">
          {total} vehicle{total !== 1 && 's'} found{' '}
        </p>
      </Col>
      <Col xs="6">
        <p
          className="search-sort search-sort--time-filter"
          onClick={() => handleChangeSort()}
          role="presentation"
        >
          {sortName} <img src={image} alt="sort" />
        </p>
        <DropdownFilterByAuctionStatus
          type={type}
          handleChangeSortByStatusFilter={handleChangeSortByStatusFilter}
        />
      </Col>
    </Row>
  );
};

SearchHeader.defaultProps = {
  type: null,
};

SearchHeader.propTypes = {
  type: PropTypes.string,
  handleChangeSortByStatusFilter: PropTypes.func.isRequired,
};

export default SearchHeader;
