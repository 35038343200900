import { fromJS } from 'immutable';

const initialData = fromJS({
  id: '',
  autofill: false,
  basicDataSaved: false,
  version: 0,
  step: 1,
  photos: [],
  videos: [],
  photosToDelete: [],
  videosToDelete: [],
  descriptionTemplate: [],
  organizationLocations: [],
  organizationGroup: null,
  basicData: null,
  allVehicleFeatures: [],
  photoRecords: [],
  vehicleFeatures: null,
  tyreThreadDepths: null,
  tyreThreadDepthsDictionary: null,
  descriptionAndSettings: null,
  uploadPhotoError: false,
  vehicleFound: false,
  validationErrors: {},
  auctionDetails: null,
  vehicleMedia: null,
  formStep: 1,
  isAuctionEditing: false,
  registrationPlate: '',
  confirmLaunchValidationError: '',
  isSseRefresh: true,
});

export const SET_BASIC_DATA_SUCCESS = 'SET_BASIC_DATA_SUCCESS';
export const AUTOFILL_SUCCESS = 'AUTOFILL_SUCCESS';
export const READ_CAP_DATA_SUCCESS = 'READ_CAP_DATA_SUCCESS';
export const READ_CAP_DATA_FAILURE = 'READ_CAP_DATA_FAILURE';
export const SET_PHOTO_STEP_SUCCESS = 'SET_PHOTO_STEP_SUCCESS';
export const ADD_PHOTO_START = 'ADD_PHOTO_START';
export const ADD_PHOTO_SUCCESS = 'ADD_PHOTO_SUCCESS';
export const SORTED_PHOTOS_SUCCESS = 'SORTED_PHOTOS_SUCCESS';
export const UPDATE_PHOTOS_SUCCESS = 'UPDATE_PHOTOS_SUCCESS';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_DAMAGE_SUCCESS = 'DELETE_DAMAGE_SUCCESS';
export const DELETE_VIDEO_IN_SUMMARY_SUCCESS = 'DELETE_VIDEO_IN_SUMMARY_SUCCESS';
export const FETCH_DESCRIPTION_TEMPLATE_SUCCESS = 'FETCH_DESCRIPTION_TEMPLATE_SUCCESS';
export const FETCH_ORGANIZATION_LOCATIONS_SUCCESS = 'FETCH_ORGANIZATION_LOCATIONS_SUCCESS';
export const FETCH_ORGANIZATION_GROUP_SUCCESS = 'FETCH_ORGANIZATION_GROUP_SUCCESS';
export const FETCH_ALL_VEHICLE_FEATURES_SUCCESS = 'FETCH_ALL_VEHICLE_FEATURES_SUCCESS';
export const ADD_PHOTO_IN_SUMMARY_SUCCESS = 'ADD_PHOTO_IN_SUMMARY_SUCCESS';
export const ADD_VIDEO_IN_SUMMARY_SUCCESS = 'ADD_VIDEO_IN_SUMMARY_SUCCESS';
export const ADD_PHOTO_IN_SERVICE_HISTORY_SUCCESS = 'ADD_PHOTO_IN_SERVICE_HISTORY_SUCCESS';
export const DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS =
  'DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS';
export const VALIDATE_TYRE_THREAD_DEPTHS_SUCCESS = 'VALIDATE_TYRE_THREAD_DEPTHS_SUCCESS';
export const VALIDATE_VEHICLE_FEATURES_SUCCESS = 'VALIDATE_VEHICLE_FEATURES_SUCCESS';
export const VALIDATE_VEHICLE_MEDIA_SUCCESS = 'VALIDATE_VEHICLE_MEDIA_SUCCESS';
export const VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS =
  'VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS';
export const HANDLE_UPLOAD_PHOTO_ERROR = 'HANDLE_UPLOAD_PHOTO_ERROR';
export const GET_DRAFT_DATA = 'GET_DRAFT_DATA';
export const SET_DAMAGE_TYPE = 'SET_DAMAGE_TYPE';
export const CLEAR_DAMAGES_TO_DELETE = 'CLEAR_DAMAGES_TO_DELETE';
export const SAVE_AUCTION_SUCCESS = 'SAVE_AUCTION_SUCCESS';
export const VEHICLE_FOUND_SUCCESS = 'VEHICLE_FOUND_SUCCESS';
export const VEHICLE_FOUND_FAILURE = 'VEHICLE_FOUND_FAILURE';
export const AUCTION_CLEAR_SUCCESS = 'AUCTION_CLEAR_SUCCESS';
export const VALIDATE_DESCRIPTION_AND_SETTINGS_FAILURE =
  'VALIDATE_DESCRIPTION_AND_SETTINGS_FAILURE';
export const CLEAR_DESCRIPTION_AND_SETTINGS_ERRORS_SUCCESS =
  'CLEAR_DESCRIPTION_AND_SETTINGS_ERRORS_SUCCESS';
export const PUBLISH_AUCTION_SUCCESS = 'PUBLISH_AUCTION_SUCCESS';
export const CANCEL_AUCTION_SUCCESS = 'CANCEL_AUCTION_SUCCESS';
export const DELETE_AUCTION_SUCCESS = 'DELETE_AUCTION_SUCCESS';
export const CLEAR_RESPONSE_DATA_SUCCESS = 'CLEAR_GET_RESPONSE_DATA_SUCCESS';
export const REDIRECT_TO_EDIT_FORM_STEP = 'REDIRECT_TO_EDIT_FORM_STEP';
export const DISABLE_AUCTION_EDITING_SUCCESS = 'DISABLE_AUCTION_EDITING_SUCCESS';
export const REPUBLISH_AUCTION_SUCCESS = 'REPUBLISH_AUCTION_SUCCESS';
export const SET_ADDITIONAL_PHOTO_POSITION = 'SET_ADDITIONAL_PHOTO_POSITION';
export const REDIRECT_TO_PHOTO_SUMMARY = 'REDIRECT_TO_PHOTO_SUMMARY';
export const REJECTION_AUCTION_SUCCESS = 'REJECTION_AUCTION_SUCCESS';
export const CLEAR_DAMAGES_TO_WITH_NO_DAMAGE_TYPE = 'CLEAR_DAMAGES_TO_WITH_DAMAGE_TYPE';
export const SET_ADD_VEHICLE_FORM_STEP = 'SET_ADD_VEHICLE_FORM_STEP';
export const CONFIRM_LAUNCH_FAILURE = 'CONFIRM_LAUNCH_FAILURE';
export const CLEAR_CONFIRM_LAUNCH_VALIDATION_ERRORS_SUCCESS =
  'CLEAR_CONFIRM_LAUNCH_VALIDATION_ERRORS_SUCCESS';
export const SET_IS_RELAUNCH_ALLOWED_SUCCESS = 'SET_IS_RELAUNCH_ALLOWED_SUCCESS';
export const CLEAR_BASIC_DATA_SAVED = 'CLEAR_BASIC_DATA_SAVED';
export const CLEAR_PHOTOS_IN_SERVICE_HISTORY = 'CLEAR_PHOTOS_IN_SERVICE_HISTORY';
export const REMOVE_PHOTO_IN_SERVICE_HISTORY = 'REMOVE_PHOTO_IN_SERVICE_HISTORY';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SET_BASIC_DATA_SUCCESS:
      return state.merge({
        basicData: payload.basicData,
        id: payload.id,
        basicDataSaved: true,
      });
    case AUTOFILL_SUCCESS:
      return state.merge({
        autofill: payload?.autofill,
      });
    case READ_CAP_DATA_SUCCESS: {
      const { basicData, registrationPlate } = payload;
      return state.merge({
        basicData,
        registrationPlate,
      });
    }
    case READ_CAP_DATA_FAILURE:
      return state.merge({
        basicData: {
          registrationPlate: payload?.registrationPlate,
        },
      });
    case SET_PHOTO_STEP_SUCCESS:
      return state.merge({
        step: payload?.step,
      });
    case ADD_PHOTO_START: {
      const { vehicleMedia } = state.toJS();
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...vehicleMedia, photos: payload?.photos },
      });
    }
    case UPDATE_PHOTOS_SUCCESS: {
      const { vehicleMedia } = state.toJS();
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...vehicleMedia, photos: payload?.photos },
      });
    }
    case ADD_PHOTO_SUCCESS: {
      const { vehicleMedia } = state.toJS();
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...vehicleMedia, photos: payload?.photos },
      });
    }
    case SORTED_PHOTOS_SUCCESS: {
      const { vehicleMedia } = state.toJS();
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...vehicleMedia, photos: payload?.photos },
      });
    }
    case DELETE_PHOTO_SUCCESS:
      return state.merge({
        photos: payload?.photos,
        photosToDelete: payload?.photosToDelete,
        vehicleMedia: {
          ...payload?.vehicleMedia,
          photos: payload?.photos,
          photosToDelete: payload?.photosToDelete,
        },
      });
    case DELETE_DAMAGE_SUCCESS:
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...payload?.vehicleMedia, photos: payload?.photos },
      });
    case DELETE_VIDEO_IN_SUMMARY_SUCCESS: {
      return state.merge({
        videos: payload?.videos,
        videosToDelete: payload?.videosToDelete,
        vehicleMedia: {
          ...payload?.vehicleMedia,
          videos: payload?.videos,
          videosToDelete: payload?.videosToDelete,
        },
      });
    }
    case FETCH_DESCRIPTION_TEMPLATE_SUCCESS:
      return state.merge({
        descriptionTemplate: payload?.descriptionTemplate,
      });
    case FETCH_ORGANIZATION_LOCATIONS_SUCCESS:
      return state.merge({
        organizationLocations: payload.organizationLocations,
      });
    case FETCH_ORGANIZATION_GROUP_SUCCESS:
      return state.merge({
        organizationGroup: payload.organizationGroup,
      });
    case FETCH_ALL_VEHICLE_FEATURES_SUCCESS:
      return state.merge({
        allVehicleFeatures: payload.allVehicleFeatures,
      });
    case ADD_PHOTO_IN_SUMMARY_SUCCESS: {
      const { photos } = state.toJS();
      photos.push(payload.photo);
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...payload.vehicleMedia, photos: payload?.photos },
      });
    }
    case ADD_VIDEO_IN_SUMMARY_SUCCESS: {
      return state.merge({
        videos: payload?.videos,
        vehicleMedia: { ...payload.vehicleMedia, videos: payload?.videos },
      });
    }
    case ADD_PHOTO_IN_SERVICE_HISTORY_SUCCESS: {
      const { photoRecords } = state.toJS();
      photoRecords.push(payload.photoRecord);
      return state.merge({
        photoRecords,
      });
    }
    case REMOVE_PHOTO_IN_SERVICE_HISTORY: {
      return state.merge({
        photoRecords: payload.photoRecords,
      });
    }

    case CLEAR_PHOTOS_IN_SERVICE_HISTORY:
      return state.merge({
        photoRecords: [],
      });
    case DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS: {
      const { photoRecords } = state.toJS();
      if (photoRecords[payload?.idPhotoRecord - 1])
        photoRecords.splice(payload?.idPhotoRecord - 1, 1);
      return state.merge({
        photoRecords,
      });
    }
    case VALIDATE_TYRE_THREAD_DEPTHS_SUCCESS: {
      return state.merge({
        tyreThreadDepths: payload.tyreThreadDepths,
        tyreThreadDepthsDictionary: payload.tyreThreadDepthsDictionary,
      });
    }
    case VALIDATE_VEHICLE_FEATURES_SUCCESS: {
      return state.merge({
        vehicleFeatures: payload.vehicleFeatures,
        vehicleFeaturesDictionary: payload.vehicleFeaturesDictionary,
      });
    }
    case VALIDATE_VEHICLE_MEDIA_SUCCESS: {
      return state.merge({
        vehicleMedia: payload.vehicleMedia,
      });
    }
    case VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS: {
      return state.merge({
        descriptionAndSettings: payload.descriptionAndSettings,
      });
    }
    case HANDLE_UPLOAD_PHOTO_ERROR: {
      return state.merge({
        uploadPhotoError: payload,
      });
    }
    case GET_DRAFT_DATA: {
      return state.merge({
        ...payload.data,
        photos: payload.photos,
        videos: payload.videos,
        vehicleMedia: { ...payload.data.vehicleMedia, photos: payload.photos },
      });
    }
    case SET_DAMAGE_TYPE: {
      return state.merge({
        photos: payload?.photos,
      });
    }
    case CLEAR_DAMAGES_TO_DELETE: {
      return state.merge({
        photos: payload?.photos,
      });
    }

    case CLEAR_DAMAGES_TO_WITH_NO_DAMAGE_TYPE: {
      const { vehicleMedia } = state.toJS();
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...vehicleMedia, photos: payload?.photos },
      });
    }
    case SAVE_AUCTION_SUCCESS: {
      return state.merge({
        auctionDetails: payload,
        isGetDetailsFromResponse: true,
      });
    }
    case PUBLISH_AUCTION_SUCCESS: {
      return state.merge({
        auctionDetails: payload,
        isGetDetailsFromResponse: true,
      });
    }
    case CLEAR_RESPONSE_DATA_SUCCESS: {
      return state.merge({
        auctionDetails: null,
        isGetDetailsFromResponse: false,
      });
    }
    case CANCEL_AUCTION_SUCCESS: {
      return state.merge({
        auctionDetails: payload,
        isGetDetailsFromResponse: true,
        isSseRefresh: false,
      });
    }
    case SET_IS_RELAUNCH_ALLOWED_SUCCESS: {
      return state.merge({
        isSseRefresh: payload,
      });
    }
    case DELETE_AUCTION_SUCCESS: {
      return state.merge({
        isGetDetailsFromResponse: true,
      });
    }
    case VEHICLE_FOUND_SUCCESS: {
      return state.merge({
        vehicleFound: payload,
      });
    }
    case VEHICLE_FOUND_FAILURE: {
      return state.merge({
        vehicleFound: payload,
      });
    }
    case AUCTION_CLEAR_SUCCESS: {
      return initialData;
    }
    case VALIDATE_DESCRIPTION_AND_SETTINGS_FAILURE: {
      return state.merge({
        validationErrors: payload,
      });
    }
    case CLEAR_DESCRIPTION_AND_SETTINGS_ERRORS_SUCCESS: {
      return state.merge({
        validationErrors: {},
      });
    }
    case REDIRECT_TO_EDIT_FORM_STEP: {
      const { formStep, id, isAuctionEditing, autofill } = payload;
      return state.merge({
        formStep,
        id,
        isAuctionEditing,
        autofill,
      });
    }
    case DISABLE_AUCTION_EDITING_SUCCESS:
      return state.merge({
        isAuctionEditing: false,
      });

    case REPUBLISH_AUCTION_SUCCESS: {
      return state.merge({
        auctionDetails: payload,
        isGetDetailsFromResponse: true,
      });
    }

    case SET_ADDITIONAL_PHOTO_POSITION: {
      return state.merge({
        photos: payload?.photos,
        vehicleMedia: { ...payload.vehicleMedia, photos: payload?.photos },
      });
    }

    case REDIRECT_TO_PHOTO_SUMMARY: {
      return state.merge({
        formStep: payload?.formStep,
      });
    }
    case SET_ADD_VEHICLE_FORM_STEP: {
      return state.merge({
        formStep: payload?.formStep,
      });
    }
    case REJECTION_AUCTION_SUCCESS: {
      return state.merge({
        auctionDetails: payload,
        isGetDetailsFromResponse: true,
      });
    }
    case CONFIRM_LAUNCH_FAILURE: {
      return state.merge({
        confirmLaunchValidationError: payload,
      });
    }
    case CLEAR_CONFIRM_LAUNCH_VALIDATION_ERRORS_SUCCESS: {
      return state.merge({
        confirmLaunchValidationError: '',
      });
    }
    case CLEAR_BASIC_DATA_SAVED: {
      return state.merge({
        basicDataSaved: false,
      });
    }

    default:
      return state;
  }
};
