import React, { useEffect } from 'react';
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import CurrencyFormat from 'react-currency-format';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormCurrencyInputField from '../../common/components/formFields/FormCurrencyInputField';
import { ordinalSuffix } from '../../common/helpers/ordinalSuffix';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { fetchAuctionBids } from '../SearchActions';
import { preparePriceFormat } from '../../common/helpers/preparePriceFormat';
import { pencesToPounds } from '../../common/helpers/pencesToPounds';
import {
  AUCTION_BID_MAX_BIDS_LIMIT,
  AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
} from '../../constants/AuctionBid';

const SearchPlaceBidModal = ({ item, toggleShow, toggleStep }) => {
  const dispatch = useDispatch();
  let auctionBids = useSelector(state => state.search.get('auctionBids'));

  useEffect(() => {
    if (auctionBids?.length) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      auctionBids = auctionBids.sort((a, b) => a.amountInPence - b.amountInPence);
    }
  }, [auctionBids]);

  useEffect(() => {
    dispatch(fetchAuctionBids(item?.id));
  }, [dispatch, item]);

  const isBidAllowed = item?.bids?.length < AUCTION_BID_MAX_BIDS_LIMIT;

  let highestBidAmount = null;
  if (item && item.highestBidInPence) {
    highestBidAmount =
      pencesToPounds(item.highestBidInPence) +
      pencesToPounds(AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE);
  }
  let minimumPrice = null;
  if (!highestBidAmount) {
    const minimum = pencesToPounds(item.minimumPriceInPence);
    const minimumBidValue = pencesToPounds(AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE);
    minimumPrice = minimum || minimumBidValue;
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required('Amount value is required')
      .integer('Bid must be an integer')
      .min(
        highestBidAmount || minimumPrice,
        `Your bid must be greater than the ${preparePriceFormat(
          highestBidAmount || minimumPrice,
        )}.`,
      ),
  });

  const handlePlaceBid = (values, { setSubmitting }) => {
    setSubmitting(false);
    toggleStep(+values.amount);
  };

  return (
    <Modal isOpen={true} backdrop="static" keyboard={false} className="place-bid-modal">
      <Formik
        initialValues={{ amount: '' }}
        onSubmit={handlePlaceBid}
        validationSchema={validationSchema}
      >
        <Form autoComplete="off" className="text-left">
          <ModalBody>
            <h5 className="text-center">Place a bid</h5>

            <Row className="d-flex justify-content-end align-items-center">
              <Col lg="12">
                <FormCurrencyInputField
                  inputClassName="font-weight-bold"
                  maxLength={14}
                  name="amount"
                  label="Enter your maximum bid"
                  disabled={!isBidAllowed}
                />
              </Col>
            </Row>

            {auctionBids && auctionBids.length > 0 && (
              <div className="place-bid-modal_bids-list text-center">
                {auctionBids.length === AUCTION_BID_MAX_BIDS_LIMIT && (
                  <p className="font-weight-bold">
                    You have reached a limit of {AUCTION_BID_MAX_BIDS_LIMIT} bids
                  </p>
                )}
                <p className="auction-detail-buyer__section-title mb-2">
                  Bids from your organisation:
                </p>
                <ul>
                  {auctionBids.map(
                    ({ bidderFirstName, bidderLastName, amountInPence, id }, index) => {
                      const fullName = `${bidderFirstName} ${bidderLastName} `;
                      return (
                        <li key={id}>
                          <i className="fas fa-angle-right auction-detail-buyer__arrow-icon" />
                          {ordinalSuffix(index + 1)} bid: {fullName}
                          <CurrencyFormat
                            value={pencesToPounds(amountInPence)}
                            thousandSeparator={true}
                            allowNegative={false}
                            prefix="£"
                            displayType="text"
                          />
                        </li>
                      );
                    },
                  )}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <CommonButton
              variant={CommonButtonVariants.GREY}
              label="Cancel"
              handleClick={toggleShow}
            />
            <CommonButton
              variant={CommonButtonVariants.PRIMARY}
              label="Bid"
              type="submit"
              disabled={!isBidAllowed}
            />
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default SearchPlaceBidModal;
