import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reverse } from 'named-urls';
import { clearResponseData, getAuction } from '../MyVehiclesActions';
import AuctionDetailsItem from '../../common/components/AuctionDetailsItem';
import { auctionListType } from '../../constants/AuctionListType';
import { SSE_SELLER, SSEClient } from '../../api/SSEClient';
import RouterPaths from '../../constants/RouterPaths';
import AddVehicleConfirmPage from '../../addvehicle/AddVehicleConfirmPage';

const MyVehiclesDetailsPage = ({ history, location }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const responseAuctionDetails = useSelector(state => state.addVehicle.get('auctionDetails'));
  const [item, setItem] = useState(null);
  const sseClient = new SSEClient(SSE_SELLER);
  const sellerId = useSelector(state => state.auth.get('id'));
  const [step, setStep] = useState(1);
  const [isRepublish, setIsRepublish] = useState(false);
  const [auctionTimeStamp, setAuctionTimestamp] = useState(null);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.location.state = {
          ...location.state,
          auctionId: id,
        };
      }
    };
  }, [location, history, id]);

  useEffect(() => {
    if (!responseAuctionDetails) {
      handleGetAuction();
    }
    return () => {
      dispatch(clearResponseData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const handleGetAuction = auction => {
    setAuctionTimestamp(new Date());
    if (auction) {
      setItem(auction);
    } else {
      dispatch(
        getAuction(id, auction => {
          if (sellerId === auction.seller.id) {
            setItem(auction);
          } else {
            history.push({
              pathname: reverse(RouterPaths.SEARCH_DETAILS, { id }),
              state: {
                ...history.location.state,
                auctionId: id,
              },
            });
          }
        }),
      );
    }
  };

  const prevStep = () => {
    setStep(prevState => prevState - 1);
  };

  const nextStep = () => {
    setStep(prevState => prevState + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          (responseAuctionDetails || item) && (
            <AuctionDetailsItem
              item={responseAuctionDetails || item}
              listType={auctionListType.MY_VEHICLES}
              refreshItem={handleGetAuction}
              sseClient={sseClient}
              typeSseClient={SSE_SELLER}
              nextStep={nextStep}
              setIsRepublish={setIsRepublish}
              auctionTimestamp={auctionTimeStamp}
            />
          )
        );
      case 2:
        return (
          <AddVehicleConfirmPage
            item={item}
            prevStep={prevStep}
            nextStep={nextStep}
            isRepublish={isRepublish}
            refreshItem={handleGetAuction}
          />
        );
      default:
        return null;
    }
  };

  return renderStep();
};

export default MyVehiclesDetailsPage;
