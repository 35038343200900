export const AuctionStatus = {
  CANCELLED: 'CANCELLED',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  SOLD: 'SOLD',
  TO_ACCEPT: 'TO_ACCEPT',
  WAITING: 'WAITING',
  ENDED: 'ENDED',
};

export const AuctionStatusName = {
  CANCELLED: 'Cancelled',
  DRAFT: 'Parked',
  PUBLISHED: 'Live',
  REJECTED: 'Declined Offer',
  SOLD: 'Sold',
  TO_ACCEPT: 'To accept',
  WAITING: 'Waiting',
  ENDED: 'Ended',
};

export const AuctionStatusColors = {
  CANCELLED: '#f46363',
  DRAFT: '#aaa7a4',
  PUBLISHED: '#174e86',
  REJECTED: '#cc2727',
  SOLD: '#297422',
  TO_ACCEPT_BUYER: '#55b84b',
  TO_ACCEPT_SELLER: '#fbc658',
  WAITING: '#fbc658',
  ENDED: '#b12983',
};

export const AuctionStatusFromSse = {
  AUCTION_WAITING: 'WAITING',
  AUCTION_TO_ACCEPT: 'TO_ACCEPT',
  AUCTION_SOLD: 'SOLD',
  AUCTION_REJECTED: 'REJECTED',
  AUCTION_CANCELLED: 'CANCELLED',
  AUCTION_ENDED: 'ENDED',
};
