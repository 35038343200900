export const capitalizeString = (string, allWords = false) => {
  if (typeof string === 'string') {
    if (!allWords) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    const stringsArray = string.split(' ');
    if (stringsArray) {
      return stringsArray
        .map(item => {
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        })
        .join(' ');
    }
  }
  return null;
};
