export const AuctionStatusType = {
  PHYSICAL: 'PHYSICAL',
  MINIMUM_PRICE: 'MINIMUM_PRICE',
  TRYING_TO_DEAL: 'TRYING_TO_DEAL',
};

export const AuctionStatusTypeName = {
  TRYING_TO_DEAL: 'Trying to Deal',
  MINIMUM_PRICE: 'Minimum Offer',
  PHYSICAL: 'Physical Vehicle',
};
