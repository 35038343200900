import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Provider } from 'react-redux';
import { setUserInSession } from './auth/AuthActions';
import { prepareStore } from './redux/Store';
import history from './browserHistory';
import AlertContainer from './common/components/alert/AlertContainer';
import './App.css';
import Header from './common/components/Header/Header';
import Content from './common/components/Content/Content';
import Footer from './footer/Footer';
import AuthRefreshToken from './auth/AuthRefreshToken';
import RouterPaths, { DIRECT_DEBIT } from './constants/RouterPaths';
import StripeDirectDebitCheckout from './stripedirectdebit/StripeDirectDebitCheckout';
import StripeDirectDebitSuccess from './stripedirectdebit/StripeDirectDebitSuccess';
import StripeDirectDebitCancel from './stripedirectdebit/StripeDirectDebitCancel';
import NotFound from './notfound/NotFound';

export const store = prepareStore();
setUserInSession(store.dispatch);

// prevent sessionStorage duplication (for tab duplicate)
window.addEventListener('beforeunload', () => {
  sessionStorage.removeItem('__lock');
});
if (sessionStorage.getItem('__lock')) {
  sessionStorage.clear();
}
sessionStorage.setItem('__lock', '1');

const StripeDirectDebitRoutes = () => {
  return (
    <div className="stripe-direct-debit-wrapper">
      <Switch>
        <Route
          path={RouterPaths.STRIPE_CHECKOUT}
          component={StripeDirectDebitCheckout}
          exact
        />
        <Route path={RouterPaths.STRIPE_SUCCESS} component={StripeDirectDebitSuccess} exact />
        <Route path={RouterPaths.STRIPE_CANCEL} component={StripeDirectDebitCancel} exact />
        <Route component={NotFound} exact />
      </Switch>
    </div>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <AuthRefreshToken />
    <Router history={history}>
      {history?.location?.pathname.includes(DIRECT_DEBIT) ? (
        <StripeDirectDebitRoutes />
      ) : (
        <>
          <Header />
          <Content />
          <Footer />
          <AlertContainer />
        </>
      )}
    </Router>
  </Provider>,
  document.getElementById('root'),
);
