export const BidStatusType = {
  PLACED_BIDS: 'PLACED_BIDS',
  WAITING: 'WAITING',
  TO_ACCEPT: 'TO_ACCEPT',
  WON: 'WON',
  LOST: 'LOST',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
};

export const BidStatusTypeName = {
  PLACED_BIDS: 'Placed bids',
  WAITING: 'Waiting',
  TO_ACCEPT: 'To accept',
  WON: 'Won',
  LOST: 'Lost',
  REJECTED: 'Declined Offer',
  CANCELLED: 'Cancelled',
};
