import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RoleForm from './RoleForm';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import AddProfileForm from './AddProfileForm';
import AddPaymentDetailsForm from './AddPaymentDetailsForm';
import RegisterSuccess from '../RegisterSuccess';

const RegisterForm = ({ values, setFieldValue }) => {
  const [step, setStep] = useState(1);

  const prevStep = () => {
    setStep(prevState => prevState - 1);
  };

  const nextStep = () => {
    setStep(prevState => prevState + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <RoleForm nextStep={nextStep} values={values} />;
      case 2:
        return (
          <OrganizationDetailsForm
            prevStep={prevStep}
            nextStep={nextStep}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case 3:
        return <AddProfileForm prevStep={prevStep} nextStep={nextStep} values={values} />;
      case 4:
        return <AddPaymentDetailsForm nextStep={nextStep} values={values} />;
      case 5:
        return <RegisterSuccess />;
      default:
        return null;
    }
  };

  return <>{renderStep()}</>;
};

RegisterForm.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default RegisterForm;
