export const constantToSelect = data => {
  const result = [];
  Object.keys(data).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key]) {
      result.push({
        value: key,
        label: data[key],
      });
    }
  });
  return result;
};
