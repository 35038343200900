import * as Yup from 'yup';
import { FormRegExp } from '../../constants/FormRegExp';

export default () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, 'First name is too long - should be 50 chars maximum'),
    lastName: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, 'Last name is too long - should be 50 chars maximum'),
    email: Yup.string()
      .required('Field is required')
      .email('Email has the wrong format'),
    phoneNumber: Yup.string()
      .max(15, 'Max length equals 15 characters')
      .required('Field is required')
      .matches(FormRegExp.INTEGER, 'Type only numbers'),
    password: Yup.string().min(6, 'Min length equals 6 characters'),
    passwordRepeat: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must be the same',
    ),
    acceptNewsletter: Yup.string().required('Field is required'),
  });
};
