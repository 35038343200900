import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import { searchEmployees } from '../DashboardActions';
import EmployeesSearch from './search/EmployeesSearch';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';
import CommonButton from '../../common/components/CommonButton';
import EmployeesTable from './table/EmployeesTable';
import RouterPaths from '../../constants/RouterPaths';

const EmployeesPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.myOrganization.get('items'));
  const totalItems = useSelector(state => state.myOrganization.get('totalItems'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const [hasAccess, setHasAccess] = useState(false);

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    if (!isOwner) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isOwner, history]);

  useEffect(() => {
    dispatch(searchEmployees(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  return (
    <>
      {hasAccess && (
        <CommonMyOrganizationContainer>
          <h2 className="text-center font-weight-bold text-uppercase">My employees</h2>
          <Row className="d-flex justify-content-between align-items-center mb-3">
            <Col md="9">
              <EmployeesSearch handleSearch={handleSearch} />
            </Col>
            <Col md="3" className="employees-add-new-button-wrapper mt-2">
              <CommonButton
                label="Add new"
                handleClick={() => history.push(RouterPaths.CREATE_EMPLOYEE)}
              />
            </Col>
          </Row>
          <Row>
            <EmployeesTable
              data={{ items, total: totalItems }}
              offset={searchParams.offset}
              changeOffset={changeOffset}
            />
          </Row>
        </CommonMyOrganizationContainer>
      )}
    </>
  );
};

export default withRouter(EmployeesPage);
