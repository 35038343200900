import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field } from 'formik';

const FormCheckboxFieldInner = props => {
  const { label, form, field, disabled, id } = props;
  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || false;

  return (
    <FormGroup check disabled={form.isSubmitting}>
      <Label check className={`${disabled ? 'form-checkbox__label--disabled' : ''}`}>
        <Input
          type="checkbox"
          name={field.name}
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={({ target }) => form.setFieldValue(field.name, target.checked)}
          checked={value}
          disabled={disabled}
          id={id}
        />
        <span className="form-check-sign">
          <span className="check" />
        </span>
        {label}
      </Label>
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </FormGroup>
  );
};

const FormCheckboxField = props => {
  return <Field {...props} component={FormCheckboxFieldInner} />;
};

FormCheckboxField.defaultProps = {
  disabled: false,
};

FormCheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormCheckboxField;
