import { PhotoPerspective } from '../../constants/PhotoPerspective';

export const checkPhotoStep = value => {
  switch (value?.perspective) {
    case PhotoPerspective.MASTER:
      return 1;
    case PhotoPerspective.LEFT_FRONT:
      return 2;
    case PhotoPerspective.LEFT_SIDE:
      return 3;
    case PhotoPerspective.LEFT_REAR:
      return 4;
    case PhotoPerspective.REAR:
      return 5;
    case PhotoPerspective.RIGHT_REAR:
      return 6;
    case PhotoPerspective.RIGHT_SIDE:
      return 7;
    case PhotoPerspective.RIGHT_FRONT:
      return 8;
    case PhotoPerspective.INTERIOR_1:
      return 9;
    case PhotoPerspective.INTERIOR_2:
      return 10;
    case PhotoPerspective.INTERIOR_3:
      return 11;
    case PhotoPerspective.INTERIOR_4:
      return 12;
    default:
      return 13;
  }
};
