import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { handleCarDataAutofil } from '../AddVehicleActions';
import FormInputField from '../../common/components/formFields/FormInputField';

const ConfirmNumber = ({ nextForm, prevForm, values }) => {
  const dispatch = useDispatch();
  const { SECONDARY } = CommonButtonVariants;

  const moveToNextStep = e => {
    if (e.target.classList.contains('primary-button')) {
      dispatch(handleCarDataAutofil(true));
    } else {
      dispatch(handleCarDataAutofil(false));
    }
    nextForm();
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="ml-auto mr-auto " lg="6" md="6" sm="12">
          <div className="vehicle-not-found__container">
            <h6 className="vehicle-not-found__title">ADD VEHICLE</h6>

            <FormInputField
              className="vehicle-not-found__input"
              type="text"
              label="Vehicle registration plate"
              initialValue={values.basicData?.registrationPlate}
              upperCase
              disabled
            />
            <div className="vehicle-not-found__number-field">
              <p className="vehicle-not-found__question">
                We could not find the data for this vehicle.
              </p>
              <p className="vehicle-not-found__question">
                Do you want to change the registration number?
              </p>
            </div>

            <Row className="d-flex flex-column">
              <Col className="d-flex justify-content-between">
                <CommonButton
                  label="no"
                  className="vehicle-not-found__button"
                  handleClick={moveToNextStep}
                  variant={SECONDARY}
                />
                <CommonButton
                  label="yes"
                  className="vehicle-not-found__button"
                  handleClick={prevForm}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ConfirmNumber.propTypes = {
  values: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
  prevForm: PropTypes.func.isRequired,
};

export default ConfirmNumber;
