import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { CommonString } from 'constants/CommonString';
import { useSelector } from 'react-redux';
import noImageImg from '../assets/img/search/noimage.svg';
import { AuctionStatus, AuctionStatusColors } from '../constants/AuctionStatus';
import { capitalizeString } from '../common/helpers/capitalizeString';
import { checkIsPropertySpecified } from '../common/helpers/checkIsPropertySpecified';
import { PhotoPerspective } from '../constants/PhotoPerspective';
import { AuctionItemMessage } from '../constants/AuctionItemMessage';
import OrganizationRoleBox from './OrganizationRoleBox';
import { pencesToPounds } from '../common/helpers/pencesToPounds';
import AuctionTypeLabel from './AuctionTypeLabel';
import { getPriceColor } from '../common/helpers/auctionColorHelper';
import AuctionTimeLabel from './AuctionTimeLabel';
import { auctionListType } from '../constants/AuctionListType';
import CurrentBidLabel from './CurrentBidLabel';

const MyVehiclesItem = ({ item, onClickItem, getRef }) => {
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const [heightOfItem, setHeightOfItem] = useState(0);
  const [bidBarColor, setBidBarColor] = useState('');
  const [bidBarMessage, setBidBarMessage] = useState('');
  const [windowFocus, setWindowFocus] = useState(false);
  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    renderMyBidItemMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  let image = noImageImg;
  if (item?.vehicle?.vehicleMedia?.photos?.length) {
    const masterPhoto = item?.vehicle?.vehicleMedia?.photos?.find(
      item => item?.perspective === PhotoPerspective.MASTER,
    );
    image = masterPhoto?.fileUrl || noImageImg;
  }

  const title =
    (item?.vehicle?.vehicleModel &&
      `${item?.vehicle?.vehicleModel?.makeName} ${item?.vehicle?.vehicleModel?.name} ${item?.vehicle?.derivative}`) ||
    CommonString.VEHICLE_NOT_SPECIFIED;

  const organizationRole = item?.organizationRole || item?.seller?.organizationContact?.role;

  const renderAuctionType = (isDesktop = true) => {
    if (!item.type) {
      return null;
    }
    return (
      <>
        <p
          className={`auction-item__status-type ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />
        </p>
        <p
          className={`auction-item__status-type ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <CurrentBidLabel hasBid={!!item?.highestBidInPence} type={item.type} />
          <span className="search-min-price" style={getPriceColor(item.shouldPriceBeGreen)}>
            <CurrencyFormat
              value={
                pencesToPounds(item?.highestBidInPence) ||
                pencesToPounds(item?.minimumPriceInPence) ||
                0
              }
              thousandSeparator={true}
              allowNegative={false}
              prefix="£"
              displayType="text"
            />
          </span>
        </p>
      </>
    );
  };

  const renderMyBidItemMessage = () => {
    let message;
    let statusColor;

    switch (item?.status) {
      case AuctionStatus.DRAFT:
        message = AuctionItemMessage.DRAFT;
        statusColor = AuctionStatusColors.DRAFT;
        break;
      case AuctionStatus.PUBLISHED:
        message = AuctionItemMessage.PUBLISHED;
        statusColor = AuctionStatusColors.PUBLISHED;
        break;
      case AuctionStatus.CANCELLED:
        message = AuctionItemMessage.CANCELLED;
        statusColor = AuctionStatusColors.CANCELLED;
        break;
      case AuctionStatus.TO_ACCEPT:
        message = AuctionItemMessage.TO_ACCEPT_SELLER;
        statusColor = AuctionStatusColors.TO_ACCEPT_BUYER;
        break;
      case AuctionStatus.WAITING:
        message = AuctionItemMessage.WAITING;
        statusColor = AuctionStatusColors.TO_ACCEPT_SELLER;
        break;
      case AuctionStatus.REJECTED:
        message = AuctionItemMessage.REJECTED;
        statusColor = AuctionStatusColors.REJECTED;
        break;
      case AuctionStatus.SOLD:
        message = AuctionItemMessage.SOLD;
        statusColor = AuctionStatusColors.SOLD;
        break;
      case AuctionStatus.ENDED:
        message = AuctionItemMessage.ENDED;
        statusColor = AuctionStatusColors.ENDED;
        break;
      default:
        return null;
    }
    setBidBarColor(statusColor);
    setBidBarMessage(message);
    return { message, statusColor };
  };

  return (
    <Row
      className="search-box-of-car my-vehicles-box-of-car search-box-of-car--mobile"
      key={item.id}
      onClick={onClickItem}
    >
      <Col
        xs={7}
        md="3"
        className="search-box-of-img search-box-of-img--mobile"
        style={{ height: `${heightOfItem}px` }}
      >
        <div className="search-box-of-img-wrapper" ref={el => getRef(el)}>
          <OrganizationRoleBox role={organizationRole} />
          <img
            className="search-img-in-box"
            src={image}
            alt="car"
            onError={e => {
              e.target.src = noImageImg;
            }}
          />
        </div>
        <div className="search-title-wrapper--mobile">
          <p className="search-title--mobile">{title}</p>
        </div>
        <div className="search-status-wrapper--mobile">{renderAuctionType(false)}</div>
      </Col>
      <Col xs={5} md="9">
        <div
          ref={el => {
            if (!el) return;
            setHeightOfItem(el.getBoundingClientRect().height + 15);
          }}
        >
          <Row className="auction-basic-data-row">
            <Col md="8" className="search-content-left">
              <p className="search-title">{checkIsPropertySpecified(title, true)}</p>
              <Row md="12" lg="10" className="auction-basic-data-wrapper">
                <Col md="6" lg="4" className="search-content__inner-left">
                  <p className="auction-item__basic-car-data search-year">
                    {checkIsPropertySpecified(item?.vehicle?.year)}
                  </p>
                  <p className="auction-item__basic-car-data search-registration">
                    {checkIsPropertySpecified(item?.vehicle?.registrationPlate)}
                  </p>
                  <p
                    className={`auction-item__basic-car-data ${
                      !item?.vehicle?.mileage ? 'empty-search-mileage' : ''
                    } search-mileage`}
                  >
                    {checkIsPropertySpecified(item?.vehicle?.mileage)}
                  </p>
                </Col>
                <Col md="6">
                  <p className="auction-item__basic-car-data search-transmission">
                    {checkIsPropertySpecified(capitalizeString(item?.vehicle?.transmission))}
                  </p>
                  <p className="auction-item__basic-car-data search-fuel">
                    {checkIsPropertySpecified(item?.vehicle?.fuel)}
                  </p>
                  <p className="auction-item__basic-car-data search-city">
                    {checkIsPropertySpecified(item?.location?.city)}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="search-content-right">
              <AuctionTimeLabel
                auctionTimestamp={item?.timeStamp}
                item={item}
                listType={auctionListType.MY_VEHICLES}
                organizationId={organizationId}
                windowFocus={windowFocus}
              />
              {renderAuctionType(true)}
            </Col>
          </Row>
        </div>
      </Col>
      {bidBarMessage && bidBarColor && (
        <Col xs="12" className="my-vehicles-warning" style={{ backgroundColor: bidBarColor }}>
          <p className="my-vehicles-warning-text">{bidBarMessage}</p>
        </Col>
      )}
    </Row>
  );
};
MyVehiclesItem.defaultProps = {
  onClickItem: () => {},
  getRef: () => {},
};
MyVehiclesItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClickItem: PropTypes.func,
  getRef: PropTypes.func,
};

export default withRouter(MyVehiclesItem);
