import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import { confirmStripeCardSetup } from '../common/helpers/confirmStripeCardSetup';
import { clearStripeValidationMessage } from '../register/RegisterActions';
import { GET_PAYMENT_METHOD_SUCCESS } from './MyWalletReducer';

const ACCOUNT_API_ENDPOINT = '/account';
export const STRIPE_DIRECT_DEBIT_CHECKOUT_API_ENDPOINT = '/payment/direct-debit/checkout';

export const updateCreditCard = (stripePaymentMethodId, successCallback) => dispatch => {
  dispatch(showLoader());
  return apiClient
    .put(`${ACCOUNT_API_ENDPOINT}/refresh-credit-card`, { stripePaymentMethodId })
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Credit card updated successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          dispatch(getPaymentMethod());
          successCallback();
        }
      }
    })
    .catch(err => {
      return dispatch(handleError(err));
    });
};

export const getStripeAccountClientSecret = (
  values,
  stripe,
  elements,
  updateCallback,
) => dispatch => {
  dispatch(clearStripeValidationMessage());
  dispatch(showLoader());

  return apiClient
    .get(`${ACCOUNT_API_ENDPOINT}/organization-stripe-client-secret`, {
      timeout: 10000,
    })
    .then(async res => {
      if (res.status === 200) {
        const stripeResponse = await confirmStripeCardSetup(
          stripe,
          elements,
          res,
          values,
          dispatch,
        );
        if (!stripeResponse?.error) {
          updateCallback(stripeResponse?.setupIntent?.payment_method);
        } else {
          dispatch(hideLoader());
        }
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch(hideLoader());
    });
};

export const getPaymentMethod = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${ACCOUNT_API_ENDPOINT}/organization-payment-method`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_PAYMENT_METHOD_SUCCESS,
          payload: {
            last4: res?.data?.data?.last4,
            paymentMethod: res?.data?.data?.paymentMethod,
          },
        });
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const getStripeDirectDebitCheckoutSession = stripe => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(STRIPE_DIRECT_DEBIT_CHECKOUT_API_ENDPOINT)
    .then(async res => {
      if (res.status === 200) {
        await stripe.redirectToCheckout({
          sessionId: res?.data?.data?.checkoutSessionId,
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch(hideLoader());
    });
};
