import React from 'react';
import PropTypes from 'prop-types';
import { DealerRoleLabel } from 'itrade-admin-panel/src/constants/DealerRole';

const OrganizationRoleBox = ({ role, listView = true }) => {
  const label = DealerRoleLabel[role] || role;
  return label ? (
    <div
      className={`organization-role-box organization-role-box-${label.toLowerCase()} ${
        listView ? 'organization-role-box-list' : ''
      }`}
    >
      {label}
    </div>
  ) : null;
};

OrganizationRoleBox.propTypes = {
  role: PropTypes.string.isRequired,
};

export default OrganizationRoleBox;
