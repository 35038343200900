import React from 'react';
import StripeDirectDebitWrapper from './StripeDirectDebitWrapper';

const StripeDirectDebitSuccess = () => {
  return (
    <StripeDirectDebitWrapper
      title="Your payment method successfully added"
      text="Direct debit for your account has been successfully set up. You can now use iTrade30."
    />
  );
};

export default StripeDirectDebitSuccess;
