import { get } from 'lodash';
import moment from 'moment';
import { apiClient } from '../api/ApiClient';
import {
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_INVOICES_SUCCESS,
  FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_STATISTICS_SUCCESS,
  FETCH_TRANSACTIONS_SUCCESS,
} from './DashboardReducer';
import { prepareSearchData, SORT_ASC, SORT_DESC } from '../common/helpers/prepareSearchData';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import RouterPaths from '../constants/RouterPaths';
import history from '../browserHistory';
import AlertVariant from '../common/components/alert/AlertVariant';
import { UserRole } from '../constants/UserRole';
import { REVERSE_DASH_DATE_FORMAT } from '../common/utils/date/Date.utils';
import { prepareFilename } from '../common/helpers/prepareFilename';
import { FileExtensions } from '../constants/FileExtensions';

const ACCOUNT_API_ENDPOINT = '/account';
const ORGANIZATION_API_ENDPOINT = '/organization';
const PDF_TYPE = 'application/pdf';
const INVOICE = 'Invoice';

export const searchEmployees = values => dispatch => {
  dispatch(showLoader());
  return apiClient
    .post(
      `${ACCOUNT_API_ENDPOINT}/employee/search`,
      prepareSearchData(values, { lastName: SORT_ASC, firstName: SORT_ASC }, true),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_EMPLOYEES_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const fetchOrganizationDetails = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${ACCOUNT_API_ENDPOINT}/organization`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ORGANIZATION_SUCCESS,
          payload: {
            organizationDetails: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const fetchOrganizationStatistics = () => dispatch => {
  let dateFrom = new Date();
  const dateTo = null;
  dateFrom = moment(dateFrom.setDate(dateFrom.getDate() - 30)).format(
    REVERSE_DASH_DATE_FORMAT,
  );

  dispatch(showLoader());
  return apiClient
    .post(`${ACCOUNT_API_ENDPOINT}/statistic`, {
      dateFrom,
      dateTo,
    })
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_STATISTICS_SUCCESS,
          payload: {
            organizationStatistics: res?.data?.data,
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const saveOrganizationDetails = (
  values,
  prevAdditionalLocation,
  locationIdsToDelete,
) => dispatch => {
  const {
    landlinePhoneNumber,
    postalCodeId,
    address,
    address2,
    city,
    county,
    additionalLocation,
    mainLocationVersion,
    website,
  } = values;

  const locationsToSave =
    additionalLocation &&
    additionalLocation
      .filter(item => !item.id)
      .map(item => {
        return {
          postalCodeId: item?.postalCode?.value,
          address: item?.address,
          address2: item?.address2?.trim() || null,
          city: item?.city,
          county: item?.county,
        };
      });
  const data = {
    locationIdsToDelete: locationIdsToDelete.length > 0 ? locationIdsToDelete : null,
    locationsToSave: locationsToSave.length > 0 ? locationsToSave : null,
    mainLocation: {
      postalCodeId: postalCodeId.value,
      address,
      address2: address2?.trim() || null,
      city,
      county,
      version: mainLocationVersion,
    },
    landlinePhoneNumber: landlinePhoneNumber || null,
    website: website?.trim() || null,
  };

  dispatch(showLoader());
  return apiClient
    .put(`${ACCOUNT_API_ENDPOINT}/organization`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Organization details updated successfully', AlertVariant.SUCCESS));
        dispatch(fetchOrganizationDetails());
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        dispatch(showAlert('Access denied', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const fetchEmployeeDetails = id => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${ACCOUNT_API_ENDPOINT}/employee/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_EMPLOYEE_SUCCESS,
          payload: {
            employeeDetails: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const removeEmployee = id => dispatch => {
  dispatch(showLoader());
  return apiClient
    .delete(`${ACCOUNT_API_ENDPOINT}/employee/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Employee removed successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.EMPLOYEES);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const updateEmployeeDetails = (values, id, setErrors) => dispatch => {
  dispatch(showLoader());
  const { location, position, role, password, passwordRepeat, version } = values;
  const data = {
    password: password || null,
    passwordRepeat: passwordRepeat || null,
    locationId: location?.value,
    position: position?.value,
    isBuyer: !!role.some(item => item === UserRole.BUYER),
    isSeller: !!role.some(item => item === UserRole.SELLER),
    isTransactionAcceptor: !!role.some(item => item === UserRole.TRANSACTION_MANAGEMENT),
    version,
  };
  return apiClient
    .put(`${ACCOUNT_API_ENDPOINT}/employee/${id}`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Employee updated successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.EMPLOYEES);
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        setErrors(err?.response?.data?.data);
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const updateRejectedEmployeeDetails = (values, id, setErrors) => dispatch => {
  dispatch(showLoader());
  const { location, position, role, password, passwordRepeat, version } = values;
  const data = {
    id,
    ...values,
    password: password || null,
    passwordRepeat: passwordRepeat || null,
    locationId: location?.value,
    position: position?.value,
    isBuyer: !!role.some(item => item === UserRole.BUYER),
    isSeller: !!role.some(item => item === UserRole.SELLER),
    isTransactionAcceptor: !!role.some(item => item === UserRole.TRANSACTION_MANAGEMENT),
    version,
  };
  return apiClient
    .put(`${ACCOUNT_API_ENDPOINT}/employee/rejected/${id}`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Employee updated successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.EMPLOYEES);
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        setErrors(err?.response?.data?.data);
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const createEmployee = (values, setErrors) => dispatch => {
  dispatch(showLoader());
  const data = {
    ...values,
    locationId: values?.location?.value,
    position: values?.position?.value,
    isBuyer: values?.role?.includes(UserRole.BUYER),
    isSeller: values?.role?.includes(UserRole.SELLER),
    isTransactionAcceptor: values?.role?.includes(UserRole.TRANSACTION_MANAGEMENT),
  };
  return apiClient
    .post(`${ACCOUNT_API_ENDPOINT}/employee`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Employee created successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.EMPLOYEES);
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        setErrors(err?.response?.data?.data);
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const fetchOrganizationLocations = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${ACCOUNT_API_ENDPOINT}/organization-locations`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
          payload: {
            organizationLocations: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const searchTransactions = values => dispatch => {
  const sortOptions = { createdOn: SORT_DESC };

  dispatch(showLoader());
  return apiClient
    .post(`${ORGANIZATION_API_ENDPOINT}/transactions`, prepareSearchData(values, sortOptions))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_TRANSACTIONS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const searchInvoices = values => dispatch => {
  const sortOptions = { createdOn: SORT_DESC };

  dispatch(showLoader());
  return apiClient
    .post(`${ORGANIZATION_API_ENDPOINT}/invoices`, prepareSearchData(values, sortOptions))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_INVOICES_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const downloadInvoice = ({ id, invoiceNumber }) => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${ORGANIZATION_API_ENDPOINT}/invoices/${id}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    })
    .then(res => {
      if (res.status === 200) {
        const url = URL.createObjectURL(new Blob([res.data], { type: PDF_TYPE }));
        const link = document.createElement('a');
        link.href = url;
        link.download = prepareFilename([INVOICE, invoiceNumber], FileExtensions.PDF);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};
