import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonMyOrganizationNav from './CommonMyOrganizationNav';

const CommonMyOrganizationContainer = ({ children }) => {
  return (
    <Row>
      <CommonMyOrganizationNav />
      <Col md={9} className="my-organization-children-col">
        {children}
      </Col>
    </Row>
  );
};

CommonMyOrganizationContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export default CommonMyOrganizationContainer;
