import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useKeenSlider } from 'keen-slider/react';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import { isEmpty } from 'lodash';
import { DamageTypesName } from 'constants/DamageTypes';
import { prepareDamagesDescription } from 'common/helpers/prepareDamagesDescription';
import iconPoint from '../../assets/img/grupa11.png';
import warningIcon from '../../assets/img/Ripple-2.7s-211px.gif';
import 'keen-slider/keen-slider.min.css';
import { UploadFileTypes } from '../../constants/UploadFileTypes';

const VehicleImagesPopupSlider = ({
  photos,
  videos,
  initial,
  onDamageClick,
  showImageTitle,
}) => {
  const [currentSlide, setCurrentSlide] = useState(initial);
  const [hidden, setHidden] = useState(true);
  const [sliderRef, slider] = useKeenSlider({
    initial,
    spacing: 5,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    mounted: () => {
      setHidden(false);
    },
  });
  const [secondSliderRef, secondSlider] = useKeenSlider({
    initial,
    slidesPerView: 6,
  });

  const filteredRequiredPhotos = photos?.filter(item => item?.id);

  return (
    <div className="popup__slider-wrapper">
      <div ref={sliderRef} className="keen-slider popup__slider">
        {filteredRequiredPhotos?.length &&
          filteredRequiredPhotos.map(item =>
            item?.fileUrl ? (
              <div
                key={item.id}
                className={`keen-slider__slide popup__slide-container ${
                  hidden ? 'hidden' : ''
                }`}
              >
                <div className="popup__slide">
                  <img
                    src={item.fileUrl}
                    alt="Auction vehicle"
                    className="popup__slide-image"
                  />
                  {!isEmpty(item?.damages) && (
                    <>
                      <button
                        type="button"
                        className="photo_gallery_damage_button"
                        onClick={() => onDamageClick(item.damages, item.perspective)}
                      >
                        <img
                          className="damages__warning-icon"
                          src={warningIcon}
                          alt="Warning icon"
                        />
                      </button>
                      <div className="photo_gallery_damage_info">
                        <span>
                          Tap <img src={iconPoint} alt="tab icon" /> to see{' '}
                          {item?.damages.length > 1 ? 'damages' : 'damage'}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {item?.damageType && showImageTitle && (
                  <span className="popup__damage-type">
                    {DamageTypesName[item.damageType]}
                  </span>
                )}
              </div>
            ) : null,
          )}
        {videos.length &&
          videos.map(item => (
            <div
              key={item.id}
              className={`keen-slider__slide popup__slide-container summary__slider_video_container ${
                hidden ? 'hidden' : ''
              }`}
            >
              <Player src={item.fileUrl} className="video-react_popup_slider">
                <BigPlayButton position="center" />
                <ControlBar />

                {!isEmpty(item?.damages) && (
                  <>
                    <button
                      type="button"
                      className="photo_gallery_damage_button"
                      onClick={() => onDamageClick(item.damages, null)}
                    >
                      <img
                        className="damages__warning-icon"
                        src={warningIcon}
                        alt="Warning icon"
                      />
                    </button>
                    <div className="photo_gallery_damage_info">
                      <span>
                        Tap <img src={iconPoint} alt="tab icon" /> to see{' '}
                        {prepareDamagesDescription(item?.damages)}
                      </span>
                    </div>
                  </>
                )}
              </Player>
              {item?.damageType && (
                <span className="popup__damage-type">{DamageTypesName[item.damageType]}</span>
              )}
            </div>
          ))}
        {slider && (
          <>
            {currentSlide > 0 && (
              <button
                type="button"
                onClick={e => e.stopPropagation() || slider.prev() || secondSlider.prev()}
                className="popup__slider-arrow-button popup__slider-arrow-button--left"
              >
                <i className="fas fa-chevron-left" />
              </button>
            )}
            {filteredRequiredPhotos?.concat(videos)?.length &&
              currentSlide < filteredRequiredPhotos.concat(videos).length - 1 && (
                <button
                  type="button"
                  onClick={e => e.stopPropagation() || slider.next() || secondSlider.next()}
                  className="popup__slider-arrow-button popup__slider-arrow-button--right"
                >
                  <i className="fas fa-chevron-right" />
                </button>
              )}
          </>
        )}
      </div>
      <div
        ref={secondSliderRef}
        className={`keen-slider popup__thumbnails-slider ${
          (filteredRequiredPhotos?.length || videos?.length) &&
          filteredRequiredPhotos?.length + videos?.length < 5
            ? `popup__thumbnails-slider-${filteredRequiredPhotos.length + videos?.length}`
            : ''
        }`}
      >
        {filteredRequiredPhotos.concat(videos).length &&
          filteredRequiredPhotos.concat(videos).map((item, idx) => (
            <aside key={item.id} className="keen-slider__slide popup-slider__slide">
              <button
                className="popup__slide-button"
                type="button"
                onClick={() => {
                  slider.moveToSlideRelative(idx, true);
                  secondSlider.moveToSlideRelative(idx, true);
                }}
              >
                <aside
                  className={`popup__thumbnails-slide ${
                    idx === currentSlide ? 'popup__slide-active' : ''
                  }`}
                >
                  <img
                    className="popup__thumbnails-slide-image"
                    src={
                      item?.type === UploadFileTypes.VIDEO_TYPE
                        ? item.previewUrl
                        : item.fileUrl
                    }
                    alt="Auction vehicle thumbnail"
                  />
                </aside>
              </button>
            </aside>
          ))}
        {secondSlider && (
          <>
            {currentSlide > 0 && (
              <button
                type="button"
                onClick={e => e.stopPropagation() || secondSlider.prev() || slider.prev()}
                className="popup__slider-arrow-button popup__slider-arrow-button--left"
              >
                <i className="fas fa-chevron-left" />
              </button>
            )}
            {filteredRequiredPhotos.concat(videos)?.length &&
              currentSlide < filteredRequiredPhotos.concat(videos).length - 1 && (
                <button
                  type="button"
                  onClick={e => e.stopPropagation() || secondSlider.next() || slider.next()}
                  className="popup__slider-arrow-button popup__slider-arrow-button--right"
                >
                  <i className="fas fa-chevron-right" />
                </button>
              )}
          </>
        )}
      </div>
    </div>
  );
};

VehicleImagesPopupSlider.propTypes = {
  photos: PropTypes.array,
  videos: PropTypes.array,
  initial: PropTypes.number,
  onDamageClick: PropTypes.func,
  showImageTitle: PropTypes.bool,
};

VehicleImagesPopupSlider.defaultProps = {
  photos: [],
  videos: [],
  initial: 0,
  showImageTitle: true,
  onDamageClick: () => {},
};

export default VehicleImagesPopupSlider;
