import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceForm from './forms/InvoiceForm';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';
import InvoicesTable from './table/InvoiceTable';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import { searchInvoices } from '../DashboardActions';
import RouterPaths from '../../constants/RouterPaths';

const InvoicesPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.myOrganization.get('invoices'));
  const totalItems = useSelector(state => state.myOrganization.get('invoicesTotalItems'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!isOwner) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isOwner, history]);

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchInvoices(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  return (
    <>
      {hasAccess && (
        <CommonMyOrganizationContainer>
          <h2 className="text-center font-weight-bold text-uppercase">My Invoice</h2>
          <Row className="d-flex justify-content-between align-items-center mb-3">
            <Col md={12}>
              <InvoiceForm handleSearch={handleSearch} />
            </Col>
          </Row>
          <Row>
            <InvoicesTable
              data={{ items, total: totalItems }}
              offset={searchParams.offset}
              changeOffset={changeOffset}
            />
          </Row>
        </CommonMyOrganizationContainer>
      )}
    </>
  );
};

export default withRouter(InvoicesPage);
