import _, { get } from 'lodash';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import {
  MY_BIDS_CLEAR_SUCCESS,
  SEARCH_MY_BIDS_SUCCESS,
  SET_BIDS_FILTER,
} from './MyBidsReducer';
import { prepareSearchData, SORT_ASC, SORT_DESC } from '../common/helpers/prepareSearchData';
import AlertVariant from '../common/components/alert/AlertVariant';

export const AUCTION_ENDPOINT = '/auction';

export const searchMyBids = (values, callback) => (dispatch, getState) => {
  dispatch(showLoader());
  const prevBids = getState().myBids?.toJS()?.items || [];
  const prevBidsIds = prevBids.map(item => item?.id);

  const sortOption = { orderByEndsOn: SORT_ASC, lastModifiedOn: SORT_DESC };

  const preparedValues = {
    ...values,
    offset: values.offset,
  };

  return apiClient
    .post(`${AUCTION_ENDPOINT}/bids`, prepareSearchData(preparedValues, sortOption))
    .then(res => {
      if (res.status === 200) {
        const items = get(res, 'data.data.content', []);
        items
          .filter(item => !prevBidsIds.includes(item))
          .map(item => {
            item.timeStamp = new Date();
            return item;
          });
        dispatch({
          type: SEARCH_MY_BIDS_SUCCESS,
          payload: {
            items,
            totalItems: values?.offset === 0 ? get(res, 'data.data.totalElements', 0) : null,
            hasMore: !get(res, 'data.data.last', true),
            offset: values.offset,
            timeStamp: new Date(),
          },
        });
        const ids = prevBidsIds || [];
        const auctionsIds = get(res, 'data.data.content', []).map(item => item.id);
        callback(_.uniq([...ids, ...auctionsIds]));
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const clearMyBids = () => dispatch => {
  dispatch({
    type: MY_BIDS_CLEAR_SUCCESS,
  });
};

export const setBidsFilter = status => dispatch => {
  dispatch({
    type: SET_BIDS_FILTER,
    payload: status,
  });
};
