import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { handleCarDataAutofil } from '../AddVehicleActions';
import FormInputField from '../../common/components/formFields/FormInputField';

const ConfirmNumber = ({ nextForm, prevForm }) => {
  const basicData = useSelector(state => state.addVehicle.get('basicData'));
  const modelName = basicData?.modelName;
  const makeName = basicData?.makeName;
  const dispatch = useDispatch();
  const { SECONDARY } = CommonButtonVariants;

  const moveToNextStep = e => {
    if (e.target.classList.contains('primary-button')) {
      dispatch(handleCarDataAutofil(true));
    } else {
      dispatch(handleCarDataAutofil(false));
    }
    nextForm();
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col className="ml-auto mr-auto " lg="6" md="6" sm="12">
            <div className="confirm-number__container">
              <h6 className="add-vehicle__title">Add Vehicle</h6>
              <FormInputField
                className="confirm-number__input"
                type="text"
                label="Vehicle registration plate"
                initialValue={basicData?.registrationPlate}
                disabled
                upperCase
              />
              <div className="confirm-number__number-field">
                <p className="confirm-number__title">Vehicle found:</p>
                <p className="confirm-number__car-number">
                  {makeName} {modelName}
                </p>
                <p className="confirm-number__question">
                  Do you want to complete the data automatically?
                </p>
              </div>

              <Row className="d-flex flex-column">
                <Col className="d-flex justify-content-between">
                  <CommonButton
                    label="no"
                    className="confirm_number__button"
                    handleClick={moveToNextStep}
                    variant={SECONDARY}
                  />
                  <CommonButton
                    label="yes"
                    className="confirm_number__button"
                    handleClick={moveToNextStep}
                  />
                </Col>

                <Col className="d-flex justify-content-start">
                  <CommonButton
                    label="back"
                    variant="secondary"
                    className="confirm_number__button"
                    handleClick={prevForm}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

ConfirmNumber.propTypes = {
  nextForm: PropTypes.func.isRequired,
  prevForm: PropTypes.func.isRequired,
};

export default ConfirmNumber;
