import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchAlertsTableRow from './SearchAlertsTableRow';
import CommonTable from '../../../common/components/CommonTable';

const SearchAlertsTable = ({
  data,
  offset,
  changeOffset,
  copyItemHandler,
  editItemHandler,
  setSearchAlertToDelete,
}) => {
  const [tableData, setTableData] = useState({ items: [], total: 0 });

  useEffect(() => {
    setTableData({
      items: data?.items,
      total: data?.total,
    });
  }, [data]);

  return (
    <CommonTable
      rowComponent={SearchAlertsTableRow}
      rowComponentProps={{
        copyItemHandler,
        editItemHandler,
        setSearchAlertToDelete,
      }}
      data={tableData}
      offset={offset}
      onOffsetChange={changeOffset}
      messageOnEmptyList="You currently have no search alerts. Click “Add” to set up an alert and receive notifications when vehicles are added."
    />
  );
};

SearchAlertsTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
  setSearchAlertToDelete: PropTypes.func.isRequired,
};

export default SearchAlertsTable;
