import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import CommonMyOrganizationContainer from '../../../common/components/CommonMyOrganizationContainer';
import validationSchema from '../forms/CreateEmployeeForm.schema';
import EmployeeForm from '../forms/EmployeeForm';
import { createEmployee } from '../../DashboardActions';

const CreateEmployeePage = () => {
  const dispatch = useDispatch();
  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    position: [],
    location: [],
    password: '',
    passwordRepeat: '',
    role: [],
    locationId: '',
    version: '',
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(createEmployee(values, setErrors));
    setSubmitting(false);
  };
  return (
    <CommonMyOrganizationContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {form => <EmployeeForm isCreateForm values={form?.values} />}
      </Formik>
    </CommonMyOrganizationContainer>
  );
};

export default CreateEmployeePage;
