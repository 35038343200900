import React from 'react';
import { useSelector } from 'react-redux';

const NavbarLoader = () => {
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  return <>{isLoading && <div className="navbar-loader" />}</>;
};

export default NavbarLoader;
