const yup = require('yup');

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .required('Field required'),

  passwordRepeat: yup
    .string()
    .required('Field is required')
    .oneOf([yup.ref('password'), null], 'Passwords must be the same'),
});

export default schema;
