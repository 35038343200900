import jwt from 'jsonwebtoken';
import history from 'browserHistory.js';
import { apiClient, setAuthorizationHeader } from '../api/ApiClient';
import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from './AuthReducer';
import { AUTH_TOKEN } from './AuthConstants';
// eslint-disable-next-line import/no-cycle
import { closeAlert, handleError, showAlert } from '../common/components/alert/AlertActions';
import { AuthApiEndpoints } from './AuthApiEndpoints';
import RouterPaths from '../constants/RouterPaths';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import { isDateBefore } from '../common/utils/date/Date.utils';
import { ErrorCodes } from '../constants/ErrorCodes';

const REFRESH_TOKEN_ENDPOINT = '/auth/refresh-token';
const IMPERSONATE_ENDPOINT = '/auth/impersonate';

const { ACCOUNT_BLOCKED, BAD_CREDENTIALS, INVALID_JWT_TOKEN } = ErrorCodes;

export const login = (email, password) => dispatch => {
  dispatch(showLoader());
  clearToken();
  apiClient
    .post('/auth/login', { email, password })
    .then(res => {
      const { status } = res.data;
      const { token } = res?.data?.data;
      if (res.status === 200 && status === 'success' && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
        history.push(RouterPaths.DASHBOARD);
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    })
    .catch(err => {
      if (err?.response?.data.code === BAD_CREDENTIALS) {
        dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      } else if (err?.response?.data.code === INVALID_JWT_TOKEN) {
        dispatch(
          showAlert(
            'User is not active or does not exist. Please contact the administrator.',
            AlertVariant.DANGER,
          ),
        );
      } else if (err?.response?.data?.code === ACCOUNT_BLOCKED) {
        dispatch(
          showAlert(
            '<div><strong>Your account is no longer active.</strong> If you would like to re-activate your account please contact a member of the team. Tel: 0116 4826044 or email: contact@itrade30.com</div>',
            AlertVariant.DANGER,
            4000,
            true,
          ),
        );
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const setUserInSession = dispatch => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  if (authToken) {
    const decoded = jwt.decode(authToken);

    const tokenExpDateInMilliseconds = decoded?.exp * 1000;
    const currentDateInMilliseconds = new Date().getTime();
    dispatch({ type: LOGIN_SUCCESS, payload: decoded });
    if (isDateBefore(tokenExpDateInMilliseconds, currentDateInMilliseconds)) {
      dispatch(logout());
    } else {
      dispatch({ type: LOGIN_SUCCESS, payload: decoded });
    }
  }
  setAuthorizationHeader(authToken, () => dispatch(logout()));
};

function deleteTokens() {
  localStorage.removeItem(AUTH_TOKEN);
}

export const logout = () => dispatch => {
  deleteTokens();
  dispatch({ type: LOGOUT });
  setAuthorizationHeader(null, () => {});
};

export const logoutUser = () => dispatch => {
  dispatch(closeAlert());
  dispatch(showLoader());
  apiClient
    .post(AuthApiEndpoints.LOGOUT)
    .then(() => {})
    .catch(() => {})
    .finally(() => {
      deleteTokens();
      dispatch({ type: LOGOUT });
      setAuthorizationHeader(null, () => {});
    })
    .finally(() => dispatch(hideLoader()));
};

export const forgotPassword = ({ email }) => dispatch => {
  dispatch(showLoader());
  apiClient
    .post(AuthApiEndpoints.PASSWORD_RESET_EMAIL, { email })
    .then(res => {
      if (res.status === 200) {
        history.push(RouterPaths.CONFIRM_FORGOT_PASSWORD);
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const resetPassword = (
  { password, passwordRepeat, token },
  failureCallback,
) => dispatch => {
  dispatch(showLoader());
  apiClient
    .post(AuthApiEndpoints.PASSWORD_RESET_PASSWORD, {
      password,
      passwordRepeat,
      token,
    })
    .then(res => {
      if (res.status === 200) {
        history.push(RouterPaths.LOGIN);
      }
    })
    .catch(err => dispatch(handleError(err)))
    .catch(failureCallback())
    .finally(() => dispatch(hideLoader()));
};

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const clearToken = () => {
  deleteTokens();
  setAuthorizationHeader(null, () => {});
};

export const refreshToken = () => dispatch =>
  apiClient
    .post(REFRESH_TOKEN_ENDPOINT, {})
    .then(res => {
      const {
        data: { token },
      } = res.data;
      if (res.status === 200 && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
      }
    })
    .catch(err => dispatch(handleError(err)));

export const impersonate = ({ organizationMemberId, token }) => dispatch => {
  dispatch(logout());
  return apiClient
    .post(IMPERSONATE_ENDPOINT, { om: organizationMemberId, t: token })
    .then(res => {
      const { status } = res.data;
      const { token } = res?.data?.data;
      if (res.status === 200 && status === 'success' && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
        history.push(RouterPaths.HOMEPAGE);
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    })
    .catch(err => dispatch(handleError(err)));
};
