import RouterPaths from './RouterPaths';

export const InformationForUsersList = [
  {
    label: 'Terms and conditions',
    path: RouterPaths.TERMS_AND_CONDITIONS,
  },
  {
    label: 'Privacy Policy',
    path: RouterPaths.PRIVACY_POLICY,
  },
  {
    label: 'FAQ',
    path: RouterPaths.FAQ,
  },
  {
    label: 'Contact us',
    path: RouterPaths.CONTACT_US,
  },
];
