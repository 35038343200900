export const ALL_SELECTED = {
  value: -1,
  label: 'All',
};

export const NATIONAL_SELECTED = {
  value: -1,
  label: 'National',
};

export const mapToNullIfAllSelected = value => {
  return value === -1 ? null : value;
};

export const mapToAllIfNull = (id, label) => {
  return !id
    ? ALL_SELECTED
    : {
        value: id?.toString(),
        label: label?.toString(),
      };
};

export const mapToNationalIfNull = (id, label) => {
  return !id
    ? NATIONAL_SELECTED
    : {
        value: id?.toString(),
        label: label?.toString(),
      };
};
