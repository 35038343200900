import { FormRegExp } from '../../constants/FormRegExp';
import { pencesToPounds } from './pencesToPounds';

export const preparePriceFormat = (price, isPence) => {
  const preparedPrice = isPence ? pencesToPounds(price) : price;
  if (typeof price === 'number') {
    return `£${preparedPrice.toString().replace(FormRegExp.PRICE, ',')}`;
  }
  return '';
};
