import { DamageTypes, DamageTypesName } from '../../constants/DamageTypes';

const generateValue = damage => {
  if (typeof damage !== 'object') {
    switch (damage) {
      case DamageTypes.DENT:
        return { value: DamageTypes.DENT, label: DamageTypesName.DENT };
      case DamageTypes.DENT_SMART_REPAIR:
        return {
          value: DamageTypes.DENT_SMART_REPAIR,
          label: DamageTypesName.DENT_SMART_REPAIR,
        };
      case DamageTypes.SCRATCH:
        return { value: DamageTypes.SCRATCH, label: DamageTypesName.SCRATCH };
      case DamageTypes.SCRATCH_SMART_REPAIR:
        return {
          value: DamageTypes.SCRATCH_SMART_REPAIR,
          label: DamageTypesName.SCRATCH_SMART_REPAIR,
        };
      case DamageTypes.CHIP:
        return { value: DamageTypes.CHIP, label: DamageTypesName.CHIP };
      case DamageTypes.CHIPS:
        return { value: DamageTypes.CHIPS, label: DamageTypesName.CHIPS };
      case DamageTypes.PAINT_OFF_COLOUR:
        return {
          value: DamageTypes.PAINT_OFF_COLOUR,
          label: DamageTypesName.PAINT_OFF_COLOUR,
        };
      case DamageTypes.PAINT_PREVIOUS_REPAIR:
        return {
          value: DamageTypes.PAINT_PREVIOUS_REPAIR,
          label: DamageTypesName.PAINT_PREVIOUS_REPAIR,
        };
      case DamageTypes.RUST:
        return { value: DamageTypes.RUST, label: DamageTypesName.RUST };
      case DamageTypes.SCUFF:
        return { value: DamageTypes.SCUFF, label: DamageTypesName.SCUFF };
      case DamageTypes.BROKEN_ELEMENT:
        return { value: DamageTypes.BROKEN_ELEMENT, label: DamageTypesName.BROKEN_ELEMENT };
      case DamageTypes.WHEEL_DAMAGE:
        return { value: DamageTypes.WHEEL_DAMAGE, label: DamageTypesName.WHEEL_DAMAGE };
      case DamageTypes.OTHER:
        return { value: DamageTypes.OTHER, label: DamageTypesName.OTHER };
      default:
        return '';
    }
  }
  return damage;
};

export const getOptionsFromDraft = damageType => {
  return generateValue(damageType);
};
