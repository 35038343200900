import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  items: [],
  totalItems: 0,
  organizationDetails: {},
  organizationLocations: {},
  organizationStatistics: {},
  employeeDetails: {},
  transactions: [],
  invoices: [],
  transactionsTotalItems: 0,
  invoicesTotalItems: 0,
});

export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_ORGANIZATION_LOCATIONS_SUCCESS = 'FETCH_ORGANIZATION_LOCATIONS_SUCCESS';
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case FETCH_EMPLOYEES_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case FETCH_ORGANIZATION_SUCCESS:
      return state.merge({
        organizationDetails: payload.organizationDetails,
      });
    case FETCH_STATISTICS_SUCCESS:
      return state.merge({
        organizationStatistics: payload.organizationStatistics,
        hasAccessToWidget: true,
      });
    case FETCH_EMPLOYEE_SUCCESS:
      return state.merge({
        employeeDetails: payload.employeeDetails,
      });
    case FETCH_ORGANIZATION_LOCATIONS_SUCCESS:
      return state.merge({
        organizationLocations: payload.organizationLocations,
      });
    case FETCH_TRANSACTIONS_SUCCESS:
      return state.merge({
        transactions: payload.items,
        transactionsTotalItems: payload.totalItems,
      });
    case FETCH_INVOICES_SUCCESS:
      return state.merge({
        invoices: payload.items,
        invoicesTotalItems: payload.totalItems,
      });
    default:
      return state;
  }
};
