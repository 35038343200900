import React from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'reactstrap';
import { Form, Formik } from 'formik';
import { forgotPassword } from '../AuthActions';
import CommonButton from '../../common/components/CommonButton';
import validationSchema from './ForgotPasswordPage.schema';
import FormInputField from '../../common/components/formFields/FormInputField';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const { DISABLED, PRIMARY } = CommonButtonVariants;

const initialValues = {
  email: '',
};

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const handleSubmit = ({ email }) => {
    dispatch(forgotPassword({ email }));
  };

  return (
    <Col className="ml-auto mr-auto" lg={5} md={6}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {form => {
          return (
            <Form className="forgot-password-form">
              <h3 className="text-uppercase font-weight-bold text-center">
                Forgot your password
              </h3>
              <FormInputField name="email" label="E-mail address" />
              <CommonButton
                type="submit"
                label="Reset password"
                className="forgot-password-form__submit-button"
                disabled={!form?.values?.email}
                variant={!form?.values?.email ? DISABLED : PRIMARY}
              />
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export default ForgotPasswordPage;
