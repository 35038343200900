import { PerspectiveType } from 'itrade-admin-panel/src/constants/PerspectiveType';
import { PhotoPerspective } from '../../constants/PhotoPerspective';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';

export const sortedByPerspective = auctionPhotos => {
  const obligatoryPhotos = auctionPhotos.filter(el => el.perspective !== 'OTHER');
  const additionalPhotos = auctionPhotos.filter(el => el.perspective === 'OTHER');

  const sortedObligatoryPhotos = [];
  let sortedPhotos = [];
  const checkPerspective = el => {
    switch (el.perspective) {
      case PhotoPerspective.MASTER:
        return 0;
      case PhotoPerspective.LEFT_FRONT:
        return 1;
      case PhotoPerspective.LEFT_SIDE:
        return 2;
      case PhotoPerspective.LEFT_REAR:
        return 3;
      case PhotoPerspective.REAR:
        return 4;
      case PhotoPerspective.RIGHT_REAR:
        return 5;
      case PhotoPerspective.RIGHT_SIDE:
        return 6;
      case PhotoPerspective.RIGHT_FRONT:
        return 7;
      case PhotoPerspective.INTERIOR_1:
        return 8;
      case PhotoPerspective.INTERIOR_2:
        return 9;
      case PhotoPerspective.INTERIOR_3:
        return 10;
      case PhotoPerspective.INTERIOR_4:
        return 11;
      default:
        return null;
    }
  };
  obligatoryPhotos.map((el, index) => {
    sortedObligatoryPhotos[checkPerspective(el, index)] = el;
    return null;
  });
  const obligatorySlots = new Array(REQUIRED_PHOTOS_AMOUNT).fill({});

  const obligatorySorted = obligatorySlots.map((el, index) => {
    if (sortedObligatoryPhotos[index]) {
      return sortedObligatoryPhotos[index];
    }
    return el;
  });
  sortedPhotos = [...obligatorySorted, ...additionalPhotos];
  addAnotherImageBugFix(auctionPhotos, sortedPhotos);
  return sortedPhotos;
};

const addAnotherImageBugFix = (photos, sortedPhotos) => {
  if (photos.length === sortedPhotos.length) return;
  photos.forEach(item => {
    if (!sortedPhotos.includes(item)) {
      item.perspective = PerspectiveType.OTHER;
      sortedPhotos.push(item);
    }
  });
  sortedPhotos.forEach((item, index) => {
    item.sortNumber = index + 1;
  });
};
