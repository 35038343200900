export const DealerRole = {
  FRANCHISED_DEALER: 'FRANCHISED_DEALER',
  INDEPENDENT_DEALER: 'INDEPENDENT_DEALER',
};

export const DealerRoleName = {
  FRANCHISED_DEALER: 'Franchised Dealer',
  INDEPENDENT_DEALER: 'Independent Dealer',
};

export const DealerRoleLabel = {
  FRANCHISED_DEALER: 'FRANCHISE',
  INDEPENDENT_DEALER: 'APPROVED',
};
