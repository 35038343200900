import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import {
  clearBidErrorMessage,
  placeBid,
  searchAuctions,
  setSearchQuery,
} from './SearchActions';
import SearchHeader from './SearchHeader';
import AuctionItem from './AuctionItem';
import InfinityLoader from '../common/components/InfinityLoader';
import { AuctionSortType } from '../constants/AuctionSortType';
import { AUCTION_PUBLISHED, SSE_BUYER, SSE_SELLER, SSEClient } from '../api/SSEClient';
import SearchBidModal from './bidModals/SearchBidModal';
import {
  getAuction,
  handleAuctionEvent,
  refreshAuctionList,
} from '../myvehicles/MyVehiclesActions';
import { getAuctionsAggregatedByMake } from '../homepage/HomePageActions';
import { ListingType } from '../constants/ListingType';
import { closeAlert, showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import refreshIcon from '../assets/img/refreshIcon.png';
import FindBestVehicles from './FindBestVehicles';
import { REFRESH_AUCTION_SUCCESS } from './SearchReducer';
import CommonButton from '../common/components/CommonButton';
import { clearCopiedSearchAlertState } from '../dashboard/searchalerts/SearchAlertActions';
import RouterPaths from '../constants/RouterPaths';

const sseClientSeller = new SSEClient(SSE_SELLER);
const sseClientBuyer = new SSEClient(SSE_BUYER);

const SearchPage = ({ history }) => {
  const dispatch = useDispatch();
  const itemsRef = useRef({});
  const headerRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState(AuctionSortType.ENDING_SOONEST);
  const [status, setStatus] = useState(null);
  const [auctions, setAuctions] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [currentBidAuction, setCurrentBidAuction] = useState(null);

  const [scrollTo, setScrollTo] = useState(null);
  const hasMore = useSelector(state => state.search.get('hasMore'));
  const refreshQuery = useSelector(state => state.search.get('refreshQuery'));
  const makeId = useSelector(state => state.search.get('makeId'));
  const searchQuery = useSelector(state => state.search.get('searchQuery'));
  const searchItems = useSelector(state => state.search.get('items').toJS());
  const searchTotalItems = useSelector(state => state.search.get('totalItems'));
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const vehicleMakeVisible = useSelector(state => state.homePage.get('vehicleMakeVisible'));

  const location = useLocation();

  const updateSearchItems = newItem => {
    dispatch({
      type: REFRESH_AUCTION_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  const clearAll = () => {
    scrollToHeader();
    setOffset(0);
    setScrollTo(null);
    setStatus(null);
    history.replace({ ...history.location, state: {} });
    setSort(AuctionSortType.ENDING_SOONEST);
  };

  useEffect(() => {
    dispatch(clearBidErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    sseClientSeller.listen(
      auctions?.filter(item => item.organizationId === organizationId).map(item => item?.id),
      response => {
        handleAuctionEvent(response, () =>
          dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          ),
        );
      },
    );
    return () => {
      sseClientSeller.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctions, organizationId, dispatch]);

  useEffect(() => {
    sseClientBuyer.listen(
      auctions.map(item => item.id),
      response => {
        if (response?.type === AUCTION_PUBLISHED) {
          return dispatch(
            refreshAuctionList(
              { auctionId: response?.auctionId, context: ListingType.SEARCH },
              () =>
                dispatch(
                  showAlert(
                    `<img class="refresh-icon" src=${refreshIcon} alt=""> New vehicle - click to refresh`,
                    AlertVariant.NEW_VEHICLE_AVAILABLE,
                    null,
                    true,
                    () => {
                      clearAll();
                      dispatch(setSearchQuery('', true));
                      dispatch(closeAlert());
                    },
                  ),
                ),
            ),
          );
        }
        return dispatch(
          handleAuctionEvent(
            response,
            () =>
              dispatch(
                getAuction(response.auctionId, auction => {
                  updateSearchItems(auction);
                }),
              ),
            dispatch(getAuctionsAggregatedByMake()),
          ),
        );
      },
    );
    return () => {
      sseClientBuyer.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctions, dispatch]);

  const _searchAuctions = () => {
    if (loading) return;
    setLoading(true);
    dispatch(
      searchAuctions(
        {
          search: searchQuery,
          makeId,
          offset,
          type: status,
        },
        sort,
        auctions => {
          setLoading(false);
          setAuctions(auctions);
          setLoaded(true);
          scrollToItem();
        },
        () => {
          setLoading(false);
        },
      ),
    );
  };
  useEffect(() => {
    clearAll();
    dispatch(getAuctionsAggregatedByMake());
    setRefresh(refreshQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshQuery]);

  useEffect(() => {
    if (
      _.has(location, 'state.offset') &&
      _.has(location, 'state.auctionId') &&
      !_.isEmpty(searchItems)
    ) {
      setOffset(_.get(location, 'state.offset', 0) + 1);
      setStatus(_.get(location, 'state.status', null));
      setScrollTo(location.state.auctionId);
      history.replace({ ...history.location, state: {} });
      return;
    }
    _searchAuctions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, offset, sort, dispatch, status, refresh]);

  const scrollToItem = () => {
    if (!scrollTo) return;
    if (itemsRef.current[scrollTo]) {
      itemsRef.current[scrollTo].scrollIntoView({ behavior: 'auto', block: 'center' });
      setScrollTo(null);
    }
  };

  const scrollToHeader = () => {
    if (!headerRef || !headerRef.current) return;
    headerRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
  };

  const loadData = () => {
    setOffset(prev => prev + 1);
  };

  const handleChangeSort = () => {
    setSort(
      sort === AuctionSortType.ENDING_SOONEST
        ? AuctionSortType.LATEST_ADDED
        : AuctionSortType.ENDING_SOONEST,
    );
  };

  const handleChangeSortByStatusFilter = type => {
    setStatus(type);
    setOffset(0);
  };

  const handleBidModal = item => {
    setCurrentBidAuction(item);
    setShowBidModal(true);
  };

  const toggleBidModal = () => {
    setShowBidModal(false);
  };

  const handlePlaceBid = amount => {
    dispatch(placeBid(currentBidAuction?.id, amount * 100));
  };

  const setRef = (ref, id) => {
    itemsRef.current[id] = ref;
  };

  return (
    <>
      {vehicleMakeVisible && <FindBestVehicles />}
      {loaded && (
        <>
          <div ref={headerRef} />
          <SearchHeader
            total={searchTotalItems}
            sort={sort}
            handleChangeSort={handleChangeSort}
            type={status}
            handleChangeSortByStatusFilter={handleChangeSortByStatusFilter}
          />
          {searchTotalItems > 0 ? (
            <InfiniteScroll
              next={loadData}
              hasMore={hasMore}
              loader={<InfinityLoader />}
              dataLength={searchItems.length}
            >
              {searchItems.map(item => (
                <AuctionItem
                  locationState={{
                    offset,
                    status,
                    auctionId: item.id,
                  }}
                  getRef={el => setRef(el, item.id)}
                  item={item}
                  key={item.id}
                  handleBid={handleBidModal}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <>
              <div className="text-center font-weight-bold">
                {searchQuery ? (
                  <p>
                    Oops, we could not find any result for the given phrase.
                    <br />
                    Click the add button to set a new search alert and we will inform you about
                    any new vehicles that match your criteria.
                  </p>
                ) : (
                  <>
                    {status ? (
                      <p>
                        Oops, we could not find any result for the given filter.
                        <br />
                        Click the add button to set a new search alert and we will inform you
                        about any new vehicles that match your criteria.
                      </p>
                    ) : (
                      <p>
                        We&apos;ve Sold Everything!
                        <br />
                        But don&apos;t Worry, There&apos;s More stock arriving daily.
                        <br />
                        Make Sure You
                        <br />
                        <br />
                        &quot;DOWNLOAD THE APP&quot;
                        <br />
                        <br />
                        To Receive Notifications For The Latest Vehicles Added
                      </p>
                    )}
                    {searchQuery ||
                      (status && (
                        <div className="d-flex justify-content-center">
                          <CommonButton
                            type="submit"
                            label="Add"
                            handleClick={() => {
                              dispatch(clearCopiedSearchAlertState());
                              history.push(RouterPaths.CREATE_SEARCH_ALERT);
                            }}
                          />
                        </div>
                      ))}
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
      {showBidModal && (
        <SearchBidModal
          toggleShow={toggleBidModal}
          item={currentBidAuction}
          handlePlaceBid={handlePlaceBid}
        />
      )}
    </>
  );
};
export default withRouter(SearchPage);
