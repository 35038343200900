import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CommonButton from 'common/components/CommonButton';
import history from 'browserHistory';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import RouterPaths from '../constants/RouterPaths';
import { getMyLatestBids } from './HomePageActions';
import { getAuction, handleAuctionEvent } from '../myvehicles/MyVehiclesActions';
import { SSE_BUYER, SSEClient } from '../api/SSEClient';
import MyBidsItem from '../mybids/MyBidsItem';
import { MY_BIDS_REFRESH_SUCCESS } from './HomePageReducer';
import { setBidsFilter } from '../mybids/MyBidsActions';

const sseClient = new SSEClient(SSE_BUYER);

const HomeMyBids = () => {
  const dispatch = useDispatch();
  const myLatestBids = useSelector(state => state.homePage.get('myLatestBids').toJS());
  const [auctionsIds, setAuctionsIds] = useState([]);

  const navigateToMyBids = () => {
    dispatch(setBidsFilter(''));
    history.push(RouterPaths.MY_BIDS);
  };

  const navigateToDetails = item => {
    dispatch(setBidsFilter(''));
    history.push({
      pathname: reverse(RouterPaths.MY_BIDS_DETAILS, { id: item.id }),
    });
  };

  const updateSearchItems = newItem => {
    dispatch({
      type: MY_BIDS_REFRESH_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  useEffect(() => {
    dispatch(
      getMyLatestBids('', auctionsIds => {
        setAuctionsIds(prev => prev.concat(auctionsIds));
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    sseClient.listen(auctionsIds, response => {
      dispatch(
        handleAuctionEvent(response, () =>
          dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          ),
        ),
      );
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionsIds, dispatch]);

  return (
    <div className="home-my-bids">
      <Container>
        <Row>
          <Col className=" d-flex justify-content-center">
            <h3 className="home-my-bids__title">My Bids</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {myLatestBids?.map(item => (
              <MyBidsItem
                item={item}
                key={item.id}
                onClickItem={() => navigateToDetails(item)}
              />
            ))}
          </Col>
        </Row>
        <Row className="justify-content-center home-my-bids__button-wrapper" lg={6}>
          <Col className="d-flex justify-content-center">
            <CommonButton label="My bids" handleClick={navigateToMyBids} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeMyBids;
