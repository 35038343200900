import React from 'react';
import PropTypes from 'prop-types';

const CommonSwitchButton = ({ isOn, setSwitch, onLabel, offLabel }) => {
  return (
    <button
      type="button"
      className={`common-switch-button ${
        isOn ? 'common-switch-button--on' : 'common-switch-button--off'
      }`}
      onClick={() => setSwitch(!isOn)}
    >
      <span className="common-switch-button__label">{isOn ? onLabel : offLabel}</span>
    </button>
  );
};

CommonSwitchButton.propTypes = {
  isOn: PropTypes.bool.isRequired,
  setSwitch: PropTypes.func.isRequired,
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
};

export default CommonSwitchButton;
