import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    position: Yup.string().required('Field is required'),
    location: Yup.string().required('Field is required'),
    password: Yup.string().min(6, 'Password is too short - should be 6 chars minimum.'),
    passwordRepeat: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must be the same',
    ),
    role: Yup.string().required('Field is required'),
  });
};
