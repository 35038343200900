import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import * as PropTypes from 'prop-types';
import FormInputField, {
  FormInputFieldType,
} from '../common/components/formFields/FormInputField';
import CommonButton from '../common/components/CommonButton';
import { CommonButtonVariants } from '../constants/CommonButtonVariants';
import { checkIsFieldEmpty } from '../common/helpers/checkIsFieldEmpty';

const { PRIMARY, DISABLED } = CommonButtonVariants;

const CustomSiteContactForm = ({ values }) => {
  const [sendDisabled, setSendDisabled] = useState(true);

  useEffect(() => {
    const { email, message } = values;
    checkIsFieldEmpty([email, message], isDisabled => setSendDisabled(isDisabled));
  }, [values]);

  return (
    <Form autoComplete="off" className="text-left">
      <Row>
        <Col>
          <FormInputField id="contact-email" name="email" label="Enter your email" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInputField
            type={FormInputFieldType.TEXTAREA}
            id="contact-message"
            name="message"
            label="Provide your comments and/or questions"
            maxLength={1000}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex flex-column align-items-end">
          <CommonButton
            type="submit"
            label="Send"
            variant={sendDisabled ? DISABLED : PRIMARY}
            disabled={sendDisabled}
          />
        </Col>
      </Row>
    </Form>
  );
};

CustomSiteContactForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default CustomSiteContactForm;
