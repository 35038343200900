import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'formik';
import { Col, Row } from 'reactstrap';
import { PhotoUploadState } from 'itrade-admin-panel/src/constants/PhotoUploadState';
import {
  getDraft,
  handleUploadPhotoError,
  removePhotosSoft,
  saveAddPhotoDraft,
  saveToSendVehiclePhoto,
  sendVehiclePhoto,
  sortPhotosAndFillEmpty,
} from '../AddVehicleActions';
import { getPhotoDescription } from '../../common/helpers/getPhotoDescription';
import CommonDeleteButton from '../../common/components/CommonDeleteButton';
import { resizeImage } from '../../common/utils/image/Image.utils';

const AddPhotoDragInner = ({ form, replace, nextForm }) => {
  const [files, setFiles] = useState({});
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const [style, setStyle] = useState({});

  const sentPhotos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const photosToDelete = useSelector(state => state.addVehicle.get('photosToDelete')) || [];
  const index = currentStep - 1;
  const id = useSelector(state => state.addVehicle.get('id'));
  const firstRender = useSelector(state => state.addVehicle.get('firstRender'));
  const draftPhotos = useSelector(state => state.addVehicle.get('vehicleMedia'))?.photos;
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));
  const uploadedPhotos = form?.values?.vehicleMedia?.photos;

  const onDrop = useCallback(
    async acceptedFiles => {
      if (!acceptedFiles.length) return dispatch(handleUploadPhotoError(true));
      dispatch(handleUploadPhotoError(false));
      const files = await Promise.all(
        acceptedFiles.map(async (image, imgIndex) => {
          const imageResized = await resizeImage(image);
          Object.assign(imageResized, {
            fileUrl: URL.createObjectURL(imageResized),
            description: getPhotoDescription(currentStep + imgIndex).PERSPECTIVE,
            state: PhotoUploadState.PENDING,
          });
          return imageResized;
        }),
      );
      return setFiles(files);
    },
    [dispatch, currentStep],
  );

  useEffect(() => {
    if (files.length === 1) {
      dispatch(
        sendVehiclePhoto(files[0], sentPhotos, index, () =>
          replace(index, {
            ...files[0],
            status: PhotoUploadState.SENT,
            damages: form?.values?.vehicleMedia?.photos[index]?.damages,
          }),
        ),
      ).then(() => dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id)))));
    } else if (files.length > 1) {
      files.forEach((file, fileIndex) => {
        const indexToSave =
          fileIndex === 0 ? index : getFirstEmptyIndex(index + fileIndex, sentPhotos);
        dispatch(saveToSendVehiclePhoto(file, sentPhotos, indexToSave));
        replace(indexToSave, {
          ...file,
          damages: form?.values?.vehicleMedia?.photos[index]?.damages,
        });
      });
      dispatch(sortPhotosAndFillEmpty(sentPhotos));
      nextForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, dispatch]);

  const getFirstEmptyIndex = (minIndex, sentPhotos) => {
    if (!sentPhotos[minIndex]?.fileUrl) {
      return minIndex;
    }
    return getFirstEmptyIndex(minIndex + 1, sentPhotos);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
    noDrag: false,
    multiple: true,
  });

  useEffect(() => {
    if (isDragActive) {
      setStyle('add-photo__base-style add-photo__active-style');
    } else if (isDragAccept) {
      setStyle('add-photo__base-style add-photo__accept-style');
    } else if (isDragReject) {
      setStyle('add-photo__base-style add-photo__accept-style');
    } else if (isLoading) {
      setStyle('add-photo__base-style add-photo__base-style--disabled');
    } else {
      setStyle('add-photo__base-style');
    }
  }, [isDragActive, isDragReject, isDragAccept, isLoading]);

  const handleDelete = () => {
    dispatch(removePhotosSoft(index, sentPhotos, vehicleMedia, photosToDelete));
  };

  if (
    (uploadedPhotos.length && uploadedPhotos[index]?.fileUrl) ||
    (!firstRender && sentPhotos?.[index]?.fileUrl)
  ) {
    return (
      <Row>
        <Col className="added-photo-container">
          <CommonDeleteButton
            dataStep={currentStep}
            className="delete-photo__button"
            handleClick={handleDelete}
          />
          <img
            alt="Preview car"
            src={uploadedPhotos[index]?.fileUrl || draftPhotos[index]?.fileUrl}
            className="added-photo"
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <div {...getRootProps({ className: style })}>
        <input {...getInputProps()} disabled={isLoading} />
        <p className="add-photo__drop-text">Click here to upload vehicle images</p>
      </div>
    </>
  );
};

const AddPhotoDrag = props => {
  return (
    <FieldArray
      name="vehicleMedia.photos"
      render={innerProps => <AddPhotoDragInner {...props} {...innerProps} />}
    />
  );
};
export default AddPhotoDrag;
