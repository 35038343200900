import * as Yup from 'yup';
import { FormRegExp } from '../../constants/FormRegExp';

export default () => {
  return Yup.object().shape({
    type: Yup.string().required('Field is required'),
    manualRecords: Yup.array().of(
      Yup.object({
        date: Yup.string().required('Field is required'),
        mileage: Yup.string()
          .required('Field is required')
          .matches(FormRegExp.INTEGER, 'Type only number')
          .max(10, 'Field can be at most 10 characters')
          .nullable(),
        serviceName: Yup.string()
          .max(50, 'Service name is too long - should be 50 chars maximum')
          .required('Field is required'),
      }),
    ),
  });
};
