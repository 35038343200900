import React from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import FormInputField from '../../../common/components/formFields/FormInputField';
import CommonButton from '../../../common/components/CommonButton';
import FormDatePickerField from '../../../common/components/formFields/FormDatePickerField';
import calendarIcon from '../../../assets/img/calendarIcon.svg';

const initialValues = {
  invoiceNumber: '',
  invoiceDateFrom: '',
  invoiceDateTo: '',
};

const InvoiceForm = ({ handleSearch }) => {
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSearch} validateOnBlur={true}>
        {() => (
          <Form noValidate>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={12}>
                <FormInputField name="invoiceNumber" label="Number" />
              </Col>
            </Row>
            <Row className="d-flex  align-items-center">
              <Col md={8}>
                <Row className="d-flex justify-content-between">
                  <Col md={6}>
                    <FormDatePickerField
                      name="invoiceDateFrom"
                      label="From"
                      icon={calendarIcon}
                      iconClassName="calendar-icon"
                    />
                  </Col>
                  <Col md={6}>
                    <FormDatePickerField
                      name="invoiceDateTo"
                      label="To"
                      icon={calendarIcon}
                      iconClassName="calendar-icon"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="d-flex justify-content-end">
                <CommonButton type="submit" label="Search" />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
InvoiceForm.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default InvoiceForm;
