export const pencesToPounds = price => {
  if (typeof price === 'number') {
    if (price === 0) {
      return Number(0);
    }
    return Number((price / 100).toFixed(0));
  }
  return null;
};

export const poundsToPences = price => {
  if (typeof price === 'number') {
    return price * 100;
  }
  return null;
};
