import React from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import CommonButton from '../common/components/CommonButton';
import RouterPaths from '../constants/RouterPaths';

const RegisterSuccess = ({ history }) => {
  return (
    <Col md="6" className="justify-content-center m-auto">
      <h3 className="text-uppercase font-weight-bold text-center">
        Thank you for registering
      </h3>
      <Row>
        <p className="text-center register-success-paragraph">
          We will now verify the details you have provided and a member of our team will
          contact you shortly.
        </p>
      </Row>
      <Row className="mt-3 justify-content-center">
        <CommonButton
          label="Home page"
          handleClick={() => history.push(RouterPaths.HOMEPAGE)}
        />
      </Row>
    </Col>
  );
};

export default withRouter(RegisterSuccess);
