import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import CommonButton from '../../common/components/CommonButton';
import { NotificationConfig } from './NotificationConfig';

const { PRIMARY, SECONDARY } = CommonButtonVariants;

const NotificationTypeSection = ({ title, config, onChangeConfig }) => {
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  return (
    <>
      <h5 className="text-center font-weight-bold mt-3">{title}</h5>
      <div className="d-flex flex-row flex-wrap justify-content-center">
        <div className="p-2">
          <CommonButton
            className="notification-button"
            label={
              <div>
                <i className={config.pushEnabled ? 'fa fa-check' : 'fa fa-times'} />{' '}
                <b> PUSH</b>
              </div>
            }
            variant={config.pushEnabled ? PRIMARY : SECONDARY}
            handleClick={() => {
              onChangeConfig(config.pressPush());
            }}
            disabled={isLoading}
          />
        </div>
        <div className="p-2">
          <CommonButton
            className="notification-button"
            label={
              <div>
                <i className={config.emailEnabled ? 'fa fa-check' : 'fa fa-times'} />{' '}
                <b>EMAIL</b>
              </div>
            }
            variant={config.emailEnabled ? PRIMARY : SECONDARY}
            handleClick={() => {
              onChangeConfig(config.pressEmail());
            }}
            disabled={isLoading}
          />
        </div>
      </div>
    </>
  );
};

NotificationTypeSection.propTypes = {
  title: PropTypes.string.isRequired,
  config: PropTypes.instanceOf(NotificationConfig).isRequired,
  onChangeConfig: PropTypes.func.isRequired,
};

export default withRouter(NotificationTypeSection);
