import { useLocation } from 'react-router-dom';

/**
 *
 * @returns {URLSearchParams}
 */
export const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

export const setQueryString = (location, key, value) => {
  const queryParams = new URLSearchParams(location.search);
  queryParams.set(key, value);
  return {
    pathname: location.pathname,
    search: queryParams.toString(),
  };
};
