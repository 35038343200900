import { fromJS } from 'immutable';
import { ALL, getInitialValue, prepareYear, RANGE_OF_YEARS } from './utils/yearsRangeOptions';
import {
  ALL_SELECTED,
  mapToAllIfNull,
  mapToNationalIfNull,
  NATIONAL_SELECTED,
} from './utils/defaultValue';

const initialData = fromJS({
  searchAlerts: [],
  searchAlertsTotalItems: 0,
  distanceInMiles: [],
  valueInMiles: [],
  yearsAgo: [],
  copiedSearchAlert: {
    allRangeOfYears: getInitialValue(),
    yearMinValue: null,
    yearMaxValue: null,
    mileageMaxValue: ALL_SELECTED,
    distanceMaxValueInMiles: NATIONAL_SELECTED,
  },
  editSearchAlert: {
    make: ALL_SELECTED,
    model: ALL_SELECTED,
    allRangeOfYears: getInitialValue(),
    yearMinValue: null,
    yearMaxValue: null,
    mileageMaxValue: ALL_SELECTED,
    distanceMaxValueInMiles: NATIONAL_SELECTED,
  },
});

export const CLEAR_EDIT_SEARCH_ALERT_STATE = 'CLEAR_EDIT_SEARCH_ALERT_STATE';
export const CLEAR_COPIED_SEARCH_ALERT_STATE = 'CLEAR_COPIED_SEARCH_ALERT_STATE';
export const COPY_SEARCH_ALERT = 'COPY_SEARCH_ALERT';
export const FETCH_SEARCH_ALERTS_SUCCESS = 'FETCH_SEARCH_ALERTS_SUCCESS';
export const GET_SEARCH_ALERT_SUCCESS = 'GET_SEARCH_ALERT_SUCCESS';
export const FETCH_SEARCH_ALERTS_OPTION_SUCCESS = 'FETCH_SEARCH_ALERTS_OPTION_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case FETCH_SEARCH_ALERTS_SUCCESS:
      return state.merge({
        searchAlerts: payload.items,
        searchAlertsTotalItems: payload.totalItems,
      });
    case FETCH_SEARCH_ALERTS_OPTION_SUCCESS:
      return state.merge({
        distanceInMiles: payload.data.distanceInMiles,
        valueInMiles: payload.data.valueInMiles,
        yearsAgo: payload.data.yearsAgo,
      });
    case CLEAR_EDIT_SEARCH_ALERT_STATE:
      return state.merge({
        editSearchAlert: initialData.get('editSearchAlert'),
      });
    case COPY_SEARCH_ALERT: {
      const { yearFrom, yearTo, distanceMaxValueInMiles, mileageMaxValue } = payload.data;
      return state.merge({
        copiedSearchAlert: fromJS({
          allRangeOfYears: yearTo ? RANGE_OF_YEARS : ALL,
          yearMinValue: prepareYear(yearTo),
          yearMaxValue: prepareYear(yearFrom),

          mileageMaxValue: mapToAllIfNull(mileageMaxValue, mileageMaxValue),
          distanceMaxValueInMiles: mapToNationalIfNull(
            distanceMaxValueInMiles,
            distanceMaxValueInMiles,
          ),
        }),
      });
    }
    case CLEAR_COPIED_SEARCH_ALERT_STATE:
      return state.merge({
        copiedSearchAlert: initialData.get('copiedSearchAlert'),
      });
    case GET_SEARCH_ALERT_SUCCESS: {
      const {
        id,
        vehicleMakeId,
        vehicleMakeName,
        vehicleModelId,
        vehicleModelName,
        yearFrom,
        yearTo,
        distanceMaxValueInMiles,
        mileageMaxValue,
      } = payload.data;
      return state.merge({
        editSearchAlert: fromJS({
          id,
          make: mapToAllIfNull(vehicleMakeId, vehicleMakeName),
          model: mapToAllIfNull(vehicleModelId, vehicleModelName),
          allRangeOfYears: yearTo ? RANGE_OF_YEARS : ALL,
          yearMinValue: prepareYear(yearTo),
          yearMaxValue: prepareYear(yearFrom),
          mileageMaxValue: mapToAllIfNull(mileageMaxValue, mileageMaxValue),
          distanceMaxValueInMiles: mapToNationalIfNull(
            distanceMaxValueInMiles,
            distanceMaxValueInMiles,
          ),
        }),
      });
    }
    default:
      return state;
  }
};
