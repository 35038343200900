import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import RouterPaths from '../../../constants/RouterPaths';

const EmployeesTableRow = ({ item, history }) => {
  return (
    <>
      <td>{item?.firstName}</td>
      <td>{item?.lastName}</td>
      <td className="employees-table-row-email">{item?.email}</td>
      <td className="text-right pr-4">
        <button
          type="button"
          className="employees-table-row__action-button"
          onClick={() => history.push(reverse(RouterPaths.EMPLOYEE_DETAILS, { id: item?.id }))}
        >
          {null}
        </button>
      </td>
    </>
  );
};

EmployeesTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(EmployeesTableRow);
