import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import CommonButton from './CommonButton';
import { MyOrganizationSubpageButtonLabels } from '../../constants/MyOrganizationSubpageButtonLabels';
import RouterPaths from '../../constants/RouterPaths';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { MyProfileSubpageButtonLabels } from '../../constants/MyProfileSubpageButtonLabels';

const CommonMyOrganizationNav = () => {
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const MyOrganizationNavItems = [
    {
      label: MyOrganizationSubpageButtonLabels.INFORMATIONS,
      path: RouterPaths.DASHBOARD,
      hasAccess: true,
    },
    {
      label: MyOrganizationSubpageButtonLabels.EMPLOYEES,
      path: RouterPaths.EMPLOYEES,
      hasAccess: isOwner,
    },
    {
      label: MyOrganizationSubpageButtonLabels.MY_WALLET,
      path: RouterPaths.MY_WALLET,
      hasAccess: isOwner,
    },
    {
      label: MyOrganizationSubpageButtonLabels.TRANSACTIONS,
      path: RouterPaths.TRANSACTIONS,
      hasAccess: isOwner,
    },
    {
      label: MyOrganizationSubpageButtonLabels.INVOICES,
      path: RouterPaths.INVOICES,
      hasAccess: isOwner,
    },
    {
      label: MyProfileSubpageButtonLabels.MY_PROFILE,
      path: RouterPaths.MY_PROFILE,
    },
    {
      label: MyProfileSubpageButtonLabels.SEARCH_ALERTS,
      path: RouterPaths.SEARCH_ALERTS,
    },
    {
      label: MyProfileSubpageButtonLabels.CREATE_SEARCH_ALERTS,
      path: RouterPaths.CREATE_SEARCH_ALERT,
    },
    {
      label: MyProfileSubpageButtonLabels.EDIT_SEARCH_ALERTS,
      path: RouterPaths.EDIT_SEARCH_ALERT,
    },
    {
      label: MyProfileSubpageButtonLabels.NOTIFICATIONS,
      path: RouterPaths.NOTIFICATIONS,
    },
    {
      label: MyProfileSubpageButtonLabels.MY_PROFILE,
      path: RouterPaths.MY_PROFILE,
      hasAccess: true,
    },
    {
      label: MyProfileSubpageButtonLabels.SEARCH_ALERTS,
      path: RouterPaths.SEARCH_ALERTS,
      hasAccess: true,
    },
    {
      label: MyProfileSubpageButtonLabels.NOTIFICATIONS,
      path: RouterPaths.NOTIFICATIONS,
      hasAccess: true,
    },
  ];
  return (
    <Col md={3} className="my-organization-nav-col">
      {MyOrganizationNavItems.filter(item => item.hasAccess).map(item => (
        <div key={item.path}>
          <CommonButton
            variant={CommonButtonVariants.ORGANIZATION_NAV}
            label={item.label}
            path={item.path}
            navLink
          />
        </div>
      ))}
    </Col>
  );
};

export default withRouter(CommonMyOrganizationNav);
