import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';

export const SITE_ENDPOINT = '/site-creator';
export const CONTACT_ENDPOINT = '/help-contact';

export const getSiteByLink = (link, callbackSuccess, callbackFailure) => dispatch => {
  dispatch(showLoader());
  return apiClient
    .post(SITE_ENDPOINT, { link })
    .then(res => {
      if (res.status === 200) {
        const content = res?.data?.data?.content;
        if (typeof callbackSuccess === 'function') {
          callbackSuccess(content);
        }
      }
    })
    .catch(err => {
      if (err?.response?.status === 404) {
        if (typeof callbackFailure === 'function') {
          return callbackFailure();
        }
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const sendQuestions = data => dispatch => {
  dispatch(showLoader());
  return apiClient
    .post(CONTACT_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Email sent successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};
