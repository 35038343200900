import history from 'browserHistory';
import { CLOSE_ALERT, SHOW_ALERT } from './AlertReducer';
import AlertVariant from './AlertVariant';
// eslint-disable-next-line
import { logout } from '../../../auth/AuthActions';
import { ErrorCodes, ErrorMessages } from '../../../constants/ErrorCodes';
import RouterPaths from '../../../constants/RouterPaths';

const {
  PAYMENT_FAILURE,
  MISSING_ORGANIZATION_IMMEDIATE_PAYMENT_METHOD,
  MISSING_ORGANIZATION_CARD_TOKEN,
} = ErrorCodes;

const PAYMENT_ERRORS_MESSAGE_ARRAY = [
  PAYMENT_FAILURE,
  MISSING_ORGANIZATION_IMMEDIATE_PAYMENT_METHOD,
  MISSING_ORGANIZATION_CARD_TOKEN,
];

export const showAlert = (
  text,
  variant,
  duration = 4000,
  isMarkup = false,
  onClickAction,
) => dispatch => {
  if (variant !== AlertVariant.NEW_VEHICLE_AVAILABLE) {
    dispatch(closeAlert());
  }
  dispatch({
    type: SHOW_ALERT,
    payload: {
      text,
      variant,
      duration,
      isMarkup,
      onClickAction,
    },
  });

  if (variant === AlertVariant.NEW_VEHICLE_AVAILABLE) return;
  if (duration) {
    setTimeout(() => dispatch(closeAlert()), duration);
  }
};

export const closeAlert = () => dispatch => dispatch({ type: CLOSE_ALERT });

export const handleError = err => dispatch => {
  let errorCode;
  if (err?.response) {
    switch (err.response.status) {
      case 401:
        errorCode = 'Your session has expired';
        break;
      case 404:
        errorCode = 'Not Found';
        break;
      case 400:
      case 403:
      case 409:
      case 418:
        if (PAYMENT_ERRORS_MESSAGE_ARRAY.includes(err?.response?.data?.code)) {
          dispatch(showAlert(ErrorMessages.PAYMENT_FAILED, AlertVariant.DANGER));
          return history.push(RouterPaths.MY_WALLET);
        }
        if (err?.response?.data?.code === ErrorCodes.DEALER_ROLES_STALE) {
          dispatch(logout());
          return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
        }
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      case 412: {
        const response = err?.response?.data?.data;
        if (response && typeof response === 'object' && Object.keys(response)?.length) {
          const errorMessage = Object.values(response)?.[0];
          return dispatch(showAlert(errorMessage, AlertVariant.DANGER));
        }
        return null;
      }
      case 500:
        if (
          err?.response?.data?.code === ErrorCodes.DEALER_ROLES_STALE ||
          err?.response?.data?.code === ErrorCodes.ORGANIZATION_BLOCKED
        ) {
          dispatch(logout());
        }

        errorCode = err?.response?.data?.message || err?.response?.data?.code;
        break;

      default:
        errorCode = err?.response?.data?.message || (typeof err === 'string' ? err : 'Error');
    }
    dispatch(showAlert(errorCode, AlertVariant.DANGER));
  } else {
    switch (err?.code) {
      case ErrorCodes.ECONNABORTED:
        errorCode =
          'There was a problem with the network. Please check your internet connection and try again.';
        break;
      default:
        errorCode = err?.response?.data?.message || err;
    }
    dispatch(showAlert(errorCode, AlertVariant.DANGER));
  }
  return null;
};

export const handleSaveSuccess = message => dispatch => {
  const saveSuccessMessage = `Saved ${message} successfully`;
  dispatch(showAlert(saveSuccessMessage, AlertVariant.SUCCESS));
};
