import React from 'react';
import * as PropTypes from 'prop-types';

const CommonErrorLabel = ({ value }) => {
  return <>{value && <label className="error-label">{value}</label>}</>;
};

CommonErrorLabel.defaultProps = {
  value: '',
};

CommonErrorLabel.propTypes = {
  value: PropTypes.string,
};

export default CommonErrorLabel;
