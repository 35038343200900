import { fromJS } from 'immutable';
import uuid from 'react-uuid';

const initialData = fromJS({
  searchQuery: '',
  makeId: '',
  items: [],
  totalItems: 0,
  auctionBids: [],
  bidErrorMessage: '',
  timeStamp: null,
  hasMore: true,
  refreshQuery: uuid(),
});

export const SEARCH_QUERY_SUCCESS = 'SEARCH_QUERY_SUCCESS';
export const SEARCH_AUCTIONS_SUCCESS = 'SEARCH_AUCTIONS_SUCCESS';
export const CLEAR_AUCTIONS_SUCCESS = 'CLEAR_AUCTIONS_SUCCESS';
export const REFRESH_AUCTION_SUCCESS = 'REFRESH_AUCTION_SUCCESS';
export const FETCH_AUCTION_BIDS_SUCCESS = 'FETCH_AUCTION_BIDS_SUCCESS';
export const CLEAR_BID_ERROR_SUCCESS = 'CLEAR_BID_ERROR_SUCCESS';
export const REFRESH_QUERY_SUCCESS = 'REFRESH_QUERY_SUCCESS';
export const MAKE_ID_SUCCESS = 'MAKE_ID_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_QUERY_SUCCESS:
      return state.merge({
        searchQuery: payload,
        makeId: '',
      });
    case MAKE_ID_SUCCESS:
      return state.merge({
        searchQuery: '',
        makeId: payload,
      });
    case REFRESH_QUERY_SUCCESS:
      return state.merge({
        refreshQuery: uuid(),
      });
    case SEARCH_AUCTIONS_SUCCESS:
      return state.merge({
        totalItems: payload.totalItems,
        items: payload.offset
          ? state.get('items').merge(fromJS(payload.items))
          : fromJS(payload.items),
        timeStamp: payload.timeStamp,
        hasMore: payload.hasMore,
      });
    case CLEAR_AUCTIONS_SUCCESS:
      return state.merge(
        fromJS({
          items: [],
          totalItems: 0,
          timeStamp: null,
          hasMore: true,
        }),
      );
    case REFRESH_AUCTION_SUCCESS: {
      const index = state
        .get('items')
        .toJS()
        .findIndex(item => item.id === payload.item.id);
      if (index !== -1) {
        const itemData = payload.item;
        itemData.timeStamp = new Date();
        itemData.organizationId = itemData.organizationId
          ? itemData.organizationId
          : itemData?.seller?.organizationContact?.id;
        itemData.organizationRole = itemData.organizationRole
          ? itemData.organizationRole
          : itemData?.seller?.organizationContact?.role;
        return state.merge({
          items: state.get('items').update(index, () => itemData),
        });
      }
      return state;
    }
    case FETCH_AUCTION_BIDS_SUCCESS:
      return state.merge({
        auctionBids: payload,
      });
    case CLEAR_BID_ERROR_SUCCESS:
      return state.merge({
        bidErrorMessage: '',
      });
    default:
      return state;
  }
};
