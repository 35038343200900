import { fromJS } from 'immutable';

const initialData = fromJS({
  companyDetails: {},
  companyNumber: '',
  organizationRole: '',
  validateErrors: null,
  stripeValidationMessage: '',
  officialCompanyName: '',
});

export const COMPLETE_COMPANY_DETAILS_SUCCESS = 'COMPLETE_COMPANY_DETAILS';
export const VALIDATE_MAIN_PROFILE_FAILURE = 'VALIDATE_MAIN_PROFILE_FAILURE';
export const VALIDATE_ORGANIZATION_FAILURE = 'VALIDATE_ORGANIZATION_FAILURE';
export const CLEAR_VALIDATION_ERRORS_SUCCESS = 'CLEAR_VALIDATION_ERRORS_SUCCESS';
export const CLEAR_COMPANY_DETAILS_SUCCESS = 'CLEAR_COMPANY_DETAILS_SUCCESS';
export const GET_STRIPE_CLIENT_SECRET_FAILURE = 'GET_STRIPE_CLIENT_SECRET_FAILURE';
export const CLEAR_STRIPE_VALIDATION_MESSAGE = 'CLEAR_STRIPE_VALIDATION_MESSAGE';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case COMPLETE_COMPANY_DETAILS_SUCCESS:
      return state.merge({
        officialCompanyName: payload.officialCompanyName,
        companyDetails: payload.companyDetails,
        companyNumber: payload.companyNumber,
        organizationRole: payload.organizationRole,
      });
    case VALIDATE_MAIN_PROFILE_FAILURE:
      return state.merge({
        validateErrors: payload,
      });
    case VALIDATE_ORGANIZATION_FAILURE:
      return state.merge({
        validateErrors: payload,
      });
    case CLEAR_VALIDATION_ERRORS_SUCCESS:
      return state.merge({
        validateErrors: null,
      });
    case CLEAR_COMPANY_DETAILS_SUCCESS:
      return state.merge({
        companyDetails: null,
        companyNumber: '',
        organizationRole: '',
        officialCompanyName: '',
      });
    case GET_STRIPE_CLIENT_SECRET_FAILURE:
      return state.merge({
        stripeValidationMessage: payload,
      });
    case CLEAR_STRIPE_VALIDATION_MESSAGE:
      return state.merge({
        stripeValidationMessage: '',
      });
    default:
      return state;
  }
};
