import jwt from 'jsonwebtoken';
import { apiClient } from '../../api/ApiClient';
import { handleError, showAlert } from '../../common/components/alert/AlertActions';
import {
  hideLoader,
  showLoader,
} from '../../common/components/NavbarLoader/NavbarLoaderActions';
import AlertVariant from '../../common/components/alert/AlertVariant';
import { FETCH_MY_PROFILE_SUCCESS, GET_TOKEN_INFO_SUCCESS } from './MyProfileReducer';
import { AUTH_TOKEN } from '../../auth/AuthConstants';

const ACCOUNT_API_ENDPOINT = '/account';
const NOTIFICATIONS_API_ENDPOINT = '/organization-member-notification';

export const fetchMyProfileDetails = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${ACCOUNT_API_ENDPOINT}/profile`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_MY_PROFILE_SUCCESS,
          payload: {
            myProfileDetails: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const updateMyProfileDetails = values => dispatch => {
  dispatch(showLoader());
  const { password, passwordRepeat, position, location, acceptNewsletter, version } = values;
  const data = {
    password: password || null,
    passwordRepeat: passwordRepeat || null,
    organizationLocationId: location?.value,
    position: position?.value,
    acceptNewsletter,
    version,
  };
  return apiClient
    .put(`${ACCOUNT_API_ENDPOINT}/profile`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Profile updated successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const fetchMemberNotification = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(NOTIFICATIONS_API_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        return res?.data?.data;
      }
      return null;
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const updateMemberNotification = payload => dispatch => {
  dispatch(showLoader());
  return apiClient
    .put(NOTIFICATIONS_API_ENDPOINT, payload)
    .then(res => {
      if (res.status === 200) {
        return res?.data?.data;
      }
      return null;
    })
    .catch(err => {
      dispatch(handleError(err));
      return Promise.reject(err);
    })
    .finally(() => dispatch(hideLoader()));
};

export const getTokenInfo = () => dispatch => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const decodedToken = jwt.decode(authToken);
  dispatch({ type: GET_TOKEN_INFO_SUCCESS, payload: decodedToken });
};
