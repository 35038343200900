import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AuctionDetailsItem from '../../common/components/AuctionDetailsItem';
import { getAuction } from '../../myvehicles/MyVehiclesActions';
import { auctionListType } from '../../constants/AuctionListType';
import { SSEClient, SSE_BUYER } from '../../api/SSEClient';

const MyBidsDetailsPage = ({ location, history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [item, setItem] = useState(null);
  const [auctionTimeStamp, setAuctionTimestamp] = useState(null);

  const sseClient = new SSEClient(SSE_BUYER);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.location.state = {
          ...location.state,
          auctionId: id,
        };
      }
    };
  }, [id, history, location]);

  useEffect(() => {
    setAuctionTimestamp(new Date());
    dispatch(
      getAuction(id, auction => {
        setItem(auction);
      }),
    );
  }, [dispatch, id]);

  const handleGetAuction = auction => {
    setAuctionTimestamp(new Date());
    if (auction) {
      setItem(auction);
    } else {
      dispatch(
        getAuction(id, auction => {
          setItem(auction);
        }),
      );
    }
  };

  return (
    item && (
      <AuctionDetailsItem
        item={item}
        listType={auctionListType.MY_BIDS}
        sseClient={sseClient}
        refreshItem={handleGetAuction}
        typeSseClient={SSE_BUYER}
        auctionTimestamp={auctionTimeStamp}
      />
    )
  );
};

export default MyBidsDetailsPage;
