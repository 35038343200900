import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from 'browserHistory.js';
import RouterPaths from '../../../constants/RouterPaths';
import HomePage from '../../../homepage/HomePage';
import Login from '../../../auth/Login';
import ForgotPasswordPage from '../../../auth/forgotpassword/ForgotPasswordPage';
import ConfirmForgotPasswordPage from '../../../auth/forgotpassword/ConfirmForgotPasswordPage';
import ResetPasswordPage from '../../../auth/resetpassword/ResetPasswordPage';
import RegisterPage from '../../../register/RegisterPage';
import ProtectedRoute from '../../hoc/ProtectedRoute';
import DashboardPage from '../../../dashboard/DashboardPage';
import EditEmployeePage from '../../../dashboard/employees/edit/EditEmployeePage';
import CreateEmployeePage from '../../../dashboard/employees/create/CreateEmployeePage';
import AddVehiclePage from '../../../addvehicle/AddVehiclePage';
import EmployeesPage from '../../../dashboard/employees/EmployeesPage';
import MyProfilePage from '../../../dashboard/myprofile/MyProfilePage';
import SearchAlertsPage from '../../../dashboard/searchalerts/SearchAlertsPage';
import NotificationsPage from '../../../dashboard/notifications/NotificationsPage';
import MyWalletPage from '../../../mywallet/MyWalletPage';
import SearchPage from '../../../search/SearchPage';
import SearchDetailsPage from '../../../search/details/SearchDetailsPage';
import TransactionsPage from '../../../dashboard/transactions/TransactionsPage';
import InvoicesPage from '../../../dashboard/invoices/InvoicesPage';
import CustomSite from '../../../customsite/CustomSite';
import MyVehiclesPage from '../../../myvehicles/MyVehiclesPage';
import MyVehiclesDetailsPage from '../../../myvehicles/details/MyVehiclesDetailsPage';
import MyBidsPage from '../../../mybids/MyBidsPage';
import MyBidsDetailsPage from '../../../mybids/details/MyBidsDetailsPage';
import { getTokenInfo } from '../../../dashboard/myprofile/MyProfileActions';
import { getPaymentMethod } from '../../../mywallet/MyWalletActions';
import { UserStatus } from '../../../constants/UserStatus';
import ImpersonatePage from '../../../auth/impersonate/ImpersonatePage';
import CreateSearchAlertPage from '../../../dashboard/searchalerts/create/CreateSearchAlertPage';
import EditSearchAlertsPage from '../../../dashboard/searchalerts/edit/EditSearchAlertPage';

const Content = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isHomePage = location.pathname === RouterPaths.HOMEPAGE;
  const isSearchPage = location.pathname === RouterPaths.SEARCH;
  const isImpersonatePage = location.pathname === RouterPaths.IMPERSONATE;
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const userAuthStatus = useSelector(state => state.auth.get('status'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTokenInfo());
      if (isOwner && !isImpersonatePage) {
        dispatch(getPaymentMethod());
      }
      if (userAuthStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT)
        history.push(RouterPaths.MY_WALLET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated, userAuthStatus, isOwner]);

  return (
    <div className={isHomePage ? null : 'content-wrapper'}>
      <div className={isHomePage ? null : 'content-inner-wrapper'}>
        {isSearchPage ? null : window.scrollTo(0, 0)}
        <Switch>
          <Route path={RouterPaths.HOMEPAGE} component={HomePage} exact />
          <Route path={RouterPaths.LOGIN} component={Login} exact />
          <Route path={RouterPaths.FORGOT_PASSWORD} component={ForgotPasswordPage} exact />
          <Route
            path={RouterPaths.CONFIRM_FORGOT_PASSWORD}
            component={ConfirmForgotPasswordPage}
            exact
          />
          <Route path={RouterPaths.RESET_PASSWORD} component={ResetPasswordPage} exact />
          <Route path={RouterPaths.REGISTER} component={RegisterPage} exact />
          <Route path={RouterPaths.IMPERSONATE} component={ImpersonatePage} exact />
          <ProtectedRoute path={RouterPaths.ADD_VEHICLE} component={AddVehiclePage} exact />
          <ProtectedRoute path={RouterPaths.DASHBOARD} component={DashboardPage} exact />
          <ProtectedRoute path={RouterPaths.EMPLOYEES} component={EmployeesPage} exact />
          <ProtectedRoute
            path={RouterPaths.EMPLOYEE_DETAILS}
            component={EditEmployeePage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.CREATE_EMPLOYEE}
            component={CreateEmployeePage}
            exact
          />
          <ProtectedRoute path={RouterPaths.MY_PROFILE} component={MyProfilePage} exact />
          <ProtectedRoute
            path={RouterPaths.SEARCH_ALERTS}
            component={SearchAlertsPage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.CREATE_SEARCH_ALERT}
            component={CreateSearchAlertPage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.EDIT_SEARCH_ALERT}
            component={EditSearchAlertsPage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.NOTIFICATIONS}
            component={NotificationsPage}
            exact
          />
          <ProtectedRoute path={RouterPaths.MY_VEHICLES} component={MyVehiclesPage} exact />
          <ProtectedRoute
            path={RouterPaths.MY_VEHICLES_DETAILS}
            component={MyVehiclesDetailsPage}
            exact
          />
          <ProtectedRoute path={RouterPaths.MY_WALLET} component={MyWalletPage} exact />
          <ProtectedRoute path={RouterPaths.SEARCH} component={SearchPage} exact />
          <ProtectedRoute
            path={RouterPaths.SEARCH_DETAILS}
            component={SearchDetailsPage}
            exact
          />
          <ProtectedRoute path={RouterPaths.TRANSACTIONS} component={TransactionsPage} exact />
          <ProtectedRoute path={RouterPaths.INVOICES} component={InvoicesPage} exact />
          <ProtectedRoute path={RouterPaths.MY_BIDS} component={MyBidsPage} exact />
          <ProtectedRoute
            path={RouterPaths.MY_BIDS_DETAILS}
            component={MyBidsDetailsPage}
            exact
          />
          <Route component={CustomSite} exact />
        </Switch>
      </div>
    </div>
  );
};

export default Content;
