import { fromJS } from 'immutable';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

const initialData = fromJS({
  isLoading: false,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return state.merge({
        isLoading: true,
      });
    case HIDE_LOADER:
      return state.merge({
        isLoading: false,
      });
    default:
      return state;
  }
};
