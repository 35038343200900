import React from 'react';
import { Col } from 'reactstrap';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validationSchema from '../forms/TyreTreadDepthsForm.schema';
import TyreTreadDepthsForm from '../forms/TyreTreadDepthsForm';
import { validateMediaAndTyres } from '../AddVehicleActions';
import AddPhotoSummary from './AddPhotoSummary';

const AddPhotoSummaryPage = ({ prevForm, nextForm }) => {
  const dispatch = useDispatch();
  const tyreThreadDepths = useSelector(state => state.addVehicle.get('tyreThreadDepths'));

  const prepareTyreThreadDepths = () => {
    if (tyreThreadDepths) {
      const tyreThreadDepthsObj = {};
      Object.keys(tyreThreadDepths).forEach(key => {
        tyreThreadDepthsObj[key] = {
          value: tyreThreadDepths[key],
          label: tyreThreadDepths[key],
        };
      });
      return tyreThreadDepthsObj;
    }
    return '';
  };
  const initialValues = {
    ...prepareTyreThreadDepths(),
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await dispatch(validateMediaAndTyres(values, () => nextForm()));
    setSubmitting(false);
  };

  return (
    <Col md={8} className="justify-content-center mr-auto ml-auto">
      <AddPhotoSummary prevForm={prevForm} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        {form => <TyreTreadDepthsForm form={form} prevForm={prevForm} values={form.values} />}
      </Formik>
    </Col>
  );
};

AddPhotoSummaryPage.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddPhotoSummaryPage;
