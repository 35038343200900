import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { logout, refreshToken } from './AuthActions';

const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 15;

let interval = null;

const AuthRefreshToken = () => {
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const dispatch = useDispatch();

  const { getLastActiveTime } = useIdleTimer({});

  useEffect(() => {
    const checkIsRefreshToken = () => {
      if (isAuthenticated) {
        interval = setInterval(() => {
          const idleTime = new Date().getTime() - getLastActiveTime();
          if (idleTime <= REFRESH_TOKEN_INTERVAL) {
            dispatch(refreshToken());
          }
          if (idleTime >= 1000 * 60 * 60 * 8) {
            dispatch(logout());
          }
        }, REFRESH_TOKEN_INTERVAL);
      } else {
        clearInterval(interval);
      }
    };
    checkIsRefreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return null;
};

export default AuthRefreshToken;
