import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import FormInputField, {
  FormInputFieldType,
} from '../../common/components/formFields/FormInputField';
import FormCurrencyInputField from '../../common/components/formFields/FormCurrencyInputField';
import { AuctionTimeName } from '../../constants/AuctionTime';
import { AuctionStatusType, AuctionStatusTypeName } from '../../constants/AuctionStatusType';
import FormGroupRadioField from '../../common/components/formFields/FormGroupRadioField';
import { AuctionPublishTypeName } from '../../constants/AuctionPublishType';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import CommonButton from '../../common/components/CommonButton';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import {
  clearDescriptionsAndSettingsErrors,
  fetchDescriptionTemplate,
  fetchOrganizationGroup,
  fetchOrganizationLocations,
  getDraft,
  saveDescriptionAndSettingsDraft,
} from '../AddVehicleActions';
import { reverseDateToString } from '../../common/utils/date/Date.utils';
import { checkIsFieldEmpty } from '../../common/helpers/checkIsFieldEmpty';
import { parseCurrencyToIntIfString } from '../../common/helpers/parseCurrencyToIntIfString';
import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';

const auctionTimes = constantToSelect(AuctionTimeName);
const auctionTypes = constantToSelect(AuctionStatusTypeName);
const auctionPublishTypes = constantToSelect(AuctionPublishTypeName);
const dueInDateTypes = constantToSelect(DueInDateTypeName);

const { PRIMARY, SECONDARY, DISABLED } = CommonButtonVariants;

const AddDescriptionForm = ({ form, prevForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const descriptionTemplate = useSelector(state =>
    state.addVehicle.get('descriptionTemplate'),
  );
  const organizationLocations = useSelector(state =>
    state.addVehicle.get('organizationLocations'),
  );
  const organizationGroup = useSelector(state => state.addVehicle.get('organizationGroup'));
  const validationErrors = useSelector(state => state.addVehicle.get('validationErrors'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const checkIfDisableNextButton = () => {
      const { PHYSICAL, MINIMUM_PRICE, TRYING_TO_DEAL } = AuctionStatusType;
      const {
        description,
        vehicleLocation,
        timeOption,
        type,
        minimumPriceInPence,
        autoAcceptPriceInPence,
        tryingToDealDeliveryDate,
        tryingToDealDeliveryDateType,
      } = form?.values;
      switch (form?.values?.type?.value) {
        case PHYSICAL: {
          checkIsFieldEmpty(
            [description, vehicleLocation, timeOption, type, autoAcceptPriceInPence],
            isDisabled => setIsNextDisabled(isDisabled),
          );
          break;
        }
        case MINIMUM_PRICE: {
          checkIsFieldEmpty(
            [description, vehicleLocation, timeOption, type, minimumPriceInPence],
            isDisabled => setIsNextDisabled(isDisabled),
          );
          break;
        }
        case TRYING_TO_DEAL: {
          checkIsFieldEmpty(
            [
              description,
              vehicleLocation,
              timeOption,
              type,
              tryingToDealDeliveryDateType,
              tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
                ? tryingToDealDeliveryDate
                : true,
            ],
            isDisabled => setIsNextDisabled(isDisabled),
          );
          break;
        }
        default:
          return null;
      }
      return null;
    };
    if (form.values) {
      checkIfDisableNextButton();
    }
  }, [form]);

  const templateDescription =
    descriptionTemplate &&
    descriptionTemplate.map(item => {
      return {
        value: item?.id,
        label: item?.template,
      };
    });
  const preparedOrganizationLocations =
    organizationLocations &&
    organizationLocations.map(item => {
      return {
        value: item?.id,
        label: item?.postalCode?.postcode,
      };
    });

  useEffect(() => {
    dispatch(fetchDescriptionTemplate());
    dispatch(fetchOrganizationLocations());
    dispatch(fetchOrganizationGroup());
  }, [dispatch]);

  const handleSaveDraft = () => {
    const {
      description,
      vehicleLocation,
      type,
      minimumPriceInPence,
      autoAcceptPriceInPence,
      tryingToDealDeliveryDate,
      tryingToDealDeliveryDateType,
      timeOption,
      publishTarget,
    } = form?.values;

    const descriptionAndSettings = {
      description,
      locationId: vehicleLocation?.value,
      type: type?.value,
      minimumPriceInPence:
        type?.value === AuctionStatusType.MINIMUM_PRICE
          ? parseCurrencyToIntIfString(minimumPriceInPence) * 100
          : null,
      autoAcceptPriceInPence:
        type?.value === AuctionStatusType.PHYSICAL
          ? parseCurrencyToIntIfString(autoAcceptPriceInPence) * 100
          : null,
      tryingToDealDeliveryDate:
        type?.value === AuctionStatusType.TRYING_TO_DEAL &&
        tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
          ? reverseDateToString(tryingToDealDeliveryDate)
          : null,
      tryingToDealDeliveryDateType:
        type?.value === AuctionStatusType.TRYING_TO_DEAL
          ? tryingToDealDeliveryDateType?.value
          : null,
      timeOption: timeOption?.value,
      publishTarget,
    };
    dispatch(
      saveDescriptionAndSettingsDraft(descriptionAndSettings, () => dispatch(getDraft(id))),
    );
    form.setErrors({});
  };

  const handleStatusChange = e => {
    dispatch(clearDescriptionsAndSettingsErrors());
    if (e.value === AuctionStatusType.TRYING_TO_DEAL) {
      form.setFieldValue('tryingToDealDeliveryDate', new Date());
    } else {
      form.setFieldValue('tryingToDealDeliveryDate', null);
    }
    form.setFieldValue('minimumPriceInPence', '');
    form.setFieldValue('autoAcceptPriceInPence', '');
  };

  return (
    <Form autoComplete="off" className="text-left">
      <h3 className="text-uppercase font-weight-bold text-center">Add vehicle</h3>
      <Row>
        <Col>
          <FormSelectField
            name="templateDescription"
            label="Description"
            placeholder="Choose a description"
            options={[...templateDescription]}
            classNames="example-description-select"
            setRef={setRef}
            onChangeHandler={option => {
              form.setFieldValue(
                'description',
                `${form?.values?.description ?? ''}${option?.label} `,
              );
              ref.focus();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInputField
            type={FormInputFieldType.TEXTAREA}
            id="add-description__user-description"
            name="description"
            placeholder="or write your own"
            setRef={setRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormSelectField
            name="vehicleLocation"
            label="Location"
            options={[...preparedOrganizationLocations]}
          />
        </Col>

        <Col md={6}>
          <FormSelectField name="timeOption" label="Time" options={auctionTimes} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormSelectField
            name="type"
            label="Status"
            options={auctionTypes}
            onChangeHandler={handleStatusChange}
          />

          {form?.values?.type?.value === AuctionStatusType.MINIMUM_PRICE && (
            <FormCurrencyInputField
              name="minimumPriceInPence"
              label="Minimum Offer"
              invalid={
                !!form.errors.minimumPriceInPence ||
                validationErrors?.vehicleDescriptionAndSettingsForm
              }
            />
          )}

          {form?.values?.type?.value === AuctionStatusType.PHYSICAL && (
            <FormCurrencyInputField
              name="autoAcceptPriceInPence"
              label="Reserve Price"
              invalid={
                !!form.errors.autoAcceptPriceInPence ||
                validationErrors?.vehicleDescriptionAndSettingsForm
              }
            />
          )}

          {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL && (
            <FormSelectField
              name="tryingToDealDeliveryDateType"
              label="Due in date"
              options={dueInDateTypes}
            />
          )}

          {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL &&
            form?.values?.tryingToDealDeliveryDateType?.value ===
              DueInDateType.CUSTOM_DATE && (
              <FormDatePickerField
                name="tryingToDealDeliveryDate"
                label="Delivery date"
                error={
                  !!form.errors.tryingToDealDeliveryDate ||
                  validationErrors?.vehicleDescriptionAndSettingsForm
                }
                readOnly
              />
            )}
        </Col>

        <Col md={6} className="pt-3">
          {organizationGroup && (
            <FormGroupRadioField
              name="publishTarget"
              radios={auctionPublishTypes}
              classNames="auction-publish-radios"
            />
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <CommonButton label="Back" variant={SECONDARY} handleClick={prevForm} />
        </Col>
        <Col md={6} className="d-flex flex-column align-items-end">
          <CommonButton
            type="submit"
            label="Next"
            className="mb-3"
            variant={isLoading || isNextDisabled ? DISABLED : PRIMARY}
            disabled={isLoading || isNextDisabled}
          />
          <CommonButton
            label="Save"
            variant={isLoading ? DISABLED : SECONDARY}
            disabled={isLoading}
            handleClick={handleSaveDraft}
          />
        </Col>
      </Row>
    </Form>
  );
};

AddDescriptionForm.propTypes = {
  form: PropTypes.object.isRequired,
  prevForm: PropTypes.func.isRequired,
};

export default AddDescriptionForm;
