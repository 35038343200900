export const makeAmdModelsOrAll = (makes, models) => {
  if (!makes && !models) return 'ALL MAKES & MODELS';
  if (makes && !models) return `${makes}, ALL MODELS`;
  return `${makes} ${models}`;
};

export const mileageOrAll = value => {
  return value ? `Up to ${value} miles` : 'Mileage: All';
};

export const yearsOrAll = (yearFrom, yearTo) => {
  if (!yearFrom || !yearTo) return 'Years: All';
  return `${yearFrom}-${yearTo}`;
};

export const distanceOrAll = value => {
  return value ? `Distance ${value} miles` : 'Distance: National';
};
