import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Form } from 'formik';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { UserStatus } from '../../constants/UserStatus';
import CardElementWrapper from '../../common/components/CardElementWrapper';
import FormInputField from '../../common/components/formFields/FormInputField';
import cardNumberIcon from '../../assets/img/cardNumberIcon.svg';
import cardholderIcon from '../../assets/img/cardholderIcon.svg';
import {
  getStripeAccountClientSecret,
  getStripeDirectDebitCheckoutSession,
  updateCreditCard,
} from '../MyWalletActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { StripeValidationErrorType } from '../../constants/StripeValidationError';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import { PaymentMethod } from '../../constants/PaymentMethod';

const { PRIMARY, DISABLED } = CommonButtonVariants;
const { CREDIT_CARD, DIRECT_DEBIT, BASED_ON_THE_INVOICE } = PaymentMethod;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const WalletDetailsFormInner = ({ values }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const last4 = useSelector(state => state.myWallet.get('last4'));
  const paymentMethod = useSelector(state => state.myWallet.get('paymentMethod'));
  const userAuthStatus = useSelector(state => state.auth.get('status'));
  const stripeValidationMessage = useSelector(state =>
    state.register.get('stripeValidationMessage'),
  );

  const [isChangeAccepted, setIsChangeAccepted] = useState(false);
  const [errors, setErrors] = useState({
    cardholderError: '',
  });

  const isSubmittingDisabled = isLoading || !values?.cardholder;

  const renderBlockedDueToPaymentMessage = isDirectDebitMethodPayment => {
    if (last4) {
      return (
        <p className="text-center payment wallet-details__change-accepted">
          {`Thank you for adding the ${
            isDirectDebitMethodPayment ? 'direct debit account' : 'card'
          }. After successful
          verification your account will be activated in a few minutes. If not, please contact
          us.`}
        </p>
      );
    }
    if (last4 === null) {
      return (
        <p className="text-center payment wallet-details__user-blocked-warning">
          We could not charge the payment from your account. Please enter a valid credit card
          number.
        </p>
      );
    }
    return null;
  };

  const renderPaymentMethodForm = () => {
    switch (paymentMethod) {
      case CREDIT_CARD:
      case BASED_ON_THE_INVOICE:
        return (
          <>
            {userAuthStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT ? (
              <Row>
                <Col>{renderBlockedDueToPaymentMessage()}</Col>
              </Row>
            ) : (
              <>
                {last4 === null && !isChangeAccepted && (
                  <p className="text-center mb-5 payment wallet-details__no-card">
                    No card added to the account. Click{' '}
                    <span className="text-uppercase font-weight-bold">add</span> to add a new
                    card.
                  </p>
                )}
              </>
            )}
            {!isChangeAccepted && (
              <Row className="justify-content-between">
                <Col md="12" className="d-flex flex-column">
                  <FormInputField
                    name="cardNumber"
                    placeholder="Card Number"
                    icon={cardNumberIcon}
                    disabled={!isChangeAccepted}
                  />
                </Col>
              </Row>
            )}
            {isChangeAccepted && (
              <>
                <Row className="justify-content-between ">
                  <Col md="12">
                    <CardElementWrapper elements={elements} disabled={isLoading} />
                    <CommonErrorLabel value={stripeValidationMessage} />
                  </Col>
                  <Col md="12">
                    <FormInputField
                      name="cardholder"
                      placeholder="Cardholder's name"
                      icon={cardholderIcon}
                      nolabel
                      disabled={isLoading}
                    />
                    {errors.cardholderError && (
                      <CommonErrorLabel value={errors.cardholderError} />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      label={last4 ? 'Change' : 'Add'}
                      variant={
                        isSubmittingDisabled
                          ? CommonButtonVariants.DISABLED
                          : CommonButtonVariants.PRIMARY
                      }
                      disabled={isSubmittingDisabled}
                      handleClick={handleSubmit}
                    />
                  </Col>
                </Row>
              </>
            )}
            {!isChangeAccepted && (
              <Row className="mt-3">
                <Col className="d-flex justify-content-center">
                  <CommonButton
                    type="button"
                    label={last4 ? 'Change' : 'Add'}
                    handleClick={() => {
                      setIsChangeAccepted(true);
                    }}
                    variant={isLoading ? DISABLED : PRIMARY}
                    disabled={isLoading}
                  />
                </Col>
              </Row>
            )}
          </>
        );
      case DIRECT_DEBIT:
        return (
          <div className="d-flex flex-column align-items-center">
            {userAuthStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT && (
              <Row>
                <Col>{renderBlockedDueToPaymentMessage(true)}</Col>
              </Row>
            )}
            {!last4 && (
              <p className="text-center mb-5 payment wallet-details__no-card">
                Your payment method is{' '}
                <span className="text-uppercase font-weight-bold">Direct Debit</span>. Click{' '}
                <span className="text-uppercase font-weight-bold">Add</span> to start process.
              </p>
            )}
            {last4 && (
              <Col md="12" className="d-flex flex-column">
                <FormInputField
                  name="cardNumber"
                  placeholder="Card Number"
                  icon={cardNumberIcon}
                  disabled={!isChangeAccepted}
                />
              </Col>
            )}

            <CommonButton
              type="button"
              label={last4 ? 'Change' : 'Add'}
              variant={
                isLoading ? CommonButtonVariants.DISABLED : CommonButtonVariants.PRIMARY
              }
              disabled={isLoading}
              handleClick={() => dispatch(getStripeDirectDebitCheckoutSession(stripe))}
              className="mt-3"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { cardholder } = values;
      const errors = {
        cardholderError: validateFormField(cardholder, FormFieldTypes.STRING),
      };
      setErrors(errors);

      const { cardholderError } = errors;
      if (cardholderError) {
        reject();
      }
      resolve();
    });

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      await validateForm();
      dispatch(
        getStripeAccountClientSecret(values, stripe, elements, stripePaymentMethodId =>
          dispatch(updateCreditCard(stripePaymentMethodId, () => setIsChangeAccepted(false))),
        ),
      );
    } catch (e) {
      console.error(StripeValidationErrorType.VALIDATION_ERROR);
    }
  };

  return (
    <Form>
      <Col md={12} className="justify-content-center mr-auto ml-auto">
        <h3 className="text-uppercase font-weight-bold text-center">My card</h3>
        {renderPaymentMethodForm()}
      </Col>
    </Form>
  );
};

const WalletDetailsForm = ({ values }) => {
  return (
    <Elements stripe={stripePromise}>
      <WalletDetailsFormInner values={values} />
    </Elements>
  );
};

export default WalletDetailsForm;
