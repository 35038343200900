export const FormFieldTypes = {
  STRING: 'string',
  NAME: 'name',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  EMAIL: 'email',
  PASSWORD: 'password',
  ENUM: 'enum',
  MONTH: 'month',
  YEAR: 'year',
  PROD_YEAR: 'prod_year',
  DERIVATIVE: 'derivative',
  DOORS: 'doors',
  PREV_KEEPERS: 'prev_keepers',
  DATE_OF_REG: 'date_of_reg',
  ENGINE_SIZE: 'engine_size',
  URL: 'url',
  COMPANY_REGISTRATION_NUMBER: 'company_registration_number',
  VIN: 'vin',
};
