import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo-2.png';
import { InformationForUsersList } from '../constants/FooterNavList';
import { CompanyInfo } from '../constants/CompanyInfo';
import RouterPaths from '../constants/RouterPaths';
import CommonDownloadIcons from '../common/CommonDownloadIcons';

const FooterNav = () => {
  return (
    <div className="footer-nav">
      <Container>
        <Row className="footer-nav__row">
          <Col md={3} className="footer-nav__row-item d-flex align-items-center">
            <Link to={RouterPaths.DASHBOARD}>
              <img src={logo} alt="Page logo - itrade30" />
            </Link>
          </Col>
          <Col md={3}>
            <p className="footer-nav__list-heading">Information for users</p>
            <ul className="d-flex flex-column">
              {InformationForUsersList.map(item => (
                <li key={item.path} className="footer-nav__list-item">
                  <Link to={item.path} className="footer-nav__list-item-link">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3}>
            <p className="footer-nav__list-heading">Download app</p>
            <CommonDownloadIcons />
          </Col>
          <Col md={3}>
            <p className="footer-nav__list-heading">Quick support</p>
            <p className="footer-nav__paragraph">
              For help please send us a message on{' '}
              <a
                href={`mailto:${CompanyInfo.EMAIL}`}
                className="footer-nav__company-email-link"
              >
                {CompanyInfo.EMAIL}
              </a>{' '}
              or call <b>0116 4826044</b> and we will answer you as soon as possible.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterNav;
