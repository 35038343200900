import React from 'react';
import FooterNav from './FooterNav';

class Footer extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="styled-footer">
        <FooterNav />
        <div className="footer-company-info">
          <p className="text-center mb-0">{`ITrade ${currentYear} | All rights reserved`}</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
