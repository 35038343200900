export const DIRECT_DEBIT = '/direct-debit';

export default {
  HOMEPAGE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  IMPERSONATE: '/impersonate',
  CONFIRM_FORGOT_PASSWORD: '/confirm-forgot-password',
  ADD_VEHICLE: '/add-vehicle',
  ADD_NUMBER: '/add-number',
  ADD_PHOTO: '/add-photo',
  CONFIRM_NUMBER: '/confirm-number',
  CONTACT_US: '/contact-us',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  FAQ: '/faq',
  DASHBOARD: '/dashboard',
  MY_WALLET: '/my-organization/my-wallet',
  TRANSACTIONS: '/my-organization/transactions',
  INVOICES: '/my-organization/invoices',
  EMPLOYEES: '/my-organization/employees',
  EMPLOYEE_DETAILS: '/my-organization/employees/:id',
  CREATE_EMPLOYEE: '/my-organization/employee',
  MY_PROFILE: '/my-organization/my-profile',
  SEARCH_ALERTS: '/my-organization/forms-alerts',
  CREATE_SEARCH_ALERT: '/my-organization/forms-alerts/create',
  EDIT_SEARCH_ALERT: '/my-organization/forms-alerts/edit/:id',
  NOTIFICATIONS: '/my-organization/notifications',
  MY_VEHICLES: '/my-vehicles',
  MY_VEHICLES_DETAILS: '/my-vehicles/:id',
  MY_VEHICLES_DETAILS_CONFIRM: '/my-vehicles/:id/confirm',
  MY_BIDS: '/my-bids',
  MY_BIDS_DETAILS: '/my-bids/:id',
  SEARCH: '/search',
  SEARCH_DETAILS: '/auction/:id',
  STRIPE_CHECKOUT: `${DIRECT_DEBIT}/checkout`,
  STRIPE_SUCCESS: `${DIRECT_DEBIT}/success`,
  STRIPE_CANCEL: `${DIRECT_DEBIT}/cancel`,
};
