import React, { useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DealerRoleName } from '../../constants/DealerRole';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import FormGroupRadioField from '../../common/components/formFields/FormGroupRadioField';
import CommonButton from '../../common/components/CommonButton';
import { validateOrganizationRole } from '../RegisterActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';

const dealerRoles = constantToSelect(DealerRoleName);

const paragraphs = {
  FRANCHISED_DEALER: '',
  INDEPENDENT_DEALER: '',
};

const RoleForm = ({ values, nextStep }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    organizationRoleError: '',
  });

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { organizationRole } = values;
      const errors = {
        organizationRoleError: organizationRole ? '' : 'Field is required',
      };
      setErrors(errors);

      const { organizationRoleError } = errors;
      if (organizationRoleError) {
        reject();
      }
      resolve();
    });

  const moveToNextStep = () => {
    validateForm()
      .then(() =>
        dispatch(validateOrganizationRole(values.organizationRole, () => nextStep())),
      )
      .catch(() => {});
  };
  const { organizationRoleError } = errors;
  return (
    <Col md="8" className="justify-content-center m-auto">
      <div className="text-center">
        <h2 className="text-uppercase font-weight-bold">SELECT YOUR DEALER TYPE</h2>
      </div>
      <Form autoComplete="off" className="mt-5">
        <Col md="8" className="justify-content-center m-auto">
          <Row className="justify-content-md-center">
            <Col md="9">
              <FormGroupRadioField
                name="organizationRole"
                radios={dealerRoles}
                paragraphs={paragraphs}
              />
              <CommonErrorLabel value={organizationRoleError} />
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <CommonButton label="Next" handleClick={moveToNextStep} asyncButton />
          </Row>
        </Col>
      </Form>
    </Col>
  );
};

RoleForm.defaultProps = {
  nextStep: null,
};

RoleForm.propTypes = {
  nextStep: PropTypes.func,
  values: PropTypes.object.isRequired,
};

export default RoleForm;
