import React, { useState } from 'react';
import {
  Navbar,
  Container,
  NavbarToggler,
  Collapse,
  NavItem,
  Nav,
  Col,
  Row,
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery } from 'search/SearchActions';
import RouterPaths from '../../../constants/RouterPaths';
import logo from '../../../assets/img/logo-2.png';
import { AuthenticatedNavItems } from '../../../constants/AuthenticatedNavItems';
import { logoutUser } from '../../../auth/AuthActions';
import NavbarLoader from '../NavbarLoader/NavbarLoader';
import SearchForm from './SearchForm';

const Header = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const roles = {
    buyer: useSelector(state => state.auth.get('isBuyer')),
    seller: useSelector(state => state.auth.get('isSeller')),
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="styled-header">
      <Container
        className={`header-container pt-2 pb-2 ${
          isAuthenticated ? 'd-flex justify-content-center' : ''
        }`}
      >
        <Navbar expand="lg" className="header__navbar" color="white">
          <Link
            to={RouterPaths.HOMEPAGE}
            onClick={() => dispatch(setSearchQuery(''))}
            className="page-logo-link"
          >
            <img src={logo} alt="logo" />
          </Link>

          <>
            {isAuthenticated ? (
              <div>
                <Collapse isOpen={isOpen} navbar className="justify-content-start">
                  <Nav navbar className="page-nav--logged">
                    {AuthenticatedNavItems.filter(
                      item => !item.role || (item.role && roles[item.role]),
                    ).map(item => (
                      <NavItem key={item.path} className="page-nav__item">
                        <NavLink
                          to={item.path}
                          className="page-nav__link text-uppercase"
                          activeClassName="page-nav__link--active"
                          onClick={() => {
                            if (item?.action) dispatch(item.action());
                          }}
                          exact
                        >
                          {item.label}
                        </NavLink>
                      </NavItem>
                    ))}
                    <NavItem className="page-nav__item">
                      <button
                        type="button"
                        className="page-nav__link text-uppercase"
                        onClick={() => dispatch(logoutUser())}
                      >
                        Log out
                      </button>
                    </NavItem>
                  </Nav>
                </Collapse>
                <Row className="d-flex mt-3">
                  <Col xs="9" lg="12">
                    <SearchForm />
                  </Col>
                </Row>
              </div>
            ) : (
              <Collapse isOpen={isOpen} navbar className="justify-content-end">
                <Nav
                  navbar
                  className="d-flex flex-row align-items-center justify-content-center register-wrapper"
                >
                  <NavItem>
                    <NavLink
                      to={RouterPaths.LOGIN}
                      className="auth-button"
                      activeClassName="auth-button--active"
                    >
                      Login
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={RouterPaths.REGISTER}
                      className="auth-button"
                      activeClassName="auth-button--active"
                    >
                      Register
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            )}
          </>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
        </Navbar>
      </Container>
      <NavbarLoader />
    </header>
  );
};

export default Header;
