export class NotificationConfig {
  constructor(emailEnabled, pushEnabled) {
    this.emailEnabled = emailEnabled;
    this.pushEnabled = pushEnabled;
  }

  pressEmail() {
    return new NotificationConfig(!this.emailEnabled, this.pushEnabled);
  }

  pressPush() {
    return new NotificationConfig(this.emailEnabled, !this.pushEnabled);
  }
}
