import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CommonButton from 'common/components/CommonButton';
import history from 'browserHistory';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import RouterPaths from '../constants/RouterPaths';
import MyVehiclesItem from '../myvehicles/MyVehiclesItem';
import { getMyLatestAuctions } from './HomePageActions';
import {
  getAuction,
  handleAuctionEvent,
  setVehicleFilter,
} from '../myvehicles/MyVehiclesActions';
import { SSE_SELLER, SSEClient } from '../api/SSEClient';
import { LATEST_AUCTIONS_REFRESH_SUCCESS } from './HomePageReducer';

const sseClient = new SSEClient(SSE_SELLER);

const HomeMyVehicles = () => {
  const dispatch = useDispatch();
  const myLatestAuctions = useSelector(state => state.homePage.get('myLatestAuctions').toJS());
  const auctionsTimestamp = useSelector(state =>
    state.homePage.get('myLatestAuctionsTimeStamp'),
  );
  const [auctionsIds, setAuctionsIds] = useState([]);

  const navigateToMyVehicles = () => {
    dispatch(setVehicleFilter(''));
    history.push(RouterPaths.MY_VEHICLES);
  };

  const navigateToDetails = item => {
    dispatch(setVehicleFilter(''));
    history.push({
      pathname: reverse(RouterPaths.MY_VEHICLES_DETAILS, { id: item.id }),
    });
  };

  const updateSearchItems = newItem => {
    dispatch({
      type: LATEST_AUCTIONS_REFRESH_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  useEffect(() => {
    dispatch(
      getMyLatestAuctions('', auctionsIds => {
        setAuctionsIds(prev => prev.concat(auctionsIds));
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    sseClient.listen(auctionsIds, response => {
      dispatch(
        handleAuctionEvent(response, () =>
          dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          ),
        ),
      );
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionsIds, dispatch]);

  return (
    <div className="home-my-vehicles">
      <Container>
        <Row>
          <Col className=" d-flex justify-content-center text-center">
            <h3 className="home-my-vehicles__title">My Vehicles</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {myLatestAuctions?.map(item => (
              <MyVehiclesItem
                item={item}
                key={item.id}
                auctionTimestamp={auctionsTimestamp}
                onClickItem={() => navigateToDetails(item)}
              />
            ))}
          </Col>
        </Row>
        <Row className="justify-content-center home-my-vehicles__button-wrapper" lg={6}>
          <Col className="d-flex justify-content-center">
            <CommonButton label="My vehicles" handleClick={navigateToMyVehicles} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeMyVehicles;
