import React from 'react';
import { Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import magnifierIcon from 'assets/img/magnifierIcon.svg';
import PropTypes from 'prop-types';
import CommonButton from 'common/components/CommonButton';
import FormInputField from '../common/components/formFields/FormInputField';

const MyBidsSearch = ({ handleSearch }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    handleSearch(values);
    setSubmitting(false);
  };
  return (
    <Formik initialValues={{ search: '' }} onSubmit={handleSubmit}>
      <Form autoComplete="off" className="text-left">
        <Row className="justify-content-start d-flex align-items-center">
          <Col lg="4">
            <FormInputField
              name="search"
              placeholder="Find a vehicle"
              icon={magnifierIcon}
              iconClassName="search-icon"
            />
          </Col>
          <Col lg="3">
            <div className="my-bids-search-button-wrapper">
              <CommonButton label="Search" type="submit" />
            </div>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

MyBidsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default MyBidsSearch;
