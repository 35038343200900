import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import InfinityLoader from '../common/components/InfinityLoader';
import RouterPaths from '../constants/RouterPaths';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeDirectDebitCheckoutInner = () => {
  const { search } = useLocation();
  const stripe = useStripe();

  const sessionId = qs.parse(search, { ignoreQueryPrefix: true })?.session_id;

  useEffect(() => {
    const redirectToCheckout = async () => {
      try {
        await stripe.redirectToCheckout({
          sessionId,
        });
      } catch (e) {
        window.location.href = RouterPaths.MY_WALLET;
      }
    };
    if (stripe && sessionId) {
      redirectToCheckout();
    }
  }, [stripe, sessionId]);

  return <InfinityLoader />;
};

const StripeDirectDebitCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripeDirectDebitCheckoutInner />
    </Elements>
  );
};

export default StripeDirectDebitCheckout;
