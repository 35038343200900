import React from 'react';
import PropTypes from 'prop-types';
import InfinityLoader from './InfinityLoader';

export const CommonShadowLoaderType = {
  NOT_PENDING: 'NOT_PENDING',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
};

const CommonShadowLoader = ({ status = CommonShadowLoaderType.NOT_PENDING, children }) => {
  return (
    <>
      {(CommonShadowLoaderType.IN_PROGRESS === status ||
        CommonShadowLoaderType.PENDING === status) && (
        <div className="common-shadow-loader">
          {CommonShadowLoaderType.IN_PROGRESS === status && <InfinityLoader />}
        </div>
      )}
      {children}
    </>
  );
};

CommonShadowLoader.propTypes = {
  status: PropTypes.oneOf(Object.keys(CommonShadowLoaderType)).isRequired,
};

export default CommonShadowLoader;
