import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { login } from './AuthActions';
import RouterPaths from '../constants/RouterPaths';
import CommonButton from '../common/components/CommonButton';
import FormInputField, {
  FormInputFieldType,
} from '../common/components/formFields/FormInputField';
import validationSchema from './Login.schema';
import { CommonButtonVariants } from '../constants/CommonButtonVariants';

const { DISABLED, PRIMARY } = CommonButtonVariants;

const initialValues = {
  email: '',
  password: '',
};

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  useEffect(() => {
    if (isAuthenticated) {
      history.push(RouterPaths.HOMEPAGE);
    }
  }, [isAuthenticated, history]);

  const handleSubmit = ({ email, password }, { setSubmitting }) => {
    dispatch(login(email, password));
    setSubmitting(false);
  };

  return (
    <Col className="ml-auto mr-auto" lg={5} md={6}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form className="login-form">
            <h3 className="text-uppercase font-weight-bold text-center">Login</h3>
            <FormInputField name="email" label="E-mail address" />
            <FormInputField
              type={FormInputFieldType.PASSWORD}
              name="password"
              label="Password"
            />
            <Link
              className="login-form__forgot-password-link"
              to={RouterPaths.FORGOT_PASSWORD}
            >
              Forgot your password?
            </Link>
            <CommonButton
              type="submit"
              label="Login"
              className="login-form__submit-button"
              disabled={isLoading}
              variant={isLoading ? DISABLED : PRIMARY}
            />
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export default withRouter(Login);
