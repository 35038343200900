import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  isFinished,
  prepareAuctionTimeLabel,
} from '../common/helpers/prepareAuctionTimeLabel';
import { auctionListType } from '../constants/AuctionListType';
import { CommonString } from '../constants/CommonString';
import {
  dateTimeToString,
  isDateAfter,
  timerFromSeconds,
} from '../common/utils/date/Date.utils';
import { AuctionStatus } from '../constants/AuctionStatus';

const { PUBLISHED } = AuctionStatus;

const AuctionTimeLabel = ({
  item,
  windowFocus,
  auctionTimestamp,
  listType,
  organizationId,
}) => {
  const [diffTime, setDiffTime] = useState('');

  useEffect(() => {
    let interval;
    /* Delay of useEffect's call is ~-1 */
    const timeSinceLastRequest = Math.round(
      (new Date()?.getTime() - auctionTimestamp?.getTime()) / 1000,
    );
    let secondsLeft = item?.publishSecondsLeft - timeSinceLastRequest - 1;
    if (
      isDateAfter(item?.endsOn, new Date()) &&
      secondsLeft > 0 &&
      item?.status === PUBLISHED
    ) {
      setDiffTime(timerFromSeconds(secondsLeft));
      interval = setInterval(() => {
        if (secondsLeft > 0) {
          secondsLeft--;
          return setDiffTime(timerFromSeconds(secondsLeft));
        }
        return setDiffTime(null);
      }, 1000);
    } else {
      setDiffTime(null);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.publishSecondsLeft, item.endsOn, item.status, windowFocus]);

  const renderAuctionTime = () => {
    return (
      <div>
        {item.status === AuctionStatus.PUBLISHED && !isFinished(diffTime) && item.endsOn && (
          <p className="ends-time">Ends - {dateTimeToString(item.endsOn)}</p>
        )}
        <p className="search-time">
          {prepareAuctionTimeLabel(
            diffTime,
            item,
            listType === auctionListType.MY_VEHICLES ||
              (listType === auctionListType.SEARCH &&
                item?.seller?.organizationContact?.id !== organizationId),
          ) || CommonString.NOT_SPECIFIED}
        </p>
      </div>
    );
  };

  return renderAuctionTime();
};

AuctionTimeLabel.defaultProps = {
  item: null,
  windowFocus: false,
  auctionTimestamp: 0,
};

AuctionTimeLabel.propTypes = {
  item: PropTypes.object,
  windowFocus: PropTypes.bool,
  auctionTimestamp: PropTypes.any,
  listType: PropTypes.string,
  organizationId: PropTypes.string,
};

export default AuctionTimeLabel;
