import React from 'react';
import PropTypes from 'prop-types';
import {
  distanceOrAll,
  makeAmdModelsOrAll,
  mileageOrAll,
  yearsOrAll,
} from '../../../search/utils';

const SearchAlertsTableRow = ({
  item,
  setSearchAlertToDelete,
  copyItemHandler,
  editItemHandler,
}) => {
  return (
    <>
      <td>
        <p className="font-weight-bold">
          {makeAmdModelsOrAll(item?.vehicleMakeName, item?.vehicleModelName)}
          <i
            tabIndex={0}
            aria-label="edit"
            role="button"
            onClick={() => editItemHandler(item.id)}
            className="fas fa-pen search-alerts__trash-icon"
          />
          <i
            tabIndex={0}
            aria-label="copy"
            role="button"
            onClick={() => copyItemHandler(item)}
            className="far fa-copy search-alerts__trash-icon"
          />
        </p>
        <span>
          {yearsOrAll(item?.yearFrom, item?.yearTo)}, {mileageOrAll(item?.mileageMaxValue)},{' '}
          {distanceOrAll(item?.distanceMaxValueInMiles)}
        </span>
      </td>
      <td className="text-right pr-4">
        <button type="button" onClick={() => setSearchAlertToDelete(item.id)}>
          <i className="far fa-trash-alt search-alerts__trash-icon" />
        </button>
      </td>
    </>
  );
};

SearchAlertsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SearchAlertsTableRow;
