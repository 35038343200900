import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { TyreDepths } from '../../constants/TyreDepths';
import { FormSelectFieldSuffix } from '../../constants/FormSelectFieldSuffix';
import { getDraft, saveMediaAndTyresDraft } from '../AddVehicleActions';

const { PRIMARY, SECONDARY, DISABLED } = CommonButtonVariants;

const tyreDepths = constantToSelect(TyreDepths);

const TyreTreadDepthsForm = ({ form, prevForm, values }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const id = useSelector(state => state.addVehicle.get('id'));

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    const isFieldValueEmpty = Object.values(form?.values).some(item => !item?.value);
    if (isFieldValueEmpty) {
      return setIsNextDisabled(true);
    }
    return setIsNextDisabled(false);
  }, [form]);

  const saveDraft = () => {
    const tyreThreadDepths = {
      nearsideFront: values?.nearsideFront?.label,
      nearsideRear: values?.nearsideRear?.label,
      offsideFront: values?.offsideFront?.label,
      offsideRear: values?.offsideRear?.label,
    };
    dispatch(saveMediaAndTyresDraft(tyreThreadDepths, () => dispatch(getDraft(id))));
    form.setErrors({});
  };

  return (
    <Form>
      <p className="font-weight-bold text-center mt-4">Tyre tread depths</p>
      <Row>
        <Col md={6}>
          <FormSelectField
            name="nearsideFront"
            label="Nearside front"
            options={tyreDepths}
            suffix={FormSelectFieldSuffix.MILLIMETERS}
          />
          <FormSelectField
            name="nearsideRear"
            label="Nearside rear"
            options={tyreDepths}
            suffix={FormSelectFieldSuffix.MILLIMETERS}
          />
        </Col>
        <Col md={6}>
          <FormSelectField
            name="offsideFront"
            label="Offside front"
            options={tyreDepths}
            suffix={FormSelectFieldSuffix.MILLIMETERS}
          />
          <FormSelectField
            name="offsideRear"
            label="Offside rear"
            options={tyreDepths}
            suffix={FormSelectFieldSuffix.MILLIMETERS}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col
          className="d-flex flex-column align-items-md-start align-items-sm-center"
          sm="12"
          md="6"
        >
          <CommonButton label="Back" variant={SECONDARY} handleClick={prevForm} />
        </Col>
        <Col
          className="d-flex flex-column align-items-md-end align-items-sm-center"
          sm="12"
          md="6"
        >
          <CommonButton
            type="submit"
            label="Next"
            className="mb-3"
            variant={isLoading || isNextDisabled ? DISABLED : PRIMARY}
            disabled={isLoading || isNextDisabled}
          />
          <CommonButton
            label="Save"
            handleClick={saveDraft}
            variant={isLoading ? DISABLED : SECONDARY}
            disabled={isLoading}
          />
        </Col>
      </Row>
    </Form>
  );
};

TyreTreadDepthsForm.propTypes = {
  prevForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default TyreTreadDepthsForm;
