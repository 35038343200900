import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setMakeId, setRefreshQuery } from 'search/SearchActions';
import defaultLogo from '../assets/img/logo-2.png';

const LogoItem = ({ logoUrl, make, id, count }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const makeId = useSelector(state => state.search.get('makeId'));
  const handleSearchByMake = id => {
    dispatch(setMakeId(id === makeId ? null : id));
    dispatch(setRefreshQuery());
  };

  const handleError = () => {
    setError(true);
  };

  const getClassByActive = () => {
    if (id === makeId) {
      return 'find-best-vehicles__logo-thumnbnail find-best-vehicles__logo-wrapper_active';
    }
    return 'find-best-vehicles__logo-thumnbnail';
  };

  return (
    <button
      type="button"
      className={getClassByActive()}
      onClick={() => handleSearchByMake(id)}
    >
      <div className="find-best-vehicles__logo-wrapper ">
        <img
          src={error ? defaultLogo : logoUrl}
          alt={make}
          onError={handleError}
          className="find-best-vehicles__logo"
        />
      </div>
      <div className="find-best-vehicles__count">{count}</div>
    </button>
  );
};

export default LogoItem;

LogoItem.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};
