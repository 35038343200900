import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UnderwriteImg from 'assets/img/HomePage/underwrite.png';
import { AUCTION_BID_MAX_BIDS_LIMIT } from '../constants/AuctionBid';

const Underwrite = () => {
  return (
    <div className="underwrite">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs="12" md="3">
            <p className="bulletpoints">
              <i className="fas fa-check-circle" /> Never miss the opportunity to do a deal
              while your customer is present because your unable to get the best price for the
              part exchange.
            </p>
            <p className="bulletpoints">
              <i className="fas fa-check-circle" /> Our innovative mobile app enables you to
              upload vehicles straight from your mobile device along with fully detailed
              vehicle appraisal, images and video.
            </p>
            <p className="bulletpoints">
              <i className="fas fa-check-circle" /> Get the best price offered in record time.
              You can have a firm offer for the part exchange by the time you return from the
              test drive.
            </p>
          </Col>
          <Col xs="12" md="6">
            <h3>Underwrite</h3>
            <img src={UnderwriteImg} alt="itrade30" className="img-example" />
          </Col>
          <Col xs="12" md="3">
            <p className="bulletpoints">
              <i className="fas fa-check-circle" /> Receive instant alerts via the app as soon
              as a new vehicle is launched making sure you never miss the chance to place an
              underwrite.
            </p>
            <p className="bulletpoints">
              <i className="fas fa-check-circle" /> A Maximum of {AUCTION_BID_MAX_BIDS_LIMIT}{' '}
              bids from each buyer ensures we get the highest offers first time every time,
              helping you to close every deal.
            </p>
            <p className="bulletpoints">
              <i className="fas fa-check-circle" /> Our interactive dashboard keeps you up to
              date with every offer, listing and all completed transactions.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Underwrite;
