export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const prepareSearchData = (values, sortOptions = {}, isOneFieldSearch = null) => {
  const page = {
    pageNumber: values.offset,
    pageSize: 10,
  };

  const criteria = {};
  Object.keys(values).forEach(key => {
    if (values[key]) {
      if (
        typeof values[key] === 'object' &&
        Object.prototype.hasOwnProperty.call(values[key], 'value')
      ) {
        criteria[key] = values[key].value;
      } else if (isOneFieldSearch && values?.search) {
        criteria.search = values[key];
      } else {
        criteria[key] = values[key];
      }
    }
  });

  if (criteria.offset) {
    delete criteria.offset;
  }

  if (Object.keys(sortOptions).length) {
    page.sort = {
      orders: [],
    };

    Object.keys(sortOptions).forEach(key => {
      page.sort.orders.push({
        direction: sortOptions[key],
        property: key,
      });
    });
  }

  return { criteria, page };
};
