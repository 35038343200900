import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RegisterForm from './forms/RegisterForm';
import RouterPaths from '../constants/RouterPaths';
import { clearCompanyDetails } from './RegisterActions';

const RegisterPage = ({ history }) => {
  const dispatch = useDispatch();
  const companyDetails = useSelector(state => state.register.get('companyDetails'));
  const companyNumber = useSelector(state => state.register.get('companyNumber'));
  const officialCompanyName = useSelector(state => state.register.get('officialCompanyName'));
  const organizationRole = useSelector(state => state.register.get('organizationRole'));
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  useEffect(() => {
    if (isAuthenticated) {
      history.push(RouterPaths.DASHBOARD);
    }
  }, [isAuthenticated, history]);

  useEffect(() => {
    return () => {
      return dispatch(clearCompanyDetails());
    };
  }, [dispatch]);

  const initialValues = {
    organizationRole: organizationRole || '',
    vatNumber: companyDetails?.vatNumber || '',
    officialCompanyName: officialCompanyName || '',
    tradeName: companyDetails?.tradeName || '',
    companyRegisterNumber: companyNumber || '',
    postalCodeId: companyDetails?.postalCodeId?.value
      ? {
          value: companyDetails?.postalCodeId?.value,
          label: companyDetails?.postalCodeId?.label,
        }
      : '',
    address: companyDetails?.address || '',
    address2: companyDetails?.address2 || '',
    city: companyDetails?.city || '',
    county: companyDetails?.county || '',
    phoneNumber: companyDetails?.phoneNumber || '',
    mobileNumber: companyDetails?.mobileNumber || '',
    website: companyDetails?.website || '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordRepeat: '',
    position: '',
    acceptTos: false,
    acceptNewsletter: true,
    cardholder: '',
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={() => null} enableReinitialize>
        {({ values, setFieldValue }) => (
          <RegisterForm values={values} setFieldValue={setFieldValue} />
        )}
      </Formik>
    </>
  );
};

export default withRouter(RegisterPage);
