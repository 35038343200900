import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import SweetAlert from 'react-bootstrap-sweetalert';
import SearchAlertsTable from './table/SearchAlertsTable';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';
import CommonButton from '../../common/components/CommonButton';
import RouterPaths from '../../constants/RouterPaths';
import {
  clearCopiedSearchAlertState,
  clearEditSearchAlertState,
  copyCopiedSearchAlertState,
  removeSearchAlert,
  searchSearchAlerts,
} from './SearchAlertActions';

const SearchAlertsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();
  const [searchAlertToDelete, setSearchAlertToDelete] = useState(null);

  const items = useSelector(state => state.searchAlerts.get('searchAlerts'));
  const totalItems = useSelector(state => state.searchAlerts.get('searchAlertsTotalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchSearchAlerts(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const removeItemHandler = id => {
    if (id) {
      dispatch(removeSearchAlert(id)).then(() => {
        dispatch(searchSearchAlerts(searchParams));
      });
    }
  };

  const copyItemHandler = values => {
    if (values) {
      dispatch(clearCopiedSearchAlertState());
      dispatch(copyCopiedSearchAlertState(values));
      history.push(RouterPaths.CREATE_SEARCH_ALERT);
    }
  };

  const editItemHandler = id => {
    if (id) {
      dispatch(clearEditSearchAlertState(id));
      history.push(reverse(RouterPaths.EDIT_SEARCH_ALERT, { id }));
    }
  };

  return (
    <CommonMyOrganizationContainer>
      <h2 className="text-center font-weight-bold text-uppercase">Search Alerts</h2>
      <div className="box-of-button">
        <CommonButton
          type="submit"
          label="Add"
          handleClick={() => {
            dispatch(clearCopiedSearchAlertState());
            history.push(RouterPaths.CREATE_SEARCH_ALERT);
          }}
        />
      </div>
      <SearchAlertsTable
        data={{ items, total: totalItems }}
        offset={searchParams.offset}
        changeOffset={changeOffset}
        removeItemHandler={removeItemHandler}
        copyItemHandler={copyItemHandler}
        editItemHandler={editItemHandler}
        setSearchAlertToDelete={setSearchAlertToDelete}
      />
      <SweetAlert
        danger
        showCancel
        show={searchAlertToDelete !== null}
        title="Warning!"
        onConfirm={() => {
          removeItemHandler(searchAlertToDelete);
          setSearchAlertToDelete(null);
        }}
        onCancel={() => setSearchAlertToDelete(null)}
        confirmBtnBsStyle="info"
      >
        ​Are you sure you want to delete this alert? We will no longer notify you when this
        type of vehicle is added.
      </SweetAlert>
    </CommonMyOrganizationContainer>
  );
};
export default withRouter(SearchAlertsPage);
