import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'formik';
import { isEmpty } from 'lodash';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonSize, CommonButtonVariants } from '../../constants/CommonButtonVariants';
import {
  fetchAllVehicleFeatures,
  getDraft,
  saveVehicleFeaturesDraft,
  validateVehicleFeatures,
} from '../AddVehicleActions';
import FormPill from '../../common/components/formFields/FormPill';
import { FeatureGroups } from '../../constants/FeatureGroups';
import FormInputField from '../../common/components/formFields/FormInputField';
import { KEY_CODES } from '../../constants/KeyCodes';

const {
  PRIMARY,
  SECONDARY,
  DISABLED,
  CAR_FEATURE,
  CAR_FEATURE_SELECTED,
} = CommonButtonVariants;
const { CAP_FEATURES_GROUP } = FeatureGroups;

const AddFeaturesForm = ({ prevForm, nextForm, values, form }) => {
  const [ref, setRef] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const allVehicleFeatures = useSelector(state => state.addVehicle.get('allVehicleFeatures'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const vehicleFeaturesDictionaryIds = useSelector(state =>
    state.addVehicle.get('vehicleFeatures'),
  )?.vehicleFeaturesDictionaryIds;

  const [vehicleFeaturesDictionary, setVehicleFeaturesDictionary] = useState([]);
  const [vehicleFeaturesCustom, setVehicleFeaturesCustom] = useState(
    values?.vehicleFeatures?.vehicleFeaturesCustom.map(item => {
      return {
        value: item,
        selected: true,
      };
    }),
  );

  const isAddingCustomFeatureDisabled =
    !form?.values?.newCustomFeature.trim() ||
    vehicleFeaturesCustom.findIndex(
      item => item.value.toLowerCase() === form.values.newCustomFeature.toLowerCase(),
    ) !== -1;

  useEffect(() => {
    if (vehicleFeaturesDictionaryIds) {
      setVehicleFeaturesDictionary(vehicleFeaturesDictionaryIds);
    }
  }, [vehicleFeaturesDictionaryIds]);

  useEffect(() => {
    dispatch(fetchAllVehicleFeatures());
  }, [dispatch]);

  const { capFeatures } = values;

  const isFeatureOn = featureName => {
    return Object.keys(capFeatures).some(key => capFeatures[key] === featureName);
  };

  const handleAddCapFeature = item => {
    setVehicleFeaturesDictionary(prevState => {
      if (vehicleFeaturesDictionary.some(el => el.id === item.id)) {
        return prevState.filter(prevItem => prevItem.id !== item.id);
      }
      if (vehicleFeaturesDictionary.some(el => el === item.id)) {
        return prevState.filter(prevItem => prevItem !== item.id);
      }

      const featuresArray = [...prevState, item];
      form.setFieldValue('vehicleFeatures.vehicleFeaturesDictionary', featuresArray);
      return featuresArray;
    });
  };

  const moveToNextForm = () => {
    dispatch(
      validateVehicleFeatures(
        {
          vehicleFeaturesDictionary,
          vehicleFeaturesCustom:
            vehicleFeaturesCustom.length > 0 &&
            vehicleFeaturesCustom.filter(item => item.selected).map(item => item.value),
        },
        () => nextForm(),
      ),
    );
  };

  const isNextButtonDisabled =
    (!vehicleFeaturesDictionary?.length &&
      !vehicleFeaturesCustom?.filter(item => item.selected).length) ||
    isLoading ||
    !allVehicleFeatures?.length;

  const handleSaveDraft = () => {
    let preparedVehicleCapFeatures;
    let preparedVehicleFeaturesCustom;
    if (vehicleFeaturesDictionary?.length) {
      preparedVehicleCapFeatures = vehicleFeaturesDictionary.map(item => {
        if (typeof item === 'object') {
          return item.id;
        }
        return item;
      });
    } else {
      preparedVehicleCapFeatures = null;
    }

    if (vehicleFeaturesCustom?.length) {
      preparedVehicleFeaturesCustom = vehicleFeaturesCustom
        .filter(item => item.selected)
        .map(item => {
          return item.value;
        });
    } else {
      preparedVehicleCapFeatures = null;
    }

    const vehicleFeatures = {
      vehicleFeaturesDictionaryIds: preparedVehicleCapFeatures,
      vehicleFeaturesCustom: preparedVehicleFeaturesCustom,
    };
    dispatch(saveVehicleFeaturesDraft(vehicleFeatures, () => dispatch(getDraft(id))));
  };

  const handleCustomFeatureState = value => {
    setVehicleFeaturesCustom(prevState =>
      prevState.map(item => {
        if (item.value === value) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      }),
    );
  };

  const handleCustomFeatureAdd = () => {
    setVehicleFeaturesCustom(prevState => [
      ...prevState,
      { value: form.values.newCustomFeature, selected: true },
    ]);
    form.setFieldValue('newCustomFeature', '');
    ref.focus();
  };

  const handleEnterKeyAdd = e => {
    if (!isAddingCustomFeatureDisabled && e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault();
      handleCustomFeatureAdd();
    }
  };

  return (
    <Form autoComplete="off" className="text-center">
      <Container>
        <Row className="justify-content-center">
          <Col className="ml-auto mr-auto justify-content-center" lg="8" md="12" sm="12">
            {!isLoading && allVehicleFeatures.length > 0 && (
              <>
                <h6 className="basic-data__title">ADD VEHICLE</h6>
                <p className="featured-specification__label">Featured specification</p>
                <Row className="mb-4 justify-content-center">
                  <Col
                    className="ml-auto mr-auto d-flex flex-row flex-wrap justify-content-center add-features__buttons"
                    style={isEmpty(vehicleFeaturesCustom) ? { paddingBottom: '34px' } : null}
                  >
                    {allVehicleFeatures &&
                      allVehicleFeatures.map(item => {
                        return (
                          <FormPill
                            key={item.id}
                            feature={item.value}
                            isFeatureSelected={allVehicleFeatures.length ? isFeatureOn : null}
                            groupName={CAP_FEATURES_GROUP}
                            handleClick={() => handleAddCapFeature(item)}
                          />
                        );
                      })}{' '}
                    {vehicleFeaturesCustom &&
                      vehicleFeaturesCustom.map(({ value, selected }) => {
                        return (
                          <div key={value}>
                            <CommonButton
                              variant={selected ? CAR_FEATURE_SELECTED : CAR_FEATURE}
                              label={value}
                              handleClick={() => handleCustomFeatureState(value)}
                            />
                          </div>
                        );
                      })}
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col
                    className="ml-auto mr-auto justify-content-center"
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <p className="form-input-field-label--bold mb-0">Add specification</p>
                    <FormInputField
                      name="newCustomFeature"
                      withButton
                      buttonLabel="Add"
                      buttonSize={CommonButtonSize.SMALL}
                      buttonClassNames="add-feature__button"
                      buttonHandleClick={handleCustomFeatureAdd}
                      buttonDisabled={isAddingCustomFeatureDisabled}
                      setRef={setRef}
                      handleKeyDown={e => handleEnterKeyAdd(e)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col
                    className="d-flex flex-column align-items-center align-items-md-start"
                    sm="12"
                    lg="5"
                    md="5"
                  >
                    <CommonButton
                      className="basic-data__button"
                      label="back"
                      variant={SECONDARY}
                      handleClick={prevForm}
                    />
                  </Col>
                  <Col
                    className="d-flex flex-column align-items-center align-items-md-end"
                    sm="12"
                    lg="5"
                    md="5"
                  >
                    <CommonButton
                      className="basic-data__button"
                      label="next"
                      handleClick={moveToNextForm}
                      variant={isNextButtonDisabled ? DISABLED : PRIMARY}
                      disabled={isNextButtonDisabled}
                    />
                    <CommonButton
                      className="basic-data__button"
                      label="save"
                      variant={isLoading ? DISABLED : SECONDARY}
                      disabled={isLoading || !allVehicleFeatures?.length}
                      handleClick={handleSaveDraft}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

AddFeaturesForm.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default AddFeaturesForm;
