import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotificationTypeSection from './NotificationTypeSection';
import {
  fetchMemberNotification,
  updateMemberNotification,
} from '../myprofile/MyProfileActions';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';

import { NotificationConfig } from './NotificationConfig';
import {
  getSearchAlertNotifications,
  getSystemNotifications,
  mapToUpdatePayload,
} from './Utils';

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const [systemNotifications, setSystemNotification] = useState(
    new NotificationConfig(false, false),
  );
  const [searchAlertNotification, setSearchAlertNotification] = useState(
    new NotificationConfig(false, false),
  );

  const updateNotifications = (systemNotifications, searchAlertNotification) => {
    dispatch(
      updateMemberNotification(
        mapToUpdatePayload(systemNotifications, searchAlertNotification),
      ),
    )
      .then(config => setConfig(config))
      .catch(() => {
        dispatch(fetchMemberNotification()).then(config => setConfig(config));
      });
  };

  const setConfig = config => {
    if (!config) return;
    const { emailNotifications, pushNotifications } = config;
    setSystemNotification(
      new NotificationConfig(
        getSystemNotifications(emailNotifications),
        getSystemNotifications(pushNotifications),
      ),
    );
    setSearchAlertNotification(
      new NotificationConfig(
        getSearchAlertNotifications(emailNotifications),
        getSearchAlertNotifications(pushNotifications),
      ),
    );
  };

  useEffect(() => {
    dispatch(fetchMemberNotification()).then(config => setConfig(config));
  }, [dispatch]);

  return (
    <CommonMyOrganizationContainer>
      <h2 className="notifications-title text-center font-weight-bold text-uppercase">
        Notifications SETTINGS
      </h2>
      <NotificationTypeSection
        title="System notifications"
        config={systemNotifications}
        onChangeConfig={config => {
          setSystemNotification(config);
          updateNotifications(config, searchAlertNotification);
        }}
      />
      <NotificationTypeSection
        title="Search Alerts"
        config={searchAlertNotification}
        onChangeConfig={config => {
          setSearchAlertNotification(config);
          updateNotifications(systemNotifications, config);
        }}
      />
    </CommonMyOrganizationContainer>
  );
};
export default withRouter(NotificationsPage);
