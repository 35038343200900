import history from 'browserHistory.js';
import _, { get } from 'lodash';
import { DealerStatus } from 'itrade-admin-panel/src/constants/DealerStatus';
import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import {
  CLEAR_AUCTIONS_SUCCESS,
  CLEAR_BID_ERROR_SUCCESS,
  FETCH_AUCTION_BIDS_SUCCESS,
  MAKE_ID_SUCCESS,
  REFRESH_AUCTION_SUCCESS,
  REFRESH_QUERY_SUCCESS,
  SEARCH_AUCTIONS_SUCCESS,
  SEARCH_QUERY_SUCCESS,
} from './SearchReducer';
import RouterPaths from '../constants/RouterPaths';
import { prepareSearchData, SORT_ASC, SORT_DESC } from '../common/helpers/prepareSearchData';
import { AuctionSortType } from '../constants/AuctionSortType';
import { AUCTION_ENDPOINT } from '../mybids/MyBidsActions';
import { getAuction } from '../myvehicles/MyVehiclesActions';
import AlertVariant from '../common/components/alert/AlertVariant';

export const setSearchQuery = (query, refresh = false) => dispatch => {
  if (refresh) dispatch({ type: REFRESH_QUERY_SUCCESS, payload: true });
  dispatch({ type: SEARCH_QUERY_SUCCESS, payload: query });
  history.push(RouterPaths.SEARCH);
};

export const setMakeId = makeId => dispatch => {
  dispatch({ type: MAKE_ID_SUCCESS, payload: makeId });
};

export const setRefreshQuery = () => dispatch => {
  dispatch({ type: REFRESH_QUERY_SUCCESS, payload: true });
};

export const searchAuctions = (values, sort, callback, errCallback) => (
  dispatch,
  getState,
) => {
  const sortOptions =
    sort === AuctionSortType.ENDING_SOONEST ? { endsOn: SORT_ASC } : { launchedOn: SORT_DESC };

  if (values.offset === 0) {
    dispatch({
      type: CLEAR_AUCTIONS_SUCCESS,
    });
  }
  const prevVehicles = getState().search?.toJS()?.items || [];

  dispatch(showLoader());
  return apiClient
    .post('/auction/search', prepareSearchData(values, sortOptions))
    .then(res => {
      if (res.status === 200) {
        const items = get(res, 'data.data.content', []);
        items.map(item => {
          item.timeStamp = new Date();
          return item;
        });
        dispatch({
          type: SEARCH_AUCTIONS_SUCCESS,
          payload: {
            items,
            totalItems: get(res, 'data.data.totalElements', 0),
            offset: values?.offset,
            timeStamp: new Date(),
            hasMore: !get(res, 'data.data.last', true),
          },
        });
        const auctionsUniq = _.uniqBy([...prevVehicles, ...items], item => item.id);
        callback(auctionsUniq);
      }
    })
    .catch(err => {
      if (errCallback) {
        errCallback();
      }
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const clearAuctions = () => dispatch => {
  dispatch({
    type: CLEAR_AUCTIONS_SUCCESS,
  });
};

export const placeBid = (auctionId, amountInPence, callback = null) => (
  dispatch,
  getState,
) => {
  const userStatus = getState()?.auth?.toJS()?.status;

  dispatch(showLoader());

  return apiClient
    .post('/auction/bid', { auctionId, amountInPence })
    .then(res => {
      if (res.status === 200) {
        if (typeof callback === 'function') {
          callback({ success: true, result: get(res, 'data.data', null), auctionId });
        }
      }
    })
    .catch(err => {
      if (
        err?.response?.status === 418 &&
        userStatus === DealerStatus.BLOCKED_DUE_TO_PAYMENT
      ) {
        dispatch(handleError('Please enter a valid credit card number.'));
      } else {
        dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }

      if (typeof callback === 'function') {
        callback({
          success: false,
          result: err?.response?.data?.message,
          auctionId,
        });
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const refreshAuction = id => dispatch => {
  setTimeout(() => {
    dispatch(
      getAuction(
        id,
        item => {
          dispatch({
            type: REFRESH_AUCTION_SUCCESS,
            payload: {
              item,
            },
          });
        },
        true,
      ),
    );
  }, 1000);
};

export const fetchAuctionBids = (id = null) => dispatch => {
  dispatch(showLoader());
  if (id) {
    return apiClient
      .get(`${AUCTION_ENDPOINT}/${id}/bids`)
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: FETCH_AUCTION_BIDS_SUCCESS, payload: res?.data?.data });
        }
      })
      .catch(err => dispatch(handleError(err)))
      .finally(() => dispatch(hideLoader()));
  }
  return null;
};

export const clearBidErrorMessage = () => dispatch => {
  dispatch({
    type: CLEAR_BID_ERROR_SUCCESS,
  });
};
