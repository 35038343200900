import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import FormInputField from '../../common/components/formFields/FormInputField';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import FormCheckboxField from '../../common/components/formFields/FormCheckboxField';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { DealerPositionName } from '../../constants/DealerPosition';
import { fetchOrganizationLocations } from '../DashboardActions';
import { AUTH_TOKEN } from '../../auth/AuthConstants';

const dealerRole = constantToSelect(DealerPositionName);

const ProfileDetailsForm = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationLocations = useSelector(state =>
    state.myOrganization.get('organizationLocations'),
  );
  const [location, setLocation] = useState([]);
  const [isOwner, setIsOwner] = useState(true);

  useEffect(() => {
    dispatch(fetchOrganizationLocations());
    if (organizationLocations.length > 0) {
      const preparedOrganizationLocations = organizationLocations.map((item, index) => {
        return {
          label: `${item.city}, ${item.address} - ${
            item.isMain ? 'Main location' : `Additional location ${index + 1}`
          }`,
          value: item.id,
        };
      });
      setLocation(preparedOrganizationLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organizationLocations.length]);

  useEffect(() => {
    if (authToken) {
      const decoded = jwt.decode(authToken);
      const isOwner = decoded?.isOwner;
      setIsOwner(isOwner);
    }
  }, [authToken]);

  return (
    <Col md={12} className="justify-content-center mr-auto ml-auto">
      <Form autoComplete="off" className="text-left">
        <h2 className="text-center font-weight-bold text-uppercase">My profile</h2>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField name="firstName" label="First name" disabled />
          </Col>
          <Col md="6">
            <FormInputField name="lastName" label="Last name" disabled />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormSelectField
              name="position"
              label="Choose your position"
              options={dealerRole}
              disabled
            />
          </Col>
          <Col md="6">
            <FormSelectField
              name="location"
              label="Choose your location"
              options={location}
              disabled={isOwner}
            />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField name="phoneNumber" label="Phone number" disabled />
          </Col>
          <Col md="6">
            <FormInputField name="email" label="E-mail address" disabled />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField type="password" name="password" label="Password" />
          </Col>
          <Col md="6">
            <FormInputField type="password" name="passwordRepeat" label="Repeat password" />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormCheckboxField label="Sign me for Newsletter" name="acceptNewsletter" />
          </Col>
        </Row>
        <Row className="justify-content-between mt-3">
          <Col className="d-flex justify-content-center">
            <CommonButton
              type="submit"
              label="Save"
              variant={
                isLoading ? CommonButtonVariants.DISABLED : CommonButtonVariants.PRIMARY
              }
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export default ProfileDetailsForm;
