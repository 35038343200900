import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getPostcodes } from './PostcodeActions';
import { dataToSelect } from '../../../common/helpers/dataToSelect';
import FormAsyncSelectField from '../../../common/components/formFields/FormAsyncSelectField';
import { FormRegExp } from '../../../constants/FormRegExp';

const PostcodeSelectField = props => {
  const dispatch = useDispatch();

  const loadOptions = (inputValue, callback) => {
    if (
      (inputValue &&
        (inputValue.length < 2 || inputValue.length > 8) &&
        props?.loadRightAway) ||
      (!props?.loadRightAway && inputValue && inputValue.length > 8) ||
      (!isEmpty(inputValue.charAt(0)) &&
        !inputValue.charAt(0).match(FormRegExp.STRING_ONLY_LETTERS)) ||
      (!isEmpty(inputValue.charAt(6)) &&
        !inputValue.charAt(6).match(FormRegExp.STRING_ONLY_LETTERS))
    ) {
      callback(false);
      return false;
    }

    dispatch(
      getPostcodes(inputValue, postcodes => {
        callback(dataToSelect(postcodes.data, ['id', 'postcode']));
      }),
    );
    return true;
  };

  return (
    <FormAsyncSelectField
      {...props}
      loadOptions={loadOptions}
      loadRightAway={props?.loadRightAway}
      defaultOptions
    />
  );
};

PostcodeSelectField.defaultProps = {
  loadRightAway: true,
};

PostcodeSelectField.propTypes = {
  loadRightAway: PropTypes.bool,
};

export default PostcodeSelectField;
