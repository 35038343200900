import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field } from 'formik';

const FormGroupCheckboxFieldInner = props => {
  const { form, field, checkboxes, checkDependencies, isRoleGroupCheckbox } = props;
  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || [];
  return (
    <>
      {checkboxes.map(checkbox => (
        <FormGroup
          check
          disabled={checkbox.disabled || form.isSubmitting}
          key={checkbox.label}
        >
          <Label
            check
            className={checkbox.disabled ? 'form-group-checkbox-field--disabled' : ''}
          >
            <Input
              type="checkbox"
              disabled={checkbox.disabled || form.isSubmitting}
              value={value}
              checked={form.values[field.name].includes(checkbox.value)}
              onBlur={() => form.setFieldTouched(field.name, true)}
              onChange={({ target }) => {
                const values = form.values[field.name];
                if (target.checked) {
                  values.push(checkbox.value);
                } else {
                  const idx = values.indexOf(checkbox.value);
                  if (idx !== -1) {
                    values.splice(idx, 1);
                  }
                }
                if (isRoleGroupCheckbox) {
                  checkDependencies(values);
                }
                form.setFieldValue(field.name, [...values]);
              }}
            />
            {checkbox.label}
            <span className="form-check-sign">
              <span className="check" />
            </span>
          </Label>
        </FormGroup>
      ))}

      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </>
  );
};

const FormGroupCheckboxField = props => {
  return <Field {...props} component={FormGroupCheckboxFieldInner} />;
};

FormGroupCheckboxField.defaultProps = {
  disabled: false,
  checkDependencies: () => {},
  isRoleGroupCheckbox: false,
};

FormGroupCheckboxField.defaultProps = {
  checkboxes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  checkDependencies: PropTypes.func,
  isRoleGroupCheckbox: PropTypes.bool,
};

export default FormGroupCheckboxField;
