import React from 'react';
import googlePlayBadge from 'assets/img/google-play-badge.png';
import appStoreBadge from 'assets/img/app-store-badge.svg';

const downloadLinks = [
  {
    link: 'https://play.google.com/store/apps/details?id=com.sensilabs.itrade',
    badge: googlePlayBadge,
    alt: 'Google Play',
  },
  {
    link: 'https://apps.apple.com/us/app/itrade30/id1526466103',
    badge: appStoreBadge,
    alt: 'App Store',
  },
];

const CommonDownloadIcons = () => {
  return (
    <div className="footer-nav__download-app-wrapper">
      {downloadLinks.map(item => (
        <a key={item.link} href={item.link} className="download-badge">
          <img src={item.badge} alt={item.alt} />
        </a>
      ))}
    </div>
  );
};

export default CommonDownloadIcons;
