import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CommonSwitchButton from '../../common/components/CommonSwitchButton';
import creditCardsImg from '../../assets/img/credit-cards.png';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import {
  clearStripeValidationMessage,
  getStripeClientSecret,
  register,
} from '../RegisterActions';
import FormInputField from '../../common/components/formFields/FormInputField';
import cardholderIcon from '../../assets/img/cardholderIcon.svg';
import CardElementWrapper from '../../common/components/CardElementWrapper';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { DealerRole } from '../../constants/DealerRole';
import { StripeValidationErrorType } from '../../constants/StripeValidationError';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentDetailsFormInner = ({ values, nextStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [isOn, setSwitch] = useState(true);

  const isFranchisedDealer = values?.organizationRole === DealerRole.FRANCHISED_DEALER;
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const stripeValidationMessage = useSelector(state =>
    state.register.get('stripeValidationMessage'),
  );
  const [errors, setErrors] = useState({
    cardholderError: '',
  });

  const isSubmittingDisabled = isLoading || (isOn && !values?.cardholder);

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { cardholder } = values;
      const errors = {
        cardholderError: cardholder ? '' : 'Field is required',
      };
      setErrors(errors);

      const { cardholderError } = errors;
      if (cardholderError) {
        reject();
      }
      resolve();
    });

  const handleSubmit = async e => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try {
      if (isFranchisedDealer && isOn) {
        await validateForm();
      }
      dispatch(
        getStripeClientSecret(values, stripe, elements, stripeCustomerId =>
          dispatch(register(values, () => nextStep(), stripeCustomerId)),
        ),
      );
    } catch {
      console.error(StripeValidationErrorType.VALIDATION_ERROR);
    }
  };

  return (
    <Col md="6" className="justify-content-center m-auto">
      <h3 className="text-uppercase font-weight-bold text-center">Add payment details</h3>
      <Row className="justify-content-between ">
        <Col
          md="12"
          className={`d-flex mb-2 ${
            isFranchisedDealer
              ? 'justify-content-between align-items-center'
              : 'justify-content-end'
          }`}
        >
          {isFranchisedDealer && (
            <CommonSwitchButton
              isOn={isOn}
              setSwitch={setSwitch}
              onLabel="Skip"
              offLabel="Add"
              id="skip"
            />
          )}
          <img src={creditCardsImg} alt="Credit cards images" />
        </Col>
        <Col md="12">
          <CardElementWrapper elements={elements} disabled={!isOn} />
          <CommonErrorLabel value={stripeValidationMessage} />
        </Col>
        <Col md="12">
          <FormInputField
            name="cardholder"
            id="cardholder"
            placeholder="Cardholder's name"
            icon={cardholderIcon}
            disabled={!isOn}
            nolabel
          />
          {errors.cardholderError && <CommonErrorLabel value={errors.cardholderError} />}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <CommonButton
            type="button"
            label="Register"
            variant={
              isSubmittingDisabled
                ? CommonButtonVariants.DISABLED
                : CommonButtonVariants.PRIMARY
            }
            disabled={isSubmittingDisabled}
            handleClick={handleSubmit}
          />
        </Col>
      </Row>
      <Row className="justify-content-between register-price">
        <p>
          <strong>NO</strong>&nbsp;subscription payments&nbsp;will be taken for the first 3
          months while you enjoy your Free Trial. After your free trial a monthly subscription
          of just £10 a month will be debited.
        </p>
        <p>
          Payments for buying and selling will be debited on a pay as you go basis please see
          table below
        </p>
        <p>
          <strong>Sellers Fees:</strong>
        </p>
        <table className="price-table">
          <tbody>
            <tr>
              <td>
                <p className="price__cell-text">£0 - £5000</p>
              </td>
              <td>
                <p className="price__cell-text">
                  <strong>£35.00 + VAT</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="price__cell-text">£5,001 - £15,000</p>
              </td>
              <td>
                <p className="price__cell-text">
                  <strong>£50.00 + VAT</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="price__cell-text">£15,001 +</p>
              </td>
              <td>
                <p className="price__cell-text">
                  <strong>£75.00 + VAT</strong>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <p>
          <strong>Buyers Fees:</strong>
        </p>
        <table className="price-table">
          <tbody>
            <tr>
              <td>
                <p className="price__cell-text">£0 - £5000</p>
              </td>
              <td>
                <p className="price__cell-text">
                  <strong>£35.00 + VAT</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="price__cell-text">£5,001 - £15,000</p>
              </td>
              <td>
                <p className="price__cell-text">
                  <strong>£50.00 + VAT</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="price__cell-text">£15,001 +</p>
              </td>
              <td>
                <p className="price__cell-text">
                  <strong>£75.00 + VAT</strong>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
    </Col>
  );
};

const AddPaymentDetailsForm = ({ values, nextStep }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(clearStripeValidationMessage());
  }, [dispatch]);
  return (
    <Elements stripe={stripePromise}>
      <AddPaymentDetailsFormInner nextStep={nextStep} values={values} />
    </Elements>
  );
};

AddPaymentDetailsForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default withRouter(AddPaymentDetailsForm);
