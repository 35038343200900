import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('Field is required'),
    password: Yup.string().required('Field required'),
  });
};
