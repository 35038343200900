import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import _, { cloneDeep } from 'lodash';
import { reverse } from 'named-urls';
import InfinityLoader from '../common/components/InfinityLoader';
import { SSE_BUYER, SSEClient } from '../api/SSEClient';
import { clearMyBids, searchMyBids, setBidsFilter } from './MyBidsActions';
import MyBidsHeader from './MyBidsHeader';
import MyBidsSearch from './MyBidsSearch';
import SearchBidModal from '../search/bidModals/SearchBidModal';
import { placeBid } from '../search/SearchActions';
import MyBidsItem from './MyBidsItem';
import RouterPaths from '../constants/RouterPaths';
import { getAuction, handleAuctionEvent } from '../myvehicles/MyVehiclesActions';
import { MY_BIDS_REFRESH_SUCCESS } from './MyBidsReducer';

const sseClient = new SSEClient(SSE_BUYER);

const MyBidsPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const itemsRef = useRef({});
  const [scrollTo, setScrollTo] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [offset, setOffset] = useState(0);
  const [auctionsIds, setAuctionsIds] = useState([]);
  const [search, setSearch] = useState('');
  const [currentBidAuction, setCurrentBidAuction] = useState(null);
  const [showBidModal, setShowBidModal] = useState(false);
  const [bidsNotifications, setBidsNotifications] = useState({});

  const hasMore = useSelector(state => state.myBids.get('hasMore'));
  const bidsSearchFilter = useSelector(state => state.myBids.get('filter'));
  const searchQuery = useSelector(state => state.search.get('searchQuery'));
  const searchItems = useSelector(state => state.myBids.get('items').toJS());
  const auctionTimestamp = useSelector(state => state.myBids.get('timeStamp'));
  const searchTotalItems = useSelector(state => state.myBids.get('totalItems'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const [hasAccess, setHasAccess] = useState(false);

  const updateSearchItems = newItem => {
    dispatch({
      type: MY_BIDS_REFRESH_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  useEffect(() => {
    if (!isBuyer) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isBuyer, isSeller, history]);

  useEffect(() => {
    sseClient.listen(auctionsIds, response => {
      dispatch(
        handleAuctionEvent(response, () =>
          dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          ),
        ),
      );
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionsIds, dispatch]);

  const scrollToItem = () => {
    if (!scrollTo) return;
    if (itemsRef.current[scrollTo]) {
      itemsRef.current[scrollTo].scrollIntoView({ behavior: 'auto', block: 'center' });
      setScrollTo(null);
    }
  };

  useEffect(() => {
    if (location.state && location.state.offset && location.state.auctionId) {
      setOffset(_.get(location, 'state.offset', 0) + 1);
      setScrollTo(location.state.auctionId);
      history.replace({ ...history.location, state: {} });
      return;
    }
    dispatch(
      searchMyBids(
        {
          search,
          bidsSearchFilter,
          offset,
        },
        auctionsIds => {
          setAuctionsIds(auctionsIds);
          setLoaded(true);
          scrollToItem();
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, bidsSearchFilter, search, dispatch]);

  const loadData = () => {
    setOffset(prev => prev + 1);
  };

  const clearResults = () => {
    dispatch(clearMyBids());
    setOffset(0);
    setAuctionsIds([]);
    setLoaded(false);
  };

  const handleFilter = status => {
    clearResults();
    dispatch(setBidsFilter(status));
  };

  const handleSearch = values => {
    if (search !== values.search) {
      clearResults();
    }
    setSearch(values.search);
  };

  const handleBidModal = item => {
    setCurrentBidAuction(item);
    setShowBidModal(true);
  };

  const toggleBidModal = () => {
    setShowBidModal(false);
  };

  const addNotification = (id, message, className) => {
    const notifications = cloneDeep(bidsNotifications);
    notifications[id] = {
      className,
      message,
    };
    setBidsNotifications(notifications);
  };

  const handlePlaceBid = amount => {
    dispatch(
      placeBid(currentBidAuction?.id, amount * 100, response => {
        const { success, auctionId, result } = response;
        if (!success) {
          addNotification(auctionId, result, 'warning');
        } else {
          let message;

          const bidAmount = `£${amount.toLocaleString('en-GB')}`;

          if (result.isHighestBid) {
            message = `Your bid of ${bidAmount} is the highest`;
          } else {
            message = `Your bid of ${bidAmount} is not the highest - try again`;
          }
          addNotification(auctionId, message, 'warning');
        }
      }),
    );
  };

  const setRef = (ref, id) => {
    itemsRef.current[id] = ref;
  };

  return (
    <>
      {hasAccess && (
        <>
          <MyBidsHeader handlerChange={handleFilter} />
          <MyBidsSearch handleSearch={handleSearch} />
          {loaded && (
            <>
              {searchTotalItems > 0 ? (
                <InfiniteScroll
                  next={loadData}
                  hasMore={hasMore}
                  loader={<InfinityLoader />}
                  dataLength={searchItems.length}
                >
                  {searchItems.map(item => (
                    <MyBidsItem
                      getRef={el => setRef(el, item.id)}
                      item={item}
                      key={item.id}
                      handleBid={handleBidModal}
                      auctionTimestamp={auctionTimestamp}
                      onClickItem={() =>
                        history.push({
                          pathname: reverse(RouterPaths.MY_BIDS_DETAILS, { id: item.id }),
                          state: {
                            offset,
                          },
                        })
                      }
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <>
                  {searchQuery ? (
                    <p>Oops, we could not find any result for the given phrase</p>
                  ) : (
                    <p>There are no bids</p>
                  )}
                </>
              )}
            </>
          )}
          {showBidModal && (
            <SearchBidModal
              toggleShow={toggleBidModal}
              item={currentBidAuction}
              handlePlaceBid={handlePlaceBid}
            />
          )}
        </>
      )}
    </>
  );
};
export default MyBidsPage;
