import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import { Form, Formik } from 'formik';
import magnifierIcon from 'assets/img/magnifierIcon.svg';
import FormInputField from '../common/components/formFields/FormInputField';
import CommonButton from '../common/components/CommonButton';

const MyVehicleSearch = ({ handleSearch }) => {
  return (
    <Formik
      initialValues={{ search: '' }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleSearch(values);
      }}
    >
      <Form autoComplete="off" className="text-left">
        <Row className="justify-content-start d-flex align-items-center">
          <Col lg="4">
            <FormInputField
              name="search"
              placeholder="Find a vehicle"
              icon={magnifierIcon}
              iconClassName="search-icon"
            />
          </Col>
          <Col lg="3">
            <div className="my-vehicles-search-button-wrapper">
              <CommonButton label="Search" type="submit" />
            </div>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

MyVehicleSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default MyVehicleSearch;
