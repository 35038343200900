import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { closeAlert } from './AlertActions';
import AlertVariant from './AlertVariant';

const AlertContainer = ({
  open,
  text,
  variant = AlertVariant.PRIMARY,
  closeAlert,
  isMarkup,
  onClickAction,
}) => {
  const createMarkup = () => {
    return { __html: text };
  };

  const checkTypeOfText = () => {
    if (typeof text === 'object') {
      return text?.message;
    }
    return text;
  };

  return (
    <>
      {text && (
        <Alert
          isOpen={open}
          color={variant}
          className="alert-container"
          toggle={closeAlert}
          onClick={onClickAction}
        >
          {/* eslint-disable-next-line react/no-danger */}
          {isMarkup ? (
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={createMarkup()}
            />
          ) : (
            checkTypeOfText()
          )}
        </Alert>
      )}
    </>
  );
};

const mapStateToProps = function({ alert }) {
  return {
    text: alert.get('text'),
    variant: alert.get('variant'),
    open: alert.get('show'),
    isMarkup: alert.get('isMarkup'),
    onClickAction: alert.get('onClickAction'),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      closeAlert,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
