export const DamageTypes = {
  DENT: 'DENT',
  DENT_SMART_REPAIR: 'DENT_SMART_REPAIR',
  SCRATCH: 'SCRATCH',
  SCRATCH_SMART_REPAIR: 'SCRATCH_SMART_REPAIR',
  CHIP: 'CHIP',
  CHIPS: 'CHIPS',
  PAINT_OFF_COLOUR: 'PAINT_OFF_COLOUR',
  PAINT_PREVIOUS_REPAIR: 'PAINT_PREVIOUS_REPAIR',
  RUST: 'RUST',
  SCUFF: 'SCUFF',
  BROKEN_ELEMENT: 'BROKEN_ELEMENT',
  WHEEL_DAMAGE: 'WHEEL_DAMAGE',
  OTHER: 'OTHER',
};
export const DamageTypesName = {
  DENT: 'Dent',
  DENT_SMART_REPAIR: 'Dent (Smart Repair)',
  SCRATCH: 'Scratch',
  SCRATCH_SMART_REPAIR: 'Scratch (Smart Repair)',
  CHIP: 'Chip',
  CHIPS: 'Chips (Multiple)',
  PAINT_OFF_COLOUR: 'Paint (Off Colour)',
  PAINT_PREVIOUS_REPAIR: 'Paint (Previous Repair)',
  RUST: 'Rust',
  SCUFF: 'Scuff',
  BROKEN_ELEMENT: 'Broken',
  WHEEL_DAMAGE: 'Wheel Damage',
  OTHER: 'Other',
};
