export const AuctionItemMessage = {
  DRAFT: 'Parked',
  PUBLISHED: 'Live',
  CANCELLED: 'Cancelled',
  TO_ACCEPT_BUYER: "Waiting for the seller's acceptance",
  TO_ACCEPT_SELLER: 'Waiting for you to accept',
  WAITING: "Waiting for the buyer's acceptance",
  REJECTED: 'Declined Offer',
  SOLD: 'Sold',
  ENDED: 'Ended',
  WON: 'Won',
};
