export const ErrorCodes = {
  ECONNABORTED: 'ECONNABORTED',
  DEALER_ROLES_STALE: 'DEALER_ROLES_STALE',
  ORGANIZATION_BLOCKED: 'ORGANIZATION_BLOCKED',
  ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',
  BAD_CREDENTIALS: 'BAD_CREDENTIALS',
  INVALID_JWT_TOKEN: 'INVALID_JWT_TOKEN',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  MISSING_ORGANIZATION_IMMEDIATE_PAYMENT_METHOD:
    'MISSING_ORGANIZATION_IMMEDIATE_PAYMENT_METHOD',
  MISSING_ORGANIZATION_CARD_TOKEN: 'MISSING_ORGANIZATION_CARD_TOKEN',
};

export const ErrorMessages = {
  PAYMENT_FAILED: 'Payment failed. Add a valid card to be able to publish auctions.',
};
