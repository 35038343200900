import React from 'react';
import * as PropTypes from 'prop-types';
import bucketIcon from '../../assets/img/bucket.svg';
import elipsa from '../../assets/img/elipsa.svg';

const CommonDeleteButton = ({ handleClick, className, dataStep }) => {
  return (
    <div className={`common-delete-button__icon-wrapper ${className}`}>
      <button
        type="button"
        data-step={dataStep}
        className="common-delete"
        onClick={handleClick}
      >
        <img className="common-delete-button__bucket-icon" src={bucketIcon} alt="alt" />
        <img className="common-delete-button__background" src={elipsa} alt="alt" />
      </button>
    </div>
  );
};

CommonDeleteButton.defaultProps = {
  handleClick: null,
  className: '',
  dataStep: null,
};

CommonDeleteButton.propTypes = {
  handleClick: PropTypes.func,
  className: PropTypes.string,
  dataStep: PropTypes.number,
};

export default CommonDeleteButton;
