export const ServiceHistory = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
  NONE: 'NONE',
};

export const ServiceHistoryName = {
  FULL: 'Full service history',
  PARTIAL: 'Partial service history',
  NONE: 'No service history',
};
