import React from 'react';
import { Col } from 'reactstrap';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AuctionStatusTypeName } from 'constants/AuctionStatusType';
import AddDescriptionForm from '../forms/AddDescriptionForm';
import { validateDescriptionAndSettings } from '../AddVehicleActions';
import { AuctionPublishType } from '../../constants/AuctionPublishType';
import { prepareSelectLabel } from '../../common/helpers/prepareSelectLabel';
import validationSchema from '../forms/AddDescriptionForm.schema';
import { AuctionTime, AuctionTimeName } from '../../constants/AuctionTime';
import { prepareTryingToDealDeliveryTypeLabel } from '../../common/helpers/prepareTryingToDealDeliveryTypeLabel';

const AddDescriptionPage = ({ prevForm, generalValues }) => {
  const dispatch = useDispatch();
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );
  const mainLocation = useSelector(
    state => state.myOrganization.get('organizationDetails')?.mainLocation,
  );

  const organizationLocations = useSelector(state =>
    state.addVehicle.get('organizationLocations'),
  );

  const prepareTimeOptionSelect = () => {
    const {
      AUCTION_TIME_60,
      AUCTION_TIME_180,
      AUCTION_TIME_1440,
      AUCTION_TIME_2880,
      AUCTION_TIME_4320,
    } = AuctionTime;
    const {
      AUCTION_TIME_60: AUCTION_TIME_60_LABEL,
      AUCTION_TIME_180: AUCTION_TIME_180_LABEL,
      AUCTION_TIME_1440: AUCTION_TIME_1440_LABEL,
      AUCTION_TIME_2880: AUCTION_TIME_2880_LABEL,
      AUCTION_TIME_4320: AUCTION_TIME_4320_LABEL,
    } = AuctionTimeName;
    switch (descriptionAndSettings?.timeOption) {
      case AUCTION_TIME_60:
        return {
          label: AUCTION_TIME_60_LABEL,
          value: AUCTION_TIME_60,
        };
      case AUCTION_TIME_180:
        return {
          label: AUCTION_TIME_180_LABEL,
          value: AUCTION_TIME_180,
        };
      case AUCTION_TIME_1440:
        return {
          label: AUCTION_TIME_1440_LABEL,
          value: AUCTION_TIME_1440,
        };
      case AUCTION_TIME_2880:
        return {
          label: AUCTION_TIME_2880_LABEL,
          value: AUCTION_TIME_2880,
        };
      case AUCTION_TIME_4320:
        return {
          label: AUCTION_TIME_4320_LABEL,
          value: AUCTION_TIME_4320,
        };
      default:
        return null;
    }
  };

  const preparedOrganizationLocation =
    organizationLocations &&
    organizationLocations
      ?.map(item => {
        return {
          value: item?.id,
          label: item?.postalCode?.postcode,
        };
      })
      ?.find(item => item?.value === mainLocation?.id);

  const initialValues = {
    description: descriptionAndSettings ? descriptionAndSettings?.description : '',
    vehicleLocation: preparedOrganizationLocation,
    type: descriptionAndSettings?.type
      ? {
          label: AuctionStatusTypeName?.[descriptionAndSettings?.type]
            ? AuctionStatusTypeName?.[descriptionAndSettings?.type]
            : prepareSelectLabel(descriptionAndSettings?.type),
          value: descriptionAndSettings?.type,
        }
      : {
          label: '',
          value: '',
        },
    autoAcceptPriceInPence: descriptionAndSettings?.autoAcceptPriceInPence
      ? descriptionAndSettings?.autoAcceptPriceInPence / 100
      : '',
    minimumPriceInPence: descriptionAndSettings
      ? descriptionAndSettings?.minimumPriceInPence / 100
      : '',
    timeOption: descriptionAndSettings?.timeOption ? prepareTimeOptionSelect() : null,
    tryingToDealDeliveryDate: descriptionAndSettings?.tryingToDealDeliveryDate || new Date(),
    publishTarget: descriptionAndSettings?.publishTarget
      ? descriptionAndSettings?.publishTarget
      : AuctionPublishType.EVERYWHERE,
    tryingToDealDeliveryDateType: descriptionAndSettings?.tryingToDealDeliveryDateType
      ? {
          label: prepareTryingToDealDeliveryTypeLabel(
            descriptionAndSettings?.tryingToDealDeliveryDateType,
          ),
          value: descriptionAndSettings?.tryingToDealDeliveryDateType,
        }
      : null,
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(validateDescriptionAndSettings(values, setErrors));
    setSubmitting(false);
  };

  return (
    <Col md={8} className="justify-content-center mr-auto ml-auto">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        {form => (
          <AddDescriptionForm form={form} prevForm={prevForm} generalValues={generalValues} />
        )}
      </Formik>
    </Col>
  );
};

AddDescriptionPage.propTypes = {
  prevForm: PropTypes.func.isRequired,
  generalValues: PropTypes.object.isRequired,
};

export default AddDescriptionPage;
