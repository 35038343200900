import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { isEmpty } from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import uuid from 'react-uuid';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VehicleImagesSlider from './VehicleImagesSlider';
import tireIcon from '../../assets/img/tireIcon.png';
import leftArrowIcon from '../../assets/img/left-arrow.svg';
import { AuctionStatusType } from '../../constants/AuctionStatusType';
import { AuctionStatusFromSse } from '../../constants/AuctionStatus';
import RouterPaths from '../../constants/RouterPaths';
import AuctionDetailsSellerSection from './AuctionDetailsSellerSection';
import { auctionListType } from '../../constants/AuctionListType';
import VehicleImagesPopupSlider from './VehicleImagesPopupSlider';
import AuctionDetailsBuyerSection from './AuctionDetailsBuyerSection';
import { ServiceHistoryName } from '../../constants/ServiceHistory';
import magnifierServiceIcon from '../../assets/img/magnifierServiceIcon.png';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import { dateToString } from '../utils/date/Date.utils';
import { preparePriceFormat } from '../helpers/preparePriceFormat';
import { AUCTION_CANCELLED, BID_PLACED, SSE_BUYER, SSE_SELLER } from '../../api/SSEClient';
import { CommonString } from '../../constants/CommonString';
import { capitalizeString } from '../helpers/capitalizeString';
import { DueInDateTypeName } from '../../constants/DueInDateType';
import { sortedByPerspective } from '../../addvehicle/helpers/sortByPerspective';
import { setRelaunchAllow } from '../../myvehicles/MyVehiclesActions';
import { BasicCarDataFieldsName } from '../../constants/BasicCarDataFields';
import noImageImg from '../../assets/img/noImageDetail.svg';
import OrganizationRoleBox from '../../myvehicles/OrganizationRoleBox';
import { pencesToPounds, poundsToPences } from '../helpers/pencesToPounds';
import AuctionTypeLabel from '../../myvehicles/AuctionTypeLabel';
import AuctionTimeLabel from '../../myvehicles/AuctionTimeLabel';
import { getPriceColor } from '../helpers/auctionColorHelper';
import CurrentBidLabel from '../../myvehicles/CurrentBidLabel';

const {
  REGISTRATION_PLATE,
  MILEAGE,
  YEAR,
  MOT,
  CAR_TYPE,
  TRANSMISSION,
  ENGINE_SIZE,
  FUEL,
  COLOUR,
  DOORS,
  DATE_OFF_FIRST_REGISTRATION,
  PREVIOUS_KEEPERS_IN_TOTAL,
  VIN,
  LOCATION,
} = BasicCarDataFieldsName;

const AuctionDetailsItem = ({
  item,
  listType,
  refreshItem,
  sseClient,
  typeSseClient,
  nextStep,
  setIsRepublish,
  auctionTimestamp,
  location,
}) => {
  const organizationId = useSelector(state => state.auth.get('organizationId'));

  const [showPopupServiceHistorySlider, setShowPopupServiceHistorySlider] = useState(false);
  const [clickedPhoto, setClickedPhoto] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [shouldPriceBeGreen, setShouldPriceBeGreen] = useState(false);
  const [highestBid, setHighestBid] = useState(null);
  const [bidNotification, setBidNotification] = useState();
  const [bidsNotification, setBidsNotification] = useState();
  const [status, setStatus] = useState(false);
  const [isAuctionRelaunched, setIsAuctionRelaunched] = useState(false);
  const [windowFocus, setWindowFocus] = useState(false);
  const dispatch = useDispatch();
  const organizationRole = item?.seller?.organizationContact?.role;

  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    setHighestBid(pencesToPounds(item?.highestBidInPence));
    setShouldPriceBeGreen(shouldPriceBeGreen || item?.shouldPriceBeGreen);
    sseClient.listen([item.id], response => {
      setStatus(!!AuctionStatusFromSse[response.type]);
      if (response.type === BID_PLACED) {
        handleBidEvent(response);
      }
      if (response?.type === AUCTION_CANCELLED) {
        dispatch(setRelaunchAllow(true));
      }
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  const changeStatus = data => {
    setStatus(data);
  };

  const handleBidEvent = event => {
    setIsAuctionRelaunched(false);
    setShouldPriceBeGreen(shouldPriceBeGreen || event.shouldPriceBeGreen);
    setHighestBid(pencesToPounds(event.highestBidInPence));
    if (typeSseClient === SSE_SELLER) {
      if (!event.highestBids.length) {
        return false;
      }
      const highestAmountBid = event.highestBids.reduce((acc, current) => {
        if (current.amountInPence > acc.amountInPence) {
          return current;
        }
        return acc;
      });
      if (highestAmountBid) {
        const bidAmount = preparePriceFormat(highestAmountBid.amountInPence, true);
        setBidNotification(bidAmount);
      }
    }
    if (typeSseClient === SSE_BUYER) {
      setBidsNotification(event.highestBids);
    }
    return true;
  };

  useEffect(() => {
    if (!isEmpty(item?.vehicle?.vehicleMedia?.photos)) {
      const preparedPhotos = item?.vehicle?.vehicleMedia?.photos.map(item => {
        if (item?.fileUrl) {
          return {
            id: item?.mediaMetadataId,
            fileUrl: item?.fileUrl,
            perspective: item?.perspective,
            damages: item?.damages
              ? item.damages.map(damage => {
                  return {
                    id: damage?.mediaMetadataId,
                    fileUrl: damage?.fileUrl,
                    damageType: damage?.damageType,
                  };
                })
              : [],
          };
        }
        return null;
      });

      setPhotos(sortedByPerspective(preparedPhotos));
    }
    if (!isEmpty(item?.vehicle?.vehicleMedia?.videos)) {
      const preparedVideo = item?.vehicle?.vehicleMedia?.videos.map(item => {
        if (item.fileUrl) {
          return {
            fileUrl: item.fileUrl,
            id: item?.mediaMetadataId,
            previewId: item?.previewMediaMetadataId,
            previewUrl: item?.previewUrl,
            type: UploadFileTypes.VIDEO_TYPE,
          };
        }
        return null;
      });
      setVideos(preparedVideo);
    }
  }, [item]);

  const serviceHistoryThumbnailUrls =
    item?.vehicle?.serviceHistory?.photoRecords &&
    item?.vehicle?.serviceHistory?.photoRecords.map(item => item.fileUrl);

  const renderLocationValue = () => {
    if (item?.location?.city) {
      if (item?.location?.county) {
        return `${item?.location?.city}, ${item?.location?.county}`;
      }
      return `${item?.location?.city}`;
    }
    return CommonString.NOT_SPECIFIED;
  };

  const carInfo = [
    {
      title: REGISTRATION_PLATE,
      value: item?.vehicle?.registrationPlate || CommonString.NOT_SPECIFIED,
    },
    {
      title: MILEAGE,
      value: item?.vehicle?.mileage
        ? `${item?.vehicle?.mileage} miles`
        : CommonString.NOT_SPECIFIED,
    },
    {
      title: CAR_TYPE,
      value: capitalizeString(item?.vehicle?.carType) || CommonString.NOT_SPECIFIED,
    },
    {
      title: TRANSMISSION,
      value: capitalizeString(item?.vehicle?.transmission) || CommonString.NOT_SPECIFIED,
    },
    {
      title: COLOUR,
      value: capitalizeString(item?.vehicle?.colour) || CommonString.NOT_SPECIFIED,
    },
    {
      title: DOORS,
      value: item?.vehicle?.doors || CommonString.NOT_SPECIFIED,
    },
    {
      title: YEAR,
      value: item?.vehicle?.year || CommonString.NOT_SPECIFIED,
    },
    {
      title: ENGINE_SIZE,
      value: item?.vehicle?.engineSize || CommonString.NOT_SPECIFIED,
    },
    {
      title: FUEL,
      value: item?.vehicle?.fuel || CommonString.NOT_SPECIFIED,
    },
    {
      title: DATE_OFF_FIRST_REGISTRATION,
      value:
        dateToString(item?.vehicle?.dateOfFirstRegistration) || CommonString.NOT_SPECIFIED,
    },
    {
      title: PREVIOUS_KEEPERS_IN_TOTAL,
      value:
        item?.vehicle?.previousKeepersInTotal !== undefined &&
        item?.vehicle?.previousKeepersInTotal !== null
          ? item?.vehicle?.previousKeepersInTotal
          : CommonString.NOT_SPECIFIED,
    },
    {
      title: VIN,
      value: item?.vehicle?.vin
        ? item?.vehicle?.vin?.toUpperCase()
        : CommonString.NOT_SPECIFIED,
    },
    {
      title: MOT,
      value: dateToString(item?.vehicle?.mot) || CommonString.NOT_SPECIFIED,
    },
    {
      title: LOCATION,
      value: renderLocationValue() || CommonString.NOT_SPECIFIED,
    },
  ];

  const minimumPrice = pencesToPounds(item?.minimumPriceInPence);

  const tyreThreadDepths = [
    {
      name: 'NSF',
      value: item?.vehicle?.tireNearsideFrontMm,
    },
    {
      name: 'NSR',
      value: item?.vehicle?.tireNearsideRearMm,
    },
    {
      name: 'OSF',
      value: item?.vehicle?.tireOffsideFrontMm,
    },
    {
      name: 'OSR',
      value: item?.vehicle?.tireOffsideRearMm,
    },
  ];

  let backLinkUrl;
  let backLinkText;

  switch (listType) {
    case auctionListType.MY_VEHICLES:
      backLinkUrl = RouterPaths.MY_VEHICLES;
      backLinkText = 'Back to "My Vehicles"';
      break;
    case auctionListType.MY_BIDS:
      backLinkUrl = RouterPaths.MY_BIDS;
      backLinkText = 'Back to "My Bids"';
      break;
    case auctionListType.SEARCH:
    default:
      backLinkUrl = RouterPaths.SEARCH;
      backLinkText = 'Back to the search results';
      break;
  }

  const renderDetailsSection = () => {
    if (
      listType === auctionListType.MY_BIDS ||
      (listType === auctionListType.SEARCH &&
        item?.seller?.organizationContact?.id !== organizationId)
    ) {
      return (
        <AuctionDetailsBuyerSection
          highestBidInPence={poundsToPences(highestBid) || minimumPrice || 0}
          item={item}
          refreshItem={refreshItem}
          bids={bidsNotification}
          changeStatus={changeStatus}
          status={status}
        />
      );
    }
    return (
      <AuctionDetailsSellerSection
        item={item}
        refreshItem={refreshItem}
        bidNotification={bidNotification}
        changeStatus={changeStatus}
        status={status}
        isAuctionRelaunched={isAuctionRelaunched}
        setIsAuctionRelaunched={setIsAuctionRelaunched}
        nextStep={nextStep}
        setIsRepublish={setIsRepublish}
      />
    );
  };
  return (
    <Container>
      <Row className="my-vehicles-back-wrapper">
        <Link to={{ pathname: backLinkUrl, state: { ...location.state, auctionId: item.id } }}>
          <img src={leftArrowIcon} alt={backLinkText} className="my-vehicles-back-icon" />
          <span>{backLinkText}</span>
        </Link>
      </Row>
      <Row>
        {!isEmpty(photos || videos) ? (
          <>
            <OrganizationRoleBox role={organizationRole} listView={false} />
            <VehicleImagesSlider photos={photos} videos={videos} />
          </>
        ) : (
          <img className="summary__slide" src={noImageImg} alt="Vehicle" />
        )}
      </Row>

      <Row className="summary__main-description-container">
        <Col md={6}>
          <h2 className="font-weight-bold summary__auction-title">
            {(item?.vehicle?.vehicleModel &&
              `${item?.vehicle?.vehicleModel?.makeName} ${item?.vehicle?.vehicleModel?.name} ${item?.vehicle?.derivative}`) ||
              CommonString.VEHICLE_NOT_SPECIFIED}
          </h2>
        </Col>
        <Col md={6} className="text-right summary__action-time-and-price-wrapper">
          <AuctionTimeLabel
            auctionTimestamp={auctionTimestamp}
            item={item}
            listType={listType}
            organizationId={organizationId}
            windowFocus={windowFocus}
          />
          <p className="summary__auction-price-paragraph">
            <AuctionTypeLabel hasBid={!!highestBid} type={item.type} />
          </p>
          <p className="summary__auction-price-paragraph">
            <CurrentBidLabel hasBid={!!highestBid} type={item.type} />
            <span className="summary__auction-price" style={getPriceColor(shouldPriceBeGreen)}>
              {!!item.type && (
                <CurrencyFormat
                  value={highestBid || minimumPrice || 0}
                  thousandSeparator={true}
                  allowNegative={false}
                  prefix="£"
                  displayType="text"
                />
              )}
            </span>
          </p>
          {!!item.type &&
            item.type === AuctionStatusType.PHYSICAL &&
            !!item.autoAcceptPriceInPence && (
              <p className="summary__auction-auto-accept-price-paragraph">
                {`Reserve Price: `}
                <span className="summary__auction-price">
                  {!!item && (
                    <CurrencyFormat
                      value={pencesToPounds(item.autoAcceptPriceInPence)}
                      thousandSeparator={true}
                      allowNegative={false}
                      prefix="£"
                      displayType="text"
                    />
                  )}
                </span>
              </p>
            )}
        </Col>
        <Col className="summary__feature-list" md={12}>
          <ul className="d-flex flex-wrap">
            {carInfo &&
              carInfo.map(item => (
                <Col lg={2} md={3} sm={4} xs={12} key={item.title}>
                  <li className="summary__feature-list-item">
                    <div>
                      <span>{item.title}</span>
                      <b>{item.value}</b>
                    </div>
                  </li>
                </Col>
              ))}
          </ul>
        </Col>
      </Row>

      {item?.type === AuctionStatusType.TRYING_TO_DEAL && (
        <Row className="d-flex justify-content-center summary__auction-due-date-row">
          <span className="summary__auction-due-date font-weight-bold">
            {`Due in date: ${dateToString(item?.tryingToDealDeliveredOn) ||
              DueInDateTypeName[item?.tryingToDealDeliveredDateType]}`}
          </span>
        </Row>
      )}

      {renderDetailsSection()}

      {(!item?.vehicle?.vehicleFeaturesCustom ||
        item?.vehicle?.vehicleFeaturesCustom.length === 0) &&
      (!item?.vehicle?.vehicleFeaturesDictionary ||
        item?.vehicle?.vehicleFeaturesDictionary.length === 0) ? (
        <Row className="summary__car-features_not_specified">
          <h3 className="summary__section-title">Featured specification</h3>
          <p className="summary__description-paragraph">
            {CommonString.FEATURED_NOT_SPECIFIED}
          </p>
        </Row>
      ) : (
        <Row className="summary__car-features">
          {item?.vehicle?.vehicleFeaturesDictionary &&
            item?.vehicle?.vehicleFeaturesDictionary.map(feature => (
              <div className="summary__feature-pills" key={feature.id}>
                <span className="summary__feature-pills-name">{feature.value}</span>
              </div>
            ))}
          {item?.vehicle?.vehicleFeaturesCustom &&
            item?.vehicle?.vehicleFeaturesCustom.map(feature => (
              <div className="summary__feature-pills" key={uuid()}>
                <span className="summary__feature-pills-name">{feature}</span>
              </div>
            ))}
        </Row>
      )}

      <Row className="summary__description">
        <h3 className="summary__section-title">Description</h3>
        <p className="summary__description-paragraph">
          {item?.vehicle?.description || CommonString.DESCRIPTION_NOT_SPECIFIED}
        </p>
      </Row>
      <Row className="summary__services-and-tyres-wrapper">
        <Col md={6} className="summary__service-history-wrapper">
          <h3 className="summary__section-title mb-3">
            {ServiceHistoryName[item?.vehicle?.serviceHistory?.type] || 'Service history'}
          </h3>
          {!ServiceHistoryName[item?.vehicle?.serviceHistory?.type] && (
            <span>{CommonString.HISTORY_NOT_SPECIFIED}</span>
          )}
          <ul>
            {item?.vehicle?.serviceHistory?.manualRecords.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="summary__service-list-item">
                <p className="m-0">
                  <span className="font-weight-bold mr-1">{dateToString(item.date)}</span>
                  {item.serviceName}
                </p>
                <p className="m-0">
                  <span className="font-weight-bold mr-1">{item.mileage}</span>miles service
                  performed
                </p>
              </li>
            ))}
          </ul>
          <ul className="summary__service-history-list">
            {serviceHistoryThumbnailUrls.map((item, index) => {
              return (
                <li key={item} className="summary__service-history-list-item">
                  <button
                    type="button"
                    className="summary__service-history-list-item-photo"
                    onClick={() => {
                      setClickedPhoto(index);
                      setShowPopupServiceHistorySlider(true);
                    }}
                  >
                    <img
                      className="summary__service-history-list-item-photo"
                      src={item}
                      alt="Vehicle"
                    />
                    <img
                      src={magnifierServiceIcon}
                      alt="Magnifier icon"
                      className="summary__service-history-list-item-icon"
                    />
                  </button>
                </li>
              );
            })}
          </ul>
          <SweetAlert
            custom
            show={showPopupServiceHistorySlider}
            onCancel={() => {
              setShowPopupServiceHistorySlider(false);
            }}
            onConfirm={() => {}}
            title=""
            customButtons={
              <>
                <button
                  type="button"
                  className="photo_gallery_close_button"
                  onClick={() => setShowPopupServiceHistorySlider(false)}
                >
                  X
                </button>
              </>
            }
            customClass="photo_gallery_popup"
          >
            <VehicleImagesPopupSlider
              photos={serviceHistoryThumbnailUrls.map(item => ({
                id: item,
                fileUrl: item,
              }))}
              initial={clickedPhoto}
            />
          </SweetAlert>
        </Col>
        <Col md={6}>
          <h3 className="summary__section-title mb-3">Tyre tread depths</h3>
          <Row>
            {tyreThreadDepths.map(tyre => (
              <Col md={6} key={tyre.name} className="d-flex align-items-center mb-4">
                <img src={tireIcon} alt="Tire icon" />
                <p className="mb-0 ml-3">
                  {tyre.name}{' '}
                  <span className="font-weight-bold">
                    {tyre.value ? `${tyre.value}mm` : `--`}
                  </span>
                </p>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

AuctionDetailsItem.defaultProps = {
  nextStep: () => {},
  setIsRepublish: () => {},
};

AuctionDetailsItem.propTypes = {
  item: PropTypes.object.isRequired,
  listType: PropTypes.string.isRequired,
  refreshItem: PropTypes.func,
  typeSseClient: PropTypes.string.isRequired,
  nextStep: PropTypes.func,
  setIsRepublish: PropTypes.func,
};
AuctionDetailsItem.defaultProps = {
  refreshItem: () => {},
};

export default withRouter(AuctionDetailsItem);
