const SYSTEM_SETTINGS = 'SYSTEM';
const SEARCH_ALERT_SETTINGS = 'SEARCH_ALERTS';

export const getSystemNotifications = config => {
  if (!config) return false;
  const founded = config.find(item => item.setting === SYSTEM_SETTINGS);
  if (founded) {
    return founded.checked;
  }
  return false;
};

export const getSearchAlertNotifications = config => {
  if (!config) return false;
  const founded = config.find(item => item.setting === SEARCH_ALERT_SETTINGS);
  if (founded) {
    return founded.checked;
  }
  return false;
};

export const mapToUpdatePayload = (systemConfig, searchAlertConfig) => {
  const pushNotifications = [
    {
      settingsType: SYSTEM_SETTINGS,
      checked: systemConfig.pushEnabled,
    },
    {
      settingsType: SEARCH_ALERT_SETTINGS,
      checked: searchAlertConfig.pushEnabled,
    },
  ];
  const emailNotifications = [
    {
      settingsType: SYSTEM_SETTINGS,
      checked: systemConfig.emailEnabled,
    },
    {
      settingsType: SEARCH_ALERT_SETTINGS,
      checked: searchAlertConfig.emailEnabled,
    },
  ];
  return { pushNotifications, emailNotifications };
};
