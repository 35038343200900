import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import history from 'browserHistory';
import { fetchMyProfileDetails } from '../dashboard/myprofile/MyProfileActions';
import NotFound from '../notfound/NotFound';
import { getSiteByLink, sendQuestions } from './CustomSiteActions';
import CustomSiteContactForm from './CustomSiteContactForm';
import validationSchema from './CustomSiteContactForm.schema';

const CONTACT_URL = 'contact-us';

const CustomSite = () => {
  const [content, setContent] = useState('');
  const [showNotFound, setShowNotFound] = useState(false);
  const dispatch = useDispatch();
  const link = history.location.pathname.substring(1);
  const email = useSelector(state => state.myProfile.get('myProfileDetails').email);
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));

  useEffect(() => {
    if (link === CONTACT_URL && isAuthenticated) {
      dispatch(fetchMyProfileDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, link]);

  const initialValues = {
    email: email || '',
    message: '',
  };

  const notFound = () => {
    setShowNotFound(true);
  };

  const handleContact = (values, { setSubmitting }) => {
    dispatch(sendQuestions(values)).finally(setSubmitting(false));
  };

  useEffect(() => {
    setShowNotFound(false);
    setContent('');
    dispatch(getSiteByLink(link, setContent, notFound));
  }, [dispatch, link]);

  return (
    <>
      {showNotFound && <NotFound />}
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      {link === CONTACT_URL && (
        <Col md={8} className="justify-content-center mr-auto ml-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleContact}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
          >
            {({ values }) => <CustomSiteContactForm values={values} />}
          </Formik>
        </Col>
      )}
    </>
  );
};
export default withRouter(CustomSite);
