import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import RegisterImg from 'assets/img/HomePage/register.png';
import BrowseImg from 'assets/img/HomePage/browse.png';
import BuyImg from 'assets/img/HomePage/buy.png';

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <Container>
        <h4 className="how-it-works-title">How it works?</h4>
        <Row>
          <Col>
            <img src={RegisterImg} alt="Register" className="img-fluid mx-auto d-block" />
            <p className="how-it-works-subtitle">
              <span className="how-it-works-subtitle-number">1.</span>Register
            </p>
          </Col>
          <Col>
            <img src={BrowseImg} alt="Browse cars" className="img-fluid mx-auto d-block" />
            <p className="how-it-works-subtitle">
              <span className="how-it-works-subtitle-number">2.</span>Browse cars
            </p>
          </Col>
          <Col>
            <img src={BuyImg} alt="Buy a car" className="img-fluid mx-auto d-block" />
            <p className="how-it-works-subtitle">
              <span className="how-it-works-subtitle-number">3.</span>Buy a car
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowItWorks;
