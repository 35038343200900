import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPhotoDescription } from '../../common/helpers/getPhotoDescription';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import addMorePicsIcon from '../../assets/img/add_more_pics.svg';
import CommonButton from '../../common/components/CommonButton';
import AddPhotoDrag from '../drag/AddPhotoDrag';
import {
  clearDamagesWithNoDamageType,
  getDraft,
  saveAddPhotoDraft,
  setFormStep,
  setPhotoStep,
} from '../AddVehicleActions';
import AddDamageDrag from '../drag/AddDamageDrag';
import AddDamageDragPreview from '../drag/AddDamageDragPreview';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';

const AddPhotoPage = ({ values, prevForm, nextForm }) => {
  const [displayAdditional, setDisplayAdditional] = useState(false);
  const { SECONDARY, PRIMARY, DISABLED } = CommonButtonVariants;
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const photos = useSelector(state => state.addVehicle.get('photos'));
  const uploadPhotoError = useSelector(state => state.addVehicle.get('uploadPhotoError'));
  const index = currentStep - 1;
  const isAuctionEditing = useSelector(state => state.addVehicle.get('isAuctionEditing'));

  const damages =
    values?.vehicleMedia?.photos && values?.vehicleMedia?.photos[index]?.damages
      ? values?.vehicleMedia?.photos[index]?.damages
      : [];
  const isPhotoLoaded = values.vehicleMedia?.photos[index]?.fileUrl;

  const prevStep = () => {
    dispatch(clearDamagesWithNoDamageType(photos));
    if (currentStep > 1) {
      const updateStep = currentStep - 1;
      dispatch(setPhotoStep(updateStep));
    }
  };

  const nextStep = () => {
    const updateStep = currentStep + 1;
    dispatch(setPhotoStep(updateStep));
  };

  const handleAdditionalPhoto = () => {
    dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id))));
    return nextStep();
  };

  const handleNextButton = () => {
    dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id))));
    if (currentStep < REQUIRED_PHOTOS_AMOUNT) {
      return nextStep();
    }

    return nextForm();
  };

  const handleSave = () => {
    dispatch(clearDamagesWithNoDamageType(photos));
    dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id))));
  };

  const handleBack = () => {
    if (currentStep === 1) {
      prevForm();
    } else {
      prevStep();
    }
  };

  const handleGoToSummary = () => {
    dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id)))).then(() =>
      dispatch(setFormStep(5)),
    );
  };

  useEffect(() => {
    if (currentStep >= REQUIRED_PHOTOS_AMOUNT) {
      setDisplayAdditional(true);
    } else {
      setDisplayAdditional(false);
    }
  }, [currentStep, photos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const isDisabled = !(
    (currentStep <= photos.length && photos[index]?.data) ||
    (photos[index]?.fileUrl && (!damages || !damages.some(dmg => !dmg.damageType)))
  );

  const doneIsDisabled = !(
    !values?.vehicleMedia?.photos[index]?.fileUrl ||
    (photos[index]?.fileUrl && (!damages || !damages.some(dmg => !dmg.damageType)))
  );

  return (
    <>
      <Container className="d-flex flex-column justify-content-center text-center">
        <Row className=" d-flex justify-content-center">
          <Col lg="6" md="8" sm="12">
            <Row>
              <Col>
                <h6>ADD VEHICLE</h6>
                <p>{getPhotoDescription(currentStep).DESCRIPTION}</p>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <AddPhotoDrag step={currentStep} values={values} nextForm={nextForm} />
                {uploadPhotoError && (
                  <label className="error-label">Upload only image/video format.</label>
                )}
              </Col>
            </Row>
            {currentStep > 1 && isPhotoLoaded && <p className="damages__title">Add damage</p>}
            {damages.length > 0 && <AddDamageDragPreview values={values} />}
            {currentStep > 1 && isPhotoLoaded && damages.length < 5 && (
              <Row>
                <Col>
                  <AddDamageDrag />
                </Col>
              </Row>
            )}
            <Row>
              <Col
                className="d-flex flex-column align-items-md-start align-items-sm-center"
                sm="12"
                md="6"
                lg="6"
              >
                {(!isAuctionEditing || (isAuctionEditing && currentStep > 1)) && (
                  <CommonButton
                    className="basic-data__button"
                    label="back"
                    variant={SECONDARY}
                    handleClick={handleBack}
                  />
                )}

                {displayAdditional ? (
                  <CommonButton
                    className="basic-data__button"
                    label="more photo"
                    handleClick={handleAdditionalPhoto}
                    icon={addMorePicsIcon}
                    iconAlt="Add more pictures"
                    variant={isLoading || isDisabled ? DISABLED : PRIMARY}
                    disabled={isLoading || isDisabled}
                  />
                ) : (
                  <CommonButton
                    className="basic-data__button"
                    label="summary"
                    variant={doneIsDisabled ? DISABLED : PRIMARY}
                    disabled={isLoading || doneIsDisabled}
                    handleClick={handleGoToSummary}
                  />
                )}
              </Col>
              <Col
                className="d-flex flex-column align-items-md-end align-items-sm-center"
                sm="12"
                md="6"
                lg="6"
              >
                {currentStep > REQUIRED_PHOTOS_AMOUNT ? (
                  <CommonButton
                    className="basic-data__button"
                    label="finished"
                    variant={doneIsDisabled ? DISABLED : PRIMARY}
                    disabled={isLoading || doneIsDisabled}
                    handleClick={handleNextButton}
                  />
                ) : (
                  <CommonButton
                    className="basic-data__button"
                    label={currentStep === REQUIRED_PHOTOS_AMOUNT ? 'finished' : 'NEXT'}
                    variant={isLoading || isDisabled ? DISABLED : PRIMARY}
                    disabled={isLoading || isDisabled}
                    handleClick={handleNextButton}
                  />
                )}

                {currentStep <= REQUIRED_PHOTOS_AMOUNT && (
                  <CommonButton
                    className="basic-data__button"
                    label="save"
                    variant={isLoading ? DISABLED : SECONDARY}
                    disabled={isLoading}
                    handleClick={handleSave}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

AddPhotoPage.propTypes = {
  values: PropTypes.object.isRequired,
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddPhotoPage;
