import { fromJS } from 'immutable';
import { BID_PLACED } from '../api/SSEClient';

const initialData = fromJS({
  items: [],
  totalItems: 0,
  timeStamp: null,
  filter: '',
  hasMore: true,
  isAuctionAvailable: false,
});

export const MY_VEHICLES_SEARCH_SUCCESS = 'MY_VEHICLES_SEARCH_SUCCESS';
export const MY_VEHICLES_CLEAR_SUCCESS = 'MY_VEHICLES_CLEAR_SUCCESS';
export const AUCTION_EVENT_SUCCESS = 'AUCTION_EVENT_SUCCESS';
export const SET_VEHICLES_FILTER = 'SET_VEHICLES_FILTER';
export const MY_VEHICLES_REFRESH_SUCCESS = 'MY_VEHICLES_REFRESH_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case MY_VEHICLES_SEARCH_SUCCESS:
      return state.merge({
        totalItems: payload.totalItems,
        items: fromJS(payload.items),
        timeStamp: payload.timeStamp,
        hasMore: payload.hasMore,
      });
    case MY_VEHICLES_CLEAR_SUCCESS:
      return state.merge(
        fromJS({
          items: [],
          totalItems: 0,
          hasMore: true,
        }),
      );
    case AUCTION_EVENT_SUCCESS:
      {
        const { auctionId, highestBids, type } = payload;

        const index = state
          .get('items')
          .toJS()
          .findIndex(item => item.id === auctionId);
        if (index !== -1) {
          const auction = state
            .get('items')
            .get(index)
            .toJS();
          if (type === BID_PLACED) {
            auction.bids = highestBids;
          }

          return state.merge({
            items: state.get('items').update(index, () => fromJS(auction)),
          });
        }
      }
      return state;
    case MY_VEHICLES_REFRESH_SUCCESS: {
      const index = state
        .get('items')
        .toJS()
        .findIndex(item => item.id === payload.item.id);
      if (index !== -1) {
        const itemData = payload.item;
        itemData.timeStamp = new Date();
        itemData.organizationId = itemData.organizationId
          ? itemData.organizationId
          : itemData?.seller?.organizationContact?.id;
        return state.merge({
          items: state.get('items').update(index, () => itemData),
        });
      }
      return state;
    }
    case SET_VEHICLES_FILTER:
      return state.merge({
        filter: payload,
      });
    default:
      return state;
  }
};
