import React, { Fragment, useState } from 'react';
import { Field, FieldArray, Form, getIn } from 'formik';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormInputField from '../../../common/components/formFields/FormInputField';
import PostcodeSelectField from '../../../register/forms/postcode/PostcodeSelectField';
import CommonButton from '../../../common/components/CommonButton';
import { saveOrganizationDetails } from '../../DashboardActions';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { DealerLocationStatusTypes } from '../../../constants/DealerLocationStatusTypes';

const { PRIMARY, DISABLED } = CommonButtonVariants;

const InformationsForm = ({ values, setSubmitting, isValid }) => {
  const dispatch = useDispatch();
  const organizationDetails = useSelector(state =>
    state.myOrganization.get('organizationDetails'),
  );
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const prevAdditionalLocation = organizationDetails?.additionalLocation;
  const [locationIdsToDelete, setLocationIdsToDelete] = useState([]);
  const [idDelLocation, setIdDelLocation] = useState(null);
  const handleDeleteAdditionalLocation = (location, arrayHelpers, index) => {
    if (location && values) {
      setLocationIdsToDelete(prevState => [...prevState, location?.id]);
      arrayHelpers.remove(index);
    }
  };

  const handleSaveOrganizationDetails = async () => {
    if (isValid) {
      setSubmitting(true);
      await dispatch(
        saveOrganizationDetails(values, prevAdditionalLocation, locationIdsToDelete),
      );
      setSubmitting(false);
    }
  };

  const isAdditionalLocationDisabled = location => {
    return !!(!isOwner || location?.id);
  };

  return (
    <Col>
      <Form autoComplete="off" className="informations-form">
        <Row className="informations-form-row">
          <Col md={6}>
            <FormInputField
              name="companyRegistrationNumber"
              label="Company registration number"
              disabled
            />
          </Col>
          <Col md={6}>
            <FormInputField name="vatNumber" label="VAT Number" disabled />
          </Col>
        </Row>
        <Row
          className={`${values?.mainLocationIsPending &&
            'informations-form__pending-field'} informations-form-row mt-2`}
        >
          <Col md={12}>
            <p className="form-input-field-label--bold">Main location</p>
          </Col>
          <Col md={6}>
            <PostcodeSelectField
              name="postalCodeId"
              label="Postal code"
              loadRightAway={false}
              disabled={!isOwner}
            />
          </Col>
          <Col md={6}>
            <FormInputField name="city" label="City" disabled={!isOwner} />
          </Col>
          <Col md={6}>
            <FormInputField name="address" label="Address" disabled={!isOwner} />
          </Col>
          <Col md={6}>
            <FormInputField name="address2" label="Address (line 2)" disabled={!isOwner} />
          </Col>
          <Col md={6}>
            <FormInputField name="county" label="County" disabled={!isOwner} />
          </Col>
          {values?.mainLocation && (
            <button type="button">
              <i className="far fa-trash-alt informations-form__trash-icon" />
            </button>
          )}
        </Row>
        <Row>
          <Col md={12}>
            <FieldArray
              name="additionalLocation"
              render={arrayHelpers => (
                <Row className="d-flex flex-column justify-content-center mt-2">
                  <div>
                    {values?.additionalLocation.map((location, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                          <Col
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={`mb-3 ${location.status &&
                              location.status === DealerLocationStatusTypes.PENDING &&
                              'informations-form__pending-field'}`}
                          >
                            {isOwner && (
                              <button
                                type="button"
                                className="information-form_trash-button"
                                onClick={() => setIdDelLocation(index)}
                                disabled={!isOwner}
                              >
                                {null}
                              </button>
                            )}
                            <Col md={12} className="pl-2">
                              <p className="form-input-field-label--bold">
                                Additional location
                              </p>
                            </Col>
                            <Row className="informations-form-row">
                              <Col md={6}>
                                <Field name={`additionalLocation.${index}.postalCode`}>
                                  {({ form }) => {
                                    const error = getIn(
                                      form.errors,
                                      `additionalLocation.${index}.postalCode`,
                                    );
                                    const touch = getIn(
                                      form.touched,
                                      `additionalLocation.${index}.postalCode`,
                                    );
                                    return (
                                      <div>
                                        <PostcodeSelectField
                                          name={`additionalLocation.${index}.postalCode`}
                                          loadRightAway={false}
                                          label="Postal Code"
                                          disabled={isAdditionalLocationDisabled(location)}
                                          invalid={!!touch && !!error}
                                        />
                                        {touch && error ? (
                                          <label className="error-label">{error}</label>
                                        ) : null}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col md={6}>
                                <Field name={`additionalLocation.${index}.city`}>
                                  {({ form }) => {
                                    const error = getIn(
                                      form.errors,
                                      `additionalLocation.${index}.city`,
                                    );
                                    const touch = getIn(
                                      form.touched,
                                      `additionalLocation.${index}.city`,
                                    );
                                    return (
                                      <div>
                                        <FormInputField
                                          name={`additionalLocation.${index}.city`}
                                          invalid={!!touch && !!error}
                                          label="City"
                                          disabled={isAdditionalLocationDisabled(location)}
                                        />
                                        {touch && error ? (
                                          <label className="error-label">{error}</label>
                                        ) : null}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row className="informations-form-row">
                              <Col md={6}>
                                <Field name={`additionalLocation.${index}.address`}>
                                  {({ form }) => {
                                    const error = getIn(
                                      form.errors,
                                      `additionalLocation.${index}.address`,
                                    );
                                    const touch = getIn(
                                      form.touched,
                                      `additionalLocation.${index}.address`,
                                    );
                                    return (
                                      <div>
                                        <FormInputField
                                          name={`additionalLocation.${index}.address`}
                                          invalid={!!touch && !!error}
                                          label="Address"
                                          disabled={isAdditionalLocationDisabled(location)}
                                        />
                                        {touch && error ? (
                                          <label className="error-label">{error}</label>
                                        ) : null}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col md={6}>
                                <Field name={`additionalLocation.${index}.address2`}>
                                  {({ form }) => {
                                    const error = getIn(
                                      form.errors,
                                      `additionalLocation.${index}.address2`,
                                    );
                                    const touch = getIn(
                                      form.touched,
                                      `additionalLocation.${index}.address2`,
                                    );
                                    return (
                                      <div>
                                        <FormInputField
                                          name={`additionalLocation.${index}.address2`}
                                          invalid={!!touch && !!error}
                                          label="Address (line 2)"
                                          disabled={isAdditionalLocationDisabled(location)}
                                        />
                                        {touch && error ? (
                                          <label className="error-label">{error}</label>
                                        ) : null}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row className="informations-form-row">
                              <Col md={6}>
                                <Field name={`additionalLocation.${index}.county`}>
                                  {({ form }) => {
                                    const error = getIn(
                                      form.errors,
                                      `additionalLocation.${index}.county`,
                                    );
                                    const touch = getIn(
                                      form.touched,
                                      `additionalLocation.${index}.county`,
                                    );
                                    return (
                                      <div>
                                        <FormInputField
                                          name={`additionalLocation.${index}.county`}
                                          invalid={!!touch && !!error}
                                          label="County"
                                          disabled={isAdditionalLocationDisabled(location)}
                                        />
                                        {touch && error ? (
                                          <label className="error-label">{error}</label>
                                        ) : null}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                          </Col>
                          <SweetAlert
                            danger
                            showCancel
                            show={index === idDelLocation}
                            onConfirm={() => {
                              handleDeleteAdditionalLocation(location, arrayHelpers, index);
                              setIdDelLocation(null);
                            }}
                            onCancel={() => setIdDelLocation(null)}
                            confirmBtnBsStyle="info"
                            cancelBtnCssClass="cancel-button"
                            cancelBtnBsStyle="default"
                            title=""
                          >
                            <span className="sweet-alert-text">
                              Are you sure you want to remove this location?
                            </span>
                          </SweetAlert>
                        </Fragment>
                      );
                    })}
                  </div>
                  <Row className="d-flex justify-content-center">
                    <CommonButton
                      type="button"
                      label="+ Additional location"
                      size="large"
                      className="m-3"
                      handleClick={() =>
                        arrayHelpers.push({
                          postalCode: '',
                          address: '',
                          city: '',
                          county: '',
                        })
                      }
                      disabled={!isOwner}
                      variant={!isOwner ? DISABLED : PRIMARY}
                    />
                  </Row>
                </Row>
              )}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between informations-phone-numbers-wrapper">
          <Col
            md={6}
            className={`${values?.landlinePhoneNumberIsPending &&
              'informations-form__pending-field'} informations-form-row--phone`}
          >
            <FormInputField
              name="landlinePhoneNumber"
              label="Phone number"
              disabled={!isOwner}
            />
          </Col>
          <Col
            md={6}
            className={`d-flex flex-column justify-content-center ${
              values?.landlinePhoneNumberIsPending ? 'mt-2' : ''
            } `}
          >
            <FormInputField name="website" label="Website address" disabled={!isOwner} />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-5">
          <CommonButton
            label="Save"
            type="submit"
            handleClick={handleSaveOrganizationDetails}
            variant={
              isLoading || !isOwner
                ? CommonButtonVariants.DISABLED
                : CommonButtonVariants.PRIMARY
            }
            disabled={isLoading || !isOwner}
          />
        </Row>
      </Form>
    </Col>
  );
};

InformationsForm.propTypes = {
  values: PropTypes.object.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default InformationsForm;
