import { fromJS } from 'immutable';

const initialData = fromJS({
  isAuthenticated: false,
  isAdmin: false,
  isBuyer: false,
  isOwner: false,
  isSeller: false,
  organizationId: null,
  status: null,
});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export default (state = initialData, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.merge({
        isAuthenticated: true,
        isAdmin: action.payload.roles === 'SUPER_ADMIN',
        isBuyer: action.payload.isBuyer,
        isOwner: action.payload.isOwner,
        isSeller: action.payload.isSeller,
        organizationId: action.payload.organizationId,
        id: action.payload.id,
        status: action.payload.status,
      });
    case LOGOUT:
    case LOGIN_FAILURE:
      return state.merge(initialData);
    default:
      return state;
  }
};
