import moment from 'moment';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mmA';
export const REVERSE_DASH_DATE_FORMAT = 'YYYY-MM-DD';

export function dateToString(date) {
  return date ? moment(date).format(DATE_FORMAT) : '';
}

export function dateTimeToString(date) {
  return date ? moment(date).format(DATE_TIME_FORMAT) : '';
}

export function reverseDateToString(date) {
  return date ? moment(date).format(REVERSE_DASH_DATE_FORMAT) : '';
}

export function isDateValid(dateTime) {
  return moment(dateTime, DATE_FORMAT, true).isValid();
}

export function isDateBefore(firstDate, secondDate, guaranty = null) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);

  return moment(newFirstDate).isBefore(newSecondDate, guaranty);
}

export function isDateAfter(firstDate, secondDate) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);

  return moment(newFirstDate).isAfter(newSecondDate);
}

export const timerFromSeconds = seconds => {
  return `${showDays(seconds)}${moment.utc(seconds * 1000).format(timeFormat(seconds))}`;
};

const showDays = seconds => {
  if (seconds > 86400) {
    return `${Math.floor(seconds / 86400)}d `;
  }
  return '';
};
const timeFormat = seconds => {
  if (seconds < 3600) {
    return 'mm:ss';
  }
  if (seconds < 86400) {
    return 'H:mm:ss';
  }
  return 'H:mm';
};
