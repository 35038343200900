import React from 'react';
import StripeDirectDebitWrapper from './StripeDirectDebitWrapper';

const StripeDirectDebitCancel = () => {
  return (
    <StripeDirectDebitWrapper
      title="Your payment method was declined"
      text="Please enter valid method information."
    />
  );
};

export default StripeDirectDebitCancel;
