import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import vehicleIcon from '../../assets/img/vehicleIcon.svg';
import { UserStatus } from '../../constants/UserStatus';

const CarCircleProgressChart = ({ data, title, total, handleClick }) => {
  const dealerStatus = useSelector(state => state.auth.get('status'));

  return (
    <div className="informations-box--large__circle-progress-wrapper">
      <svg className="circle-progress">
        {data.map(item => (
          <circle
            key={item.type}
            cx={105}
            cy={105}
            r={100}
            strokeDasharray={`${item.value * 6.25} ${625 - item.value * 6.25}`}
            className="circle-progress__circle"
            style={{ '--offset': `${item.offset}`, stroke: `var(${item.color})` }}
          />
        ))}
      </svg>
      <button
        type="button"
        onClick={() => handleClick()}
        className={
          dealerStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT ? 'button--disabled' : ''
        }
      >
        <div className="informations-box--large__stats-wrapper">
          <div className="informations-box--large__icon-wrapper">
            <img src={vehicleIcon} alt="Car icon" className="informations-box--large__icon" />
          </div>
          <span className="informations-box--large__counter stats-counter" data-target={total}>
            0
          </span>
          <span className="informations-box--large__title">{title}</span>
        </div>
      </button>
    </div>
  );
};

CarCircleProgressChart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
};

CarCircleProgressChart.defaultProps = {
  total: 0,
};

export default CarCircleProgressChart;
