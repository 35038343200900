import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dateToString } from '../../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../../common/helpers/preparePriceFormat';
import { downloadInvoice } from '../../DashboardActions';

const InvoiceTableRow = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <>
      <td>
        <p className="font-weight-bold mr-1">{dateToString(item?.createdOn)}</p>
        <span>
          <span className="font-weight-bold mr-1">
            {preparePriceFormat(item.totalAmountInPence, true)}
          </span>
          {`No. ${item.invoiceNumber}`}
        </span>
      </td>
      <td className="pr-4 text-right">
        <button
          type="button"
          className="invoice-table-row__action-button"
          onClick={() =>
            dispatch(downloadInvoice({ id: item?.id, invoiceNumber: item?.invoiceNumber }))
          }
        >
          {null}
        </button>
      </td>
    </>
  );
};

InvoiceTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(InvoiceTableRow);
