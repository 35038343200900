import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SearchPlaceBidModal from './SearchPlaceBidModal';
import SearchConfirmBidModal from './SearchConfirmBidModal';

const SearchBidModal = ({ toggleShow, item, handlePlaceBid }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const bidAmount = useRef(null);

  const toggleStep = amount => {
    bidAmount.current = amount;
    setShowConfirm(true);
  };

  const placeBid = () => {
    handlePlaceBid(bidAmount.current);
    toggleShow();
  };

  return !showConfirm ? (
    <SearchPlaceBidModal item={item} toggleShow={toggleShow} toggleStep={toggleStep} />
  ) : (
    <SearchConfirmBidModal
      toggleShow={toggleShow}
      placeBid={placeBid}
      bidAmount={bidAmount.current}
    />
  );
};

SearchBidModal.propTypes = {
  toggleShow: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default SearchBidModal;
