import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormSelectField from '../../../common/components/formFields/FormSelectField';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import CommonButton from '../../../common/components/CommonButton';
import {
  getVehicleMakeList,
  getVehicleModelList,
} from '../../../addvehicle/AddVehicleActions';
import { dataToSelect } from '../../../common/helpers/dataToSelect';
import FormGroupRadioField from '../../../common/components/formFields/FormGroupRadioField';
import {
  isAllOptionSelected,
  mapRangeToSelect,
  YEARS_RANGE_OPTIONS,
} from '../utils/yearsRangeOptions';
import { getSearchAlertOptions } from '../SearchAlertActions';
import RouterPaths from '../../../constants/RouterPaths';

const addAllEmptyOption = (values, emptyLabel = 'ALL') => {
  const all = {
    value: -1,
    label: emptyLabel,
  };

  return [all, ...values];
};

const valuesToSelect = values => {
  if (!values) return [];
  const array = [];
  values.forEach(item => {
    array.push({
      value: item.toString(),
      label: `${item.toString()} miles`,
    });
  });
  return array;
};

const SearchAlertForm = ({ isCreateForm, form, values, history }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const distanceInMiles = useSelector(state => state.searchAlerts.get('distanceInMiles'));
  const valueInMiles = useSelector(state => state.searchAlerts.get('valueInMiles'));
  const yearsAgo = useSelector(state => state.searchAlerts.get('yearsAgo'));
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [modelSelectDisabled, setModelSelectDisabled] = useState(true);
  const [allRangeSelected, setAllRangeSelected] = useState(true);

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makeList => {
        setMakeOptions(addAllEmptyOption(dataToSelect([...makeList?.data], ['id', 'name'])));
      }),
    );
    dispatch(getSearchAlertOptions());
  }, [dispatch]);

  useEffect(() => {
    const isAllSelected = isAllOptionSelected(values?.allRangeOfYears);
    setAllRangeSelected(isAllSelected);
    if (isAllSelected) {
      form.setErrors({});
    }
    if (values?.make?.value || values?.make?.value === -1) {
      setModelSelectDisabled(false);
    }
  }, [form, values]);

  const handleMakeSelectChange = e => {
    form.setFieldValue('model', { value: -1, label: 'All' });
    if (!e.value || e.value === -1) {
      setModelOptions([]);
      setModelSelectDisabled(true);
      return;
    }
    setModelSelectDisabled(false);
    dispatch(
      getVehicleModelList(e.value, modelList => {
        setModelOptions(dataToSelect(modelList?.data, ['id', 'name']));
      }),
    );
  };

  return (
    <Col md={12} className="justify-content-center mr-auto ml-auto">
      <Form autoComplete="off" className="text-left">
        <h2 className="text-center font-weight-bold text-uppercase">
          {isCreateForm ? 'Add new search alert' : 'Edit search alert'}
        </h2>
        <Row>
          <Col md="6" sm="12">
            <FormSelectField
              name="make"
              className="basic-data__input"
              label="Make"
              options={makeOptions}
              onChangeHandler={handleMakeSelectChange}
            />
          </Col>
          <Col md="6" sm="12">
            <FormSelectField
              name="model"
              className="basic-data__input"
              label="Model"
              disabled={modelSelectDisabled}
              options={modelOptions}
            />
          </Col>
        </Row>
        <Row className="d-flex">
          <Col md={12} className="pt-3">
            <p>Years</p>
            <FormGroupRadioField name="allRangeOfYears" radios={YEARS_RANGE_OPTIONS} />
          </Col>
          <Col md={6}>
            <FormSelectField
              name="yearMaxValue"
              className="basic-data__input"
              label="From"
              disabled={allRangeSelected}
              options={mapRangeToSelect(yearsAgo)}
            />
          </Col>
          <Col md={6}>
            <FormSelectField
              name="yearMinValue"
              className="basic-data__input"
              label="To"
              disabled={allRangeSelected}
              options={mapRangeToSelect(yearsAgo)}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={6}>
            <FormSelectField
              name="mileageMaxValue"
              className="basic-data__input"
              label="Maximum mileage"
              options={addAllEmptyOption(valuesToSelect(valueInMiles))}
            />
          </Col>
          <Col md={6}>
            <FormSelectField
              name="distanceMaxValueInMiles"
              className="basic-data__input"
              label="Maximum distance from you"
              options={addAllEmptyOption(valuesToSelect(distanceInMiles), 'National')}
            />
          </Col>
        </Row>
        <Row className="justify-content-between mt-3">
          <Col className="d-flex">
            <CommonButton
              label="Cancel"
              variant={
                isLoading ? CommonButtonVariants.DISABLED : CommonButtonVariants.SECONDARY
              }
              handleClick={() => history.push(RouterPaths.SEARCH_ALERTS)}
            />
          </Col>
          <Col
            className={`d-flex ${
              isCreateForm ? 'justify-content-center' : 'justify-content-end'
            }`}
          >
            <CommonButton
              type="submit"
              label={isCreateForm ? 'Save' : 'Update'}
              variant={
                isLoading ? CommonButtonVariants.DISABLED : CommonButtonVariants.PRIMARY
              }
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

SearchAlertForm.defaultProps = {
  isCreateForm: false,
};

SearchAlertForm.propTypes = {
  isCreateForm: PropTypes.bool,
};

export default withRouter(SearchAlertForm);
