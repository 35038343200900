export const FormRegExp = {
  STRING_WITH_SPECIAL_CHARS: /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\-*.,'()&$"^%\s]+$/,
  STRING_ONLY_LETTERS: /^[A-Za-z]+$/,
  STRING_SPACES: / /g,
  STRING: /^.*$/,
  INTEGER: /^\d+$/,
  MONTH: /^(0[1-9]|1[012])$/,
  YEAR: /^[12][0-9]{3}$/,
  PRICE: /\B(?=(\d{3})+(?!\d))/g,
  URL: /https?:\/\/www\.?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/g,
  URL_NO_HTTPS: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi,
  ALPHANUMERIC_WITHOUT_SPACES: /^[A-Z0-9]*$/i,
  ALPHANUMERIC_WITHOUT_SPACES_UPPERCASE: /^[A-Z0-9]*$/,
};
