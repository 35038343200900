import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import CarCircleProgressChart from '../dashboard/informations/CarCircleProgressChart';
import { AuctionStatus, AuctionStatusName } from '../constants/AuctionStatus';
import { BidStatusType, BidStatusTypeName } from '../constants/BidStatusType';
import { setVehicleFilter } from '../myvehicles/MyVehiclesActions';
import history from '../browserHistory';
import RouterPaths from '../constants/RouterPaths';
import { setBidsFilter } from '../mybids/MyBidsActions';
import { animateCounter } from '../common/helpers/animateCounter';

const VEHICLE_ORDER = [
  {
    type: AuctionStatus.PUBLISHED,
    name: AuctionStatusName.PUBLISHED,
    color: '--primary-color',
  },
  { type: AuctionStatus.DRAFT, name: AuctionStatusName.DRAFT, color: '--gray100' },
  {
    type: AuctionStatus.TO_ACCEPT,
    name: AuctionStatusName.TO_ACCEPT,
    color: '--active-color',
  },
  { type: AuctionStatus.WAITING, name: AuctionStatusName.WAITING, color: '--warning-color' },
  {
    type: AuctionStatus.REJECTED,
    name: AuctionStatusName.REJECTED,
    color: '--rejected-color',
  },
  { type: AuctionStatus.ENDED, name: AuctionStatusName.ENDED, color: '--ended-color' },
  {
    type: AuctionStatus.CANCELLED,
    name: AuctionStatusName.CANCELLED,
    color: '--cancelled-color',
  },
  { type: AuctionStatus.SOLD, name: AuctionStatusName.SOLD, color: '--dark-green-color' },
];

const BID_ORDER = [
  {
    type: BidStatusType.PLACED_BIDS,
    name: BidStatusTypeName.PLACED_BIDS,
    color: '--primary-color',
  },
  {
    type: BidStatusType.TO_ACCEPT,
    name: BidStatusTypeName.TO_ACCEPT,
    color: '--active-color',
  },
  { type: BidStatusType.WAITING, name: BidStatusTypeName.WAITING, color: '--warning-color' },
  { type: BidStatusType.LOST, name: BidStatusTypeName.LOST, color: '--gray100' },
  {
    type: BidStatusType.REJECTED,
    name: BidStatusTypeName.REJECTED,
    color: '--rejected-color',
  },
  {
    type: BidStatusType.CANCELLED,
    name: BidStatusTypeName.CANCELLED,
    color: '--cancelled-color',
  },
  { type: BidStatusType.WON, name: BidStatusTypeName.WON, color: '--dark-green-color' },
];

const HomePageCharts = () => {
  const [vehicleAmount, setVehicleAmount] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const [vehicleData, setVehicleData] = useState([]);
  const [bidData, setBidData] = useState([]);
  const organizationStatistics = useSelector(state =>
    state.myOrganization.get('organizationStatistics'),
  );
  const organizationDetails = useSelector(state =>
    state.myOrganization.get('organizationDetails'),
  );
  const dispatch = useDispatch();

  const handleClickVehicles = status => {
    dispatch(setVehicleFilter(status));
    history.push(RouterPaths.MY_VEHICLES);
  };

  const handleClickBids = status => {
    dispatch(setBidsFilter(status));
    history.push(RouterPaths.MY_BIDS);
  };

  useEffect(() => {
    setVehicleAmount(organizationStatistics?.auctionCount);
    setBidAmount(organizationStatistics?.auctionBidCount);
    let vehicleSum = 0;
    setVehicleData(
      VEHICLE_ORDER.map(item => {
        const percent =
          organizationStatistics?.auctionCount &&
          organizationStatistics?.auctions &&
          organizationStatistics?.auctions[item?.type]
            ? (organizationStatistics?.auctions[item?.type] /
                organizationStatistics?.auctionCount) *
              100
            : 0;
        vehicleSum += percent;
        return {
          value: percent,
          color: item.color,
          name: item.name,
          type: item.type,
          offset: (100 - vehicleSum + percent) * 6.25,
          count:
            organizationStatistics?.auctions && organizationStatistics?.auctions[item?.type]
              ? organizationStatistics?.auctions[item?.type]
              : 0,
        };
      }),
    );
    let bidSum = 0;
    setBidData(
      BID_ORDER.map(item => {
        const percent =
          organizationStatistics?.auctionBidCount &&
          organizationStatistics?.auctionBids &&
          organizationStatistics?.auctionBids[item?.type]
            ? (organizationStatistics?.auctionBids[item?.type] /
                organizationStatistics?.auctionBidCount) *
              100
            : 0;
        bidSum += percent;
        return {
          value: percent,
          color: item.color,
          name: item.name,
          type: item.type,
          offset: (100 - bidSum + percent) * 6.25,
          count:
            organizationStatistics?.auctionBids &&
            organizationStatistics?.auctionBids[item?.type]
              ? organizationStatistics?.auctionBids[item?.type]
              : 0,
        };
      }),
    );
  }, [organizationStatistics]);

  useEffect(() => {
    animateCounter();
  }, [vehicleAmount, bidAmount, vehicleData, bidData]);

  return (
    <div className="find-best-vehicles">
      <Container>
        <h2 className="text-center font-weight-bold">{organizationDetails?.tradeName}</h2>
        <p className="form-input-field-label text-right">Last 30 days</p>
        <Row className="informations-form-row">
          <Col md={12} lg={6} className="mb-3 d-flex">
            <div className="informations-box informations-box--left informations-box--large">
              <div className="informations-box--large__chart-wrapper informations-box--large__chart-wrapper--left">
                <CarCircleProgressChart
                  data={vehicleData}
                  title="Selling"
                  total={vehicleAmount}
                  handleClick={() => handleClickVehicles('')}
                />
                <div className="informations-box--large__single-stats-wrapper cancelled-stat">
                  <button
                    type="button"
                    onClick={() => handleClickVehicles('SOLD')}
                    className="informations-box--large__single-stats"
                  >
                    <span className="informations-box--large__single-stats-title">Sold</span>
                    <span
                      className="informations-box--large__single-stats-counter stats-counter"
                      data-target={
                        organizationStatistics?.auctions
                          ? organizationStatistics?.auctions.SOLD
                          : 0
                      }
                    />
                  </button>
                  <i className="fas fa-circle informations-box--large__single-stats--sold" />
                </div>
              </div>
              <div className="informations-box--large__chart-stats-wrapper informations-box--large__chart-stats-wrapper--right">
                {vehicleData.map(item => {
                  if (item?.type && item?.type !== AuctionStatus.SOLD) {
                    return (
                      <div
                        key={item.type}
                        className="informations-box--large__single-stats-wrapper"
                      >
                        <button
                          type="button"
                          onClick={() => handleClickVehicles(item.type)}
                          className="informations-box--large__single-stats"
                        >
                          <span className="informations-box--large__single-stats-title">
                            {item?.name}
                          </span>
                          <span
                            className="informations-box--large__single-stats-counter stats-counter"
                            data-target={item.count}
                          />
                        </button>
                        <i
                          className="fas fa-circle"
                          style={{ color: `var(${item?.color})` }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </Col>
          <Col md={12} lg={6} className="mb-3 d-flex flex-wrap align-content-between">
            <div className="informations-box informations-box--right informations-box--large">
              <div className="informations-box--large__chart-stats-wrapper informations-box--large__chart-stats-wrapper--left ">
                {bidData.map(item => {
                  if (item?.type && item?.type !== BidStatusType.WON) {
                    return (
                      <div
                        key={item.type}
                        className="informations-box--large__single-stats-wrapper"
                      >
                        <button
                          type="button"
                          onClick={() => handleClickBids(item.type)}
                          className="informations-box--large__single-stats"
                        >
                          <span className="informations-box--large__single-stats-title">
                            {item?.name}
                          </span>
                          <span
                            className="informations-box--large__single-stats-counter stats-counter"
                            data-target={item.count}
                          />
                        </button>
                        <i
                          className="fas fa-circle"
                          style={{ color: `var(${item?.color})` }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="informations-box--large__chart-wrapper informations-box--large__chart-wrapper--right">
                <CarCircleProgressChart
                  data={bidData}
                  title="Buying"
                  total={bidAmount}
                  handleClick={() => handleClickBids('')}
                />
                <div className="informations-box--large__single-stats-wrapper cancelled-stat">
                  <button
                    type="button"
                    onClick={() => handleClickBids('WON')}
                    className="informations-box--large__single-stats"
                  >
                    <span className="informations-box--large__single-stats-title">Won</span>
                    <span
                      className="informations-box--large__single-stats-counter stats-counter"
                      data-target={
                        organizationStatistics?.auctionBids
                          ? organizationStatistics?.auctionBids.WON
                          : 0
                      }
                    />
                  </button>
                  <i className="fas fa-circle informations-box--large__single-stats--sold" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePageCharts;
