export const mapErrors = errors => {
  if (!errors) return {};
  const parsedError = {};
  Object.keys(errors).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(errors, key) && errors[key]) {
      const newKey = errorMap[key];
      if (newKey) {
        parsedError[newKey] = errors[key];
      } else {
        parsedError[key] = errors[key];
      }
    }
  });
  return parsedError;
};

const errorMap = {
  addOrganizationMemberSearchAlertForm: 'yearMinValue',
  validateOrganizationMemberSearchAlertForm: 'yearMinValue',
  updateOrganizationMemberSearchAlertForm: 'yearMinValue',
};
