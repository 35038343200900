import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import VideoSnapshot from 'video-snapshot';
import uuid from 'react-uuid';
import { PhotoUploadState } from 'itrade-admin-panel/src/constants/PhotoUploadState';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import addPhotoIcon from '../../assets/img/addPhotoIcon.svg';
import warningIcon from '../../assets/img/warningIcon.svg';
import { checkPhotoStep } from '../helpers/checkPhotoStep';
import { UploadFileTypes } from '../../constants/UploadFileTypes';

import {
  deleteVehicleVideoInSummary,
  getDraft,
  prepareThumbnailFile,
  removePhotos,
  saveAddPhotoDraft,
  sendVehiclePhoto,
  sendVehicleVideoInSummary,
  setFormStep,
  setPhotoStep,
  updatePhotos,
} from '../AddVehicleActions';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import CommonShadowLoader, {
  CommonShadowLoaderType,
} from '../../common/components/CommonShadowLoader';
import bucketIcon from '../../assets/img/bucket.svg';
import arrowsIcon from '../../assets/img/arrows.png';
import { getPhotoDescription } from '../../common/helpers/getPhotoDescription';
import { PhotoPerspective } from '../../constants/PhotoPerspective';

const AddPhotoSummary = () => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.addVehicle.get('id'));
  const [validationError, setValidationError] = useState(false);
  const [isVideoLoading, setVideoLoading] = useState(false);
  const photos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const videos = Array.from(useSelector(state => state.addVehicle.get('videos')));
  const photosToDelete = useSelector(state => state.addVehicle.get('photosToDelete')) || [];
  const sentPhotos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));
  const [gridColumnCount, setGridColumnCount] = useState(3);
  const handleRemoveVideoClick = id => {
    dispatch(deleteVehicleVideoInSummary(id));
  };
  const sendingPhotos = useRef(false);

  const updateDimensions = () => {
    if (window.innerWidth > 767) {
      setGridColumnCount(3);
    } else {
      setGridColumnCount(1);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (sendingPhotos.current) return;
    sendingPhotos.current = true;
    const photosToSave = photos.map((item, index) => {
      if (item.status === PhotoUploadState.PENDING && item.file) {
        return dispatch(sendVehiclePhoto(item.file, photos, index, null, true)).then(
          () => true,
        );
      }
      return null;
    });
    Promise.all(photosToSave).then(res => {
      if (res.some(item => item === true)) {
        dispatch(saveAddPhotoDraft())
          .then(() => dispatch(getDraft(id)))
          .then(() => {
            sendingPhotos.current = false;
          });
      } else {
        sendingPhotos.current = false;
      }
    });
  }, [dispatch, photos, id]);

  const getThumbnail = async file => {
    const snapshoter = new VideoSnapshot(file);
    const snap = await snapshoter.takeSnapshot();
    const thumbnail = await prepareThumbnailFile(snap);
    return thumbnail;
  };

  const handleUploadVideo = async e => {
    setVideoLoading(true);
    const file = e.target.files[0];
    const thumbnail = await getThumbnail(file);
    if (file && file?.type.includes(UploadFileTypes.VIDEO)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(
          sendVehicleVideoInSummary(file, thumbnail, data => {
            setVideoLoading(false);
            if (!data) {
              setValidationError(true);
            }
          }),
        );
      };
    }
  };

  const handleEditPhotoClick = (e, item) => {
    saveAndGet();
    dispatch(setPhotoStep(checkPhotoStep(item)));
    dispatch(setPhotoStep(+e?.currentTarget?.dataset?.index + 1));
    dispatch(setFormStep(4));
  };

  const handleAddAdditionalPhoto = () => {
    dispatch(setPhotoStep(photos?.length + 1));
    dispatch(setFormStep(4));
  };

  const canEdit = item => {
    if (item.status === PhotoUploadState.IN_PROGRESS) return false;
    return item.status !== PhotoUploadState.PENDING;
  };

  const getLoaderStatusByPhotoStatus = photoStatus => {
    switch (photoStatus) {
      case PhotoUploadState.PENDING:
        return CommonShadowLoaderType.PENDING;
      case PhotoUploadState.IN_PROGRESS:
        return CommonShadowLoaderType.IN_PROGRESS;
      default:
        return CommonShadowLoaderType.NOT_PENDING;
    }
  };

  const handleDelete = index => {
    dispatch(removePhotos(index, sentPhotos, vehicleMedia, photosToDelete));
    setTimeout(() => {
      saveAndGet();
    }, 100);
  };

  function saveAndGet() {
    dispatch(saveAddPhotoDraft()).then(() => dispatch(getDraft(id)));
  }

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(photos, sourceIndex, targetIndex);
    nextState.forEach((item, index) => {
      item.perspective = getPhotoDescription(index + 1).PERSPECTIVE;
      item.sortNumber = index;
    });
    dispatch(updatePhotos(nextState));
  }

  const handleAddObligatoryPhoto = e => {
    const photoIndex = e?.currentTarget?.dataset?.index;
    dispatch(setPhotoStep(+photoIndex));
    dispatch(setFormStep(4));
  };
  return (
    <>
      <h3 className="text-uppercase font-weight-bold text-center">Add vehicle</h3>
      <p className="text-center">
        {`A Minimum of ${REQUIRED_PHOTOS_AMOUNT} images is required to proceed. Hold & Drag images to re-order.`}
      </p>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={gridColumnCount}
          rowHeight={(gridColumnCount > 1 ? 115 : 150) + 8}
          style={{
            height: `${((gridColumnCount > 1 ? 115 : 150) + 8) *
              Math.ceil(((photos.length || 0) + 1) / gridColumnCount) +
              30}px`,
          }}
        >
          {photos.length >= REQUIRED_PHOTOS_AMOUNT
            ? photos.map((item, index) => (
                <GridItem key={item.id || uuid()}>
                  {item?.fileUrl ? (
                    <div key={item?.id || uuid()} className="photo-summary__photo-wrapper">
                      <CommonShadowLoader status={getLoaderStatusByPhotoStatus(item.status)}>
                        <div>
                          <img
                            src={item?.fileUrl}
                            alt="Vehicle"
                            className="photo-summary__photo no-selectable"
                          />
                        </div>
                        {canEdit(item) && (
                          <>
                            <div className="photo-summary__drag-position no-selectable">
                              <img src={arrowsIcon} height={20} width={20} alt="alt" />
                            </div>
                            <div className="photo-summary__photo-icon-button-container no-selectable">
                              <button
                                data-index={index}
                                type="button"
                                className="photo-summary__photo-icon-button"
                                onClick={e => handleEditPhotoClick(e, item)}
                              >
                                <i className="fas fa-pen photo-summary__photo-icon" />
                              </button>
                              <button
                                data-index={index}
                                type="button"
                                className="photo-summary__photo-icon-button"
                                onClick={() => handleDelete(index)}
                              >
                                <img src={bucketIcon} height={18} width={15} alt="alt" />
                              </button>
                            </div>
                          </>
                        )}
                        {item.perspective !== PhotoPerspective.MASTER &&
                          item?.damages?.length > 0 && (
                            <div className="photo-summary__photo-damages-icon no-selectable">
                              <img src={warningIcon} alt="Warning icon" />
                            </div>
                          )}
                      </CommonShadowLoader>
                    </div>
                  ) : (
                    <button
                      key={item?.id || uuid()}
                      type="button"
                      data-index={index + 1}
                      className="photo-summary__add-obligatory-photo-button m-1"
                      onClick={handleAddObligatoryPhoto}
                    >
                      <label
                        htmlFor="photo-upload"
                        className="photo-summary__add-photo-upload-label"
                      >
                        <img src={addPhotoIcon} alt="Plus icon" />
                      </label>
                    </button>
                  )}
                </GridItem>
              ))
            : null}
          <GridItem key={uuid()} onMouseDown={e => e.stopPropagation()}>
            <button
              type="button"
              className="photo-summary__add-photo-button m-1"
              onClick={handleAddAdditionalPhoto}
            >
              <label htmlFor="photo-upload" className="photo-summary__add-photo-upload-label">
                <img src={addPhotoIcon} alt="Plus icon" />
              </label>
            </button>
          </GridItem>
        </GridDropZone>
      </GridContextProvider>
      <div className="photo-summary__videos-outer-wrapper">
        <p className="font-weight-bold text-center mt-4">Video</p>
        <p className="text-center">
          You can add 2 videos of the vehicle, one from inside and the other from outside
        </p>
        <Row className="photo-summary__videos-wrapper">
          {videos.length
            ? videos.map(item => (
                <div key={item.mediaMetadataId} className="photo-summary__video-wrapper">
                  <img src={item.previewUrl} alt="video" className="photo-summary__video" />
                  <div className="photo-summary__video-inner-wrapper">
                    <button
                      type="button"
                      className="photo-summary__video-icon-button"
                      onClick={() => handleRemoveVideoClick(item.mediaMetadataId)}
                    >
                      <i className="far fa-trash-alt photo-summary__video-trash-icon" />
                    </button>
                  </div>
                </div>
              ))
            : null}
          {!videos.length || videos.length < 2 ? (
            <button
              type="button"
              className="photo-summary__add-photo-button"
              disabled={isVideoLoading}
            >
              <label htmlFor="video-upload" className="photo-summary__add-photo-upload-label">
                <img
                  src={addPhotoIcon}
                  alt="Plus icon"
                  className={`${
                    isVideoLoading ? 'photo-summary__add-photo-upload-label--loading' : ''
                  }`}
                />
              </label>
              {!isVideoLoading && (
                <input
                  id="video-upload"
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={handleUploadVideo}
                />
              )}
            </button>
          ) : null}
        </Row>
      </div>
      <Row>
        {validationError && (
          <label className="error-label mt-3">Upload only image/video format.</label>
        )}
      </Row>
    </>
  );
};

export default AddPhotoSummary;
