import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Select from 'react-select';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';

const FormSelectFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    options,
    onChangeHandler,
    classNames,
    withButton,
    buttonLabel,
    buttonSize,
    buttonClassNames,
    buttonHandleClick,
    buttonDisabled,
    rowClassNames,
    suffix,
    disabled,
    invalid,
  } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  const splittedName = field?.name?.split('.');

  const getSplitedName = params => {
    let temp = form.values;
    // eslint-disable-next-line no-restricted-syntax
    for (const param of params) {
      temp = temp[param];
    }

    return temp;
  };

  const value =
    splittedName?.length > 1 ? getSplitedName(splittedName) : form.values[field.name] || '';

  return (
    <Row className={rowClassNames}>
      <Col>
        <Label className="form-select-field-label">{label}</Label>
        <FormGroup className="form-select-field-wrapper">
          <div className="form-select-field-inner-wrapper">
            <Select
              options={options}
              placeholder={placeholder}
              name={field.name}
              value={value?.value ? value : ''}
              isDisabled={form.isSubmitting || disabled}
              onBlur={() => form.setFieldTouched(field.name, true)}
              onChange={option => {
                form.setFieldValue(field.name, option);
                onChangeHandler(option);
              }}
              className={`${classNames} ${(error || invalid) && 'react-select__control'}`}
              classNamePrefix="react-select"
            />
            {suffix && <span className="form-select-field__suffix">{suffix}</span>}
            {withButton && (
              <CommonButton
                label={buttonLabel}
                size={buttonSize}
                className={buttonClassNames}
                handleClick={buttonHandleClick}
                disabled={buttonDisabled}
                variant={
                  buttonDisabled ? CommonButtonVariants.DISABLED : CommonButtonVariants.PRIMARY
                }
              />
            )}
          </div>
          {(form.submitCount > 0 || touched) && error && (
            <label className="form-select-field__label">{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormSelectField = props => {
  return <Field {...props} component={FormSelectFieldInner} />;
};

FormSelectField.defaultProps = {
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  label: '',
  classNames: '',
  withButton: false,
  buttonLabel: '',
  buttonSize: '',
  buttonClassNames: '',
  buttonHandleClick: null,
  buttonDisabled: false,
  rowClassNames: '',
  className: '',
  suffix: '',
  disabled: false,
  invalid: false,
};

FormSelectField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  classNames: PropTypes.string,
  withButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonClassNames: PropTypes.string,
  buttonHandleClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  rowClassNames: PropTypes.string,
  className: PropTypes.string,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default FormSelectField;
