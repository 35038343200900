import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonTable from '../../../common/components/CommonTable';
import TransactionTableRow from './TransactionTableRow';

const TransactionTable = ({ data, offset, changeOffset }) => {
  return (
    <Col xs="12">
      <CommonTable
        rowComponent={TransactionTableRow}
        data={data}
        offset={offset}
        onOffsetChange={changeOffset}
      />
    </Col>
  );
};

TransactionTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
};

export default TransactionTable;
