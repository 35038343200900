import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { EmployeeStatusTypes } from 'constants/EmployeeStatusTypes';
import EmployeesTableRow from './EmployeesTableRow';
import CommonTable from '../../../common/components/CommonTable';

const EmployeesTable = ({ data, offset, changeOffset }) => {
  return (
    <Col xs="12">
      <CommonTable
        rowComponent={EmployeesTableRow}
        rowStyle={item => {
          switch (item.status) {
            case EmployeeStatusTypes.PENDING:
              return 'employee-pending-row';
            case EmployeeStatusTypes.REJECTED:
              return 'employee-rejected-row';
            default:
              return '';
          }
        }}
        rowDecoration={item => {
          switch (item.status) {
            case EmployeeStatusTypes.PENDING:
              return (
                <tr>
                  <td colSpan="4" className="employee-pending-row-decoration">
                    In verification
                  </td>
                </tr>
              );
            case EmployeeStatusTypes.REJECTED:
              return (
                <tr>
                  <td colSpan="4" className="employee-rejected-row-decoration">
                    Rejected
                  </td>
                </tr>
              );
            default:
              return null;
          }
        }}
        data={data}
        offset={offset}
        onOffsetChange={changeOffset}
        title="List of my employees"
      />
    </Col>
  );
};

EmployeesTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
};

export default EmployeesTable;
