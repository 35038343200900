export const AuctionPublishType = {
  EVERYWHERE: 'EVERYWHERE',
  GROUP_ONLY: 'GROUP_ONLY',
  GROUP_FIRST: 'GROUP_FIRST',
};

export const AuctionPublishTypeName = {
  EVERYWHERE: 'Launch everywhere',
  GROUP_ONLY: 'Launch in group only',
  GROUP_FIRST:
    'Launch in group only. If there are no offers from within group launch this vehicle to everywhere',
};
