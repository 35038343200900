import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const SearchConfirmBidModal = ({ toggleShow, placeBid, bidAmount }) => {
  return (
    <Modal
      isOpen={true}
      backdrop="static"
      keyboard={false}
      fade={false}
      className="place-bid-modal"
    >
      <ModalBody>
        <p className="text-center">Please review and confirm you bid</p>
        <span className="confirm-bid-price">
          <CurrencyFormat
            value={bidAmount}
            thousandSeparator={true}
            allowNegative={false}
            prefix="£"
            displayType="text"
          />
        </span>
      </ModalBody>
      <ModalFooter>
        <CommonButton
          variant={CommonButtonVariants.DANGER}
          label="CANCEL"
          handleClick={toggleShow}
        />
        <CommonButton
          variant={CommonButtonVariants.PRIMARY}
          label="CONFIRM"
          handleClick={placeBid}
        />
      </ModalFooter>
    </Modal>
  );
};

export default SearchConfirmBidModal;
