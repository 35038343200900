import * as Yup from 'yup';
import { FormRegExp } from '../../../constants/FormRegExp';

export default () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, 'First name is too long - should be 50 chars maximum'),
    lastName: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, 'Last name is too long - should be 50 chars maximum'),
    email: Yup.string()
      .required('Field is required')
      .email('Email has the wrong format'),
    phoneNumber: Yup.string()
      .max(15, 'Max length equals 15 characters')
      .required('Field is required')
      .matches(FormRegExp.INTEGER, 'Type only numbers'),
    position: Yup.string().required('Field is required'),
    location: Yup.string().required('Field is required'),
    password: Yup.string()
      .min(6, 'Password is too short - should be 6 chars minimum.')
      .required('Field required'),
    passwordRepeat: Yup.string()
      .required('Field is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must be the same'),
    role: Yup.string().required('Field is required'),
  });
};
