import { CommonString } from '../../constants/CommonString';

const { VEHICLE_NOT_SPECIFIED, NOT_SPECIFIED } = CommonString;

export const checkIsPropertySpecified = (property, isVehicle = false) => {
  if (!property) {
    return isVehicle ? VEHICLE_NOT_SPECIFIED : NOT_SPECIFIED;
  }
  return property;
};
