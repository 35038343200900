import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AuctionStatusTypeName } from 'constants/AuctionStatusType';
import { AuctionTime, AuctionTimeName } from '../constants/AuctionTime';
import { prepareSelectLabel } from '../common/helpers/prepareSelectLabel';
import { prepareTryingToDealDeliveryTypeLabel } from '../common/helpers/prepareTryingToDealDeliveryTypeLabel';
import ConfirmLaunchForm from './forms/ConfirmLaunchForm';
import { AuctionPublishType } from '../constants/AuctionPublishType';
import {
  clearConfirmLaunchErrors,
  publishAuction,
  republishAuction,
} from '../myvehicles/MyVehiclesActions';
import validationSchema from './forms/ConfirmLaunchForm.schema';
import { AuctionStatus } from '../constants/AuctionStatus';

const AddVehicleConfirmPage = ({ item, prevStep, isRepublish, refreshItem }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    refreshItem();
    window.scrollTo(0, 0);
    return () => dispatch(clearConfirmLaunchErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const prepareTimeOptionSelect = () => {
    const {
      AUCTION_TIME_60,
      AUCTION_TIME_180,
      AUCTION_TIME_1440,
      AUCTION_TIME_2880,
      AUCTION_TIME_4320,
    } = AuctionTime;
    const {
      AUCTION_TIME_60: AUCTION_TIME_60_LABEL,
      AUCTION_TIME_180: AUCTION_TIME_180_LABEL,
      AUCTION_TIME_1440: AUCTION_TIME_1440_LABEL,
      AUCTION_TIME_2880: AUCTION_TIME_2880_LABEL,
      AUCTION_TIME_4320: AUCTION_TIME_4320_LABEL,
    } = AuctionTimeName;
    switch (item?.timeOption) {
      case AUCTION_TIME_60:
        return {
          label: AUCTION_TIME_60_LABEL,
          value: AUCTION_TIME_60,
        };
      case AUCTION_TIME_180:
        return {
          label: AUCTION_TIME_180_LABEL,
          value: AUCTION_TIME_180,
        };
      case AUCTION_TIME_1440:
        return {
          label: AUCTION_TIME_1440_LABEL,
          value: AUCTION_TIME_1440,
        };
      case AUCTION_TIME_2880:
        return {
          label: AUCTION_TIME_2880_LABEL,
          value: AUCTION_TIME_2880,
        };
      case AUCTION_TIME_4320:
        return {
          label: AUCTION_TIME_4320_LABEL,
          value: AUCTION_TIME_4320,
        };
      default:
        return null;
    }
  };

  const prepareAutoAcceptValue = () => {
    return item?.autoAcceptPriceInPence ? item?.autoAcceptPriceInPence / 100 : '';
  };

  const initialValues = {
    id: item?.id || '',
    type: item?.type
      ? {
          label: AuctionStatusTypeName?.[item?.type]
            ? AuctionStatusTypeName?.[item?.type]
            : prepareSelectLabel(item?.type),
          value: item?.type,
        }
      : {
          label: '',
          value: '',
        },
    autoAcceptPriceInPence: prepareAutoAcceptValue(),
    minimumPriceInPence: item?.minimumPriceInPence ? item?.minimumPriceInPence / 100 : '',
    timeOption: item?.timeOption ? prepareTimeOptionSelect() : null,
    tryingToDealDeliveryDate: item?.tryingToDealDeliveredOn || new Date(),
    publishTarget: item?.publishTarget ? item?.publishTarget : AuctionPublishType.EVERYWHERE,
    tryingToDealDeliveryDateType: item?.tryingToDealDeliveredDateType
      ? {
          label: prepareTryingToDealDeliveryTypeLabel(item?.tryingToDealDeliveredDateType),
          value: item?.tryingToDealDeliveredDateType,
        }
      : null,
    version: typeof item?.version === 'number' ? item?.version : null,
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (isRepublish) {
      dispatch(
        republishAuction(values, data => {
          refreshItem(data);
          prevStep();
        }),
      );
    } else {
      dispatch(
        publishAuction(values, data => {
          refreshItem(data);
          prevStep();
        }),
      );
    }
    setSubmitting(false);
  };

  return (
    <Col md={8} className="justify-content-center mr-auto ml-auto">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        {form => <ConfirmLaunchForm form={form} prevStep={prevStep} item={item} />}
      </Formik>
    </Col>
  );
};

AddVehicleConfirmPage.defaultProps = {
  item: null,
};

AddVehicleConfirmPage.propTypes = {
  item: PropTypes.object,
  prevStep: PropTypes.func.isRequired,
  isRepublish: PropTypes.bool.isRequired,
};

export default AddVehicleConfirmPage;
