import React from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import CommonDeleteButton from 'common/components/CommonDeleteButton';
import { FieldArray } from 'formik';
import DamageSelect from '../helpers/getDamageSelectOptions';
import { removeDamages, setDamageType } from '../AddVehicleActions';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { DamageTypesName } from '../../constants/DamageTypes';
import { checkInteriorPerspective } from '../helpers/checkInteriorPerspective';

const damageTypes = constantToSelect(DamageTypesName);
const AddDamageDragPreviewInner = ({ form, remove }) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const index = currentStep - 1;
  const damages = form.values.vehicleMedia?.photos[index]?.damages;
  const isInteriorPerspective = checkInteriorPerspective(
    form.values.vehicleMedia?.photos[index].perspective,
  );
  const sentPhotos = form?.values?.vehicleMedia?.photos;
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));

  const handleDelete = e => {
    const damageToRemove = e.currentTarget.dataset.step;

    const damageId = sentPhotos[index]?.damages[damageToRemove]?.mediaMetadataId;
    dispatch(removeDamages(index, sentPhotos, damageId, damageToRemove, vehicleMedia));
    remove(damageToRemove);
  };

  const updateDamageType = (option, damageIndex) => {
    dispatch(setDamageType(option, damageIndex, index));
  };
  return (
    <Row className="d-flex justify-content-center">
      <Col>
        {damages.map((e, index) => (
          <div key={Math.random()} className="damages__container">
            <div className="damages__preview">
              <div className="damages__image" style={{ backgroundImage: `url(${e.fileUrl})` }}>
                <CommonDeleteButton
                  dataStep={index}
                  className="damages__delete-button"
                  handleClick={handleDelete}
                />
              </div>
            </div>

            <div className="damages__select-area">
              <Col className="d-flex flex-column">
                <p className="damages__number">{`Damage ${index + 1}/5`}</p>
                {!isInteriorPerspective && (
                  <DamageSelect
                    options={damageTypes}
                    name={`vehicleMedia.photos[${currentStep -
                      1}].damages[${index}].damageType`}
                    label="Damage type"
                    currentStep={currentStep - 1}
                    index={index}
                    onChangeHandler={updateDamageType}
                  />
                )}
              </Col>
            </div>
          </div>
        ))}
      </Col>
    </Row>
  );
};

const AddDamageDragPreview = props => {
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const index = currentStep - 1;
  return (
    <FieldArray
      {...props}
      name={`vehicleMedia.photos[${index}].damages`}
      component={AddDamageDragPreviewInner}
    />
  );
};

export default AddDamageDragPreview;
