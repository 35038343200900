import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Select from 'react-select';
import { getOptionsFromDraft } from './getOptionsFromDraft';

const DamageSelectInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    options,
    onChangeHandler,
    currentStep,
    index,
  } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  const value = form.values.vehicleMedia.photos[currentStep]?.damages[index]?.damageType
    ? getOptionsFromDraft(
        form.values.vehicleMedia.photos[currentStep]?.damages[index]?.damageType,
      )
    : '';

  return (
    <Row>
      <Col className="d-flex flex-column">
        <Label className="form-select-field-label align-self-start">{label}</Label>
        <FormGroup>
          <Select
            options={options}
            placeholder={placeholder}
            value={value}
            isDisabled={form.isSubmitting}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={option => {
              form.setFieldValue(field.name, option);
              onChangeHandler(option.value, index);
            }}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const DamageSelect = props => {
  return <Field {...props} component={DamageSelectInner} />;
};

DamageSelect.defaultProps = {
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  label: '',
};

DamageSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
};

export default DamageSelect;
