import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { CommonButtonSize, CommonButtonVariants } from '../constants/CommonButtonVariants';
import CommonButton from '../common/components/CommonButton';
import { BidStatusTypeName } from '../constants/BidStatusType';

const { PRIMARY, SECONDARY } = CommonButtonVariants;

const MyBidsHeader = ({ handlerChange }) => {
  const selected = useSelector(state => state.myBids.get('filter'));
  const handleClick = status => {
    if (status === selected) return;
    handlerChange(status);
  };

  return (
    <Row>
      <Col>
        <div className="d-flex flex-row flex-wrap">
          <div className="p-2">
            <CommonButton
              label="All"
              variant={selected === '' ? PRIMARY : SECONDARY}
              handleClick={() => handleClick('')}
              size={CommonButtonSize.MEDIUM}
            />
          </div>
          {Object.keys(BidStatusTypeName).map(status => (
            <div className="p-2" key={status}>
              <CommonButton
                label={BidStatusTypeName[status]}
                variant={selected === status ? PRIMARY : SECONDARY}
                handleClick={() => handleClick(status)}
                size={CommonButtonSize.MEDIUM}
              />
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

MyBidsHeader.propTypes = {
  handlerChange: PropTypes.func.isRequired,
};

export default MyBidsHeader;
