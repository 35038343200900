import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { checkIsFieldEmpty } from 'common/helpers/checkIsFieldEmpty';
import { EmployeeStatusTypes } from 'constants/EmployeeStatusTypes';
import FormInputField from '../../../common/components/formFields/FormInputField';
import FormSelectField from '../../../common/components/formFields/FormSelectField';
import FormGroupCheckboxField from '../../../common/components/formFields/FormGroupCheckboxField';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import CommonButton from '../../../common/components/CommonButton';
import { fetchOrganizationLocations, removeEmployee } from '../../DashboardActions';
import { constantToSelect } from '../../../common/helpers/constantToSelect';
import { EmployeeUserRoleName, UserRole } from '../../../constants/UserRole';
import { DealerPositionName } from '../../../constants/DealerPosition';

const dealerRole = constantToSelect(DealerPositionName);

const EmployeeForm = ({ id, isCreateForm, values }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationIsBuyer = useSelector(state => state.auth.get('isBuyer'));
  const organizationIsSeller = useSelector(state => state.auth.get('isSeller'));
  const organizationLocations = useSelector(state =>
    state.myOrganization.get('organizationLocations'),
  );
  const employeeStatus = useSelector(
    state => state.myOrganization.get('employeeDetails')?.employee?.status,
  );
  const [location, setLocation] = useState([]);
  const [emptyFields, setEmptyFields] = useState(true);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const employeeUserRoles = constantToSelect(EmployeeUserRoleName).map(role => {
    const disabled =
      (role.value === UserRole.BUYER && !organizationIsBuyer) ||
      (role.value === UserRole.SELLER && !organizationIsSeller) ||
      (role.value === UserRole.TRANSACTION_MANAGEMENT &&
        (!organizationIsSeller || !values.role.includes(UserRole.SELLER)));
    return { ...role, disabled };
  });

  const checkCheckboxDependencies = value => {
    if (!value.includes(UserRole.SELLER) && value.includes(UserRole.TRANSACTION_MANAGEMENT)) {
      const idx = value.indexOf(UserRole.TRANSACTION_MANAGEMENT);
      if (idx !== -1) {
        value.splice(idx, 1);
      }
    }
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      position,
      location,
      password,
      passwordRepeat,
      role,
    } = values;
    const valuesArray = [
      firstName,
      lastName,
      phoneNumber,
      email,
      isEmpty(position) ? '' : position,
      isEmpty(location) ? '' : location,
      isEmpty(role) ? '' : role,
    ];
    if (isCreateForm) {
      valuesArray.push(password, passwordRepeat);
    }
    checkIsFieldEmpty(valuesArray, setEmptyFields);
  }, [values, isCreateForm]);

  useEffect(() => {
    dispatch(fetchOrganizationLocations());

    if (organizationLocations.length > 0) {
      const preparedOrganizationLocations = organizationLocations.map(item => {
        return {
          label: item?.postalCode?.postcode,
          value: item.id,
        };
      });
      setLocation(preparedOrganizationLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organizationLocations.length]);
  return (
    <Col md={12} className="justify-content-center mr-auto ml-auto">
      <Form autoComplete="off" className="text-left">
        <h2 className="text-center font-weight-bold text-uppercase">
          {isCreateForm ? 'Add new employee' : 'Edit employee'}
        </h2>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField
              name="firstName"
              label="First name"
              disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
            />
          </Col>
          <Col md="6">
            <FormInputField
              name="lastName"
              label="Last name"
              disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
            />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormSelectField
              name="position"
              label="Choose a position"
              options={dealerRole}
              disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
            />
          </Col>
          <Col md="6">
            <FormSelectField name="location" label="Choose a location" options={location} />
            <FormSelectField rowClassNames="form-select-field-hidden" />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField
              name="phoneNumber"
              label="Phone number"
              disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
            />
          </Col>
          <Col md="6">
            <FormInputField
              name="email"
              label="E-mail address"
              disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
            />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField type="password" name="password" label="Password" />
          </Col>
          <Col md="6">
            <FormInputField type="password" name="passwordRepeat" label="Repeat password" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroupCheckboxField
              inline={true}
              name="role"
              label="Role"
              checkboxes={employeeUserRoles}
              checkDependencies={checkCheckboxDependencies}
              isRoleGroupCheckbox={true}
            />
          </Col>
        </Row>
        <Row className="justify-content-between mt-3">
          {!isCreateForm && (
            <>
              <Col className="d-flex">
                <CommonButton
                  label="Delete"
                  variant={
                    isLoading ? CommonButtonVariants.DISABLED : CommonButtonVariants.SECONDARY
                  }
                  handleClick={() => setShowDeleteAlert(true)}
                />
              </Col>
              <SweetAlert
                danger
                showCancel
                show={showDeleteAlert}
                onConfirm={() => {
                  dispatch(removeEmployee(id));
                  setShowDeleteAlert(false);
                }}
                onCancel={() => setShowDeleteAlert(false)}
                confirmBtnBsStyle="info"
                cancelBtnCssClass="cancel-button"
                cancelBtnBsStyle="default"
              >
                <span className="sweet-alert-text">
                  Are you sure you want to remove this employee?
                </span>
              </SweetAlert>
            </>
          )}
          <Col
            className={`d-flex ${
              isCreateForm ? 'justify-content-center' : 'justify-content-end'
            }`}
          >
            <CommonButton
              type="submit"
              label="Save"
              variant={
                isLoading || emptyFields
                  ? CommonButtonVariants.DISABLED
                  : CommonButtonVariants.PRIMARY
              }
              disabled={isLoading || emptyFields}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

EmployeeForm.defaultProps = {
  id: '',
  isCreateForm: false,
  values: {},
};

EmployeeForm.propTypes = {
  id: PropTypes.string,
  isCreateForm: PropTypes.bool,
  values: PropTypes.object,
};

export default EmployeeForm;
