export const animateCounter = () => {
  const counters = document.querySelectorAll('.stats-counter');
  const speed = 200;

  counters.forEach(item => {
    const updateCounter = () => {
      const target = +item.getAttribute('data-target');
      const count = +item.innerText;

      const increment = target / speed;
      if (count < target) {
        item.innerText = Math.ceil(count + increment);
        setTimeout(updateCounter, 1);
      } else {
        item.innerText = target;
      }
    };
    updateCounter();
  });
};
