import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Form, FormFeedback, Input, Row } from 'reactstrap';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import ResetPasswordSchema from './ResetPasswordPage.schema';
import { resetPassword } from '../AuthActions';
import CommonButton from '../../common/components/CommonButton';

const ResetPasswordPage = ({ match }) => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errors, setErrors] = useState({});
  const token = get(match, 'params.token', null);

  const dispatch = useDispatch();

  const handleRecoverPassword = () => {
    setErrors({});
    ResetPasswordSchema.validate(
      {
        password,
        passwordRepeat,
      },
      { abortEarly: false },
    )
      .then(() => {
        dispatch(
          resetPassword({ password, passwordRepeat, token }, () => {
            setPassword('');
            setPasswordRepeat('');
          }),
        );
      })
      .catch(validation => {
        const err = {};
        validation.inner.forEach(e => {
          err[e.path] = e.message;
        });

        setErrors({
          ...err,
        });
      });
  };

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" lg="4" md="6">
          <Form method="" action="" className="text-center">
            <h3>Reset your password</h3>
            <Input
              placeholder="Password"
              type="password"
              autoComplete="off"
              value={password}
              invalid={!!errors.password}
              onChange={e => setPassword(e.target.value)}
              className="mt-3"
            />
            {errors && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
            <Input
              placeholder="Repeat password"
              type="password"
              autoComplete="off"
              value={passwordRepeat}
              invalid={!!errors.passwordRepeat}
              onChange={e => setPasswordRepeat(e.target.value)}
              className="mt-3"
            />
            {errors && errors.passwordRepeat && (
              <FormFeedback>{errors.passwordRepeat}</FormFeedback>
            )}
            <CommonButton
              label="Reset password"
              handleClick={() => handleRecoverPassword(password, token, resetPassword)}
              className="mt-3"
            />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ResetPasswordPage);
