import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { validateFormField } from 'common/helpers/validateFormField';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../../common/components/CommonButton';
import FormInputField from '../../common/components/formFields/FormInputField';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { clearBasicDataSaved, sendPaleteNumber } from '../AddVehicleActions';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const AddNumber = ({ values, nextForm }) => {
  const dispatch = useDispatch();
  const { DISABLED, PRIMARY } = CommonButtonVariants;
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const [errors, setErrors] = useState({
    regPlateError: '',
  });

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { registrationPlate } = values?.basicData;
      const errors = {
        regPlateError: validateFormField(registrationPlate, null, true, 10),
      };
      setErrors(errors);
      const { regPlateError } = errors;
      if (regPlateError) {
        reject();
      }
      resolve();
    });

  const { regPlateError } = errors;
  const { registrationPlate } = values?.basicData;
  const moveToNextStep = () => {
    validateForm().then(() => dispatch(sendPaleteNumber(registrationPlate, () => nextForm())));
  };

  const handleEnter = e => {
    if (e.key !== 'Enter') return;
    e.preventDefault();
    moveToNextStep();
  };

  useEffect(() => {
    dispatch(clearBasicDataSaved());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form className="text-left" onKeyDown={handleEnter}>
      <Container>
        <Row>
          <Col>
            <Row className="justify-content-center">
              <Col className="d-flex flex-column align-items-center " sm={8} lg={6}>
                <h6 className="add-number__title">ADD VEHICLE</h6>
              </Col>
            </Row>
            <Row className="align-items-center flex-column text-left">
              <Col className="d-flex flex-column my-2 " sm={5} lg={3}>
                <FormInputField
                  name="basicData.registrationPlate"
                  type="text"
                  label="Vehicle registration plate"
                  invalid={!!regPlateError}
                  upperCase
                />
                <CommonErrorLabel value={regPlateError} />
              </Col>
              <Col className="d-flex flex-column align-items-center" sm={6} lg={4}>
                <CommonButton
                  label="next"
                  handleClick={moveToNextStep}
                  disabled={!!isLoading}
                  variant={isLoading ? DISABLED : PRIMARY}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

AddNumber.propTypes = {
  values: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddNumber;
