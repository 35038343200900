import PropTypes from 'prop-types';
import { AuctionStatusType } from '../constants/AuctionStatusType';

const CurrentBidLabel = ({ hasBid, type }) => {
  const getAuctionTypeName = hasBid => {
    const isPhysicalType = type === AuctionStatusType.PHYSICAL;
    return hasBid || isPhysicalType ? `Current Bid: ` : '';
  };

  return getAuctionTypeName(hasBid);
};

CurrentBidLabel.defaultProps = {
  hasBid: false,
};

CurrentBidLabel.propTypes = {
  hasBid: PropTypes.bool,
};

export default CurrentBidLabel;
