export const DealerStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  REJECTED: 'REJECTED',
  BLOCKED_DUE_TO_PAYMENT: 'BLOCKED_DUE_TO_PAYMENT',
};

export const DealerStatusName = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  BLOCKED: 'Archived',
  REJECTED: 'Rejected',
  BLOCKED_DUE_TO_PAYMENT: 'Blocked due to payment',
};

export const DealerStatusActionName = {
  ACTIVATE: 'Activate',
  REJECT: 'Reject',
  BLOCK: 'Archive',
};
