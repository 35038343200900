export const StripeValidationErrorCode = {
  INCOMPLETE_NUMBER: 'incomplete_number',
  INCOMPLETE_EXPIRY: 'incomplete_expiry',
  INCOMPLETE_CVC: 'incomplete_cvc',
  INCOMPLETE_ZIP: 'incomplete_zip',
  AUTH_FAILURE: 'setup_intent_authentication_failure',
};

export const StripeValidationErrorType = {
  VALIDATION_ERROR: 'validation_error',
  INVALID_REQUEST_ERROR: 'invalid_request_error',
};

export const StripeCustomErrorMessage = {
  AUTH_FAILURE: 'We are unable to authenticate your payment method. Please try again.',
};

export const StripeValidationErrorParam = {
  INVALID_CARDHOLDER: 'payment_method_data[billing_details][name]',
};
