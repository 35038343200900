import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { dateToString } from '../../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../../common/helpers/preparePriceFormat';

const TransactionTableRow = ({ item }) => {
  return (
    <td>
      <p className="font-weight-bold">{dateToString(item?.createdOn)}</p>
      <span>
        <span className="font-weight-bold mr-1">
          {preparePriceFormat(item.amountInPence, true)}
        </span>
        {item.title}
      </span>
    </td>
  );
};

TransactionTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(TransactionTableRow);
