import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../../common/components/CommonButton';
import FormInputField from '../../common/components/formFields/FormInputField';
import {
  clearValidationErrors,
  completeCompanyDetails,
  validateOrganization,
} from '../RegisterActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import PostcodeSelectField from './postcode/PostcodeSelectField';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const OrganizationDetailsForm = ({ prevStep, nextStep, values, setFieldValue }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const validateErrors = useSelector(state => state.register.get('validateErrors'));
  const officialCompanyName = useSelector(state => state.register.get('officialCompanyName'));

  const [errors, setErrors] = useState({
    tradeNameError: '',
    companyRegisterNumberError: '',
    vatNumberError: '',
    postalCodeIdError: '',
    addressError: '',
    address2Error: '',
    cityError: '',
    countyError: '',
    phoneNumberError: '',
    mobileNumberError: '',
    websiteError: '',
  });
  const [isFieldEmpty, setFieldEmpty] = useState(true);

  useEffect(() => {
    checkIsFieldEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    return () => {
      dispatch(clearValidationErrors());
    };
  }, [dispatch]);

  const setNotFoundError = res => {
    if (res?.tradeName === null) {
      setErrors({
        companyRegisterNumberError:
          'No data was found for the entered company registration number',
      });
    }
  };

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const {
        officialCompanyName,
        tradeName,
        companyRegisterNumber,
        vatNumber,
        postalCodeId,
        address,
        address2,
        city,
        county,
        phoneNumber,
        mobileNumber,
        website,
      } = values;

      const errors = {
        officialCompanyNameError: validateFormField(
          officialCompanyName,
          FormFieldTypes.NAME,
          false,
          50,
        ),
        tradeNameError: validateFormField(tradeName, FormFieldTypes.NAME, false, 50),
        companyRegisterNumberError: validateFormField(
          companyRegisterNumber,
          FormFieldTypes.NUMBER,
          false,
          8,
          6,
        ),
        vatNumberError: validateFormField(vatNumber, FormFieldTypes.NUMBER, true, 12, 9),
        postalCodeIdError: validateFormField(postalCodeId, FormFieldTypes.ENUM, false),
        addressError: validateFormField(address, null, false, 100),
        address2Error: validateFormField(address2, null, false, 100),
        cityError: validateFormField(city, null, false, 100),
        countyError: validateFormField(county, null, false, 100),
        phoneNumberError: validateFormField(phoneNumber, FormFieldTypes.NUMBER, false, 15),
        mobileNumberError: validateFormField(mobileNumber, FormFieldTypes.NUMBER, false, 15),
        websiteError: validateFormField(website, FormFieldTypes.URL, false, 200),
      };
      setErrors(errors);

      const {
        officialCompanyNameError,
        tradeNameError,
        companyRegisterNumberError,
        vatNumberError,
        postalCodeIdError,
        addressError,
        address2Error,
        cityError,
        phoneNumberError,
        mobileNumberError,
        websiteError,
      } = errors;
      if (
        officialCompanyNameError ||
        tradeNameError ||
        companyRegisterNumberError ||
        vatNumberError ||
        postalCodeIdError ||
        addressError ||
        address2Error ||
        cityError ||
        phoneNumberError ||
        mobileNumberError ||
        websiteError
      ) {
        reject();
      }
      resolve();
    });
  };

  const validateCompanyDetailsField = () => {
    return new Promise((resolve, reject) => {
      const { companyRegisterNumber } = values;

      const errors = {
        companyRegisterNumberError: validateFormField(
          companyRegisterNumber,
          FormFieldTypes.COMPANY_REGISTRATION_NUMBER,
          false,
          8,
          6,
        ),
      };
      setErrors(errors);

      const { companyRegisterNumberError } = errors;
      if (companyRegisterNumberError) {
        reject();
      }
      resolve();
    });
  };

  const handleCompleteCompanyDetails = () => {
    validateCompanyDetailsField()
      .then(() => {
        dispatch(
          completeCompanyDetails(
            values,
            values?.companyRegisterNumber,
            values?.organizationRole,
            setNotFoundError,
          ),
        );
        setFieldValue('officialCompanyName', officialCompanyName);
      })
      .catch(() => {});
  };

  const moveToNextStep = () => {
    validateForm()
      .then(() => dispatch(validateOrganization(values, () => nextStep())))
      .catch(() => {});
  };

  const checkIsFieldEmpty = () => {
    const isFieldEmpty = !values.vatNumber;
    return setFieldEmpty(isFieldEmpty);
  };

  const {
    officialCompanyNameError,
    tradeNameError,
    companyRegisterNumberError,
    vatNumberError,
    postalCodeIdError,
    addressError,
    address2Error,
    cityError,
    countyError,
    mobileNumberError,
    phoneNumberError,
    websiteError,
  } = errors;

  return (
    <Col md="8" className="justify-content-center m-auto">
      <Form autoComplete="off" className="text-left">
        <h3 className="text-uppercase font-weight-bold text-center">Add company details</h3>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField
              name="companyRegisterNumber"
              label="Company registration number"
              invalid={
                !!companyRegisterNumberError ||
                validateErrors?.['organizationForm.companyRegisterNumber']
              }
            />
            <CommonErrorLabel
              value={
                companyRegisterNumberError ||
                validateErrors?.['organizationForm.companyRegisterNumber']
              }
            />
          </Col>
          <Col md="6">
            <CommonButton
              label="Complete company details"
              className="add-organization-custom-button"
              handleClick={handleCompleteCompanyDetails}
              disabled={isLoading || !values?.companyRegisterNumber}
              variant={
                isLoading || !values?.companyRegisterNumber
                  ? CommonButtonVariants.DISABLED
                  : CommonButtonVariants.PRIMARY
              }
            />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField
              name="officialCompanyName"
              label="Company Name"
              invalid={!!officialCompanyNameError}
            />
            <CommonErrorLabel value={officialCompanyNameError} />
          </Col>
          <Col md="6">
            <FormInputField
              name="vatNumber"
              label="VAT Number"
              invalid={!!vatNumberError || validateErrors?.addOrganizationAndRoleForm}
            />
            <CommonErrorLabel
              value={vatNumberError || validateErrors?.addOrganizationAndRoleForm}
            />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField name="tradeName" label="Trade Name" invalid={!!tradeNameError} />
            <CommonErrorLabel value={tradeNameError} />
          </Col>
          <Col md="6">
            <PostcodeSelectField
              name="postalCodeId"
              label="Postal code"
              invalid={!!postalCodeIdError}
            />
            <CommonErrorLabel value={postalCodeIdError} />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField name="address" label="Address" invalid={!!addressError} />
            <CommonErrorLabel value={addressError} />
          </Col>
          <Col md="6">
            <FormInputField
              name="address2"
              label="Address (line 2)"
              invalid={!!address2Error}
            />
            <CommonErrorLabel value={address2Error} />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField name="city" label="City" invalid={!!cityError} />
            <CommonErrorLabel value={cityError} />
          </Col>
          <Col md="6">
            <FormInputField name="county" label="County" invalid={!!countyError} />
            <CommonErrorLabel value={countyError} />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField
              name="mobileNumber"
              label="Mobile number"
              invalid={!!mobileNumberError}
            />
            <CommonErrorLabel value={mobileNumberError} />
          </Col>
          <Col md="6">
            <FormInputField
              name="phoneNumber"
              label="Phone number"
              invalid={!!phoneNumberError}
            />
            <CommonErrorLabel value={phoneNumberError} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormInputField name="website" label="Website address" invalid={!!websiteError} />
            <CommonErrorLabel value={websiteError} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center justify-content-sm-start">
            <CommonButton
              label="Back"
              handleClick={prevStep}
              variant={CommonButtonVariants.SECONDARY}
            />
          </Col>
          <Col className="d-flex justify-content-center justify-content-sm-end mt-4 mt-sm-0">
            <CommonButton
              label="Next"
              handleClick={moveToNextStep}
              variant={
                isLoading || isFieldEmpty
                  ? CommonButtonVariants.DISABLED
                  : CommonButtonVariants.PRIMARY
              }
              disabled={isLoading || isFieldEmpty}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

OrganizationDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default OrganizationDetailsForm;
