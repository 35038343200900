const getPhotoDescription = step => {
  switch (step) {
    case 1:
      return {
        DESCRIPTION: '',
        PERSPECTIVE: 'MASTER',
      };
    case 2:
      return {
        DESCRIPTION: 'Upload photo of the Nearside Front',
        PERSPECTIVE: 'LEFT_FRONT',
      };
    case 3:
      return {
        DESCRIPTION: 'Upload photo of the Nearside',
        PERSPECTIVE: 'LEFT_SIDE',
      };
    case 4:
      return {
        DESCRIPTION: 'Upload photo of the Nearside Rear',
        PERSPECTIVE: 'LEFT_REAR',
      };
    case 5:
      return {
        DESCRIPTION: 'Upload photo of the Rear',
        PERSPECTIVE: 'REAR',
      };
    case 6:
      return {
        DESCRIPTION: 'Upload photo of the Offside Rear',
        PERSPECTIVE: 'RIGHT_REAR',
      };
    case 7:
      return {
        DESCRIPTION: 'Upload photo of the Offside',
        PERSPECTIVE: 'RIGHT_SIDE',
      };
    case 8:
      return {
        DESCRIPTION: 'Upload photo of the Offside Front',
        PERSPECTIVE: 'RIGHT_FRONT',
      };
    case 9:
      return {
        DESCRIPTION: 'Take the first photo of the vehicle’s interior',
        PERSPECTIVE: 'INTERIOR_1',
      };
    case 10:
      return {
        DESCRIPTION: 'Take the second photo of the vehicle’s interior',
        PERSPECTIVE: 'INTERIOR_2',
      };
    case 11:
      return {
        DESCRIPTION: 'Take the third photo of the vehicle’s interior',
        PERSPECTIVE: 'INTERIOR_3',
      };
    case 12:
      return {
        DESCRIPTION: 'Take the fourth photo of the vehicle’s interior',
        PERSPECTIVE: 'INTERIOR_4',
      };
    default:
      return {
        DESCRIPTION: `Additional photo`,
        PERSPECTIVE: 'OTHER',
      };
  }
};

export { getPhotoDescription };
