export const capitalizeSnakeCaseStringFromApi = string => {
  if (string) {
    const stringsArray = string.split('_');
    if (stringsArray) {
      return stringsArray
        .map(item => {
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        })
        .join(' ');
    }
  }
  return null;
};
