import * as Yup from 'yup';
import { AuctionStatusType } from '../../constants/AuctionStatusType';
import { FormRegExp } from '../../constants/FormRegExp';
import { DueInDateType } from '../../constants/DueInDateType';

export default () => {
  return Yup.object().shape({
    type: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }),
    autoAcceptPriceInPence: Yup.string().when('type', {
      is: value => value.value === AuctionStatusType.PHYSICAL,
      then: Yup.string()
        .matches(FormRegExp.INTEGER, 'Type only numbers')
        .max(10, 'Reserve Price must be between 1 and 10 characters')
        .required('Field is required'),
    }),
    minimumPriceInPence: Yup.string().when('type', {
      is: value => value.value === AuctionStatusType.MINIMUM_PRICE,
      then: Yup.string()
        .matches(FormRegExp.INTEGER, 'Type only numbers')
        .max(10, 'Minimum Price must be between 1 and 10 characters')
        .required('Field is required'),
    }),
    tryingToDealDeliveryDateType: Yup.string()
      .nullable()
      .when('type', {
        is: value => value.value === AuctionStatusType.TRYING_TO_DEAL,
        then: Yup.string().required('Field is required'),
      }),
    tryingToDealDeliveryDate: Yup.string()
      .nullable()
      .when('tryingToDealDeliveryDateType', {
        is: value => value === DueInDateType.CUSTOM_DATE,
        then: Yup.string().required('Field is required'),
      }),
    timeOption: Yup.string()
      .required('Field is required')
      .nullable(),
    publishTarget: Yup.string().required('Field is required'),
  });
};
