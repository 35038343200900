import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Label, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import PropTypes from 'prop-types';
import { constantToSelect } from 'common/helpers/constantToSelect';
import { Field, FieldArray, Form, getIn } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import calendarIcon from '../../assets/img/calendarIcon.svg';
import FormInputField from '../../common/components/formFields/FormInputField';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import addPhotoIcon from '../../assets/img/addPhotoIcon.svg';
import {
  clearPhotosInServiceHistory,
  getDraft,
  removePhotosFromServiceHistory,
  saveServiceHistoryDraft,
  sendServiceHistoryPhotoRecords,
} from '../AddVehicleActions';
import { reverseDateToString } from '../../common/utils/date/Date.utils';
import { DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS } from '../AddVehicleReducer';
import { resizeImage } from '../../common/utils/image/Image.utils';
import { ServiceHistory, ServiceHistoryName } from '../../constants/ServiceHistory';

const serviceHistoryList = constantToSelect(ServiceHistoryName);

const ServiceHistoryForm = ({ prevForm, values }) => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.addVehicle.get('id'));
  const [isShowForm, setShowForm] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isButtonAddDisabled, setButtonAddDisabled] = useState(true);
  const [idAlertDelPhoto, setIdAlertDelPhoto] = useState(null);
  const [idDelHistory, setIdDelHistory] = useState(null);
  const [isPhotoLoading, setPhotoLoading] = useState(false);
  const photoRecordsFromValue = values?.photoRecords ? values.photoRecords : [];
  const photoRecordsFromState = Array.from(
    useSelector(state => state.addVehicle.get('photoRecords')),
  );
  const ids = new Set(photoRecordsFromState.map(d => d?.mediaMetadataId));
  const photoRecords = [
    ...photoRecordsFromValue.filter(d => !ids.has(d?.mediaMetadataId)),
    ...photoRecordsFromState,
  ];
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const { SECONDARY, DISABLED, PRIMARY } = CommonButtonVariants;

  const handleDeleteServiceHistory = (history, arrayHelpers, index) => {
    if (history) {
      arrayHelpers.remove(index);
    }
  };

  const handleDeletePhotoRecord = id => {
    const removeIndex =
      photoRecords
        .map(function(item) {
          return item.mediaMetadataId;
        })
        .indexOf(id) + 1;
    values.photoRecords = values.photoRecords.filter(e => e.mediaMetadataId !== id);
    if (removeIndex && id) {
      values.photoRecordsToDelete.push(id);
      dispatch({
        type: DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS,
        payload: {
          idPhotoRecord: removeIndex,
        },
      });
    }
    dispatch(removePhotosFromServiceHistory(id));
  };

  const handleUploadPhoto = async e => {
    const file = e.target.files[0];
    const imageResized = await resizeImage(file);
    if (imageResized && imageResized?.type.includes(UploadFileTypes.IMAGE)) {
      setPhotoLoading(true);
      await dispatch(sendServiceHistoryPhotoRecords(imageResized))
        .then(() => {
          setButtonDisabled(false);
        })
        .catch(() => {});
      setPhotoLoading(false);
    }
  };

  const clearHistoryRecords = useCallback(() => {
    if (values?.type?.value === ServiceHistory.NONE) {
      values.manualRecords = [];
      values.photoRecordsToDelete = [...values.photoRecords.map(el => el.mediaMetadataId)];
      values.photoRecords = [];
    }
  }, [
    values.manualRecords,
    values.photoRecords,
    values.photoRecordsToDelete,
    values.type.value,
  ]);

  const handleSaveDraft = () => {
    clearHistoryRecords();
    for (let i = 0; i < values?.manualRecords?.length; i++) {
      values.manualRecords[i].date = reverseDateToString(values.manualRecords[i].date);
    }
    values.photoRecords = photoRecords;
    const data = {
      ...values,
      type: values?.type?.value || null,
    };
    dispatch(
      saveServiceHistoryDraft(data, () => {
        dispatch(getDraft(id));
      }),
    );
  };

  const handleAddAnotherService = arrayHelpers => {
    arrayHelpers.push({
      date: '',
      mileage: '',
      serviceName: '',
    });
  };

  useEffect(() => {
    if (!isEmpty(photoRecords)) {
      setButtonDisabled(false);
    }
    let manualRecordsAllowed = true;
    for (let i = 0; i < values?.manualRecords.length; i++) {
      if (
        isEmpty(values?.manualRecords[i]?.date) ||
        isEmpty(values?.manualRecords[i]?.mileage?.toString()) ||
        isEmpty(values?.manualRecords[i]?.serviceName)
      ) {
        manualRecordsAllowed = false;
      }
    }
    setButtonAddDisabled(!manualRecordsAllowed);
    if (
      values?.type?.value !== ServiceHistory.NONE &&
      !isEmpty(values?.manualRecords) &&
      manualRecordsAllowed
    ) {
      setButtonDisabled(false);
    }
  }, [values, photoRecords]);

  useEffect(() => {
    if (values?.type?.value === ServiceHistory.NONE) {
      clearHistoryRecords();
      dispatch(clearPhotosInServiceHistory());
    } else if (values?.type?.value !== ServiceHistory.NONE && !values.manualRecords.length) {
      values.manualRecords = [
        {
          date: '',
          mileage: '',
          serviceName: '',
        },
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (
      values?.type?.value === ServiceHistory.FULL ||
      values?.type?.value === ServiceHistory.PARTIAL
    ) {
      setShowForm(true);
      setButtonDisabled(true);
    } else if (values?.type?.value === ServiceHistory.NONE) {
      setShowForm(false);
      setButtonDisabled(false);
    }
  }, [values, values.type]);

  useEffect(() => {
    clearHistoryRecords();
    for (let i = 0; i < values?.manualRecords?.length; i++) {
      values.manualRecords[i].date = reverseDateToString(values.manualRecords[i].date);
    }
    values.photoRecords = photoRecords;
  }, [
    clearHistoryRecords,
    photoRecords,
    values.manualRecords,
    values.photoRecords,
    values.type.value,
  ]);

  return (
    <Form autoComplete="off">
      <Container>
        <Row className="justify-content-center">
          <Col className="ml-auto mr-auto " lg="8" md="8" sm="12">
            <div className="service-history__container">
              <h6 className="service-history__title">ADD VEHICLE</h6>
              <Row>
                <Col className="ml-auto mr-auto " xs="9">
                  <Label for="type" className="text-center  service-history__label">
                    Add service history
                  </Label>
                  <FormSelectField
                    name="type"
                    label="Choose service history type"
                    options={serviceHistoryList}
                  />
                </Col>
              </Row>
              {isShowForm ? (
                <>
                  <Row>
                    <Col xs="12">
                      <p className="service-history__method-subtitle">
                        Scan your service book
                      </p>
                    </Col>
                    <div className="service-history__images-grid">
                      {photoRecords.length
                        ? photoRecords.map(item => (
                            <div
                              className="service-history__image"
                              key={item?.mediaMetadataId}
                            >
                              <img
                                key={item?.mediaMetadataId}
                                src={item?.fileUrl}
                                alt="Service history"
                                className="service-history__image"
                              />
                              <button
                                type="button"
                                className="service-history__delete-image"
                                title="Delete"
                                onClick={() => setIdAlertDelPhoto(item?.mediaMetadataId)}
                              >
                                <i className="fa fa-trash-alt service-history__delete-image-ico" />
                              </button>
                              <SweetAlert
                                danger
                                showCancel
                                show={idAlertDelPhoto === item?.mediaMetadataId}
                                onConfirm={() => {
                                  handleDeletePhotoRecord(item?.mediaMetadataId);
                                  setIdAlertDelPhoto(null);
                                }}
                                onCancel={() => setIdAlertDelPhoto(null)}
                                confirmBtnBsStyle="info"
                                cancelBtnCssClass="cancel-button"
                                cancelBtnBsStyle="default"
                              >
                                <span className="sweet-alert-text">
                                  Are you sure you want to delete this photo?
                                </span>
                              </SweetAlert>
                            </div>
                          ))
                        : null}
                      <button type="button" className="service-history__add-photo-button">
                        <label
                          htmlFor="photo-upload"
                          className="service-history__add-photo-upload-label"
                        >
                          <img
                            src={addPhotoIcon}
                            alt="Plus icon"
                            className={`${
                              isPhotoLoading
                                ? 'service-history__add-photo-upload-label--loading'
                                : ''
                            }`}
                          />
                        </label>
                        {!isPhotoLoading && (
                          <input
                            id="photo-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleUploadPhoto}
                          />
                        )}
                      </button>
                    </div>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="service-history__method-subtitle">or fill the form</p>
                    </Col>
                    <Col xs="12">
                      <FieldArray
                        name="manualRecords"
                        render={arrayHelpers => (
                          <div>
                            {values?.manualRecords.map((service, index) => {
                              return (
                                <>
                                  {/* eslint-disable-next-line react/no-array-index-key */}
                                  <Row key={index} className="service-history__manual-record">
                                    <Col md="12" className="d-flex justify-content-end">
                                      {values?.manualRecords.length > 1 && (
                                        <button
                                          type="button"
                                          className="service-history_trash-button"
                                          onClick={() => {
                                            setIdDelHistory(index);
                                          }}
                                        >
                                          {null}
                                        </button>
                                      )}
                                    </Col>
                                    <Col md="6">
                                      <Field name={`manualRecords.${index}.date`}>
                                        {({ form }) => {
                                          const error = getIn(
                                            form.errors,
                                            `manualRecords.${index}.date`,
                                          );
                                          const touch = getIn(
                                            form.touched,
                                            `manualRecords.${index}.date`,
                                          );
                                          return (
                                            <div>
                                              <FormDatePickerField
                                                name={`manualRecords.${index}.date`}
                                                label="Date"
                                                icon={calendarIcon}
                                                iconClassName="calendar-icon"
                                                invalid={!!touch && !!error}
                                              />
                                              {touch && error ? (
                                                <label className="error-label">{error}</label>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      </Field>
                                    </Col>
                                    <Col md="6">
                                      <Field name={`manualRecords.${index}.mileage`}>
                                        {({ form }) => {
                                          const error = getIn(
                                            form.errors,
                                            `manualRecords.${index}.mileage`,
                                          );
                                          const touch = getIn(
                                            form.touched,
                                            `manualRecords.${index}.mileage`,
                                          );
                                          return (
                                            <div>
                                              <FormInputField
                                                name={`manualRecords.${index}.mileage`}
                                                label="Mileage"
                                                invalid={!!touch && !!error}
                                              />
                                              {touch && error ? (
                                                <label className="error-label">{error}</label>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      </Field>
                                    </Col>
                                    <Col md="12">
                                      <Field name={`manualRecords.${index}.serviceName`}>
                                        {({ form }) => {
                                          const error = getIn(
                                            form.errors,
                                            `manualRecords.${index}.serviceName`,
                                          );
                                          const touch = getIn(
                                            form.touched,
                                            `manualRecords.${index}.serviceName`,
                                          );
                                          return (
                                            <div>
                                              <FormInputField
                                                name={`manualRecords.${index}.serviceName`}
                                                label="Service name"
                                                invalid={!!touch && !!error}
                                              />
                                              {touch && error ? (
                                                <label className="error-label">{error}</label>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      </Field>
                                    </Col>
                                  </Row>
                                  <SweetAlert
                                    danger
                                    showCancel
                                    show={idDelHistory === index}
                                    onConfirm={() => {
                                      handleDeleteServiceHistory(
                                        service,
                                        arrayHelpers,
                                        idDelHistory,
                                      );
                                      setIdDelHistory(null);
                                    }}
                                    onCancel={() => setIdDelHistory(null)}
                                    confirmBtnBsStyle="info"
                                    cancelBtnCssClass="cancel-button"
                                    cancelBtnBsStyle="default"
                                  >
                                    <span className="sweet-alert-text">
                                      Are you sure to remove this information about service
                                      history?
                                    </span>
                                  </SweetAlert>
                                </>
                              );
                            })}{' '}
                            <Row>
                              <Col xs="12" className="d-flex justify-content-center">
                                <CommonButton
                                  type="button"
                                  variant={isButtonAddDisabled ? DISABLED : PRIMARY}
                                  label="+ Add another service"
                                  size="large"
                                  className="m-3"
                                  handleClick={() => handleAddAnotherService(arrayHelpers)}
                                  disabled={isButtonAddDisabled}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
            <Row className="mt-4">
              <Col md={6}>
                <CommonButton label="Back" variant={SECONDARY} handleClick={prevForm} />
              </Col>
              <Col md={6} className="d-flex flex-column align-items-end">
                <CommonButton
                  type="submit"
                  label="Next"
                  className="mb-3"
                  variant={isButtonDisabled || isPhotoLoading ? DISABLED : PRIMARY}
                  disabled={isButtonDisabled || isPhotoLoading}
                />
                <CommonButton
                  label="Save"
                  variant={isPhotoLoading || isLoading ? DISABLED : SECONDARY}
                  disabled={isPhotoLoading}
                  handleClick={handleSaveDraft}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

ServiceHistoryForm.propTypes = {
  prevForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default ServiceHistoryForm;
