import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field } from 'formik';
import { isEmpty } from 'lodash';
import 'assets/css/componentStyles/FormGroupRadioField.css';

const FormGroupRadioFieldInner = props => {
  const { form, field, radios, paragraphs, classNames, disabled } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || '';

  return (
    <>
      {radios.map((radio, index) => (
        <FormGroup check disabled={form.isSubmitting} key={radio.label} className="mb-4">
          <Input
            type="radio"
            name={field.name}
            id={`${field.name}-${index}`}
            value={radio.value}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={() => {
              form.setFieldValue(field.name, radio.value);
            }}
            checked={radio.value === value}
            className="form-group-radio-field"
            disabled={disabled}
          />
          <Label
            className={`registration-role-label ${classNames}`}
            for={`${field.name}-${index}`}
            check
          >
            {radio.label}
          </Label>
          {!isEmpty(paragraphs) && (
            <p className="registration-role-paragraph">{paragraphs[radio.value]}</p>
          )}
        </FormGroup>
      ))}
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </>
  );
};

const FormGroupRadioField = props => {
  return <Field {...props} component={FormGroupRadioFieldInner} />;
};

FormGroupRadioField.defaultProps = {
  radios: [],
  paragraphs: {},
  classNames: '',
  disabled: false,
};

FormGroupRadioField.propTypes = {
  radios: PropTypes.array,
  paragraphs: PropTypes.object,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormGroupRadioField;
