import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import BuySell from './BuySell';
import Underwrite from './Underwrite';
import HomeMyVehicles from './HomeMyVehicles';
import HomeMyBids from './HomeMyBids';
import HomePageCharts from './HomePageCharts';
import {
  fetchOrganizationDetails,
  fetchOrganizationStatistics,
} from '../dashboard/DashboardActions';

const HomePage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchOrganizationDetails());
      dispatch(fetchOrganizationStatistics());
    }
  }, [isAuthenticated, dispatch]);

  if (isAuthenticated) {
    return (
      <div>
        <HomePageCharts />
        {isSeller && <HomeMyVehicles />}
        {isBuyer && <HomeMyBids />}
        <HowItWorks />
      </div>
    );
  }
  return (
    <div>
      <Hero />
      <BuySell />
      <Underwrite />
    </div>
  );
};

export default HomePage;
