import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import magnifierIcon from 'assets/img/magnifierIcon.svg';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import FormInputField from '../formFields/FormInputField';
import { setSearchQuery } from '../../../search/SearchActions';
import CommonButton from '../CommonButton';

const SearchForm = ({ id }) => {
  const dispatch = useDispatch();
  const currentSearchQuery = useSelector(state => state.search.get('searchQuery'));
  const handleSearch = (values, { setSubmitting }) => {
    dispatch(setSearchQuery(values.search, true));
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ search: currentSearchQuery || '' }}
      onSubmit={handleSearch}
      enableReinitialize
    >
      <Form autoComplete="off" className="text-left">
        <Row className="d-flex justify-content-end align-items-center">
          <Col lg="6" className="header-search__input-wrapper">
            <FormInputField
              name="search"
              placeholder="Search"
              icon={magnifierIcon}
              iconClassName="search-icon"
              id={id}
              nolabel
            />
          </Col>
          <Col lg="3">
            <div className="header-search-button-wrapper">
              <CommonButton label="Search" type="submit"/>
            </div>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default SearchForm;

SearchForm.defaultProps = {
  id: '',
};

SearchForm.propTypes = {
  id: PropTypes.string,
};
