import PropTypes from 'prop-types';
import { AuctionStatusTypeName } from '../constants/AuctionStatusType';

const AuctionTypeLabel = ({ hasBid, type }) => {
  const getAuctionTypeName = (hasBid, type) => {
    if (!type || !AuctionStatusTypeName[type]) return null;
    return `${AuctionStatusTypeName[type]}`;
  };

  return getAuctionTypeName(hasBid, type);
};

AuctionTypeLabel.defaultProps = {
  type: null,
  hasBid: false,
};

AuctionTypeLabel.propTypes = {
  type: PropTypes.string,
  hasBid: PropTypes.bool,
};

export default AuctionTypeLabel;
