import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    nearsideFront: Yup.string()
      .required('Field is required')
      .nullable(),
    nearsideRear: Yup.string()
      .required('Field is required')
      .nullable(),
    offsideFront: Yup.string()
      .required('Field is required')
      .nullable(),
    offsideRear: Yup.string()
      .required('Field is required')
      .nullable(),
  });
};
