import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAuction } from '../../myvehicles/MyVehiclesActions';
import AuctionDetailsItem from '../../common/components/AuctionDetailsItem';
import { auctionListType } from '../../constants/AuctionListType';
import { SSE_BUYER, SSEClient } from '../../api/SSEClient';
import AddVehicleConfirmPage from '../../addvehicle/AddVehicleConfirmPage';

const SearchDetailsPage = ({ history, location }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const sseClient = new SSEClient(SSE_BUYER);

  const [item, setItem] = useState(null);
  const [step, setStep] = useState(1);
  const [isRepublish, setIsRepublish] = useState(false);
  const [auctionTimeStamp, setAuctionTimestamp] = useState(null);

  useEffect(() => {
    setAuctionTimestamp(new Date());
    dispatch(
      getAuction(id, auction => {
        setItem(auction);
      }),
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.location.state = {
          ...location.state,
          auctionId: id,
        };
      }
    };
  }, [id, history, location]);

  const handleGetAuction = auction => {
    setAuctionTimestamp(new Date());
    if (auction) {
      setItem(auction);
    } else {
      dispatch(
        getAuction(id, auction => {
          setItem(auction);
        }),
      );
    }
  };

  const prevStep = () => {
    setStep(prevState => prevState - 1);
  };

  const nextStep = () => {
    setStep(prevState => prevState + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          item && (
            <AuctionDetailsItem
              item={item}
              listType={auctionListType.SEARCH}
              refreshItem={handleGetAuction}
              sseClient={sseClient}
              typeSseClient={SSE_BUYER}
              nextStep={nextStep}
              setIsRepublish={setIsRepublish}
              auctionTimestamp={auctionTimeStamp}
            />
          )
        );
      case 2:
        return (
          <AddVehicleConfirmPage
            item={item}
            prevStep={prevStep}
            nextStep={nextStep}
            isRepublish={isRepublish}
            refreshItem={handleGetAuction}
          />
        );
      default:
        return null;
    }
  };

  return renderStep();
};

SearchDetailsPage.propTypes = {
  //
};

export default SearchDetailsPage;
