import React from 'react';
import { Col } from 'reactstrap';

const ConfirmForgotPasswordPage = () => {
  return (
    <Col className="register-success-paragraph ml-auto mr-auto" lg="5" md="6">
      <h4 className="mt-0 text-center">Reset password confirmation</h4>
      <p className="text-center">
        A message with further instructions has been sent to your email address.
      </p>
    </Col>
  );
};

export default ConfirmForgotPasswordPage;
