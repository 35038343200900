import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import FormInputField from '../../../common/components/formFields/FormInputField';
import magnifierIcon from '../../../assets/img/magnifierIcon.svg';
import CommonButton from '../../../common/components/CommonButton';

const initialValues = {
  search: '',
};

const EmployeesSearch = ({ handleSearch }) => {
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSearch} validateOnBlur={true}>
        {() => (
          <Form noValidate>
            <Row className="d-flex justify-content-start align-items-center">
              <Col md="8">
                <FormInputField
                  name="search"
                  placeholder="Search"
                  icon={magnifierIcon}
                  iconClassName="search-icon"
                />
              </Col>
              <Col md="4">
                <Row>
                  <Col className="employees-search-button-wrapper">
                    <CommonButton label="Search" type="submit" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

EmployeesSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};
export default EmployeesSearch;
