import React from 'react';
import 'assets/css/componentStyles/Loader.css';
import Rolling from 'assets/img/Rolling.gif';

const InfinityLoader = () => {
  return (
    <div className="infinity-loader-wrapper">
      <div className="infinity-loader">
        <img src={Rolling} alt="Loading..." />
      </div>
    </div>
  );
};

export default InfinityLoader;
