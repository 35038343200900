import React, { useEffect, useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

const CardElementWrapper = ({ elements, disabled }) => {
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    if (elements) {
      const stripeElement = elements.getElement(CardElement);
      stripeElement.update({
        style: {
          base: {
            backgroundColor: isDisabled ? '#e3e3e3' : '#fff',
          },
        },
      });
    }
    setIsDisabled(disabled);
  }, [elements, isDisabled, disabled]);

  const CARD_OPTIONS = {
    style: {
      base: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'normal',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        color: '#66615b',
        iconColor: '#66615b',
        ':-webkit-autofill': {
          color: '#66615b',
        },
        '::placeholder': {
          color: '#66615b',
          fontWeight: 'normal',
        },
      },
      invalid: {
        iconColor: '#ff0000',
        color: '#ff0000',
      },
    },
    hidePostalCode: true,
    disabled: isDisabled,
  };

  return (
    <div
      className={`form-control card-element-wrapper mb-2 ${
        isDisabled ? 'form-control--disabled' : ''
      }`}
    >
      <CardElement options={CARD_OPTIONS} id="credit-card-container" />
    </div>
  );
};

CardElementWrapper.defaultProps = {
  disabled: false,
};

CardElementWrapper.propTypes = {
  elements: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default CardElementWrapper;
