import { setSearchQuery } from 'search/SearchActions';
import RouterPaths from './RouterPaths';

export const AuthenticatedNavItems = [
  {
    label: 'Home',
    path: RouterPaths.HOMEPAGE,
  },
  {
    label: 'Vehicles live',
    path: RouterPaths.SEARCH,
    action: () => setSearchQuery('', true),
  },
  {
    label: 'Dashboard',
    path: RouterPaths.DASHBOARD,
  },
  {
    label: 'Add vehicle',
    path: RouterPaths.ADD_VEHICLE,
    role: 'seller',
  },
];
