export const prepareBidsData = (bids, organizationId) => {
  if (bids.length) {
    const bidsMyOrganization = bids.filter(bid => bid.bidderOrganizationId === organizationId);
    const highestBidMyOrganization = bidsMyOrganization.length
      ? bidsMyOrganization.reduce((a, b) => (a.amountInPence > b.amountInPence ? a : b))
      : {};

    const winningBid = bids.filter(bid => bid.isHighest)?.[0];
    const bidMyOrganizationIsHighest = winningBid?.id === highestBidMyOrganization?.id;
    return {
      bidsMyOrganization,
      highestBidMyOrganization,
      winningBid,
      bidMyOrganizationIsHighest,
    };
  }
  return {};
};
