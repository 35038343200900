import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import BrowseImg from 'assets/img/HomePage/browse.png';
import BuyImg from 'assets/img/HomePage/buy.png';

const BuySell = () => {
  return (
    <div className="buy-and-sell">
      <Container fluid>
        <Row className="d-flex align-items-center examples">
          <Col xs="12" lg="6">
            <Row className="align-items-center examples">
              <Col xs="12" sm="6" md="8" lg="6">
                <h3>Buy</h3>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> You can now buy used car stock smarter
                  and faster. Listings starting from just 1 Hour.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> Our innovative Mobile App delivers a
                  seamless buying experience with fully detailed appraisals and vehicle images.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> Buying with confidence is paramount.
                  All vehicles offered are strictly from professional new or used retail motor
                  dealers.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> The listings may be fast, but you
                  won&apos;t miss the chance to buy as you receive push notifications for all
                  vehicles offered direct to your mobile device.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> A minimal buyers fee is applicable for
                  all successful purchases.
                </p>
              </Col>
              <Col xs="12" sm="6" md="4" lg="6">
                <img src={BuyImg} alt="itrade30" className="buy-and-sell-image" />
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="6">
            <Row className="align-items-center examples">
              <Col xs="12" sm="6" md="4" lg="6" className="order-2 order-sm-1">
                <img src={BrowseImg} alt="itrade30" className="buy-and-sell-image" />
              </Col>
              <Col xs="12" sm="6" md="8" lg="6" className="order-1 order-sm-2">
                <h3>Sell</h3>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> Sell your Trade cars and part exchanges
                  direct to the industry&apos;s most trusted and professional motor dealers.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> It&apos;s simple and faster using our
                  innovative mobile app to launch vehicles for sale straight from your mobile.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> Why wait longer to sell when you can
                  sell faster. Achieve the highest bids for your vehicles with auctions running
                  from as little as 1 hour.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> Push notifications instantly alert
                  buyers when you launch a vehicle for sale. Your vehicle gets maximum exposure
                  to the best trade buyers every time.
                </p>
                <p className="bulletpoints">
                  <i className="fas fa-check-circle" /> Minimal fee&apos;s for launching and
                  selling vehicles of any value.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BuySell;
