import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import InformationsForm from './informations/forms/InformationsForm';
import CommonMyOrganizationContainer from '../common/components/CommonMyOrganizationContainer';
import { fetchOrganizationDetails } from './DashboardActions';
import validationSchema from './informations/forms/InformationsForm.schema';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const organizationDetails = useSelector(state =>
    state.myOrganization.get('organizationDetails'),
  );

  useEffect(() => {
    dispatch(fetchOrganizationDetails());
  }, [dispatch]);

  const prepareAdditionalLocationObject = () => {
    if (organizationDetails && organizationDetails?.additionalLocation) {
      return organizationDetails.additionalLocation.map(item => {
        return {
          id: item?.id,
          address: item?.address,
          address2: item?.address2,
          city: item?.city,
          county: item?.county,
          postalCode: {
            label: item?.postalCode.postcode,
            value: item?.postalCode.id,
          },
          status: item?.status,
          version: item?.version,
        };
      });
    }
    return null;
  };

  const initialValues = {
    companyRegistrationNumber: organizationDetails?.companyRegistrationNumber,
    vatNumber: organizationDetails?.vatNumber,
    postalCodeId: organizationDetails?.mainLocation?.postalCode
      ? {
          value: organizationDetails?.mainLocation?.postalCode?.id,
          label: organizationDetails?.mainLocation?.postalCode?.postcode,
        }
      : '',
    city: organizationDetails?.mainLocation?.city,
    county: organizationDetails?.mainLocation?.county,
    address: organizationDetails?.mainLocation?.address,
    address2: organizationDetails?.mainLocation?.address2,
    mainLocationIsPending: organizationDetails?.mainLocationIsPending,
    landlinePhoneNumber: organizationDetails?.landlinePhoneNumber,
    landlinePhoneNumberIsPending: organizationDetails?.landlinePhoneNumberIsPending,
    additionalLocation: prepareAdditionalLocationObject() || [
      { postalCode: {}, address: '', address2: '', city: '', county: '' },
    ],
    mainLocationVersion: organizationDetails?.mainLocation?.version,
    website: organizationDetails?.website,
  };
  const handleSubmit = () => null;

  return (
    <CommonMyOrganizationContainer>
      <h2 className="text-center font-weight-bold">{organizationDetails?.tradeName}</h2>
      <Row>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, setSubmitting, isValid }) => (
            <InformationsForm
              values={values}
              setSubmitting={setSubmitting}
              isValid={isValid}
            />
          )}
        </Formik>
      </Row>
    </CommonMyOrganizationContainer>
  );
};

export default withRouter(DashboardPage);
