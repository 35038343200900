import { combineReducers } from 'redux';
import authReducer from '../auth/AuthReducer';
import alertReducer from '../common/components/alert/AlertReducer';
import registerReducer from '../register/RegisterReducer';
import myOrganizationReducer from '../dashboard/DashboardReducer';
import navbarLoaderReducer from '../common/components/NavbarLoader/NavbarLoaderReducer';
import myProfileReducer from '../dashboard/myprofile/MyProfileReducer';
import myWalletReducer from '../mywallet/MyWalletReducer';
import AddVehicleReducer from '../addvehicle/AddVehicleReducer';
import SearchReducer from '../search/SearchReducer';
import MyVehiclesReducer from '../myvehicles/MyVehiclesReducer';
import MyBidsReducer from '../mybids/MyBidsReducer';
import HomePageReducer from '../homepage/HomePageReducer';
import searchAlertReducer from '../dashboard/searchalerts/SearchAlertReducer';

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  register: registerReducer,
  addVehicle: AddVehicleReducer,
  myOrganization: myOrganizationReducer,
  navbarLoader: navbarLoaderReducer,
  searchAlerts: searchAlertReducer,
  myProfile: myProfileReducer,
  myWallet: myWalletReducer,
  search: SearchReducer,
  myVehicles: MyVehiclesReducer,
  myBids: MyBidsReducer,
  homePage: HomePageReducer,
});
