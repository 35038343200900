import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import CommonButton from 'common/components/CommonButton';
import { CommonButtonSize } from 'constants/CommonButtonVariants';
import history from 'browserHistory';
import RouterPaths from '../constants/RouterPaths';
import LogoItem from '../homepage/LogoItem';
import SearchForm from '../common/components/Header/SearchForm';

const FindBestVehicles = () => {
  const aggregation = useSelector(state => state.homePage.get('aggregation'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const handleNavigate = () => {
    history.push(RouterPaths.ADD_VEHICLE);
  };
  return (
    <div className="find-best-vehicles">
      <Container>
        <Row>
          <Col className=" d-flex justify-content-center">
            <h3 className="find-best-vehicles__title">
              Find the best vehicles throughout the UK
            </h3>
          </Col>
        </Row>

        <Row className="find-best-vehicles__search">
          <Col sm={10}>
            <SearchForm id="find-best-vehicles__search" />
          </Col>
        </Row>
        <Row>
          <div className="find-best-vehicles__wrapper">
            {aggregation?.map(item => {
              return (
                <LogoItem
                  key={item.slug}
                  logoUrl={item.urlToLogo}
                  make={item.make}
                  id={item.makeId}
                  count={item.count}
                />
              );
            })}
          </div>
        </Row>
        {isSeller && (
          <>
            <Row>
              <Col className="d-flex justify-content-center">
                <p className="find-best-vehicle__or">or</p>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-center">
                <CommonButton
                  label="add vehicle"
                  size={CommonButtonSize.LARGE}
                  handleClick={handleNavigate}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default FindBestVehicles;
