export const ALL = 'ALL';
export const RANGE_OF_YEARS = 'Range of years';

export const YEARS_RANGE_OPTIONS = [
  {
    label: ALL,
    value: ALL,
  },
  {
    label: RANGE_OF_YEARS,
    value: RANGE_OF_YEARS,
  },
];

export const getInitialValue = () => {
  return YEARS_RANGE_OPTIONS[0].value;
};

export const isAllOptionSelected = value => {
  return value === ALL;
};

export const mapRangeToSelect = yearsAgo => {
  if (!yearsAgo) return [];
  const currentYear = new Date().getFullYear();
  const options = [];
  yearsAgo.forEach(item => {
    options.push({
      value: item.toString(),
      label: currentYear - item,
    });
  });
  return options;
};

export const prepareYear = value => {
  if (!value) return null;
  return {
    value: calculateYearAgo(value)?.toString(),
    label: value?.toString(),
  };
};

export const calculateYearAgo = year => {
  if (!year) return null;
  const currentYear = new Date().getFullYear();
  return currentYear - year;
};
