export const BasicCarDataFields = {
  REGISTRATION_PLATE: 'registrationPlate',
  MAKE_SELECT: 'makeSelect',
  MODEL_SELECT: 'modelSelect',
  MILEAGE: 'mileage',
  YEAR: 'year',
  MOT: 'mot',
  DERIVATIVE: 'derivative',
  CAR_TYPE: 'carType',
  TRANSMISSION: 'transmission',
  ENGINE_SIZE: 'engineSize',
  FUEL: 'fuel',
  COLOUR: 'colour',
  DOORS: 'doors',
  DATE_OFF_FIRST_REGISTRATION: 'dateOfFirstRegistration',
  PREVIOUS_KEEPERS_IN_TOTAL: 'previousKeepersInTotal',
  VIN: 'vin',
  VEHICLE_CAP_ID: 'vehicleCapCapId',
};

export const BasicCarDataFieldsName = {
  REGISTRATION_PLATE: 'Registration number',
  MILEAGE: 'Mileage',
  YEAR: 'Year',
  MOT: 'MOT',
  CAR_TYPE: 'Body type',
  TRANSMISSION: 'Transmission',
  ENGINE_SIZE: 'Engine size',
  FUEL: 'Fuel',
  COLOUR: 'Colour',
  DOORS: 'Doors',
  DATE_OFF_FIRST_REGISTRATION: 'Date of registration',
  PREVIOUS_KEEPERS_IN_TOTAL: 'Previous owners',
  VIN: 'VIN/Chassis No.',
  LOCATION: 'Location',
};
