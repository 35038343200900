import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RouterPaths from 'constants/RouterPaths';
import FormInputField from '../../common/components/formFields/FormInputField';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import CommonButton from '../../common/components/CommonButton';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import FormCheckboxField from '../../common/components/formFields/FormCheckboxField';
import { validateFormField } from '../../common/helpers/validateFormField';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import { DealerPositionName } from '../../constants/DealerPosition';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { validateMainProfile } from '../RegisterActions';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const dealerRole = constantToSelect(DealerPositionName);

const AddProfileForm = ({ prevStep, nextStep, values }) => {
  const dispatch = useDispatch();
  const [isFieldEmpty, setFieldEmpty] = useState(true);
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const validateErrors = useSelector(state => state.register.get('validateErrors'));
  const checkIsFieldEmpty = () => {
    const {
      firstName,
      lastName,
      email,
      position,
      password,
      passwordRepeat,
      acceptTos,
    } = values;
    const valuesArray = [
      firstName,
      lastName,
      email,
      position,
      password,
      passwordRepeat,
      acceptTos,
    ];
    const isFieldEmpty = valuesArray.some(item => !item);
    return setFieldEmpty(isFieldEmpty);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkIsFieldEmpty(), [values]);
  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    positionError: '',
    passwordError: '',
    passwordRepeatError: '',
    acceptTosError: '',
  });
  const validateForm = () =>
    new Promise((resolve, reject) => {
      const {
        firstName,
        lastName,
        email,
        position,
        password,
        passwordRepeat,
        acceptTos,
      } = values;

      const errors = {
        firstNameError: validateFormField(firstName, FormFieldTypes.STRING, true, 50),
        lastNameError: validateFormField(lastName, FormFieldTypes.STRING, true, 50),
        emailError: validateFormField(email, FormFieldTypes.EMAIL, true, 50),
        positionError: validateFormField(position, FormFieldTypes.ENUM, true),
        passwordError: validateFormField(
          password,
          FormFieldTypes.PASSWORD,
          true,
          null,
          6,
          passwordRepeat,
        ),
        passwordRepeatError: validateFormField(
          passwordRepeat,
          FormFieldTypes.PASSWORD,
          true,
          null,
          6,
          password,
        ),
        acceptTosError: validateFormField(acceptTos, FormFieldTypes.BOOLEAN, true),
      };
      setErrors(errors);
      const {
        firstNameError,
        lastNameError,
        emailError,
        positionError,
        passwordError,
        passwordRepeatError,
        acceptTosError,
      } = errors;
      if (
        firstNameError ||
        lastNameError ||
        emailError ||
        positionError ||
        passwordError ||
        passwordRepeatError ||
        acceptTosError
      ) {
        reject();
      }
      resolve();
    });

  const moveToNextStep = () => {
    validateForm()
      .then(() => dispatch(validateMainProfile(values, () => nextStep())))
      .catch(() => {});
  };
  const {
    firstNameError,
    lastNameError,
    emailError,
    positionError,
    passwordError,
    passwordRepeatError,
    acceptTosError,
  } = errors;
  return (
    <Col md="8" className="justify-content-center mr-auto ml-auto">
      <Form autoComplete="off" className="text-left">
        <h3 className="text-uppercase font-weight-bold text-center">Add personal details</h3>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField name="firstName" label="First name" invalid={!!firstNameError} />
            <CommonErrorLabel value={firstNameError} />
          </Col>
          <Col md="6">
            <FormInputField name="lastName" label="Last name" invalid={!!lastNameError} />
            <CommonErrorLabel value={lastNameError} />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormSelectField name="position" label="Choose a position" options={dealerRole} />
            <CommonErrorLabel value={positionError} />
            <FormSelectField rowClassNames="form-select-field-hidden" />
          </Col>
          <Col md="6">
            <FormInputField
              name="email"
              label="E-mail address"
              invalid={!!emailError || validateErrors?.email}
            />
            <CommonErrorLabel value={emailError || validateErrors?.email} />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="6">
            <FormInputField
              type="password"
              name="password"
              label="Password"
              invalid={!!passwordError}
            />
            <CommonErrorLabel value={passwordError} />
          </Col>
          <Col md="6">
            <FormInputField
              type="password"
              name="passwordRepeat"
              label="Repeat password"
              invalid={!!passwordRepeatError}
            />
            <CommonErrorLabel value={passwordRepeatError} />
          </Col>
        </Row>
        <Row>
          <Col md="12" className="add-profile-details-checkbox-wrapper mt-4 mb-4">
            <FormCheckboxField
              label={
                <label>
                  I accept the{' '}
                  <a href={RouterPaths.TERMS_AND_CONDITIONS}>Terms and Conditions</a> and{' '}
                  <a href={RouterPaths.PRIVACY_POLICY}>Privacy Policy</a>
                </label>
              }
              name="acceptTos"
              id="acceptTos"
            />
            <CommonErrorLabel value={acceptTosError} />
            <FormCheckboxField
              label="Sign me for newsletter"
              name="acceptNewsletter"
              id="acceptNewsletter"
            />
          </Col>
        </Row>
        <Row className="justify-content-between mt-3">
          <Col className="d-flex">
            <CommonButton label="Back" handleClick={prevStep} variant="secondary" />
          </Col>
          <Col className="d-flex justify-content-end">
            <CommonButton
              label="Next"
              handleClick={moveToNextStep}
              variant={
                isLoading || isFieldEmpty
                  ? CommonButtonVariants.DISABLED
                  : CommonButtonVariants.PRIMARY
              }
              disabled={isLoading || isFieldEmpty}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

OrganizationDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default AddProfileForm;
