import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import CommonMyOrganizationContainer from '../../../common/components/CommonMyOrganizationContainer';
import SearchAlertForm from '../forms/SearchAlertForm';
import { createSearchAlert, validateSearchAlert } from '../SearchAlertActions';
import { useIsMounted } from '../utils/isMounted';
import validationSchema from '../forms/CreateSearchAlertForm.schema';
import { ALL_SELECTED } from '../utils/defaultValue';

const CreateSearchAlertsPage = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const copiedSearchAlert = useSelector(state => state.searchAlerts.get('copiedSearchAlert'));
  const initialValues = {
    make: ALL_SELECTED,
    model: ALL_SELECTED,
    allRangeOfYears: copiedSearchAlert?.get('allRangeOfYears'),
    yearMinValue: copiedSearchAlert?.get('yearMinValue')?.toJS(),
    yearMaxValue: copiedSearchAlert?.get('yearMaxValue')?.toJS(),
    mileageMaxValue: copiedSearchAlert?.get('mileageMaxValue')?.toJS(),
    distanceMaxValueInMiles: copiedSearchAlert?.get('distanceMaxValueInMiles')?.toJS(),
  };
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [values, setValues] = useState(null);
  const [formPayload, setFormPayload] = useState(null);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(validateSearchAlert(null, values, setErrors))
      .then(res => {
        if (res.code === 'NO_ACTION') {
          save(values, { setSubmitting, setErrors });
        } else {
          setValues(values);
          setFormPayload({ setSubmitting, setErrors });
          setTitle(res.title);
          setMessage(res.message);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setSubmitting(false);
        }
      });
  };

  const save = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(createSearchAlert(values, setErrors)).finally(() => {
      if (isMounted.current) {
        setSubmitting(false);
        setTitle('');
        setMessage('');
      }
    });
  };

  return (
    <CommonMyOrganizationContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {form => <SearchAlertForm isCreateForm values={form?.values} form={form} />}
      </Formik>
      <SweetAlert
        danger
        showCancel
        show={title !== ''}
        title={title}
        onConfirm={() => {
          save(values, formPayload);
        }}
        onCancel={() => {
          setTitle('');
          setMessage('');
          setValues(null);
          setFormPayload(null);
        }}
        confirmBtnBsStyle="info"
      >
        {message}
      </SweetAlert>
    </CommonMyOrganizationContainer>
  );
};

export default CreateSearchAlertsPage;
