import React from 'react';
import PropTypes from 'prop-types';
import RouterPaths from '../constants/RouterPaths';

const StripeDirectDebitWrapper = ({ title, text }) => {
  return (
    <div className="center">
      <h1 className="stripe-direct-debit-title">{title}</h1>
      <p className="stripe-direct-debit-text">{text}</p>
      {/* Tag <a> forces reload to default routes */}
      <a
        href={RouterPaths.MY_WALLET}
        className="common-button primary-button common-button--link"
      >
        Ok
      </a>
    </div>
  );
};

StripeDirectDebitWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default StripeDirectDebitWrapper;
